import { Addon } from "../mixins/Addon";
import { Templating } from "../mixins/Templating";
import { Schema } from "../mixins/Schema";
import { Statusing } from "../mixins/Statusing";
import { Scheduling } from "../mixins/Scheduling";
import { defineAsyncComponent } from "vue";
export default {
  components: {
    OutputDisplay: defineAsyncComponent(() => import("@/components/output/OutputDisplay.vue"))
  },
  provide() {
    return {
      overrideEntries: this.templateChildren,
      overrideStatuses: this.templateStatuses
    };
  },
  mixins: [Addon, Templating, Schema, Statusing, Scheduling],
  props: {
    modelValue: Object,
    showPreview: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      showChildren: true
    };
  },
  mounted() {
    if (this.$refs.entry_display) this.$refs.entry_display.renderOutput = true;
  },
  computed: {
    template: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit("update:modelValue", value);
      }
    },
    resolvedTemplate() {
      const id = this.$nanoid();
      return this.resolveData(this.template, {
        __ID__: id
      });
    },
    templateEntry() {
      return {
        ...this.entrySchema(this.resolvedTemplate.data),
        ...{
          temp: true,
          name: this.template.name
        }
      };
    },
    templateChildren() {
      return this.resolvedTemplate.entries.map(entry => {
        return {
          ...this.entrySchema(this.resolveData(entry)),
          ...{
            temp: true
          }
        };
      });
    },
    templateStatuses() {
      return this.resolvedTemplate.data.statuses;
    }
  },
  methods: {
    onMouseEnter() {
      // this.showChildren = true;
      // if (this.$refs.entry_display)
      //   this.$refs.entry_display.renderOutput = true;
    },
    onMouseLeave() {
      // this.showChildren = false;
      // if (this.$refs.entry_display)
      //   this.$refs.entry_display.renderOutput = false;
    }
  }
};