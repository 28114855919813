import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_TrashIcon = _resolveComponent("TrashIcon");
  const _component_ButtonComponentNew = _resolveComponent("ButtonComponentNew");
  return _openBlock(), _createBlock(_component_ButtonComponentNew, {
    onClick: _cache[1] || (_cache[1] = $event => _ctx.$emit('delete')),
    title: $props.title,
    class: _normalizeClass(_ctx.$style.labelButton),
    style: _normalizeStyle({
      '--color': $props.color ? `var(--ps-color-${$props.color}-500)` : 'var(--ps-base-primary-color)',
      '--bg-color': $props.color ? `var(--ps-color-${$props.color}-50)` : 'var(--ps-base-tinted-color)',
      '--color-hover': $props.color ? `var(--ps-color-${$props.color}-600)` : 'var(--ps-base-primary-color)',
      '--bg-color-hover': $props.color ? `var(--ps-color-${$props.color}-100)` : 'var(--ps-base-tinted-color)',
      '--dark-color': $props.color ? `var(--ps-color-${$props.color}-300)` : 'var(--ps-base-primary-color)',
      '--dark-bg-color': $props.color ? `var(--ps-color-${$props.color}-950)` : 'var(--ps-base-tinted-color)',
      '--dark-color-hover': $props.color ? `var(--ps-color-${$props.color}-200)` : 'var(--ps-base-primary-color)',
      '--dark-bg-color-hover': $props.color ? `var(--ps-color-${$props.color}-900)` : 'var(--ps-base-tinted-color)'
    })
  }, {
    default: _withCtx(() => [_renderSlot(_ctx.$slots, "default", {}, undefined, true), _createElementVNode("a", {
      onClick: _cache[0] || (_cache[0] = $event => _ctx.$emit('delete')),
      class: _normalizeClass([_ctx.$style.deleteButton, "absolute bottom-0 right-0 top-0 hidden w-6 items-center justify-center"])
    }, [_createVNode(_component_TrashIcon, {
      size: "16"
    })], 2)]),
    _: 3
  }, 8, ["title", "class", "style"]);
}