import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, renderSlot as _renderSlot, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_IntersectionItem = _resolveComponent("IntersectionItem");
  return _openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.items, (item, index) => {
    return _openBlock(), _createBlock(_component_IntersectionItem, {
      key: item.id,
      ref_for: true,
      ref: "item",
      "data-index": index,
      visible: $data.visibleItems[index] || $props.preserveItems.includes(item.id) || _ctx.$store.getters.dragged.map(e => e.id).includes(item.id) || _ctx.$store.getters.selected.includes(item.id),
      class: _normalizeClass([$props.itemClass])
    }, {
      default: _withCtx(() => [_renderSlot(_ctx.$slots, "item", {
        item: item,
        index: index
      })]),
      _: 2
    }, 1032, ["data-index", "visible", "class"]);
  }), 128);
}