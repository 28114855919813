export default {
  props: {
    variant: {
      type: String,
      default: "info"
    }
  },
  computed: {
    color() {
      switch (this.variant) {
        case "info":
          return "blue";
        case "success":
          return "green";
        case "warning":
          return "yellow";
        case "error":
          return "red";
        default:
          return "blue";
      }
    }
  }
};