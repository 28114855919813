import Placeholder from "@tiptap/extension-placeholder";
import StarterKit from "@tiptap/starter-kit";
import { Markdown } from "tiptap-markdown";
import { Link } from "@tiptap/extension-link";
import { Editor, EditorContent } from "@tiptap/vue-3";
Markdown.configure({
  html: true,
  // Allow HTML input/output
  tightLists: true,
  // No <p> inside <li> in markdown output
  tightListClass: "tight",
  // Add class to <ul> allowing you to remove <p> margins when tight
  bulletListMarker: "-",
  // <li> prefix in markdown output
  linkify: true,
  // Create links from "https://..." text
  breaks: true,
  // New lines (\n) in markdown input are converted to <br>
  transformPastedText: false,
  // Allow to paste markdown text in the editor
  transformCopiedText: false // Copied text is transformed to markdown
});
export default {
  components: {
    EditorContent
  },
  props: {
    modelValue: {
      type: String,
      default: ""
    },
    editable: {
      type: Boolean,
      default: true
    },
    placeholder: String
  },
  emits: ["update:modelValue"],
  data() {
    return {
      editor: null
    };
  },
  watch: {
    modelValue(value) {
      // // HTML
      // const isSame = this.editor.getHTML() === value;

      // // JSON
      // // const isSame = JSON.stringify(this.editor.getJSON()) === JSON.stringify(value)

      // if (isSame) {
      //   return;
      // }

      this.editor.commands.setContent(value);
    },
    editable(value) {
      this.editor.setEditable(value);
    }
  },
  mounted() {
    // console.log("Mounting TextEditor", this.modelValue);
    this.editor = new Editor({
      editable: this.editable,
      extensions: [StarterKit, Link, Markdown, Placeholder.configure({
        // Use a placeholder:
        placeholder: this.placeholder
        // Use different placeholders depending on the node type:
        // placeholder: ({ node }) => {
        //   if (node.type.name === 'heading') {
        //     return 'What’s the title?'
        //   }

        //   return 'Can you add some further context?'
        // },
      })],
      content: this.modelValue,
      placeholder: "Start typing...",
      onBlur: () => {
        // if (
        //   this.modelValue?.length &&
        //   this.editor.storage.markdown.getMarkdown() == this.modelValue
        // )
        //   return;
        // console.log(this.editor.get());
        // HTML
        this.$emit("update:modelValue", this.editor.getHTML());
        // this.$emit(
        //   "update:markdown",
        //   this.editor.storage.markdown.getMarkdown(),
        // );

        // JSON
        // this.$emit('update:modelValue', this.editor.getJSON())
      }
    });
  },
  beforeUnmount() {
    const content = this.$refs.editor.innerHTML;
    this.$refs.editor.innerHTML = content;
    if (this.editor) this.editor.destroy();
  }
};