import { Filtering } from "../mixins/Filtering";
import { computed } from "vue";
export default {
  provide() {
    return {
      overrideEntries: computed(() => this.entries.slice(0, 10))
    };
  },
  mixins: [Filtering],
  props: {
    filterSuggestions: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      showSuggestions: false
    };
  },
  computed: {
    entries() {
      // Get all entries from filters
      const allEntries = this.filterSuggestions.flatMap(filter => this.filterEntries(filter));

      // Count occurrences of each entry
      const entryCounts = allEntries.reduce((acc, entry) => {
        acc[entry.id] = {
          entry,
          count: (acc[entry.id]?.count || 0) + 1
        };
        return acc;
      }, {});

      // Convert to array, sort by count (descending), and return unique entries
      return Object.values(entryCounts).sort((a, b) => b.count - a.count).map(item => item.entry);
    }
  }
};