import { renderSlot as _renderSlot, unref as _unref, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue";
import { ref } from "vue";
import { useFloating, shift, flip, autoUpdate, offset } from "@floating-ui/vue";
const __default__ = {
  data() {
    return {
      navigator: navigator
    };
  }
};
export default /*@__PURE__*/Object.assign(__default__, {
  __name: 'TitleHelper',
  props: {
    placement: {
      type: String,
      default: "bottom-center"
    }
  },
  setup(__props) {
    const reference = ref(null);
    const floating = ref(null);
    const props = __props;
    const {
      floatingStyles
    } = useFloating(reference, floating, {
      placement: props.placement,
      whileElementsMounted: autoUpdate,
      middleware: [offset(2), flip(), shift({
        crossAxis: true
      })]
    });
    return (_ctx, _cache) => {
      return _openBlock(), _createElementBlock("div", {
        ref_key: "reference",
        ref: reference,
        class: "group/title"
      }, [_renderSlot(_ctx.$slots, "default"), _ctx.$slots.title && !_ctx.navigator.userAgent.includes('Mobile') ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        ref_key: "floating",
        ref: floating,
        style: _normalizeStyle(_unref(floatingStyles)),
        class: "pointer-events-none z-10 mx-auto flex w-max translate-y-full items-center gap-2 truncate rounded-md bg-white px-2 py-1 text-left text-xs text-neutral-700 opacity-0 drop-shadow-md group-hover/title:opacity-100 group-hover/title:transition-opacity group-hover/title:delay-300 dark:bg-black dark:text-neutral-300 dark:ring-1 dark:ring-neutral-700 dark:drop-shadow-none"
      }, [_renderSlot(_ctx.$slots, "title")], 4)) : _createCommentVNode("", true)], 512);
    };
  }
});