import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  class: "flex items-center gap-1"
};
const _hoisted_2 = ["data-procrastination-count"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_CalendarX2Icon = _resolveComponent("CalendarX2Icon");
  const _component_TitleHelper = _resolveComponent("TitleHelper");
  return _openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.$style.procrastinationDisplay)
  }, [_createVNode(_component_TitleHelper, {
    class: _normalizeClass([{
      '!text-xs': $props.position == 'calendar'
    }, "ps_procrastination_display flex max-w-fit cursor-default items-center rounded bg-red-50 px-1 py-0.5 text-xs text-red-500 dark:bg-red-950 dark:text-red-500"])
  }, {
    title: _withCtx(() => [_createTextVNode(" Procrastinated " + _toDisplayString($options.procrastination?.count) + " times ", 1)]),
    default: _withCtx(() => [_createElementVNode("div", _hoisted_1, [_createVNode(_component_CalendarX2Icon, {
      size: "16",
      strokeWidth: "1.5"
    }), _createElementVNode("span", {
      "data-procrastination-count": $options.procrastination?.count
    }, _toDisplayString($options.procrastination?.count), 9, _hoisted_2)])]),
    _: 1
  }, 8, ["class"])], 2);
}