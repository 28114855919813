export default {
  props: {
    output: Object,
    entry: Object,
    settings: Object,
    parent: Object,
    linkedEntries: Array,
    computedDisplay: Object,
    position: String,
    outputFilter: Function
  }
};