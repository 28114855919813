import TimePicker from "@/components/forms/partials/TimePicker";
import DurationPicker from "@/components/forms/partials/DurationPicker";
import DurationTextPicker from "@/components/forms/partials/DurationTextPicker";
import { MinusIcon } from "lucide-vue-next";
import { Scheduling } from "@/components/mixins/Scheduling";
export default {
  components: {
    TimePicker,
    DurationPicker,
    DurationTextPicker,
    MinusIcon
  },
  mixins: [Scheduling],
  props: {
    modelValue: Object,
    entry: Object
  },
  computed: {
    schedule: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit("update:modelValue", value);
      }
    },
    time: {
      get() {
        return this.schedule.time;
      },
      set(value) {
        this.schedule.time = value;
      }
    },
    duration: {
      get() {
        return this.schedule.duration;
      },
      set(value) {
        this.schedule.duration = value;
      }
    }
  },
  data() {
    return {
      internalTime: null,
      internalDuration: null
    };
  },
  created() {
    this.internalTime = this.time;
    this.internalDuration = this.duration;
  },
  mounted() {
    // if (!this.internalTime) {
    //   this.$refs.time_picker.$el.nextSibling.querySelector("input").focus();
    // }
  },
  beforeUnmount() {
    if (this.internalTime == this.time && this.internalDuration == this.duration) {
      return;
    }
    this.schedule.time = this.internalTime;
    this.schedule.duration = this.internalDuration;
    this.$emit("update:modelValue", this.schedule);
  }
};