import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue";
const _hoisted_1 = {
  class: "flex items-center"
};
const _hoisted_2 = {
  class: "ml-3 block truncate"
};
const _hoisted_3 = {
  key: 0,
  class: "ml-1 block truncate text-xs text-neutral-500"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_StatusIndicator = _resolveComponent("StatusIndicator");
  const _component_DotIcon = _resolveComponent("DotIcon");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_StatusIndicator, {
    status: $options.status
  }, null, 8, ["status"]), _createElementVNode("span", _hoisted_2, _toDisplayString($options.status?.name || "No status"), 1), $options.status && $options.entry ? (_openBlock(), _createElementBlock("span", _hoisted_3, [_createVNode(_component_DotIcon, {
    size: "12",
    class: "inline"
  }), _createTextVNode(" " + _toDisplayString($options.entry?.name), 1)])) : _createCommentVNode("", true)]);
}