import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue";
const _hoisted_1 = {
  key: 0
};
const _hoisted_2 = {
  key: 0
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_TextIcon = _resolveComponent("TextIcon");
  const _component_ButtonComponent = _resolveComponent("ButtonComponent");
  const _component_XIcon = _resolveComponent("XIcon");
  return _openBlock(), _createElementBlock("div", null, [!_ctx.entry.description ? (_openBlock(), _createElementBlock("button", {
    key: 0,
    role: "button",
    onClick: _cache[0] || (_cache[0] = () => {
      $options.createDescription();
      $options.focusEditor();
    })
  }, [_renderSlot(_ctx.$slots, "button", {}, () => [_createVNode(_component_ButtonComponent, {
    variant: _ctx.variant,
    size: _ctx.size,
    color: _ctx.color,
    colorWeight: _ctx.colorWeight,
    title: "Add Description"
  }, {
    default: _withCtx(() => [_createVNode(_component_TextIcon, {
      size: "20"
    }), _ctx.variant != 'round' ? (_openBlock(), _createElementBlock("span", _hoisted_1, " Description ")) : _createCommentVNode("", true)]),
    _: 1
  }, 8, ["variant", "size", "color", "colorWeight"])], true)])) : (_openBlock(), _createElementBlock("button", {
    key: 1,
    role: "button",
    onClick: _cache[2] || (_cache[2] = $event => $options.description = null)
  }, [_renderSlot(_ctx.$slots, "button", {}, () => [_createVNode(_component_ButtonComponent, {
    onClick: _cache[1] || (_cache[1] = $event => $options.description = null),
    variant: _ctx.variant,
    size: _ctx.size,
    title: "Remove Description"
  }, {
    default: _withCtx(() => [_createVNode(_component_TextIcon, {
      size: "20"
    }), _createVNode(_component_ButtonComponent, {
      variant: _ctx.variant,
      size: "xs",
      color: "red",
      class: "!absolute right-0 top-0 -translate-y-1/3 translate-x-1/3 hover:bg-red-600"
    }, {
      default: _withCtx(() => [_createVNode(_component_XIcon, {
        size: "12"
      })]),
      _: 1
    }, 8, ["variant"]), _ctx.variant != 'round' ? (_openBlock(), _createElementBlock("span", _hoisted_2, "Remove Description")) : _createCommentVNode("", true)]),
    _: 1
  }, 8, ["variant", "size"])], true)]))]);
}