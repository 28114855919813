import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = ["title"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_ButtonComponent = _resolveComponent("ButtonComponent");
  const _component_TrashIcon = _resolveComponent("TrashIcon");
  return $data.entry.deleted_at ? (_openBlock(), _createBlock(_component_ButtonComponent, {
    key: 0,
    onClick: $options.undeleteEntry
  }, {
    default: _withCtx(() => _cache[1] || (_cache[1] = [_createTextVNode("undelete")])),
    _: 1
  }, 8, ["onClick"])) : (_openBlock(), _createElementBlock("button", {
    key: 1,
    onClick: _cache[0] || (_cache[0] = (...args) => $options.deleteEntry && $options.deleteEntry(...args)),
    title: 'Delete "' + $data.entry.name + '"',
    class: "self-start"
  }, [_createVNode(_component_TrashIcon, {
    class: "inline h-5 w-5"
  }), _cache[2] || (_cache[2] = _createTextVNode()), _cache[3] || (_cache[3] = _createElementVNode("span", null, " Delete ", -1))], 8, _hoisted_1));
}