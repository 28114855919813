import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, createElementVNode as _createElementVNode, renderSlot as _renderSlot, createTextVNode as _createTextVNode, createVNode as _createVNode, withCtx as _withCtx, unref as _unref, renderList as _renderList, Fragment as _Fragment, normalizeStyle as _normalizeStyle, Transition as _Transition, Teleport as _Teleport, resolveDynamicComponent as _resolveDynamicComponent } from "vue";
const _hoisted_1 = {
  class: "flex flex-1 cursor-default flex-wrap items-center gap-2"
};
const _hoisted_2 = ["onClick"];
const _hoisted_3 = {
  class: "flex flex-col gap-5"
};
const _hoisted_4 = {
  key: 0
};
import { ref } from "vue";
import { useFloating, shift, flip } from "@floating-ui/vue";
const __default__ = {
  props: {
    modelValue: Object,
    entry: Object,
    group: Object,
    groupEntries: Array,
    editable: {
      type: Boolean,
      default: true
    },
    disabled: Boolean,
    renderType: {
      type: String,
      default: "list"
    }
  },
  data() {
    return {
      showConfig: false,
      showModal: false
    };
  },
  computed: {
    // entry: {
    //   get() {
    //     return this.modelValue;
    //   },
    //   // set(value) {
    //   //   this.$emit("update:modelValue", value);
    //   // },
    // },
    output: {
      get() {
        return this.modelValue;
      },
      set(output) {
        this.$emit("update:modelValue", output);
      }
    }
  }
};
export default /*@__PURE__*/Object.assign(__default__, {
  __name: 'OutputGroupHeader',
  setup(__props) {
    const reference = ref(null);
    const floating = ref(null);
    const {
      floatingStyles
    } = useFloating(reference, floating, {
      placement: "bottom-start",
      middleware: [flip(), shift({
        crossAxis: true
      })]
    });
    return (_ctx, _cache) => {
      const _component_TourHelper = _resolveComponent("TourHelper");
      const _component_EllipsisVerticalIcon = _resolveComponent("EllipsisVerticalIcon");
      const _component_MenuButton = _resolveComponent("MenuButton");
      const _component_OverlayComponent = _resolveComponent("OverlayComponent");
      const _component_MenuItem = _resolveComponent("MenuItem");
      const _component_MenuItems = _resolveComponent("MenuItems");
      const _component_Menu = _resolveComponent("Menu");
      const _component_ViewIcon = _resolveComponent("ViewIcon");
      const _component_SectionHeader = _resolveComponent("SectionHeader");
      const _component_OutputTypePicker = _resolveComponent("OutputTypePicker");
      const _component_FilterIcon = _resolveComponent("FilterIcon");
      const _component_OutputFilterBuilder = _resolveComponent("OutputFilterBuilder");
      const _component_LayersIcon = _resolveComponent("LayersIcon");
      const _component_ArrowDownAZIcon = _resolveComponent("ArrowDownAZIcon");
      const _component_ArrowUpAZIcon = _resolveComponent("ArrowUpAZIcon");
      const _component_OutputGroupingPicker = _resolveComponent("OutputGroupingPicker");
      const _component_ChevronsUpDownIcon = _resolveComponent("ChevronsUpDownIcon");
      const _component_OutputSortingBuilder = _resolveComponent("OutputSortingBuilder");
      const _component_ModalHelper = _resolveComponent("ModalHelper");
      return _openBlock(), _createElementBlock("div", {
        class: _normalizeClass([_ctx.$style.outputGroupHeader, "ps_output_group_header"]),
        "data-tour": "output_group_header"
      }, [_createElementVNode("div", _hoisted_1, [__props.group.name ? (_openBlock(), _createElementBlock("h2", {
        key: 0,
        class: _normalizeClass(_ctx.$style.groupName)
      }, _toDisplayString(__props.group.name), 3)) : _createCommentVNode("", true), __props.entry.id == _ctx.$route.params.id ? (_openBlock(), _createBlock(_component_TourHelper, {
        key: 1,
        hook: "output_settings",
        size: "sm",
        variant: "minimal",
        class: "h-5"
      })) : _createCommentVNode("", true)]), _renderSlot(_ctx.$slots, "default"), __props.groupEntries ? (_openBlock(), _createElementBlock("a", {
        key: 0,
        onClick: _cache[0] || (_cache[0] = $event => __props.groupEntries.forEach(e => _ctx.$store.dispatch('selected', e))),
        class: _normalizeClass([[_ctx.$style.interactableElements, _ctx.$style.entriesCount], "cursor-pointer text-xs"])
      }, [_createTextVNode(_toDisplayString(__props.groupEntries?.length) + " ", 1), _createElementVNode("span", null, _toDisplayString(__props.groupEntries?.length != 1 ? "Entries" : "Entry"), 1)], 2)) : _createCommentVNode("", true), !__props.disabled ? (_openBlock(), _createBlock(_component_Menu, {
        key: 1,
        as: "div"
      }, {
        default: _withCtx(({
          open
        }) => [_createElementVNode("div", null, [_createVNode(_component_MenuButton, {
          ref_key: "reference",
          ref: reference,
          class: "flex items-start rounded-full bg-gray-100 align-text-top text-gray-400 hover:text-gray-600 focus:outline-none focus:ring-2 focus:ring-neutral-500 focus:ring-offset-2 focus:ring-offset-gray-100 focus-visible:outline-none dark:bg-neutral-900 dark:focus:ring-offset-neutral-900"
        }, {
          default: _withCtx(() => [_cache[14] || (_cache[14] = _createElementVNode("span", {
            class: "sr-only"
          }, "Open options", -1)), _createVNode(_component_EllipsisVerticalIcon, {
            class: "h-5 w-5",
            "aria-hidden": "true"
          })]),
          _: 1
        }, 512)]), (_openBlock(), _createBlock(_Teleport, {
          to: "body"
        }, [open ? (_openBlock(), _createBlock(_component_OverlayComponent, {
          key: 0,
          onClick: $event => open = false
        }, null, 8, ["onClick"])) : _createCommentVNode("", true), _createVNode(_Transition, {
          "enter-active-class": "transition ease-spring duration-100",
          "enter-from-class": "transform opacity-0 translate-y-1",
          "enter-to-class": "transform opacity-100 translate-y-0",
          "leave-active-class": "transition ease-spring duration-100",
          "leave-from-class": "transform opacity-100 translate-y-0",
          "leave-to-class": "transform opacity-0 translate-y-1"
        }, {
          default: _withCtx(() => [_createVNode(_component_MenuItems, {
            class: "absolute right-0 z-[999] mt-2"
          }, {
            default: _withCtx(() => [_createElementVNode("div", {
              ref_key: "floating",
              ref: floating,
              style: _normalizeStyle(_unref(floatingStyles)),
              class: "w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none dark:bg-black dark:ring-neutral-700"
            }, [_createVNode(_component_MenuItem, null, {
              default: _withCtx(({
                active
              }) => [_createElementVNode("a", {
                onClick: _cache[1] || (_cache[1] = $event => __props.groupEntries.forEach(e => _ctx.$store.dispatch('selected', e))),
                class: _normalizeClass([active ? 'bg-gray-100 text-gray-900 dark:bg-neutral-900 dark:text-neutral-300' : 'text-gray-700 dark:text-neutral-400', 'block cursor-pointer px-4 py-2 text-sm'])
              }, " Select all ", 2)]),
              _: 1
            }), __props.editable ? (_openBlock(), _createBlock(_component_MenuItem, {
              key: 0
            }, {
              default: _withCtx(({
                active
              }) => [_createElementVNode("a", {
                onClick: _cache[2] || (_cache[2] = $event => _ctx.showModal = true),
                class: _normalizeClass([active ? 'bg-gray-100 text-gray-900 dark:bg-neutral-900 dark:text-neutral-300' : 'text-gray-700 dark:text-neutral-400', 'block  cursor-pointer px-4 py-2 text-sm'])
              }, " Edit Output ", 2)]),
              _: 1
            })) : _createCommentVNode("", true), __props.editable ? (_openBlock(), _createBlock(_component_MenuItem, {
              key: 1
            }, {
              default: _withCtx(({
                active
              }) => [_createElementVNode("a", {
                onClick: _cache[3] || (_cache[3] = $event => _ctx.output = null),
                class: _normalizeClass([active ? 'bg-gray-100 text-gray-900 dark:bg-neutral-900 dark:text-neutral-300' : 'text-gray-700 dark:text-neutral-400', 'block cursor-pointer px-4 py-2 text-sm'])
              }, " Delete Output ", 2)]),
              _: 1
            })) : _createCommentVNode("", true), (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.group.actions, (action, index) => {
              return _openBlock(), _createBlock(_component_MenuItem, {
                key: index
              }, {
                default: _withCtx(({
                  active
                }) => [_createElementVNode("a", {
                  onClick: $event => action.fn(__props.groupEntries),
                  class: _normalizeClass([active ? 'bg-gray-100 text-gray-900 dark:bg-neutral-900 dark:text-neutral-300' : 'text-gray-700 dark:text-neutral-400', 'block cursor-pointer px-4 py-2 text-sm'])
                }, _toDisplayString(action.name), 11, _hoisted_2)]),
                _: 2
              }, 1024);
            }), 128))], 4)]),
            _: 1
          })]),
          _: 1
        })]))]),
        _: 1
      })) : _createCommentVNode("", true), _createVNode(_component_ModalHelper, {
        show: _ctx.showModal,
        onClose: _cache[13] || (_cache[13] = $event => _ctx.showModal = false)
      }, {
        title: _withCtx(() => _cache[15] || (_cache[15] = [_createTextVNode(" Edit Output ")])),
        default: _withCtx(() => [_createElementVNode("div", _hoisted_3, [_createVNode(_component_SectionHeader, {
          url: "https://help.pensive.io/outputs"
        }, {
          title: _withCtx(() => [_createVNode(_component_ViewIcon, {
            size: "16"
          }), _cache[16] || (_cache[16] = _createTextVNode(" Type "))]),
          _: 1
        }), _createVNode(_component_OutputTypePicker, {
          modelValue: _ctx.output.type,
          "onUpdate:modelValue": [_cache[4] || (_cache[4] = $event => _ctx.output.type = $event), _cache[5] || (_cache[5] = type => {
            _ctx.output = {
              ..._ctx.output,
              ...{
                type: type
              }
            };
            if (type == 'table') {
              _ctx.output = {
                ..._ctx.output,
                ...{
                  settings: {
                    table: {
                      columns: [{
                        field: 'name',
                        width: 120
                      }, {
                        field: 'status',
                        width: 80
                      }, {
                        field: 'description',
                        width: 150
                      }]
                    }
                  }
                }
              };
            }
          })],
          class: _normalizeClass(_ctx.$style.typePicker)
        }, {
          default: _withCtx(({
            option,
            active
          }) => [_createElementVNode("div", {
            class: _normalizeClass({
              [_ctx.$style.typePickerButton]: true,
              [_ctx.$style.typePickerButton_Active]: active
            })
          }, [_createElementVNode("div", null, [(_openBlock(), _createBlock(_resolveDynamicComponent(option.icon), {
            size: "26",
            "stroke-width": "1.5"
          }))]), _createElementVNode("div", null, _toDisplayString(option.label), 1)], 2)]),
          _: 1
        }, 8, ["modelValue", "class"]), _createVNode(_component_SectionHeader, {
          url: "https://help.pensive.io/outputs"
        }, {
          title: _withCtx(() => [_createVNode(_component_FilterIcon, {
            size: "16"
          }), _cache[17] || (_cache[17] = _createTextVNode(" Filters "))]),
          _: 1
        }), _createVNode(_component_OutputFilterBuilder, {
          id: "output_builder_inner",
          modelValue: _ctx.output.filters,
          "onUpdate:modelValue": [_cache[6] || (_cache[6] = $event => _ctx.output.filters = $event), _cache[8] || (_cache[8] = filters => {
            // showModal = false;
            _ctx.output = {
              ..._ctx.output,
              ...{
                filters: filters
              }
            };
          })],
          entry: __props.entry,
          onOnChange: _cache[7] || (_cache[7] = data => {
            _ctx.temporaryFilters = data;
          })
        }, null, 8, ["modelValue", "entry"]), _createVNode(_component_SectionHeader, {
          url: "https://help.pensive.io/outputs"
        }, {
          title: _withCtx(() => [_createVNode(_component_LayersIcon, {
            size: "16"
          }), _cache[18] || (_cache[18] = _createTextVNode(" Grouping "))]),
          _: 1
        }), _createVNode(_component_OutputGroupingPicker, {
          modelValue: _ctx.output.grouping,
          "onUpdate:modelValue": [_cache[9] || (_cache[9] = $event => _ctx.output.grouping = $event), _cache[10] || (_cache[10] = grouping => {
            _ctx.output = {
              ..._ctx.output,
              ...{
                grouping: grouping
              }
            };
          })],
          class: _normalizeClass(_ctx.$style.groupingPicker)
        }, {
          default: _withCtx(({
            option,
            active,
            direction
          }) => [_createElementVNode("div", {
            class: _normalizeClass({
              [_ctx.$style.groupingPickerButton]: true,
              [_ctx.$style.groupingPickerButton_Active]: active
            })
          }, [_createElementVNode("div", null, [(_openBlock(), _createBlock(_resolveDynamicComponent(option.icon), {
            size: "26",
            "stroke-width": "1.5"
          }))]), _createElementVNode("div", null, [_createElementVNode("span", null, _toDisplayString(option.label), 1)]), direction ? (_openBlock(), _createElementBlock("div", _hoisted_4, [_createElementVNode("span", null, [_cache[19] || (_cache[19] = _createTextVNode(" Direction: ")), direction == 1 ? (_openBlock(), _createBlock(_component_ArrowDownAZIcon, {
            key: 0,
            size: "16",
            class: "inline"
          })) : (_openBlock(), _createBlock(_component_ArrowUpAZIcon, {
            key: 1,
            size: "16",
            class: "inline"
          }))])])) : _createCommentVNode("", true)], 2)]),
          _: 1
        }, 8, ["modelValue", "class"]), _createVNode(_component_SectionHeader, {
          url: "https://help.pensive.io/outputs"
        }, {
          title: _withCtx(() => [_createVNode(_component_ChevronsUpDownIcon, {
            size: "16",
            strokeWidth: "1.5"
          }), _cache[20] || (_cache[20] = _createTextVNode(" Sorting "))]),
          _: 1
        }), _createVNode(_component_OutputSortingBuilder, {
          modelValue: _ctx.output.sorting,
          "onUpdate:modelValue": [_cache[11] || (_cache[11] = $event => _ctx.output.sorting = $event), _cache[12] || (_cache[12] = sorting => {
            _ctx.output = {
              ..._ctx.output,
              ...{
                sorting: sorting,
                custom_sorting: []
              }
            };
          })],
          entry: __props.entry
        }, null, 8, ["modelValue", "entry"])])]),
        _: 1
      }, 8, ["show"])], 2);
    };
  }
});