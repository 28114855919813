export default {
  props: {
    action: {
      type: String,
      default() {
        return "&times;";
      }
    },
    color: {
      type: String,
      default() {
        return "neutral";
      }
    },
    title: String,
    variant: {
      type: String,
      default: "default"
    },
    size: {
      type: String,
      default: "md"
    }
  }
};