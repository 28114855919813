import { createTextVNode as _createTextVNode, renderSlot as _renderSlot, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, normalizeStyle as _normalizeStyle, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, createSlots as _createSlots } from "vue";
const _hoisted_1 = {
  class: "flex items-center"
};
const _hoisted_2 = {
  key: 2,
  class: "ml-3 block truncate capitalize"
};
const _hoisted_3 = {
  key: 0,
  class: "pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2"
};
const _hoisted_4 = {
  class: "flex items-center"
};
const _hoisted_5 = {
  class: "flex items-center"
};
import { Addon } from "../mixins/Addon";
const __default__ = {
  mixins: [Addon],
  props: {
    label: Boolean
  },
  computed: {
    entryColor: {
      get() {
        return this.modelValue;
      },
      set(color) {
        // eslint-disable-next-line vue/no-mutating-props
        this.entry.color = color;
        this.$store.dispatch("push", {
          event: "entry_update",
          params: {
            entry: this.entry
          },
          entry: this.entry
        });
      }
    }
  }
};
export default /*@__PURE__*/Object.assign(__default__, {
  __name: 'ColorSettings',
  setup(__props) {
    const colors = ["gray", "red", "orange", "amber", "yellow", "lime", "green", "emerald", "teal", "cyan", "sky", "blue", "indigo", "violet", "purple", "fuchsia", "pink", "rose"];
    return (_ctx, _cache) => {
      const _component_SwatchBookIcon = _resolveComponent("SwatchBookIcon");
      const _component_ChevronsUpDownIcon = _resolveComponent("ChevronsUpDownIcon");
      const _component_ButtonComponent = _resolveComponent("ButtonComponent");
      const _component_ListboxOption = _resolveComponent("ListboxOption");
      const _component_CheckIcon = _resolveComponent("CheckIcon");
      const _component_ListboxHelper = _resolveComponent("ListboxHelper");
      return _openBlock(), _createElementBlock("div", null, [_createVNode(_component_ListboxHelper, {
        modelValue: _ctx.entryColor,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => _ctx.entryColor = $event)
      }, _createSlots({
        button: _withCtx(() => [_renderSlot(_ctx.$slots, "button", {}, () => [_createVNode(_component_ButtonComponent, {
          variant: _ctx.variant,
          size: _ctx.size,
          color: _ctx.color,
          colorWeight: _ctx.colorWeight,
          title: "Set Color",
          class: _normalizeClass([{
            'pr-8': _ctx.variant != 'round'
          }, "relative"]),
          style: _normalizeStyle({
            '--ps-color-settings-bg-color': _ctx.$colors[_ctx.entryColor || 'neutral'][500]
          })
        }, {
          default: _withCtx(() => [_createElementVNode("span", _hoisted_1, [_ctx.variant == 'round' ? (_openBlock(), _createBlock(_component_SwatchBookIcon, {
            key: 0,
            size: "20"
          })) : _createCommentVNode("", true), _ctx.variant != 'round' ? (_openBlock(), _createElementBlock("span", {
            key: 1,
            class: _normalizeClass(_ctx.$style.colorDot),
            "aria-hidden": "true"
          }, null, 2)) : _createCommentVNode("", true), _ctx.variant != 'round' ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(_ctx.entryColor || "No Color"), 1)) : _createCommentVNode("", true)]), _ctx.variant != 'round' ? (_openBlock(), _createElementBlock("span", _hoisted_3, [_createVNode(_component_ChevronsUpDownIcon, {
            size: "20",
            class: "text-gray-400",
            "aria-hidden": "true"
          })])) : _createCommentVNode("", true)]),
          _: 1
        }, 8, ["variant", "size", "color", "colorWeight", "class", "style"])])]),
        default: _withCtx(() => [_ctx.entryColor ? (_openBlock(), _createBlock(_component_ListboxOption, {
          key: 0,
          as: "template",
          value: null
        }, {
          default: _withCtx(({
            active,
            selected
          }) => [_createElementVNode("li", {
            class: _normalizeClass([active ? 'bg-neutral-600 text-white dark:bg-neutral-800 dark:text-neutral-300' : 'text-neutral-900 dark:text-neutral-400', 'relative cursor-default select-none py-2 pl-3 pr-9'])
          }, [_createElementVNode("div", _hoisted_4, [_cache[2] || (_cache[2] = _createElementVNode("span", {
            class: _normalizeClass(['bg-neutral-400', 'inline-block h-2 w-2 flex-shrink-0 rounded-full']),
            "aria-hidden": "true"
          }, null, -1)), _createElementVNode("span", {
            class: _normalizeClass([selected ? 'font-semibold' : 'font-normal', 'ml-3 block truncate capitalize'])
          }, " Clear ", 2)])], 2)]),
          _: 1
        })) : _createCommentVNode("", true), (_openBlock(), _createElementBlock(_Fragment, null, _renderList(colors, color => {
          return _createVNode(_component_ListboxOption, {
            as: "template",
            key: color,
            value: color
          }, {
            default: _withCtx(({
              active,
              selected
            }) => [_createElementVNode("li", {
              class: _normalizeClass([active ? 'bg-neutral-600 text-white dark:bg-neutral-800 dark:text-neutral-300' : 'text-neutral-900 dark:text-neutral-400', 'relative cursor-default select-none py-2 pl-3 pr-9']),
              style: _normalizeStyle({
                '--ps-color-settings-bg-color': _ctx.$colors[color][500]
              })
            }, [_createElementVNode("div", _hoisted_5, [_createElementVNode("span", {
              class: _normalizeClass(_ctx.$style.colorDot),
              "aria-hidden": "true"
            }, null, 2), _createElementVNode("span", {
              class: _normalizeClass([selected ? 'font-semibold' : 'font-normal', 'ml-3 block truncate capitalize'])
            }, _toDisplayString(color), 3)]), selected ? (_openBlock(), _createElementBlock("span", {
              key: 0,
              class: _normalizeClass([active ? 'bg-neutral-600 text-white dark:bg-neutral-800 dark:text-neutral-300' : 'text-neutral-900 dark:text-neutral-400', 'absolute inset-y-0 right-0 flex items-center pr-4'])
            }, [_createVNode(_component_CheckIcon, {
              class: "h-5 w-5",
              "aria-hidden": "true"
            })], 2)) : _createCommentVNode("", true)], 6)]),
            _: 2
          }, 1032, ["value"]);
        }), 64))]),
        _: 2
      }, [__props.label ? {
        name: "label",
        fn: _withCtx(() => [_cache[1] || (_cache[1] = _createTextVNode(" Color "))]),
        key: "0"
      } : undefined]), 1032, ["modelValue"])]);
    };
  }
});