import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, unref as _unref, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle } from "vue";
const _hoisted_1 = {
  key: 0
};
const _hoisted_2 = {
  role: "button"
};
const _hoisted_3 = {
  class: "whitespace-nowrap"
};
const _hoisted_4 = {
  class: "capitalize"
};
const _hoisted_5 = {
  class: "flex min-w-48 flex-col items-stretch gap-y-2 p-2"
};
const _hoisted_6 = ["onClick"];
const _hoisted_7 = {
  key: 0
};
const _hoisted_8 = {
  key: 1
};
const _hoisted_9 = {
  key: 0
};
import { addonBlueprint } from "@/addonBlueprint";
import moment from "moment";
import { Coloring } from "../mixins/Coloring";
import { getDateSpan } from "@/datesHelper";
import { Routining } from "../mixins/Routining";
import { Addon } from "../mixins/Addon";
const __default__ = {
  data() {
    return {
      open: false,
      getDateSpan: getDateSpan,
      options: [{
        title: "Daily",
        value: "daily"
      }, {
        title: "Weekly",
        value: "weekly"
      }, {
        title: "Monthly",
        value: "monthly"
      }]
    };
  },
  mixins: [Coloring, Routining, Addon],
  computed: {
    routine: {
      get: function () {
        return this.modelValue;
      },
      set(routine) {
        // eslint-disable-next-line vue/no-mutating-props
        this.entry.routine = routine;
        this.$store.dispatch("push", {
          event: "entry_update",
          params: {
            entry: this.entry
          },
          entry: this.entry
        });
      }
    },
    target: {
      get() {
        return [this.routine.target];
      },
      set(val) {
        this.routine = {
          ...this.routine,
          ...{
            target: val[0]
          }
        };
      }
    },
    slots() {
      switch (this.routine.frequency) {
        case "daily":
          return Array.from({
            length: 7
          }, (_, i) => {
            const span_at = getDateSpan(this.routine.frequency, moment().add(-i, "days"));
            return {
              date: moment().add(-i, "days").format("YYYY-MM-DD"),
              log: this.getLogBySpan({
                entry: this.entry,
                span_at
              }),
              fn: () => this.logASpan({
                entry: this.entry,
                span_at: span_at,
                frequency: this.routine.frequency
              })
            };
          }).reverse();
        case "weekly":
          return Array.from({
            length: 4
          }, (_, i) => {
            const span_at = getDateSpan(this.routine.frequency, moment().add(-1, "day").add(-i, "week"));
            return {
              date: moment().add(-i, "weeks").startOf("week").format("YYYY-MM-DD") + " - " + moment().add(-i, "weeks").endOf("week").format("YYYY-MM-DD"),
              log: this.getLogBySpan({
                entry: this.entry,
                span_at
              }),
              fn: () => this.logASpan({
                entry: this.entry,
                span_at: span_at,
                frequency: this.routine.frequency
              })
            };
          }).reverse();
        case "monthly":
          return Array.from({
            length: 3
          }, (_, i) => {
            const span_at = getDateSpan(this.routine.frequency, moment().add(-i, "month"));
            return {
              date: moment().add(-i, "month").startOf("month").format("YYYY-MM-DD") + " - " + moment().add(-i, "month").endOf("month").format("YYYY-MM-DD"),
              log: this.getLogBySpan({
                entry: this.entry,
                span_at
              }),
              fn: () => this.logASpan({
                entry: this.entry,
                span_at: span_at,
                frequency: this.routine.frequency
              })
            };
          }).reverse();
      }
      return [];
    }
  }
};
export default /*@__PURE__*/Object.assign(__default__, {
  __name: 'RoutineSettings',
  setup(__props) {
    return (_ctx, _cache) => {
      const _component_RepeatIcon = _resolveComponent("RepeatIcon");
      const _component_ButtonComponent = _resolveComponent("ButtonComponent");
      const _component_SectionHeader = _resolveComponent("SectionHeader");
      const _component_CalendarComponent = _resolveComponent("CalendarComponent");
      const _component_LabelHelper = _resolveComponent("LabelHelper");
      const _component_SelectMenu = _resolveComponent("SelectMenu");
      const _component_TextPicker = _resolveComponent("TextPicker");
      const _component_ColorPicker = _resolveComponent("ColorPicker");
      const _component_ToggleComponent = _resolveComponent("ToggleComponent");
      const _component_TrashIcon = _resolveComponent("TrashIcon");
      const _component_ButtonComponentNew = _resolveComponent("ButtonComponentNew");
      const _component_PopoverHelper = _resolveComponent("PopoverHelper");
      return _ctx.routine ? (_openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_PopoverHelper, {
        teleport: true
      }, {
        button: _withCtx(() => [_createElementVNode("button", _hoisted_2, [_renderSlot(_ctx.$slots, "button_routine_configure", {
          routine: _ctx.routine
        }, () => [_createVNode(_component_ButtonComponent, {
          title: "Configure Routine",
          size: _ctx.size,
          variant: _ctx.variant,
          "data-table-column-target": ""
        }, {
          default: _withCtx(() => [_createVNode(_component_RepeatIcon, {
            size: "16"
          }), _createElementVNode("span", _hoisted_3, [_createTextVNode(_toDisplayString(_ctx.routine.target) + "x ", 1), _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.routine.frequency), 1)])]),
          _: 1
        }, 8, ["size", "variant"])])])]),
        default: _withCtx(() => [_createElementVNode("div", _hoisted_5, [_createVNode(_component_SectionHeader, {
          url: "https://help.pensive.io/routines"
        }, {
          title: _withCtx(() => [_createVNode(_component_RepeatIcon, {
            size: "16"
          }), _cache[9] || (_cache[9] = _createTextVNode(" Routine "))]),
          _: 1
        }), _createVNode(_component_CalendarComponent, {
          mode: _ctx.routine.frequency == 'weekly' ? 'weeks' : _ctx.routine.frequency == 'monthly' ? 'months' : 'days',
          style: _normalizeStyle({
            '--ps-routine-display-positive-color': _ctx.$colors[_ctx.routine.settings?.colors?.positive || 'neutral'][500],
            '--ps-routine-display-positive-color-hover': _ctx.$colors[_ctx.routine.settings?.colors?.positive || 'neutral'][600],
            '--ps-routine-display-negative-color': _ctx.$colors[_ctx.routine.settings?.colors?.negative || 'neutral'][500],
            '--ps-routine-display-negative-color-hover': _ctx.$colors[_ctx.routine.settings?.colors?.negative || 'neutral'][600]
          })
        }, {
          default: _withCtx(({
            date,
            span
          } = _ctx.slotProps) => [_createElementVNode("button", {
            onClick: () => {
              if (_ctx.$moment(date).isSameOrBefore(_ctx.$moment(), 'day')) {
                _ctx.logASpan({
                  entry: _ctx.entry,
                  span_at: _unref(getDateSpan)(_ctx.routine.frequency, date),
                  frequency: _ctx.routine.frequency
                });
                if (!_ctx.entry.temp) {
                  _ctx.$store.dispatch('push', {
                    event: 'entry_update',
                    params: {
                      entry: _ctx.entry
                    },
                    entry: _ctx.entry
                  });
                }
              }
            },
            class: _normalizeClass([{
              [`border-[var(--ps-routine-display-positive-color)]`]: _ctx.routine.logs.find(l => l.span_at == _unref(getDateSpan)(_ctx.routine.frequency, date))?.completed === 1,
              [`border-[var(--ps-routine-display-negative-color)]`]: _ctx.routine.logs.find(l => l.span_at == _unref(getDateSpan)(_ctx.routine.frequency, date))?.completed === 0,
              'border-neural-500': _ctx.routine.logs.find(l => l.span_at == _unref(getDateSpan)(_ctx.routine.frequency, date))?.completed === null,
              'cursor-normal pointer-events-none opacity-50': _ctx.$moment(date).isAfter(_ctx.$moment(), 'day')
            }, "absolute inset-0 rounded-full border-2"])
          }, [_createTextVNode(_toDisplayString(_ctx.$moment(date).format("DD")) + " ", 1), span ? (_openBlock(), _createElementBlock("span", _hoisted_7, " - " + _toDisplayString(_ctx.$moment(date).add(span, "days").format("DD")), 1)) : _createCommentVNode("", true)], 10, _hoisted_6)]),
          _: 1
        }, 8, ["mode", "style"]), _createVNode(_component_LabelHelper, null, {
          default: _withCtx(() => _cache[10] || (_cache[10] = [_createTextVNode(" Frequency ")])),
          _: 1
        }), _createVNode(_component_SelectMenu, {
          modelValue: _ctx.routine.frequency,
          "onUpdate:modelValue": [_cache[0] || (_cache[0] = $event => _ctx.routine.frequency = $event), _cache[1] || (_cache[1] = val => _ctx.routine = {
            ..._ctx.routine,
            ...{
              frequency: val
            }
          })],
          options: _ctx.options
        }, null, 8, ["modelValue", "options"]), _createVNode(_component_LabelHelper, null, {
          default: _withCtx(() => _cache[11] || (_cache[11] = [_createTextVNode(" Target ")])),
          _: 1
        }), _createVNode(_component_TextPicker, {
          modelValue: _ctx.target,
          "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => _ctx.target = $event),
          class: _normalizeClass(_ctx.$style.inputTarget)
        }, null, 8, ["modelValue", "class"]), _createVNode(_component_ColorPicker, {
          label: "Positive Color",
          modelValue: _ctx.routine.settings?.colors?.positive,
          onUpdate: _cache[3] || (_cache[3] = color => {
            _ctx.routine = {
              ..._ctx.routine,
              ...{
                settings: {
                  ..._ctx.routine.settings,
                  ...{
                    colors: {
                      ..._ctx.routine.settings.colors,
                      ...{
                        positive: color
                      }
                    }
                  }
                }
              }
            };
          }),
          class: _normalizeClass(_ctx.$style.inputTarget)
        }, null, 8, ["modelValue", "class"]), _createVNode(_component_ColorPicker, {
          label: "Negative Color",
          modelValue: _ctx.routine.settings?.colors?.negative,
          onUpdate: _cache[4] || (_cache[4] = color => {
            _ctx.routine = {
              ..._ctx.routine,
              ...{
                settings: {
                  ..._ctx.routine.settings,
                  ...{
                    colors: {
                      ..._ctx.routine.settings.colors,
                      ...{
                        negative: color
                      }
                    }
                  }
                }
              }
            };
          }),
          class: _normalizeClass(_ctx.$style.inputTarget)
        }, null, 8, ["modelValue", "class"]), _createVNode(_component_ToggleComponent, {
          modelValue: _ctx.routine.auto_fail,
          "onUpdate:modelValue": [_cache[5] || (_cache[5] = $event => _ctx.routine.auto_fail = $event), _cache[6] || (_cache[6] = auto_fail => _ctx.routine = {
            ..._ctx.routine,
            ...{
              auto_fail
            }
          })],
          label: "Auto Fail"
        }, null, 8, ["modelValue"]), _createElementVNode("div", {
          class: _normalizeClass(_ctx.$style.utilitiesRow)
        }, [_createVNode(_component_ButtonComponentNew, {
          onClick: _cache[7] || (_cache[7] = $event => _ctx.routine = null),
          title: "Delete Routine",
          class: _normalizeClass(_ctx.$style.buttonDelete)
        }, {
          default: _withCtx(() => [_createVNode(_component_TrashIcon, {
            size: "18"
          })]),
          _: 1
        }, 8, ["class"])], 2)])]),
        _: 3
      })])) : (_openBlock(), _createElementBlock("div", _hoisted_8, [_createElementVNode("button", {
        onClick: _cache[8] || (_cache[8] = $event => _ctx.routine = _unref(addonBlueprint)('routine', _ctx.entry, this))
      }, [_renderSlot(_ctx.$slots, "button", {}, () => [_createVNode(_component_ButtonComponent, {
        title: "Add a routine",
        size: _ctx.size,
        variant: _ctx.variant,
        color: _ctx.color
      }, {
        default: _withCtx(() => [_createVNode(_component_RepeatIcon, {
          size: "20"
        }), _ctx.variant != 'round' ? (_openBlock(), _createElementBlock("span", _hoisted_9, "Routine")) : _createCommentVNode("", true)]),
        _: 1
      }, 8, ["size", "variant", "color"])])])]));
    };
  }
});