import { Filtering } from "../mixins/Filtering";
import { Schema } from "../mixins/Schema";
import { Aggregate } from "../mixins/Aggregate";
import { Lazying } from "../mixins/Lazying";
export default {
  inject: {
    overrideEntries: {
      default: null
    }
  },
  props: {
    modelValue: Object,
    entry: Object,
    full: Boolean,
    hideOnOk: Boolean,
    position: String
  },
  methods: {
    pullData() {
      this.$store.dispatch("pull", {
        filters: this.sense.filters
      });
    }
  },
  computed: {
    sense() {
      return this.modelValue;
    },
    filters() {
      return this.sense.filters;
    },
    computedEntries() {
      if (this.overrideEntries?.length) {
        return this.filterEntries(this.filters, this.overrideEntries);
      }
      return this.filterEntries(this.filters);
    },
    color() {
      return this.modelValue.color || this.modelValue.color || "neutral";
    },
    results() {
      return this.getAggregatedResults(this.sense.aggregates, this.computedEntries);
    },
    ok() {
      return !this.results.filter(a => !a.ok).length;
    }
  },
  mixins: [Lazying, Schema, Filtering, Aggregate]
};