import { Coloring } from "../../mixins/Coloring";
import { Addon } from "@/components/mixins/Addon";
export default {
  mixins: [Coloring, Addon],
  data() {
    return {
      entries: [],
      input: "",
      searchQuery: ""
    };
  },
  mounted() {
    this.entries = this.links?.map(id => this.$store.getters.entries.find(e => e.id == id)).filter(e => e);
  },
  watch: {
    links: {
      handler(links) {
        this.entries = links?.map(id => this.$store.getters.entries.find(e => e.id == id)).filter(e => e);
      }
    }
  },
  computed: {
    links: {
      get() {
        return this.modelValue;
      },
      set(links) {
        this.$emit("update:modelValue", links);
        // eslint-disable-next-line vue/no-mutating-props
        // this.entry.links = links;
        // this.$store.dispatch("push", {
        //   event: "entry_update",
        //   params: { entry: this.entry },
        //   entry: this.entry,
        // });
      }
    }

    // outputComputed() {
    //   return {
    //     id: this.$nanoid(),
    //     filters: [
    //       { key: "name", op: "any", val: this.searchQuery.toLowerCase() },
    //     ],
    //   };
    // },
  },
  methods: {
    // eslint-disable-next-line no-unused-vars
    onKeyUp(string, event) {
      this.searchQuery = string;
    },
    onSearchSelect(entry) {
      if (entry) {
        this.links = [...this.links, entry.id];
        this.$emit("add:linkId", entry.id);
        this.$emit("link", entry);
      }
      this.showSearch = false;
      this.$emit("close");
    }
  }
};