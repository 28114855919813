import "core-js/modules/es.array.push.js";
export default {
  props: {
    mode: {
      type: String,
      default: "days"
    }
  },
  data() {
    return {
      cursor: this.$moment().local().format("YYYY-MM-DD")
    };
  },
  methods: {
    getWeeksInMonth(date) {
      let momentDate = this.$moment(date);
      let month = momentDate.month() + 1; // month() returns a zero-based month (0 for January, 11 for December), so we add 1
      let year = momentDate.year();
      let startWeek = this.$moment([year, month - 1]).startOf("month").week();
      let endWeek = this.$moment([year, month - 1]).endOf("month").week();

      // Handle year wrap-around.
      if (startWeek > endWeek) {
        endWeek += this.$moment([year, month - 1]).weeksInYear();
      }
      let weeks = [];
      for (let week = startWeek; week <= endWeek; week++) {
        let weekDate = this.$moment(date).week(week).startOf("week");
        weeks.push(weekDate);
      }
      return weeks;
    },
    getDaysInWeek(date) {
      let momentDate = this.$moment(date);
      let week = momentDate.week();
      let month = momentDate.month();
      const startOfWeek = this.$moment(date).week(week).startOf("week");
      const endOfWeek = this.$moment(date).week(week).endOf("week");
      let daysInWeek = 0;
      for (let day = startOfWeek; day <= endOfWeek; day.add(1, "days")) {
        if (day.month() === month) {
          daysInWeek++;
        }
      }
      return daysInWeek;
    }
  },
  computed: {
    options() {
      switch (this.mode) {
        case "weeks":
          return this.getWeeksInMonth(this.cursor).map(week => {
            if (week.month() !== this.$moment(this.cursor).month()) {
              week = this.$moment(this.cursor).startOf("month");
            }
            const _date = week.format("YYYY-MM-DD");
            return {
              date: _date,
              from: week.format("YYYY-MM-DD"),
              to: week.endOf("week").format("YYYY-MM-DD"),
              span: this.getDaysInWeek(_date)
            };
          });
        case "months":
          var month = this.$moment(this.cursor).startOf("month");
          return [{
            date: month.format("YYYY-MM-DD"),
            from: month.format("YYYY-MM-DD"),
            to: month.endOf("month").format("YYYY-MM-DD"),
            span: month.daysInMonth()
          }];
        default:
          return Array.from({
            length: this.$moment(this.cursor).local().daysInMonth()
          }, (_, i) => i + 1).map(day => {
            const _date = this.$moment(this.$moment(this.cursor).set("date", day)).format("YYYY-MM-DD");
            return {
              date: _date
            };
          });
      }
    }
  }
};