import { Coloring } from "../mixins/Coloring";
export default {
  mixins: [Coloring],
  props: {
    modelValue: Object,
    entry: Object
  },
  computed: {
    cover: {
      get() {
        return this.modelValue;
      }
    }
  }
};