export default {
  props: {
    modelValue: Object,
    entry: Object,
    position: String,
    disabled: Boolean,
    internalSettings: {
      type: Object,
      default: () => ({
        display: {
          label: false,
          settings: false
        }
      })
    }
  },
  data() {
    return {
      showSenseModal: false
    };
  },
  computed: {
    senses: {
      get() {
        return this.modelValue;
      },
      set(val) {
        this.$emit("update:modelValue", val);
      }
    }
  }
};