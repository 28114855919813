import { vModelText as _vModelText, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withDirectives as _withDirectives, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  class: "flex flex-col gap-2"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_SearchResults = _resolveComponent("SearchResults");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_withDirectives(_createElementVNode("input", {
    ref: "searchInput",
    "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => $data.query = $event),
    type: "text",
    placeholder: "Search entries...",
    class: _normalizeClass(_ctx.$style.searchInput)
  }, null, 2), [[_vModelText, $data.query]]), _createVNode(_component_SearchResults, {
    query: $data.query,
    filtersOnEmpty: [{
      key: 'anchor',
      op: 'is set'
    }],
    filterSearchResults: e => e.input,
    onOnEntrySelect: $options.handleSendToSelect,
    onOnSearchResultsChange: _cache[1] || (_cache[1] = $event => $data.currentSearchResults = $event),
    class: "max-h-64 overflow-y-auto overflow-x-hidden px-2"
  }, null, 8, ["query", "filterSearchResults", "onOnEntrySelect"])]);
}