import { Scheduling } from "../mixins/Scheduling";
import { EditUtilities } from "../mixins/EditUtilities";
import moment from "moment";
export default {
  mixins: [Scheduling, EditUtilities],
  props: {
    entry: Object,
    otherentries: Array,
    teleport: Boolean
  },
  data() {
    return {
      moment: moment,
      now: null
    };
  },
  // watch: {
  //   "entry.schedule.time": function (n) {
  //     this.value = n;
  //   },
  // },
  mounted() {
    this.interval = setInterval(() => {
      this.now = moment.utc();
    }, 1000);
  },
  beforeUnmount() {
    clearInterval(this.interval);
  },
  computed: {
    time: {
      get() {
        return this.modelValue || {
          op: null
        };
      },
      set(date) {
        this.$emit("update", date);
        this.$emit("update:modelValue", date);
      }
    },
    date() {
      return moment().format("YYYY-MM-DD");
    }
  },
  methods: {
    valToHtml(val, format = "HH:mm", mod) {
      if (val) {
        if (val.op == "null") return "unset time";
        if (val.op == "now") {
          return "now";
        }
        if (val.op == "nextXHours") {
          if (val.x == 0) return "now";
          return "in " + val.x + " hour" + (val.x != 1 ? "s" : "");
        }
        if (val.op == "nextXMinutes") {
          if (val.x == 0) return "now";
          return "in " + val.x + " minute" + (val.x != 1 ? "s" : "");
        }
        if (val.op == "nextXSeconds") {
          if (val.x == 0) return "now";
          return "in " + val.x + " second" + (val.x != 1 ? "s" : "");
        }
        if (val.op == "lastXHours") {
          if (val.x == 0) return "now";
          return val.x + " hour" + (val.x != 1 ? "s" : "") + " ago";
        }
        if (val.op == "lastXMinutes") {
          if (val.x == 0) return "now";
          return val.x + " minute" + (val.x != 1 ? "s" : "") + " ago";
        }
        if (val.op == "lastXSeconds") {
          if (val.x == 0) return "now";
          return val.x + " second" + (val.x != 1 ? "s" : "") + " ago";
        }
        var datetime = moment.utc(moment.utc().format("YYYY-MM-DD " + val));
        if (datetime.format("ss") != "00") format = format + ":ss";
        if (mod) {
          datetime = datetime.add(mod.val, mod.key);
        }
        return datetime.local().format(format);
      }
      return;
    }
  }
};