import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, createTextVNode as _createTextVNode, withModifiers as _withModifiers } from "vue";
const _hoisted_1 = {
  key: "settings"
};
const _hoisted_2 = {
  key: 0,
  variant: "minimal",
  size: "sm"
};
const _hoisted_3 = {
  key: 1
};
const _hoisted_4 = {
  key: 0
};
const _hoisted_5 = {
  key: 1,
  title: "Upon creation, this entry will immediately start tracking"
};
const _hoisted_6 = {
  key: "toggle",
  class: "flex items-center"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_TimerIcon = _resolveComponent("TimerIcon");
  const _component_PlayIcon = _resolveComponent("PlayIcon");
  const _component_TransitionGroupHelper = _resolveComponent("TransitionGroupHelper");
  const _component_SectionHeader = _resolveComponent("SectionHeader");
  const _component_TimeTrackingsWrapper = _resolveComponent("TimeTrackingsWrapper");
  const _component_PopoverHelper = _resolveComponent("PopoverHelper");
  const _component_PauseIcon = _resolveComponent("PauseIcon");
  return _openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.$style.timeTrackingDisplay)
  }, [_createVNode(_component_TransitionGroupHelper, null, {
    default: _withCtx(() => [_createElementVNode("div", _hoisted_1, [_createVNode(_component_PopoverHelper, {
      teleport: true,
      placement: "bottom-start"
    }, {
      button: _withCtx(() => [_createElementVNode("div", {
        class: _normalizeClass(_ctx.$style.popoverButton),
        "data-table-column-target": ""
      }, [_createVNode(_component_TimerIcon, {
        size: "14"
      }), _createElementVNode("div", null, [_createVNode(_component_TransitionGroupHelper, {
        enter: {
          translateY: [50, 0],
          opacity: [0, 1]
        },
        leave: {
          translateY: [0, -50],
          opacity: [1, 0]
        }
      }, {
        default: _withCtx(() => [!$options.userHasInEntryActiveTimeTracking ? (_openBlock(), _createElementBlock("div", _hoisted_2, [_createElementVNode("span", null, _toDisplayString($options.totalTrackedTime), 1)])) : _createCommentVNode("", true), _ctx.activeTimeTrackingByUser && _ctx.activeTimeTrackingByUser.entry_id == $props.entry.id ? (_openBlock(), _createElementBlock("div", _hoisted_3, [!$props.entry.temp ? (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(_ctx.getDurationOfTimeTracking(_ctx.activeTimeTrackingByUser).format("hh:mm:ss", {
          stopTrim: "m"
        })), 1)) : (_openBlock(), _createElementBlock("span", _hoisted_5, [_createVNode(_component_PlayIcon, {
          size: "14",
          class: _normalizeClass(_ctx.$style.playIconAnimation)
        }, null, 8, ["class"])]))])) : _createCommentVNode("", true)]),
        _: 1
      })])], 2)]),
      default: _withCtx(() => [!$props.entry.temp ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: _normalizeClass(_ctx.$style.popoverContent)
      }, [_createVNode(_component_SectionHeader, {
        url: "https://help.pensive.io/time-trackings"
      }, {
        title: _withCtx(() => [_createVNode(_component_TimerIcon, {
          size: "16"
        }), _cache[3] || (_cache[3] = _createTextVNode(" Time Trackings "))]),
        _: 1
      }), _createVNode(_component_TimeTrackingsWrapper, {
        modelValue: $options.time_trackings,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => $options.time_trackings = $event),
        entry: $props.entry,
        class: "min-w-64 overflow-y-auto"
      }, null, 8, ["modelValue", "entry"])], 2)) : _createCommentVNode("", true)]),
      _: 1
    })]), !$props.entry.temp ? (_openBlock(), _createElementBlock("div", _hoisted_6, [!_ctx.activelyTimeTrackedEntry || _ctx.activelyTimeTrackedEntry.id != $props.entry.id ? (_openBlock(), _createElementBlock("button", {
      key: 0,
      onClick: _cache[1] || (_cache[1] = _withModifiers(() => _ctx.startTimeTracking($props.entry), ["stop"])),
      title: "Start time tracking"
    }, [_createVNode(_component_PlayIcon, {
      size: "14"
    })])) : _createCommentVNode("", true), _ctx.activelyTimeTrackedEntry && _ctx.activelyTimeTrackedEntry.id == $props.entry.id ? (_openBlock(), _createElementBlock("button", {
      key: 1,
      type: "button",
      ref: "reference",
      onClick: _cache[2] || (_cache[2] = _withModifiers(() => _ctx.stopTimeTracking($props.entry), ["stop"])),
      title: "Stop time tracking"
    }, [_createVNode(_component_PauseIcon, {
      size: "14"
    })], 512)) : _createCommentVNode("", true)])) : _createCommentVNode("", true)]),
    _: 1
  })], 2);
}