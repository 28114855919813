import { TimeTrackingsMixin } from "@/components/timetrackings/mixins/TimeTrackingsMixin";
import { Tracking } from "@/components/mixins/Tracking";
export default {
  inject: {
    time_tracking_selected_date: {
      default: () => this.$moment.format("YYYY-MM-DD")
    }
  },
  mixins: [TimeTrackingsMixin, Tracking],
  props: {
    modelValue: Object,
    entry: Object
  },
  computed: {
    date() {
      return this.time_tracking_selected_date;
    },
    time_trackings: {
      get() {
        return this.modelValue;
      },
      set(time_trackings) {
        // eslint-disable-next-line vue/no-mutating-props
        this.entry.time_trackings = time_trackings;
        this.$store.dispatch("push", {
          event: "entry_update",
          params: {
            entry: this.entry
          },
          entry: this.entry
        });
      }
    },
    user() {
      return this.$store.state.user;
    },
    userHasActiveTimeTracking() {
      return this.user.time_trackings.find(tt => tt.user_id == this.user.id && !tt.end_at);
    },
    userHasInEntryActiveTimeTracking() {
      return this.entry.time_trackings.find(tt => tt.user_id == this.user.id && !tt.end_at);
    },
    totalTrackedTime() {
      return this.$moment.utc(this.time_trackings
      // ?.filter((tt) => tt.end_at !== null)
      .map(tt => {
        const startTime = this.$moment.utc(tt.start_at);
        const endTime = tt.end_at ? this.$moment.utc(tt.end_at) : this.$moment.utc();
        if (!startTime.isValid() || !endTime.isValid()) return 0;
        return endTime.unix() - startTime.unix();
      }).reduce((a, b) => a + b, 0) * 1000).format("H:mm:ss");
    }
  }
};