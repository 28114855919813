import { ListIcon, CheckCircleIcon, CalendarDaysIcon } from "lucide-vue-next";
export default {
  props: {
    modelValue: null,
    options: {
      type: Array,
      default: () => [{
        icon: ListIcon,
        label: "None",
        description: "No grouping, entries are rendered as they come",
        value: null
      }, {
        icon: CheckCircleIcon,
        label: "Status",
        description: "Entries are grouped by status",
        value: {
          field: "status",
          dir: 1
        }
      }, {
        icon: CalendarDaysIcon,
        label: "Schedule · Date",
        description: "Entries are grouped by schedule date",
        value: {
          field: "schedule",
          dir: 1
        }
      }, {
        icon: CalendarDaysIcon,
        label: "Schedule · Time",
        description: "Entries are grouped by schedule time",
        value: {
          field: "schedule_time",
          dir: 1
        }
      }]
    }
  }
};