import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, withModifiers as _withModifiers } from "vue";
const _hoisted_1 = {
  class: "flex min-h-screen flex-1 flex-col justify-center py-12 sm:px-6 lg:px-8"
};
const _hoisted_2 = {
  class: "z-10 flex flex-col items-center sm:mx-auto sm:w-full sm:max-w-md"
};
const _hoisted_3 = {
  class: "mt-2 text-center text-2xl font-bold leading-9 tracking-tight text-neutral-900 dark:text-neutral-300"
};
const _hoisted_4 = {
  class: "mt-10 sm:mx-auto sm:w-full sm:max-w-[480px]"
};
const _hoisted_5 = {
  ref: "inner"
};
const _hoisted_6 = {
  key: "forgot-password",
  class: "mb-5 flex items-center justify-between",
  "data-delay": "300"
};
const _hoisted_7 = {
  class: "text-sm leading-6"
};
const _hoisted_8 = {
  key: "button",
  "data-delay": "500"
};
const _hoisted_9 = ["disabled"];
const _hoisted_10 = {
  key: 0
};
const _hoisted_11 = {
  key: 0,
  class: "mt-10 text-center text-sm text-neutral-500"
};
const _hoisted_12 = {
  key: 1,
  class: "mt-10 text-center text-sm text-neutral-500"
};
const _hoisted_13 = {
  key: 2,
  class: "mt-10 text-center text-sm text-neutral-500"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_Logo = _resolveComponent("Logo");
  const _component_SmoothText = _resolveComponent("SmoothText");
  const _component_AlertHelper = _resolveComponent("AlertHelper");
  const _component_InputWithLabel = _resolveComponent("InputWithLabel");
  const _component_router_link = _resolveComponent("router-link");
  const _component_LoaderCircleIcon = _resolveComponent("LoaderCircleIcon");
  const _component_SocialLoginButtons = _resolveComponent("SocialLoginButtons");
  const _component_TransitionGroupHelper = _resolveComponent("TransitionGroupHelper");
  const _component_PanelHelper = _resolveComponent("PanelHelper");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createVNode(_component_Logo), _createElementVNode("h2", _hoisted_3, [_createVNode(_component_SmoothText, {
    text: $options.subheading_text,
    align: "center"
  }, null, 8, ["text"])])]), _createElementVNode("div", _hoisted_4, [_createVNode(_component_PanelHelper, {
    ref: "outer",
    class: "box-content !p-12",
    style: {
      "transition": "height 0.3s cubic-bezier(0.16, 1, 0.3, 1)"
    }
  }, {
    default: _withCtx(() => [_createElementVNode("div", _hoisted_5, [$data.isMountedAndAnimated ? (_openBlock(), _createElementBlock("form", {
      key: 0,
      onSubmit: _cache[1] || (_cache[1] = _withModifiers(() => {}, ["prevent"]))
    }, [_createVNode(_component_TransitionGroupHelper, {
      appear: true,
      enter: {
        opacity: [0, 1],
        translateY: [50, 0],
        delay: el => el.dataset.delay
      }
    }, {
      default: _withCtx(() => [_ctx.message ? (_openBlock(), _createBlock(_component_AlertHelper, {
        key: "message",
        variant: _ctx.message.type,
        class: "mb-4"
      }, {
        default: _withCtx(() => [_createTextVNode(_toDisplayString(_ctx.message.message), 1)]),
        _: 1
      }, 8, ["variant"])) : _createCommentVNode("", true), (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.formFields, (field, index) => {
        return _openBlock(), _createBlock(_component_InputWithLabel, {
          key: field.key,
          label: field.label,
          type: field.type,
          placeholder: field.placeholder,
          modelValue: $data.formData[field.key],
          "onUpdate:modelValue": $event => $data.formData[field.key] = $event,
          class: "mb-5",
          "data-delay": index * 100,
          required: true
        }, null, 8, ["label", "type", "placeholder", "modelValue", "onUpdate:modelValue", "data-delay"]);
      }), 128)), _ctx.$route.name == 'login' ? (_openBlock(), _createElementBlock("div", _hoisted_6, [_createElementVNode("div", _hoisted_7, [_createVNode(_component_router_link, {
        to: {
          name: 'forgot-password'
        },
        class: "font-semibold text-neutral-600 hover:text-neutral-500 dark:text-neutral-500 dark:hover:text-neutral-400"
      }, {
        default: _withCtx(() => _cache[2] || (_cache[2] = [_createTextVNode(" Forgot password? ")])),
        _: 1
      })])])) : _createCommentVNode("", true), $options.buttonText ? (_openBlock(), _createElementBlock("div", _hoisted_8, [_createElementVNode("button", {
        onClick: _cache[0] || (_cache[0] = (...args) => $options.handleButtonClick && $options.handleButtonClick(...args)),
        type: "submit",
        disabled: _ctx.loading,
        class: "mb-5 flex w-full justify-center rounded-md bg-neutral-900 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-neutral-950 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-neutral-600 disabled:bg-neutral-700 dark:bg-neutral-800 dark:hover:bg-neutral-600 dark:disabled:bg-neutral-900"
      }, [_ctx.loading ? (_openBlock(), _createElementBlock("span", _hoisted_10, [_createVNode(_component_LoaderCircleIcon, {
        size: "24",
        class: "animate-spin"
      })])) : (_openBlock(), _createBlock(_component_SmoothText, {
        key: 1,
        text: $options.buttonText
      }, null, 8, ["text"]))], 8, _hoisted_9)])) : _createCommentVNode("", true), ['login', 'register'].includes(_ctx.$route.name) ? (_openBlock(), _createBlock(_component_SocialLoginButtons, {
        key: "social",
        "data-delay": "600",
        class: _normalizeClass({
          'pointer-events-none': _ctx.loading
        })
      }, null, 8, ["class"])) : _createCommentVNode("", true)]),
      _: 1
    }, 8, ["enter"])], 32)) : _createCommentVNode("", true)], 512)]),
    _: 1
  }, 512), _ctx.$route.name == 'login' ? (_openBlock(), _createElementBlock("p", _hoisted_11, [_cache[4] || (_cache[4] = _createTextVNode(" Not a member? ")), _createVNode(_component_router_link, {
    to: {
      name: 'register'
    },
    class: "font-semibold leading-6 text-neutral-600 hover:text-neutral-950 dark:text-neutral-400 dark:hover:text-neutral-300"
  }, {
    default: _withCtx(() => _cache[3] || (_cache[3] = [_createTextVNode(" Sign up for free ")])),
    _: 1
  })])) : _createCommentVNode("", true), _ctx.$route.name == 'register' ? (_openBlock(), _createElementBlock("p", _hoisted_12, [_cache[6] || (_cache[6] = _createTextVNode(" Already a member? ")), _createVNode(_component_router_link, {
    to: {
      name: 'login'
    },
    class: "font-semibold leading-6 text-neutral-600 hover:text-neutral-950 dark:text-neutral-400 dark:hover:text-neutral-300"
  }, {
    default: _withCtx(() => _cache[5] || (_cache[5] = [_createTextVNode(" Login ")])),
    _: 1
  })])) : _createCommentVNode("", true), _ctx.$route.name == 'forgot-password' || _ctx.$route.name == 'reset-password' ? (_openBlock(), _createElementBlock("p", _hoisted_13, [_cache[8] || (_cache[8] = _createTextVNode(" Know your password? ")), _createVNode(_component_router_link, {
    to: {
      name: 'login'
    },
    class: "font-semibold leading-6 text-neutral-600 hover:text-neutral-950 dark:text-neutral-400 dark:hover:text-neutral-300"
  }, {
    default: _withCtx(() => _cache[7] || (_cache[7] = [_createTextVNode(" Login instead ")])),
    _: 1
  })])) : _createCommentVNode("", true)])]);
}