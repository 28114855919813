import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, withDirectives as _withDirectives, normalizeStyle as _normalizeStyle } from "vue";
const _hoisted_1 = {
  key: 0,
  class: "fixed inset-0 backdrop-blur"
};
const _hoisted_2 = {
  class: "flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0"
};
const _hoisted_3 = {
  key: 1,
  class: "flex min-h-9 justify-between text-base font-semibold leading-6 text-gray-900 dark:bg-black dark:text-white"
};
const _hoisted_4 = {
  class: "flex flex-wrap items-center gap-2"
};
const _hoisted_5 = {
  class: "flex items-center gap-2"
};
const _hoisted_6 = {
  id: "entry-modal-page",
  class: "container relative mx-auto flex w-full max-w-5xl flex-col gap-4"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_BulkSelectMenu = _resolveComponent("BulkSelectMenu");
  const _component_ChevronRightIcon = _resolveComponent("ChevronRightIcon");
  const _component_SquareArrowOutUpRightIcon = _resolveComponent("SquareArrowOutUpRightIcon");
  const _component_ButtonComponentNew = _resolveComponent("ButtonComponentNew");
  const _component_XIcon = _resolveComponent("XIcon");
  const _component_DialogTitle = _resolveComponent("DialogTitle");
  const _component_EntrySettings = _resolveComponent("EntrySettings");
  const _component_DialogPanel = _resolveComponent("DialogPanel");
  const _component_Dialog = _resolveComponent("Dialog");
  const _directive_touch = _resolveDirective("touch");
  return $data.dialogIsOpen ? (_openBlock(), _createBlock(_component_Dialog, {
    key: 0,
    as: "div",
    open: true,
    class: "relative h-px w-px",
    onClose: _cache[5] || (_cache[5] = $event => {
      // $console.trace();
      // $console.log('close');
      _ctx._trackEvent('Navigation', 'Entry Modal', 'Close Modal'), _ctx.$router.replace({
        name: 'entry',
        params: {
          id: _ctx.$route.params.id
        }
      });
    }),
    style: _normalizeStyle($options.cssProps)
  }, {
    default: _withCtx(() => [_createVNode(_Transition, {
      "enter-active-class": "transition-opacity duration-200 ease-linear",
      "enter-from-class": "opacity-0",
      "enter-to-class": "opacity-100",
      "leave-active-class": "transition-opacity duration-100 ease-linear",
      "leave-from-class": "opacity-100",
      "leave-to-class": "opacity-0",
      appear: ""
    }, {
      default: _withCtx(() => [$options.entry_by_route ? (_openBlock(), _createElementBlock("div", _hoisted_1)) : _createCommentVNode("", true)]),
      _: 1
    }), _createVNode(_Transition, {
      "enter-active-class": "transition-all duration-300 ease-out",
      "enter-from-class": "opacity-0 translate-y-10",
      "enter-to-class": "opacity-100 translate-y-0",
      "leave-active-class": "transition-all duration-200 ease-out",
      "leave-from-class": "opacity-100 translate-y-0",
      "leave-to-class": "opacity-0 translate-y-10",
      appear: ""
    }, {
      default: _withCtx(() => [$options.entry_by_route ? _withDirectives((_openBlock(), _createElementBlock("div", {
        key: 0,
        ref: "wrapper",
        id: "entry-modal-page-wrapper",
        class: "fixed inset-0 w-screen overflow-y-auto overflow-x-hidden",
        onScroll: _cache[4] || (_cache[4] = $event => $data.scrollingIsMade = true)
      }, [_createElementVNode("div", _hoisted_2, [_createVNode(_component_DialogPanel, {
        class: "relative w-full max-w-[90vw] transform overflow-visible rounded-lg bg-white px-4 pb-4 pt-2 text-left shadow-xl ring-1 ring-transparent transition-all sm:my-8 sm:px-6 sm:pb-6 md:max-w-3xl lg:max-w-5xl dark:bg-black dark:ring-neutral-700"
      }, {
        default: _withCtx(() => [_createVNode(_component_DialogTitle, {
          as: "h3",
          class: "sticky top-0 z-10 -mx-4 rounded-b bg-white px-6 py-4 sm:-mx-5 md:-mx-6 dark:bg-black"
        }, {
          default: _withCtx(() => [_ctx.$store.getters.selected.length ? (_openBlock(), _createBlock(_component_BulkSelectMenu, {
            key: 0,
            size: "xs"
          })) : (_openBlock(), _createElementBlock("div", _hoisted_3, [_createElementVNode("div", _hoisted_4, [_createElementVNode("a", {
            onClick: _cache[0] || (_cache[0] = $event => (_ctx._trackEvent('Navigation', 'Entry Modal', 'Close Modal'), _ctx.$router.replace({
              name: 'entry',
              params: {
                id: _ctx.$route.params.id
              }
            }))),
            class: "hidden cursor-pointer text-xs md:inline"
          }, _toDisplayString(_ctx.clearMarkdown($options.entry_by_route_before?.name).length > 20 ? _ctx.clearMarkdown($options.entry_by_route_before?.name).substring(0, 20) + "..." : _ctx.clearMarkdown($options.entry_by_route_before?.name)), 1), _createVNode(_component_ChevronRightIcon, {
            size: "16",
            "aria-hidden": "true",
            class: "hidden md:inline"
          }), _createElementVNode("a", {
            onClick: _cache[1] || (_cache[1] = (...args) => $options.openFullPage && $options.openFullPage(...args)),
            class: "hidden cursor-pointer text-xs md:inline"
          }, _toDisplayString(_ctx.clearMarkdown($options.entry_by_route.name).length > 20 ? _ctx.clearMarkdown($options.entry_by_route.name).substring(0, 20) + "..." : _ctx.clearMarkdown($options.entry_by_route.name)), 1), _createVNode(_component_ButtonComponentNew, {
            onClick: $options.openFullPage,
            shortcode: "f",
            title: "Open this Entry in Full Page",
            class: _normalizeClass(_ctx.$style.buttonOpenFullPage)
          }, {
            default: _withCtx(() => [_cache[6] || (_cache[6] = _createElementVNode("span", {
              class: "sr-only"
            }, "Open Full Page", -1)), _createVNode(_component_SquareArrowOutUpRightIcon, {
              size: "20",
              "aria-hidden": "true"
            })]),
            _: 1
          }, 8, ["onClick", "class"])]), _createElementVNode("div", _hoisted_5, [_createVNode(_component_ButtonComponentNew, {
            onClick: _cache[2] || (_cache[2] = $event => (_ctx._trackEvent('Navigation', 'Entry Modal', 'Close Modal'), _ctx.$router.replace({
              name: 'entry',
              params: {
                id: _ctx.$route.params.id
              }
            }))),
            title: "Close this Entry",
            shortcode: "esc",
            class: _normalizeClass(_ctx.$style.buttonClose)
          }, {
            default: _withCtx(() => [_cache[7] || (_cache[7] = _createElementVNode("span", {
              class: "sr-only"
            }, "Close", -1)), _createVNode(_component_XIcon, {
              size: "20",
              "aria-hidden": "true"
            })]),
            _: 1
          }, 8, ["class"])])]))]),
          _: 1
        }), _createElementVNode("div", _hoisted_6, [_createVNode(_component_EntrySettings, {
          modelValue: $data.entry_by_route_cache,
          "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => $data.entry_by_route_cache = $event),
          position: "modal",
          class: "!px-0"
        }, null, 8, ["modelValue"])])]),
        _: 1
      })])], 32)), [[_directive_touch, () => {
        $data.scrollingIsMade = false;
        $data.scrollTopOnPress = _ctx.$refs.wrapper.scrollTop;
      }, "press"], [_directive_touch, () => {
        if ($data.scrollingIsMade && $data.scrollTopOnPress === 0 && _ctx.$refs.wrapper.scrollTop <= 0) {
          _ctx._trackEvent('Navigation', 'Entry Modal', 'Close Modal'), _ctx.$router.go(-1);
        }
      }, "release"], [_directive_touch, () => {
        if (_ctx.$refs.wrapper.scrollTop === 0) {
          _ctx._trackEvent('Navigation', 'Entry Modal', 'Close Modal'), _ctx.$router.go(-1);
        }
      }, "swipe", {
        bottom: true
      }]]) : _createCommentVNode("", true)]),
      _: 1
    })]),
    _: 1
  }, 8, ["style"])) : _createCommentVNode("", true);
}