import moment from "moment";
import { Coloring } from "../mixins/Coloring";
// import CalendarComponent from "../helpers/CalendarComponent.vue";
import { getDateSpan } from "@/datesHelper";
import { Routining } from "../mixins/Routining";
import { addonBlueprint } from "@/addonBlueprint";
export default {
  props: {
    modelValue: Object,
    entry: Object,
    disabled: Boolean,
    internalSettings: {
      type: Object,
      default: () => ({
        display: {
          label: false,
          settings: false
        }
      })
    }
  },
  data() {
    return {
      // isInitialized: false,
      open: false,
      getDateSpan: getDateSpan
    };
  },
  // mounted() {
  //   this.$onIdle(() => {
  //     this.isInitialized = true;
  //   });
  // },
  mixins: [Coloring, Routining],
  computed: {
    routine: {
      get: function () {
        return this.modelValue;
      },
      set(routine) {
        // eslint-disable-next-line vue/no-mutating-props
        this.entry.routine = routine;
        this.$store.dispatch("push", {
          event: "entry_update",
          params: {
            entry: this.entry
          },
          entry: this.entry
        });
      }
    },
    slots() {
      switch (this.routine.frequency) {
        case "daily":
          return Array.from({
            length: 7
          }, (_, i) => {
            const span_at = getDateSpan(this.routine.frequency, moment().add(-i, "days"));
            return {
              date: moment().add(-i, "days").format("YYYY-MM-DD"),
              log: this.getLogBySpan({
                entry: this.entry,
                span_at
              }),
              fn: () => {
                this.logASpan({
                  entry: this.entry,
                  span_at: span_at,
                  frequency: this.routine.frequency
                });
                if (!this.entry.temp) {
                  this.$store.dispatch("push", {
                    event: "entry_update",
                    params: {
                      entry: this.entry
                    },
                    entry: this.entry
                  });
                }
              }
            };
          }).reverse();
        case "weekly":
          return Array.from({
            length: 4
          }, (_, i) => {
            const span_at = getDateSpan(this.routine.frequency, moment() /*.add(-1, "day")*/.add(-i, "week"));
            return {
              date: moment().add(-i, "weeks").startOf("week").format("YYYY-MM-DD") + " - " + moment().add(-i, "weeks").endOf("week").format("YYYY-MM-DD"),
              log: this.getLogBySpan({
                entry: this.entry,
                span_at
              }),
              fn: () => {
                this.logASpan({
                  entry: this.entry,
                  span_at: span_at,
                  frequency: this.routine.frequency
                });
                if (!this.entry.temp) {
                  this.$store.dispatch("push", {
                    event: "entry_update",
                    params: {
                      entry: this.entry
                    },
                    entry: this.entry
                  });
                }
              }
            };
          }).reverse();
        case "monthly":
          return Array.from({
            length: 3
          }, (_, i) => {
            const span_at = getDateSpan(this.routine.frequency, moment().add(-i, "month"));
            return {
              date: moment().add(-i, "month").startOf("month").format("YYYY-MM-DD") + " - " + moment().add(-i, "month").endOf("month").format("YYYY-MM-DD"),
              log: this.getLogBySpan({
                entry: this.entry,
                span_at
              }),
              fn: () => {
                this.logASpan({
                  entry: this.entry,
                  span_at: span_at,
                  frequency: this.routine.frequency
                });
                if (!this.entry.temp) {
                  this.$store.dispatch("push", {
                    event: "entry_update",
                    params: {
                      entry: this.entry
                    },
                    entry: this.entry
                  });
                }
              }
            };
          }).reverse();
      }
      return [];
    }
  },
  methods: {
    createRoutine() {
      this.routine = addonBlueprint("routine", this.entry, this);
    },
    getTitle(date) {
      let dates = date.split(" - ");
      switch (this.routine.frequency) {
        case "daily":
          return this.$moment(date).format("DD");
        case "weekly":
          return "W " + this.$moment(dates[1]).format("W");
        case "monthly":
          return this.$moment(dates[1]).format("MMM");
      }
    }
  }
};