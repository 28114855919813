import moment from "moment";
import { Tracking } from "../mixins/Tracking";
import anime from "animejs";
import { defineAsyncComponent } from "vue";
export default {
  components: {
    OutputDisplay: defineAsyncComponent(() => import("@/components/output/OutputDisplay.vue"))
  },
  mixins: [Tracking],
  props: {
    datasets: Array,
    date: {
      type: String,
      default: moment.utc().format("YYYY-MM-DD")
    },
    initialize: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      isInitialized: false,
      loadingAnimation: null,
      animationDone: false,
      rows: 6,
      activeHour: 0,
      activeRow: 0,
      cachedTimeTrackings: []
    };
  },
  watch: {
    initialize() {
      // console.log("initialize changed", this.initialize, this.isInitialized);
      if (this.initialize && !this.isInitialized) {
        this.initializeAnimation();
      }
    },
    clock() {
      this.activeHour = this.$moment.utc(this.clock).local().hour() - this.minHour + 1;
      this.activeRow = Math.floor(this.$moment.utc(this.clock).local().minute() / this.minutesPerRow) + 1;
    },
    activeRow() {
      delete this.cachedTimeTrackings[this.activeHour][this.activeRow];
    }
  },
  mounted() {
    if (this.initialize) {
      this.initializeAnimation();
    }
  },
  computed: {
    entries() {
      return this.$store.getters.entries?.filter(e => e.deleted_at === null);
    },
    minHour() {
      return Math.min(6, this.getStartHourWithBoundaries(this.time_trackings));
    },
    maxHour() {
      return Math.max(22, this.getEndHourWithBoundaries(this.time_trackings));
    },
    hours() {
      return this.maxHour - this.minHour + 1;
    },
    clock() {
      return this.$store.getters.clock;
    },
    time_trackings() {
      // console.log("time_trackings");
      return this.$store.getters.time_trackings.filter(tt => tt && moment.utc(tt.start_at).format("YYYY-MM-DD") == this.date);
    },
    minutesPerRow() {
      return 60 / this.rows;
    }
  },
  methods: {
    initializeAnimation() {
      this.$onIdle(() => {
        // const p = performance.now();
        this.$onIdle(() => {
          anime({
            targets: this.$refs.box,
            opacity: [{
              value: 1,
              easing: "easeInOutQuad",
              duration: 500
            }, {
              value: el => {
                const hour = parseInt(el.getAttribute("data-hour"));
                const row = parseInt(el.getAttribute("data-row"));
                return this.getRowTimeTrackings(hour, row).map(tt => {
                  const [start, end] = this.getCutoffBoundariesOfTimeTracking(tt, hour, row);
                  const duration = this.$moment.duration(end.diff(start));
                  return duration / 1000 / 60;
                }).reduce((a, b) => a + b, 0) / this.minutesPerRow;
              },
              easing: "easeInOutQuad",
              duration: 500
            }],
            delay: anime.stagger(100, {
              grid: [this.rows, this.hours],
              from: "first" /*center*/
            }),
            begin: () => {
              // console.log("animation begin", performance.now() - p);
            },
            complete: () => {
              this.isInitialized = true;
              this.animationDone = true;
              // console.log("animation done", performance.now() - p);
            }
          });
        });
      });
    },
    getOutputForTimeSpan(from, to) {
      // console.log("getOutputForTimeSpan");
      return {
        data: [{
          key: ["time_trackings", "range"],
          op: "ra",
          val: [this.$moment(from).utc().format("YYYY-MM-DD HH:mm:ss"), this.$moment(to).utc().format("YYYY-MM-DD HH:mm:ss")]
        }]
      };
    },
    /**
     * Checks if the current block has an active time tracking for the specified hour and row.
     *
     * @param {number} hour - The hour to check.
     * @param {number} row - The row to check.
     * @returns {boolean} - True if the current block has an active time tracking for the specified hour and row, false otherwise.
     */
    isCurrentBlockAndHasActiveTimeTracking(hour, row) {
      if (!(this.$moment.utc(this.$store.getters.clock).local() < this.$moment(this.date).hour(this.minHour - 1 + hour).minute((row - 1) * this.minutesPerRow + (this.minutesPerRow - 1)).second(59)) || !(this.$moment.utc(this.$store.getters.clock).local() > this.$moment(this.date).hour(this.minHour - 1 + hour).minute((row - 1) * this.minutesPerRow).second(0))) return false;

      // console.log("isCurrentBlockAndHasActiveTimeTracking", hour, row);

      return this.getTimeTrackingsInBoundaries(this.time_trackings, this.$moment(this.date).hour(this.minHour - 1 + hour).minute((row - 1) * this.minutesPerRow).second(0), this.$moment(this.date).hour(this.minHour - 1 + hour).minute((row - 1) * this.minutesPerRow + (this.minutesPerRow - 1)).second(59), this.date).filter(tt => tt.end_at === null).length > 0;
    },
    isCurrentBlock(hour, row) {
      return hour == this.activeHour && row == this.activeRow;
    },
    /**
     * Retrieves the time trackings for a specific hour and row.
     *
     * @param {Array} time_trackings - The time trackings to filter.
     * @param {number} hour - The hour for which to retrieve the time trackings.
     * @param {number} row - The row for which to retrieve the time trackings.
     * @returns {Array} - An array of time trackings for the specified hour and row.
     */
    getRowTimeTrackings(hour, row) {
      if (!this.cachedTimeTrackings[hour] || !this.cachedTimeTrackings[hour][row]) {
        if (!this.cachedTimeTrackings[hour]) this.cachedTimeTrackings[hour] = [];
        this.cachedTimeTrackings[hour][row] = this.getTimeTrackingsInBoundaries(this.time_trackings, this.$moment(this.date).hour(this.minHour - 1 + hour).minute((row - 1) * this.minutesPerRow).second(0), this.$moment(this.date).hour(this.minHour - 1 + hour).minute((row - 1) * this.minutesPerRow + (this.minutesPerRow - 1)).second(59), this.date);
      }
      return this.cachedTimeTrackings[hour][row];
    },
    /**
     * Calculates the tracked duration in a specific row of time.
     *
     * @param {number} hour - The hour value.
     * @param {number} row - The row value.
     * @returns {number} The tracked duration in the specified row.
     */
    getTrackedDurationInRow(hour, row) {
      // console.log("getTrackedDurationInRow", hour, row);
      return this.getRowTimeTrackings(hour, row).map(tt => {
        const [start, end] = this.getCutoffBoundariesOfTimeTracking(tt, hour, row);
        const duration = this.$moment.duration(end.diff(start));
        return duration / 1000;
      }).reduce((a, b) => a + b, 0);
    },
    /**
     * Retrieves the cutoff boundaries of a time tracking in a given moment.
     *
     * @param {Object} tt - The time tracking object.
     * @param {number} hour - The hour value.
     * @param {number} row - The row value.
     * @returns {Object} - The cutoff boundaries of the time tracking.
     */
    getCutoffBoundariesOfTimeTracking(tt, hour, row) {
      // console.log("getCutoffBoundariesOfTimeTracking", hour, row);
      const start = this.$moment.utc(tt.start_at).local();
      if (start < this.$moment(this.date).hour(this.minHour - 1 + hour).minute((row - 1) * this.minutesPerRow).second(0)) {
        start.hour(this.minHour - 1 + hour);
        start.minute((row - 1) * this.minutesPerRow);
        start.second(0);
      }
      const end = tt.end_at ? this.$moment.utc(tt.end_at).local() : this.$moment.utc(this.$store.getters.clock).local();
      if (end > this.$moment(this.date).hour(this.minHour - 1 + hour).minute((row - 1) * this.minutesPerRow + (this.minutesPerRow - 1)).second(59)) {
        end.hour(this.minHour - 1 + hour);
        end.minute((row - 1) * this.minutesPerRow + (this.minutesPerRow - 1));
        end.second(59);
      }
      return [start, end];
    },
    /**
     * Calculates the duration of an entry inside a given row of time.
     *
     * @param {Object} entry - The entry object.
     * @param {number} hour - The hour value.
     * @param {number} row - The row value.
     * @returns {number} - The duration of the entry inside the row.
     */

    getDurationOfEntryInsideRow(entry, hour, row) {
      return this.$moment.utc(this.getRowTimeTrackings(hour, row).filter(tt => tt.entry_id == entry.id).map(tt => {
        const [start, end] = this.getCutoffBoundariesOfTimeTracking(tt, hour, row);
        return end.unix() - start.unix();
      }).reduce((a, b) => a + b, 0) * 1000);
    }
  }
};