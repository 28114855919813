import { addonBlueprint } from "@/addonBlueprint";
import { Addon } from "../mixins/Addon";
export default {
  emits: ["delete"],
  mixins: [Addon],
  computed: {
    anchors: {
      get() {
        return this.modelValue;
      },
      set(anchors) {
        // eslint-disable-next-line vue/no-mutating-props
        this.entry.anchors = anchors;
        this.$store.dispatch("push", {
          event: "entry_update",
          params: {
            entry: this.entry
          },
          entry: this.entry,
          undo: false
        });
      }
    },
    entries() {
      return this.$store.getters.entries.filter(e => e.deleted_at === null);
    }
    // anchor() {
    //   return this.entry.anchors?.find(
    //     (a) =>
    //       a.placement === "left-sidebar" || a.placement === "right-sidebar",
    //   );
    // },
  },
  methods: {
    newAnchor(placement) {
      return {
        ...addonBlueprint("anchor", this.entry, this),
        placement,
        position: 9999
      };
    },
    addToLeftSidebar() {
      this.anchors = [...this.anchors, {
        ...this.newAnchor("left-sidebar")
      }];
      this.fixPositions("left-sidebar");
    },
    addToRightSidebar() {
      this.anchors = [...this.anchors, {
        ...this.newAnchor("right-sidebar")
      }];
      this.fixPositions("right-sidebar");
    },
    removeAnchor(placement) {
      this.anchors = this.anchors.filter(a => a.placement !== placement);
      this.fixPositions(placement);
    },
    fixPositions(placement) {
      this.entries.filter(e => e.anchors?.some(a => a.placement === placement)).sort((a, b) => {
        const aAnchor = a.anchors.find(a => a.placement === placement);
        const bAnchor = b.anchors.find(a => a.placement === placement);
        return aAnchor.position - bAnchor.position;
      }).forEach((e, i) => {
        const anchor = e.anchors.find(a => a.placement === placement);
        if (anchor.position != i) {
          anchor.position = i;
          if (!e.temp) this.$store.dispatch("push", {
            event: "anchor_update",
            params: {
              anchor
            },
            entry: e,
            undo: true
          });
        }
      });
    },
    moveAnchorLeft() {
      const placement = this.anchor.placement;
      let leftEntry = this.entries.find(e => e.anchors?.some(a => a.placement === placement && a.position === this.anchor.position - 1));
      const leftAnchor = leftEntry.anchors.find(a => a.placement === placement);
      leftAnchor.position++;
      this.anchor.position--;
      this.fixPositions(placement);
      if (!this.entry.temp) this.$store.dispatch("push", {
        event: "anchor_update",
        params: {
          anchor: this.anchor
        },
        entry: this.entry,
        undo: true
      });
      if (!leftEntry.temp) this.$store.dispatch("push", {
        event: "anchor_update",
        params: {
          anchor: leftAnchor
        },
        entry: leftEntry,
        undo: true
      });
    },
    moveAnchorRight() {
      const placement = this.anchor.placement;
      let rightEntry = this.entries.find(e => e.anchors?.some(a => a.placement === placement && a.position === this.anchor.position + 1));
      const rightAnchor = rightEntry.anchors.find(a => a.placement === placement);
      rightAnchor.position--;
      this.anchor.position++;
      this.fixPositions(placement);
      if (!this.entry.temp) this.$store.dispatch("push", {
        event: "anchor_update",
        params: {
          anchor: this.anchor
        },
        entry: this.entry,
        undo: true
      });
      if (!rightEntry.temp) this.$store.dispatch("push", {
        event: "anchor_update",
        params: {
          anchor: rightAnchor
        },
        entry: rightEntry,
        undo: true
      });
    }
  }
};