import "core-js/modules/es.array.push.js";
import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, withCtx as _withCtx, createTextVNode as _createTextVNode, resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle } from "vue";
const _hoisted_1 = {
  class: "flex-1 truncate"
};
const _hoisted_2 = {
  class: "text-neutral-900 dark:text-neutral-200"
};
const _hoisted_3 = {
  class: "font-mono text-xs text-neutral-700 dark:text-neutral-400"
};
const _hoisted_4 = {
  class: "flex-shrink-0"
};
const _hoisted_5 = {
  class: "flex flex-1 flex-col"
};
const _hoisted_6 = {
  class: "relative flex flex-1 flex-col gap-y-7"
};
const _hoisted_7 = {
  class: "mt-auto flex w-full flex-col items-center"
};
const _hoisted_8 = ["href"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_Logo = _resolveComponent("Logo");
  const _component_SettingsIcon = _resolveComponent("SettingsIcon");
  const _component_ButtonComponentNew = _resolveComponent("ButtonComponentNew");
  const _component_OutputDisplay = _resolveComponent("OutputDisplay");
  const _component_PanelLeftCloseIcon = _resolveComponent("PanelLeftCloseIcon");
  const _component_PanelLeftOpenIcon = _resolveComponent("PanelLeftOpenIcon");
  return _openBlock(), _createElementBlock("div", {
    id: "left-sidebar",
    class: _normalizeClass(_ctx.$style.leftSidebar),
    style: _normalizeStyle($options.cssProps)
  }, [_createElementVNode("div", {
    onClick: _cache[0] || (_cache[0] = $event => _ctx.$router.push({
      name: 'space'
    })),
    class: _normalizeClass(_ctx.$style.leftSidebarTopOuter)
  }, [_createElementVNode("div", {
    class: _normalizeClass([_ctx.$style.leftSidebarTopInner, "flex flex-1 items-center justify-between overflow-visible"])
  }, [_createElementVNode("div", _hoisted_1, [_createElementVNode("span", _hoisted_2, [_createVNode(_component_Logo)]), _createElementVNode("p", _hoisted_3, _toDisplayString($options.space?.active_subscription.name), 1)]), _createElementVNode("div", _hoisted_4, [_createVNode(_component_ButtonComponentNew, {
    class: _normalizeClass(_ctx.$style.buttonSpaceSettings),
    title: "Space settings"
  }, {
    default: _withCtx(() => [_cache[5] || (_cache[5] = _createElementVNode("span", {
      class: "sr-only"
    }, "Open options", -1)), _createVNode(_component_SettingsIcon, {
      size: "20",
      "aria-hidden": "true"
    })]),
    _: 1
  }, 8, ["class"])])], 2)], 2), _createElementVNode("nav", _hoisted_5, [_createElementVNode("div", _hoisted_6, [(_openBlock(), _createBlock(_resolveDynamicComponent('style'), null, {
    default: _withCtx(() => [_createTextVNode(" body:not(:has(#draggable-wrapper)) ." + _toDisplayString(_ctx.$style.leftSidebar) + " [data-entry-id=\"" + _toDisplayString(_ctx.$route.params.id) + "\"] { /* --ps-output-display-entry-display-background: linear-gradient(45deg, var(--ps-left-sidebar-active-entry-background-color), var(--ps-base-tinted-color)); */ --ps-output-display-entry-display-background-color: var(--ps-left-sidebar-active-entry-background-color); --ps-output-display-entry-display-background-color-hover: var(--ps-left-sidebar-active-entry-background-color); } ", 1)]),
    _: 1
  })), _createVNode(_component_OutputDisplay, {
    id: $props.outputId,
    modelValue: $data.originsEntry.output,
    "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => $data.originsEntry.output = $event),
    entry: $data.originsEntry,
    display: {
      status: false,
      procrastination: false,
      links: false,
      description: false,
      schedule: false,
      output: true,
      output_count: true,
      input: false,
      columns: false,
      leftover: false,
      settings: true,
      senses: true,
      routine: false,
      time_trackings: false,
      custom_fields: false
    },
    overrideEntryPermissions: {
      name: false,
      description: false,
      status: false
    },
    sorting: $data.originsEntry.output.sorting,
    position: "left",
    disableViewportChecking: false,
    entriesDisabled: true,
    onOnEntryClick: _cache[2] || (_cache[2] = $event => _ctx.$emit('onEntryClick')),
    overrideEntryDisplayOutput: entry => entry.anchors.find(anchor => anchor.placement == 'left-sidebar')?.settings?.display?.output,
    overrideToggleEntryDisplayOutput: entry => {
      let anchor = entry.anchors.find(anchor => anchor.placement == 'left-sidebar');
      if (anchor) {
        if (!anchor.settings) anchor.settings = {};
        if (!anchor.settings.display) anchor.settings.display = {};
        anchor.settings.display = {
          ...anchor.settings.display,
          output: anchor.settings.display?.output ? !anchor.settings.display.output : true
        };
        if (!entry.temp) _ctx.$store.dispatch('push', {
          event: 'entry_update',
          params: {
            entry: entry
          },
          entry: entry,
          undo: true
        });
      }
    },
    editable: false,
    size: "sm",
    onCreated: _cache[3] || (_cache[3] = entry => {
      _ctx.$document.activeElement?.blur();
      _ctx.$nextTick(() => {
        this.$router.push({
          name: 'entry',
          params: {
            id: entry.id
          }
        });
      });
    }),
    overrideOnDrop: $options.handleAnchorSorting,
    class: _normalizeClass(_ctx.$style.outputDisplay)
  }, null, 8, ["id", "modelValue", "entry", "sorting", "overrideEntryDisplayOutput", "overrideToggleEntryDisplayOutput", "overrideOnDrop", "class"]), _createElementVNode("div", _hoisted_7, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.links, (link, index) => {
    return _openBlock(), _createElementBlock("a", {
      key: index,
      href: link.url,
      target: "_blank",
      class: _normalizeClass([link.classes, "text-sm leading-6 text-neutral-600 hover:text-neutral-950 focus-visible:outline-neutral-500 dark:text-neutral-400 dark:hover:text-neutral-200"])
    }, _toDisplayString(link.text), 11, _hoisted_8);
  }), 128))])])]), _createElementVNode("div", {
    class: _normalizeClass([_ctx.$style.sidebarToggleWrapper, "sidebar_toggle_wrapper"])
  }, [_createVNode(_component_ButtonComponentNew, {
    class: _normalizeClass(_ctx.$style.buttonToggleSidebar),
    title: "Toggle sidebar",
    onClick: _cache[4] || (_cache[4] = $event => _ctx.$emit('update:modelValue', !$props.modelValue)),
    shortcode: "m"
  }, {
    default: _withCtx(() => [_cache[6] || (_cache[6] = _createElementVNode("span", {
      class: "sr-only"
    }, "Toggle sidebar", -1)), $props.modelValue ? (_openBlock(), _createBlock(_component_PanelLeftCloseIcon, {
      key: 0,
      size: "20",
      strokeWidth: "1.5"
    })) : (_openBlock(), _createBlock(_component_PanelLeftOpenIcon, {
      key: 1,
      size: "20",
      strokeWidth: "1.5"
    }))]),
    _: 1
  }, 8, ["class"])], 2)], 6);
}