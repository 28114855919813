import "core-js/modules/es.array.push.js";
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, unref as _unref, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createCommentVNode as _createCommentVNode, createBlock as _createBlock, Transition as _Transition, Teleport as _Teleport } from "vue";
const _hoisted_1 = {
  class: "text-sm"
};
const _hoisted_2 = {
  class: "flex flex-wrap items-center gap-1"
};
const _hoisted_3 = {
  class: "flex items-center"
};
const _hoisted_4 = {
  class: "relative"
};
const _hoisted_5 = {
  class: "pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2"
};
const _hoisted_6 = {
  class: "flex items-center"
};
const _hoisted_7 = {
  key: 0,
  class: /*@__PURE__*/_normalizeClass(['text-gray-500', 'ml-2 truncate text-xs'])
};
import { ref } from "vue";
import { useFloating, shift, flip } from "@floating-ui/vue";
const __default__ = {
  inject: ["entry"],
  props: {
    modelValue: Array,
    statuses: Array
  },
  data() {
    return {
      showSearch: false,
      add: null,
      selected: this.modelValue
    };
  },
  computed: {
    selectedStatuses() {
      return this.selected.map(status_id => this.$store.getters.statuses.find(status => status.id === status_id)).filter(s => s);
    }
  },
  watch: {
    modelValue: {
      handler(n) {
        this.selected = n;
      },
      immediate: true
    },
    add: function () {
      if (this.add) this.selected.push(this.add);
      this.$emit("update:modelValue", this.selected);
      this.add = null;
    }
  }
};
export default /*@__PURE__*/Object.assign(__default__, {
  __name: 'StatusBuilder',
  setup(__props) {
    const reference = ref(null);
    const floating = ref(null);
    const {
      floatingStyles
    } = useFloating(reference, floating, {
      transform: false,
      placement: "bottom-start",
      middleware: [flip(), shift({
        crossAxis: true
      })]
    });
    return (_ctx, _cache) => {
      const _component_LabelButton = _resolveComponent("LabelButton");
      const _component_ChevronsUpDownIcon = _resolveComponent("ChevronsUpDownIcon");
      const _component_ListboxButton = _resolveComponent("ListboxButton");
      const _component_CheckIcon = _resolveComponent("CheckIcon");
      const _component_ListboxOption = _resolveComponent("ListboxOption");
      const _component_ListboxOptions = _resolveComponent("ListboxOptions");
      const _component_Listbox = _resolveComponent("Listbox");
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.selectedStatuses, (status, index) => {
        return _openBlock(), _createElementBlock("div", {
          key: index
        }, [_createVNode(_component_LabelButton, {
          onClick: () => {
            _ctx.selected.splice(index, 1);
            _ctx.$emit('update:modelValue', _ctx.selected);
          }
        }, {
          default: _withCtx(() => [_createTextVNode(_toDisplayString(status?.name), 1)]),
          _: 2
        }, 1032, ["onClick"])]);
      }), 128)), _createElementVNode("div", _hoisted_3, [__props.statuses.filter(s => {
        return !_ctx.selectedStatuses.map(s => s.id).includes(s.id);
      }).length ? (_openBlock(), _createBlock(_component_Listbox, {
        key: 0,
        as: "div",
        modelValue: _ctx.add,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => _ctx.add = $event)
      }, {
        default: _withCtx(() => [_createElementVNode("div", _hoisted_4, [_createVNode(_component_ListboxButton, {
          ref_key: "reference",
          ref: reference,
          class: "relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-neutral-600 sm:text-sm sm:leading-6"
        }, {
          default: _withCtx(() => [_cache[1] || (_cache[1] = _createElementVNode("span", {
            class: "flex items-center"
          }, [_createElementVNode("span", {
            class: "block truncate"
          }, " Select ...")], -1)), _createElementVNode("span", _hoisted_5, [_createVNode(_component_ChevronsUpDownIcon, {
            size: "20",
            class: "text-gray-400",
            "aria-hidden": "true"
          })])]),
          _: 1
        }, 512), (_openBlock(), _createBlock(_Teleport, {
          to: "body"
        }, [_createVNode(_Transition, {
          "leave-active-class": "transition ease-in duration-100",
          "leave-from-class": "opacity-100",
          "leave-to-class": "opacity-0"
        }, {
          default: _withCtx(() => [_createVNode(_component_ListboxOptions, {
            ref_key: "floating",
            ref: floating,
            class: "absolute z-[99999] mt-1 max-h-60 min-w-fit overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm",
            style: _normalizeStyle(_unref(floatingStyles))
          }, {
            default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.statuses.filter(s => !_ctx.selectedStatuses.map(s => s.id).includes(s.id)), status => {
              return _openBlock(), _createBlock(_component_ListboxOption, {
                as: "template",
                key: status.id,
                value: status.id
              }, {
                default: _withCtx(({
                  active,
                  _selected
                }) => [_createElementVNode("li", {
                  class: _normalizeClass([active ? 'bg-neutral-600 text-white' : 'text-gray-900', 'relative cursor-default select-none py-2 pl-3 pr-9'])
                }, [_createElementVNode("div", _hoisted_6, [_createElementVNode("span", {
                  class: _normalizeClass([_ctx.$style.statusColor, 'inline-block h-2 w-2 flex-shrink-0 rounded-full']),
                  style: _normalizeStyle({
                    '--ps-color': _ctx.$colors[status.color || 'neutral'][400]
                  }),
                  "aria-hidden": "true"
                }, null, 6), _createElementVNode("span", {
                  class: _normalizeClass([_selected ? 'font-semibold' : 'font-normal', 'ml-3 block truncate'])
                }, _toDisplayString(status.name), 3), status.entry_id ? (_openBlock(), _createElementBlock("span", _hoisted_7, _toDisplayString(_ctx.$store.getters.entries.find(e => e.id == status.entry_id)?.name), 1)) : _createCommentVNode("", true)]), _selected ? (_openBlock(), _createElementBlock("span", {
                  key: 0,
                  class: _normalizeClass([active ? 'text-white' : 'text-neutral-600', 'absolute inset-y-0 right-0 flex items-center pr-4'])
                }, [_createVNode(_component_CheckIcon, {
                  class: "h-5 w-5",
                  "aria-hidden": "true"
                })], 2)) : _createCommentVNode("", true)], 2)]),
                _: 2
              }, 1032, ["value"]);
            }), 128))]),
            _: 1
          }, 8, ["style"])]),
          _: 1
        })]))])]),
        _: 1
      }, 8, ["modelValue"])) : _createCommentVNode("", true)])])]);
    };
  }
});