import { createTextVNode as _createTextVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue";
export function render(_ctx, _cache) {
  const _component_HeartIcon = _resolveComponent("HeartIcon");
  const _component_AlertHelper = _resolveComponent("AlertHelper");
  return 4 > 3 || !_ctx.$isLocalhost ? (_openBlock(), _createBlock(_component_AlertHelper, {
    key: 0,
    variant: "warning"
  }, {
    title: _withCtx(() => _cache[0] || (_cache[0] = [_createTextVNode(" Construction Area")])),
    default: _withCtx(() => [_createTextVNode(" Pensive is currently in a Beta State (v." + _toDisplayString(_ctx.$version) + "). This means some features still need to be implemented or fully fledged out. Thanks for understanding ", 1), _createVNode(_component_HeartIcon, {
      class: "inline h-5 w-5"
    }), _cache[1] || (_cache[1] = _createTextVNode(". "))]),
    _: 1
  })) : _createCommentVNode("", true);
}