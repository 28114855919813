import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue";
const _hoisted_1 = {
  key: 0
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_EntrySettings = _resolveComponent("EntrySettings");
  const _component_EntryModalPage = _resolveComponent("EntryModalPage");
  return $options.entry_by_route ? (_openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_EntrySettings, {
    modelValue: $options.entry_by_route,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => $options.entry_by_route = $event),
    position: "center",
    "data-tour": "entry_page"
  }, null, 8, ["modelValue"]), _createVNode(_component_EntryModalPage)])) : _createCommentVNode("", true);
}