import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode } from "vue";
const _hoisted_1 = {
  class: "relative overflow-hidden"
};
const _hoisted_2 = {
  key: 0,
  class: "invisible"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", _hoisted_1, [!_ctx.$Cypress ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString($props.text), 1)) : _createCommentVNode("", true), _createVNode(_Transition, {
    onEnter: $options.onEnter,
    onLeave: $options.onLeave
  }, {
    default: _withCtx(() => [(_openBlock(), _createElementBlock("span", {
      key: $props.text,
      class: _normalizeClass({
        'absolute left-0 top-0': !_ctx.$Cypress && $props.align == 'left',
        'absolute right-0 top-0': !_ctx.$Cypress && $props.align == 'right',
        'absolute inset-0 text-center': !_ctx.$Cypress && $props.align == 'center'
      })
    }, _toDisplayString($props.text), 3))]),
    _: 1
  }, 8, ["onEnter", "onLeave"])]);
}