import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue";
const _hoisted_1 = {
  class: "z-100 p-2"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_ButtonComponent = _resolveComponent("ButtonComponent");
  const _component_UseTemplatePopover = _resolveComponent("UseTemplatePopover");
  const _component_SaveTemplate = _resolveComponent("SaveTemplate");
  const _component_PopoverHelper = _resolveComponent("PopoverHelper");
  return _openBlock(), _createBlock(_component_PopoverHelper, {
    placement: "right"
  }, {
    button: _withCtx(() => [_renderSlot(_ctx.$slots, "button", {}, () => [_cache[2] || (_cache[2] = _createElementVNode("span", {
      class: "sr-only"
    }, " Templates", -1)), _createVNode(_component_ButtonComponent, {
      color: _ctx.color
    }, {
      default: _withCtx(() => _cache[1] || (_cache[1] = [_createTextVNode(" Templates ")])),
      _: 1
    }, 8, ["color"])])]),
    default: _withCtx(() => [_createElementVNode("div", _hoisted_1, [_createVNode(_component_UseTemplatePopover, {
      entry: _ctx.entry,
      size: _ctx.size,
      color: "white",
      variant: "menu",
      onTemplateSelected: _cache[0] || (_cache[0] = $event => _ctx.$emit('close'))
    }, null, 8, ["entry", "size"]), _createVNode(_component_SaveTemplate, {
      entry: _ctx.entry,
      size: _ctx.size,
      color: "white",
      variant: "menu"
    }, null, 8, ["entry", "size"])])]),
    _: 3
  });
}