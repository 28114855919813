import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  class: "flex flex-col gap-2"
};
const _hoisted_2 = {
  class: "flex items-center justify-between gap-2"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_SelectMenu = _resolveComponent("SelectMenu");
  const _component_TextInput = _resolveComponent("TextInput");
  const _component_TimePicker = _resolveComponent("TimePicker");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createVNode(_component_SelectMenu, {
    modelValue: $options.time?.op,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = val => $options.time = {
      ...$options.time,
      op: val
    }),
    options: [{
      title: 'No Time',
      value: null
    }, {
      title: 'Now',
      value: 'now'
    }, {
      title: 'Next ...',
      value: 'nextXHours'
    }, {
      title: 'Next ...',
      value: 'nextXMinutes',
      hidden: true
    }, {
      title: 'Next ...',
      value: 'nextXSeconds',
      hidden: true
    }, {
      title: 'Last ...',
      value: 'lastXHours'
    }, {
      title: 'Last ...',
      value: 'lastXMinutes',
      hidden: true
    }, {
      title: 'Last ...',
      value: 'lastXSeconds',
      hidden: true
    }, {
      title: 'Fixed Time',
      value: 'timeX'
    }],
    class: "flex-1"
  }, null, 8, ["modelValue"]), $options.time?.op?.includes('nextX') || $options.time?.op?.includes('lastX') ? (_openBlock(), _createBlock(_component_TextInput, {
    key: 0,
    modelValue: $options.time.x,
    "onUpdate:modelValue": [_cache[1] || (_cache[1] = $event => $options.time.x = $event), _cache[2] || (_cache[2] = val => {
      $options.time = {
        ...$options.time,
        x: val
      };
    })],
    validation: val => val.replace(/[^\d]/g, ''),
    class: _normalizeClass([_ctx.$style.textInput, "w-20 text-sm"])
  }, null, 8, ["modelValue", "validation", "class"])) : _createCommentVNode("", true), $options.time?.op?.includes('next') ? (_openBlock(), _createBlock(_component_SelectMenu, {
    key: 1,
    modelValue: $options.time.op,
    "onUpdate:modelValue": _cache[3] || (_cache[3] = val => $options.time = {
      ...$options.time,
      op: val
    }),
    options: [{
      title: 'Hours',
      value: 'nextXHours'
    }, {
      title: 'Minutes',
      value: 'nextXMinutes'
    }, {
      title: 'Seconds',
      value: 'nextXSeconds'
    }],
    class: "flex-1"
  }, null, 8, ["modelValue"])) : _createCommentVNode("", true), $options.time?.op?.includes('last') ? (_openBlock(), _createBlock(_component_SelectMenu, {
    key: 2,
    modelValue: $options.time.op,
    "onUpdate:modelValue": _cache[4] || (_cache[4] = val => $options.time = {
      ...$options.time,
      op: val
    }),
    options: [{
      title: 'Hours',
      value: 'lastXHours'
    }, {
      title: 'Minutes',
      value: 'lastXMinutes'
    }, {
      title: 'Seconds',
      value: 'lastXSeconds'
    }],
    class: "flex-1"
  }, null, 8, ["modelValue"])) : _createCommentVNode("", true), $options.time?.op === 'timeX' ? (_openBlock(), _createBlock(_component_TimePicker, {
    key: 3,
    modelValue: $options.time.x,
    "onUpdate:modelValue": _cache[5] || (_cache[5] = $event => $options.time.x = $event),
    class: _normalizeClass(_ctx.$style.textInput)
  }, null, 8, ["modelValue", "class"])) : _createCommentVNode("", true)])]);
}