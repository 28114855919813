import "core-js/modules/es.array.push.js";
import { withModifiers as _withModifiers, withKeys as _withKeys, mergeProps as _mergeProps, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, unref as _unref, resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, renderSlot as _renderSlot, toDisplayString as _toDisplayString, vShow as _vShow, normalizeClass as _normalizeClass, withDirectives as _withDirectives, normalizeStyle as _normalizeStyle, Teleport as _Teleport, createBlock as _createBlock } from "vue";
const _hoisted_1 = ["value"];
const _hoisted_2 = ["onClick"];
const _hoisted_3 = {
  class: "ml-auto text-neutral-500"
};
import { ref } from "vue";
import { useFloating, shift, flip, autoUpdate } from "@floating-ui/vue";
const __default__ = {
  inheritAttrs: false,
  props: {
    modelValue: Number,
    basis: String
  },
  data() {
    return {
      internalDate: "",
      internalTime: "",
      internalVisibleTime: "",
      showPopover: false
    };
  },
  watch: {
    duration(val) {
      this.internalDuration = val;
      this.calculateTime();
    },
    // eslint-disable-next-line no-unused-vars
    basis(val) {
      this.calculateTime();
    }
  },
  mounted() {
    this.internalDate = this.$moment.utc(this.basis).clone();
    this.internalDuration = this.time;
    this.calculateTime();
  },
  computed: {
    duration: {
      get() {
        return this.modelValue;
      },
      set(val) {
        this.$emit("update:modelValue", val);
        this.internalDate = this.$moment.utc(this.basis).clone();
      }
    },
    slots() {
      if (!this.basis) {
        return Array.from({
          length: 24
        }, (_, hour) => {
          return ["00", "15", "30", "45"].map(minute => {
            return `${String(hour).padStart(2, "0")}:${minute}`;
          });
        }).flat();
      }
      const slots = [];
      const basisMoment = this.$moment.utc(this.internalDate);
      const endMoment = this.$moment.utc(this.internalDate).add(8, "hours");
      let currentMoment = basisMoment.clone();
      // Round to next 15 minute interval
      const minutes = currentMoment.minutes();
      const remainder = minutes % 15;
      if (remainder > 0) {
        currentMoment.add(15 - remainder, "minutes");
      }
      while (currentMoment.isSameOrBefore(endMoment)) {
        slots.push({
          time: currentMoment.format("HH:mm"),
          date: currentMoment.format("YYYY-MM-DD")
        });
        currentMoment.add(15, "minutes");
      }
      return slots;
    }
  },
  methods: {
    renderDuration(slot) {
      const timeMoment = this.$moment.utc(slot.date + " " + slot.time);
      const duration = this.$moment.duration(this.$moment(timeMoment).diff(this.$moment.utc(this.basis)));
      const days = Math.floor(duration.asDays());
      const hours = duration.hours();
      const minutes = duration.minutes();
      return (days ? days + "d " : "") + (hours || days ? hours + "h " : "") + minutes + "m";
    },
    isValidTime(time) {
      return /^([01]\d|2[0-3])(:[0-5]\d(:[0-5]\d)?)?$/.test(time);
    },
    calculateTime() {
      if (this.basis === null) {
        this.internalVisibleTime = "";
        return;
      }
      this.internalVisibleTime = this.$moment.utc(this.basis).add(this.duration ? this.duration : 0, "seconds").local().format("HH:mm");
    },
    handleOnFocus() {
      this.showPopover = true;
      this.$refs.reference.select();
    },
    handleOnBlur() {
      if (this.internalVisibleTime === "") {
        this.duration = null;
        this.delayShowPopover(false);
        return;
      }
      if (!this.isValidTime(this.internalVisibleTime)) {
        this.calculateTime();
        return;
      }
      if (this.isValidTime(this.internalVisibleTime)) {
        let endTimeMoment = this.$moment(this.internalDate.format("YYYY-MM-DD") + " " + this.internalVisibleTime).utc();

        // // If the end time is before the basis time, add a day
        // if (endTimeMoment.isBefore(this.$moment.utc(this.basis))) {
        //   endTimeMoment = endTimeMoment.add(1, "day");
        // }

        if (endTimeMoment.isValid()) {
          this.$refs.reference.blur();
          this.duration = endTimeMoment.utc().diff(this.$moment.utc(this.basis), "seconds");
          this.delayShowPopover(false);
        }
      }
    },
    // handleOnEnter() {
    //   if (this.isValidTime(this.internalVisibleTime)) {
    //     const endTimeMoment = this.$moment(
    //       this.$moment.utc(this.basis).format("YYYY-MM-DD") +
    //         " " +
    //         this.internalVisibleTime,
    //     );
    //     if (endTimeMoment.isValid()) {
    //       this.$refs.reference.blur();
    //       this.duration = endTimeMoment
    //         .utc()
    //         .diff(this.$moment.utc(this.basis), "seconds");
    //     }
    //   }
    // },
    delayShowPopover(show) {
      setTimeout(() => {
        this.showPopover = show;
      }, 100);
    }
  }
};
export default /*@__PURE__*/Object.assign(__default__, {
  __name: 'DurationPicker',
  setup(__props) {
    const reference = ref(null);
    const floating = ref(null);
    const {
      floatingStyles
    } = useFloating(reference, floating, {
      placement: "bottom-center",
      whileElementsMounted: autoUpdate,
      middleware: [flip(), shift({
        crossAxis: true
      })]
    });
    return (_ctx, _cache) => {
      const _component_DatePicker = _resolveComponent("DatePicker");
      return _openBlock(), _createElementBlock("div", null, [_createElementVNode("input", _mergeProps({
        ref_key: "reference",
        ref: reference,
        value: _ctx.internalVisibleTime,
        onInput: _cache[0] || (_cache[0] = $event => _ctx.internalVisibleTime = $event.target.value),
        type: "text",
        onFocus: _cache[1] || (_cache[1] = (...args) => _ctx.handleOnFocus && _ctx.handleOnFocus(...args)),
        onBlur: _cache[2] || (_cache[2] = (...args) => _ctx.handleOnBlur && _ctx.handleOnBlur(...args)),
        onKeydown: _cache[3] || (_cache[3] = _withKeys(_withModifiers($event => _ctx.$refs.reference.blur(), ["stop", "prevent"]), ["enter"])),
        class: _ctx.$style.input
      }, _ctx.$attrs), null, 16, _hoisted_1), (_openBlock(), _createBlock(_Teleport, {
        to: "body"
      }, [_ctx.showPopover ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        onClick: _cache[4] || (_cache[4] = _withModifiers(() => {}, ["stop", "prevent"])),
        onPointerdown: _cache[5] || (_cache[5] = _withModifiers($event => _ctx.showPopover = false, ["stop", "prevent"])),
        onTouchstart: _cache[6] || (_cache[6] = _withModifiers(() => {}, ["stop", "prevent"])),
        class: "fixed inset-0 bg-black opacity-0"
      }, null, 32)) : _createCommentVNode("", true), _ctx.showPopover ? (_openBlock(), _createElementBlock("div", {
        key: 1,
        ref_key: "floating",
        ref: floating,
        class: _normalizeClass(_ctx.$style.popover),
        style: _normalizeStyle(_unref(floatingStyles))
      }, [_createVNode(_component_DatePicker, {
        modelValue: _ctx.internalDate.format('YYYY-MM-DD'),
        "onUpdate:modelValue": _cache[7] || (_cache[7] = $event => _ctx.internalDate = _ctx.$moment.utc(_ctx.internalDate).set({
          year: _ctx.$moment($event).year(),
          month: _ctx.$moment($event).month(),
          date: _ctx.$moment($event).date()
        }))
      }, null, 8, ["modelValue"]), (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.slots, (slot, key) => {
        return _openBlock(), _createElementBlock("div", {
          key: key,
          onClick: _withModifiers(() => {
            _ctx.showPopover = false;
            let endTimeMoment = _ctx.$moment.utc(slot.date + ' ' + slot.time);
            _ctx.internalVisibleTime = endTimeMoment.local().format('HH:mm');
            _ctx.duration = endTimeMoment.diff(_ctx.$moment.utc(__props.basis), 'seconds');
          }, ["prevent"])
        }, [_renderSlot(_ctx.$slots, "timeSuggestion", {
          time: slot.time
        }, () => [_withDirectives(_createElementVNode("button", {
          class: _normalizeClass(_ctx.$style.buttonTimeSuggestion)
        }, [_createElementVNode("span", null, _toDisplayString(_ctx.$moment.utc(slot.date + " " + slot.time).local().format("HH:mm")), 1), _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.renderDuration(slot)), 1)], 2), [[_vShow, __props.basis || _ctx.$moment.utc(_ctx.$moment.utc(__props.basis).format('YYYY-MM-DD') + ' ' + slot.time).isAfter(__props.basis ? _ctx.$moment.utc(__props.basis) : _ctx.$moment.utc())]])])], 8, _hoisted_2);
      }), 128))], 6)) : _createCommentVNode("", true)]))]);
    };
  }
});