import "core-js/modules/es.array.push.js";
import { Routining } from "@/components/mixins/Routining";
export default {
  mixins: [Routining],
  props: {
    entry: {
      type: Object,
      required: true
    },
    date: Object
  },
  methods: {
    isCurrentSlot(date) {
      switch (this.entry.routine.frequency) {
        case "weekly":
          // console.log("weekly");
          // console.log(date.format("YYYY-MM-DD"));
          // console.log(date.startOf("week").format("YYYY-MM-DD"));
          // console.log(this.$moment().startOf("week").format("YYYY-MM-DD"));
          return date.isSame(this.$moment(), "week");
        case "monthly":
          return date.isSame(this.$moment(), "month");
        case "daily":
        default:
          return date.isSame(this.$moment(), "day");
      }
    }
  },
  computed: {
    slots() {
      switch (this.entry.routine.frequency) {
        case "weekly":
          var slots = [];
          var cursor = this.date.clone().startOf("week", {
            weekStartsOn: 1
          });
          if (cursor.isBefore(this.date)) {
            slots.push({
              gridSpan: 7 - this.date.clone().diff(cursor, "days"),
              date: cursor.clone(),
              log: this.getLogBySpan({
                entry: this.entry,
                span_at: cursor.format("YYYY-MM-DD")
              })
            });
            cursor.add(1, "week");
          }
          while (cursor.isBefore(this.date.clone().endOf("month"))) {
            slots.push({
              gridSpan: Math.min(7, this.date.clone().endOf("month").diff(cursor, "days") + 1),
              date: cursor.clone(),
              log: this.getLogBySpan({
                entry: this.entry,
                span_at: cursor.format("YYYY-MM-DD")
              })
            });
            cursor.add(1, "week");
          }
          return slots;
        case "monthly":
          return [{
            gridSpan: this.date.daysInMonth(),
            date: this.date,
            log: this.getLogBySpan({
              entry: this.entry,
              span_at: this.date.format("YYYY-MM-DD")
            })
          }];
        case "daily":
        default:
          return Array.from({
            length: this.date.daysInMonth()
          }, (_, i) => {
            return {
              gridSpan: 1,
              date: this.date.clone().add(i, "days"),
              log: this.getLogBySpan({
                entry: this.entry,
                span_at: this.date.clone().add(i, "days").format("YYYY-MM-DD")
              })
            };
          });
      }
    }
  }
};