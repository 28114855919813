//import { StripeCheckout } from "@vue-stripe/vue-stripe";
import axios from "axios";
import moment from "moment";
import Unverified from "./Unverified.vue";
export default {
  name: "SubscriptionComponent",
  components: {
    //StripeCheckout,
    Unverified
  },
  computed: {
    user() {
      return this.$store.getters.user;
    },
    subscriptions() {
      return this.$store.getters.subscriptions;
    },
    isActive() {
      return moment().unix() <= moment(this.user.active_until).unix();
    },
    details() {
      return [{
        title: "Full name",
        value: this.user.fullname
      }, {
        title: "Email address",
        value: this.user.email
      }, {
        title: "Timezone",
        value: this.user.timezone
      }];
    }
  },
  data() {
    this.publishableKey = process.env.NODE_ENV === "production" ? "pk_live_51MjPE1DONrNfdT6AfWgTdMSh6KRj05vePn6AY5Uq6IlCB45ERT7K5GJ7T8lcudkwDRNN4b2nonEj37XKiA8Ocr090005ihksck" : "pk_test_51MjPE1DONrNfdT6ATalHegQHUkO9XKz5sbJjPUeNMQDRZopnqV977FJhJByr7KyDJ9yMMJ3MLfKULNWZkH7SIWPX00eDL1Gy74";
    return {
      moment: moment,
      showApiToken: false,
      sessionId: null,
      portalURL: null,
      loadingSubscriptions: true,
      showCheckout: false
    };
  },
  mounted() {
    if (this.user.verified) {
      axios.get("stripe/session").then(response => {
        this.sessionId = response.data.id;
      });
      axios.get("stripe/portal").then(response => {
        this.portalURL = response.data.url;
      });
      this.$store.dispatch("retrieveSubscriptions").then(() => {
        this.loadingSubscriptions = false;
      });
    }
  },
  methods: {
    submit() {
      // You will be redirected to Stripe's secure checkout page
      this.showCheckout = true;
      this.$nextTick(() => {
        this.$refs.checkoutRef.redirectToCheckout();
      });
    },
    getCurrentPeriod(sub) {
      return moment(new Date(sub.current_period_end * 1000)).format("YYYY-MM-DD HH:mm:ss");
    }
  }
};