import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vModelDynamic as _vModelDynamic, withKeys as _withKeys, withDirectives as _withDirectives, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = ["for"];
const _hoisted_2 = ["id", "name", "type", "autocomplete", "disabled", "required", "placeholder"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", null, [_createElementVNode("label", {
    for: _ctx.$slugify($props.label),
    class: "block text-sm font-medium leading-6 text-neutral-900 dark:text-neutral-300"
  }, _toDisplayString($props.label), 9, _hoisted_1), _createElementVNode("div", {
    class: _normalizeClass({
      'mt-2': $props.label
    })
  }, [_withDirectives(_createElementVNode("input", {
    "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => $options.input = $event),
    id: _ctx.$slugify($props.label),
    name: _ctx.$slugify($props.label),
    type: $props.type,
    autocomplete: $props.autocomplete,
    disabled: $props.disabled,
    required: $props.required,
    placeholder: $props.placeholder,
    class: "block w-full rounded-md border-0 px-3 py-1.5 text-neutral-900 shadow-sm outline-none ring-1 ring-inset ring-neutral-300 placeholder:text-neutral-400 focus:ring-2 focus:ring-inset focus:ring-neutral-600 sm:text-sm sm:leading-6 dark:bg-black dark:text-neutral-300",
    onBlur: _cache[1] || (_cache[1] = () => _ctx.$emit('blur')),
    onKeyup: _cache[2] || (_cache[2] = _withKeys(() => _ctx.$emit('keyup-enter'), ["enter"]))
  }, null, 40, _hoisted_2), [[_vModelDynamic, $options.input]])], 2)]);
}