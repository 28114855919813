import "core-js/modules/es.array.push.js";
import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, normalizeClass as _normalizeClass, withCtx as _withCtx, createElementVNode as _createElementVNode, unref as _unref, withModifiers as _withModifiers, vModelText as _vModelText, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, createTextVNode as _createTextVNode, vModelRadio as _vModelRadio, normalizeStyle as _normalizeStyle } from "vue";
const _hoisted_1 = ["data-date"];
const _hoisted_2 = {
  class: "z-40 max-h-screen min-w-fit overflow-hidden rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5 dark:bg-black dark:text-neutral-400 dark:ring-neutral-700"
};
const _hoisted_3 = {
  key: 0
};
const _hoisted_4 = ["onClick"];
const _hoisted_5 = {
  key: 0
};
const _hoisted_6 = {
  key: 1
};
const _hoisted_7 = {
  class: "flex flex-col gap-2 p-2"
};
const _hoisted_8 = {
  class: "flex flex-wrap gap-2"
};
const _hoisted_9 = {
  key: 0,
  class: "grid grid-cols-7 gap-0.5"
};
const _hoisted_10 = {
  key: 1
};
const _hoisted_11 = {
  class: "flex flex-wrap items-center gap-2"
};
const _hoisted_12 = {
  key: 0,
  class: "grid grid-cols-7 gap-0.5"
};
const _hoisted_13 = {
  key: 1
};
const _hoisted_14 = {
  class: "flex flex-wrap gap-2"
};
const _hoisted_15 = {
  key: 0
};
const _hoisted_16 = {
  key: 1
};
const _hoisted_17 = {
  class: "grid grid-cols-7 gap-0.5"
};
const _hoisted_18 = {
  style: {
    "font-weight": "medium"
  }
};
const _hoisted_19 = {
  style: {
    "color": "var(--color-medium-gray)",
    "font-size": "var(--font-size-smaller)"
  }
};
const _hoisted_20 = {
  key: 1,
  class: "p-3"
};
const _hoisted_21 = {
  style: {
    "display": "flex",
    "justify-content": "space-between",
    "gap": "1rem"
  }
};
const _hoisted_22 = {
  style: {
    "display": "flex",
    "gap": "0.5rem"
  }
};
const _hoisted_23 = {
  style: {
    "font-weight": "bold"
  }
};
const _hoisted_24 = {
  style: {
    "display": "flex",
    "gap": "0.5rem"
  }
};
const _hoisted_25 = {
  style: {
    "font-weight": "bold",
    "text-transform": "uppercase"
  }
};
import { Popover, PopoverButton, PopoverPanel, PopoverOverlay } from "@headlessui/vue";
import { ref } from "vue";
import { useFloating, shift, flip } from "@floating-ui/vue";
import { Scheduling } from "@/components//mixins/Scheduling";
import { Statusing } from "@/components//mixins/Statusing";
const __default__ = {
  // inject: ["position"],
  name: "DatePicker",
  mixins: [Scheduling, Statusing],
  props: {
    modelValue: null,
    entry: Object,
    showRecurrence: {
      type: Boolean,
      default: true
    },
    autoSetOnClick: {
      type: Boolean,
      default: true
    },
    teleport: Boolean
  },
  data() {
    return {
      cursor: this.modelValue || this.$moment().format("YYYY-MM-DD"),
      recurrence: this.entry?.schedule?.recurrence ? JSON.parse(JSON.stringify(this.entry.schedule.recurrence)) : {
        op: null
      },
      recurrenceOptions: [{
        title: "Every Day",
        value: {
          op: "nextXDays",
          x: 1
        }
      }, {
        title: "Every 2 Days",
        value: {
          op: "nextXDays",
          x: 2
        }
      }, {
        title: "2x / Week",
        value: {
          op: "nextXWeeks",
          x: 0.5
        }
      }, {
        title: "Every Week",
        value: {
          op: "nextXWeeks",
          x: 1
        }
      }, {
        title: "Every 2 Weeks",
        value: {
          op: "nextXWeeks",
          x: 2
        }
      }, {
        title: "Every Month",
        value: {
          op: "nextXMonths",
          x: 1
        }
      }, {
        title: "Every 2 Months",
        value: {
          op: "nextXMonths",
          x: 2
        }
      }, {
        title: "Every Quarter",
        value: {
          op: "nextXMonths",
          x: 3
        }
      }, {
        title: "2x / Year",
        value: {
          op: "nextXYears",
          x: 0.5
        }
      }, {
        title: "Every Year",
        value: {
          op: "nextXYears",
          x: 1
        }
      }]
    };
  },
  watch: {
    modelValue: {
      handler() {
        this.cursor = this.modelValue || this.$moment().format("YYYY-MM-DD");
      },
      deep: true
    },
    "entry.schedule.recurrence": function () {
      this.recurrence = JSON.parse(JSON.stringify(this.entry?.schedule?.recurrence)) || {
        op: null
      };
    },
    "recurrence.op": function () {
      if (this.recurrence.op == "onDays") {
        this.recurrence.x = [0];
      } else if (["nextXDays", "nextXWeeks", "nextXMonths", "nextXYears"].includes(this.recurrence.op)) {
        this.recurrence.x = 1;
      } else if (this.recurrence.op == "customSettings") {
        this.recurrence.baseX = 1;
        this.recurrence.base = "day";
      }
    },
    "recurrence.base": function () {
      if (this.recurrence.base == "week") {
        this.recurrence.x = [0];
      } else if (this.recurrence.base == "month") {
        this.recurrence.monthMode = "date";
      }
    },
    "recurrence.monthMode": function () {
      if (this.recurrence.monthMode == "week") {
        this.recurrence.weekInMonth = 1;
        this.recurrence.dayInWeek = 0;
      }
    }
  },
  computed: {
    date: {
      get() {
        return this.modelValue;
      },
      set(date) {
        // console.log("date", date);
        this.$emit("update", date);
        this.$emit("update:modelValue", date);
      }
    },
    displayText() {
      return this.date ? this.$formattedDateOp(this.date) : null;
    },
    dayOptions() {
      return Array.from({
        length: this.$moment(this.cursor).local().daysInMonth()
      }, (_, i) => i + 1).map(day => {
        const _date = this.$moment(this.$moment(this.cursor).set("date", day)).format("YYYY-MM-DD");
        return {
          title: this.$moment(_date).date().toString().padStart(2, "0") /* +
                                                                        (_date == date ? " · " + this.$moment(_date).format("ddd") : "")*/,
          date: _date,
          active: _date == this.modelValue
        };
      });
    }
  }
};
export default /*@__PURE__*/Object.assign(__default__, {
  setup(__props) {
    const reference = ref(null);
    const floating = ref(null);
    const {
      floatingStyles
    } = useFloating(reference, floating, {
      placement: "top-start",
      middleware: [flip(), shift({
        crossAxis: true
      })]
    });
    return (_ctx, _cache) => {
      const _component_CalendarIcon = _resolveComponent("CalendarIcon");
      const _component_Repeat2Icon = _resolveComponent("Repeat2Icon");
      const _component_ButtonComponent = _resolveComponent("ButtonComponent");
      const _component_TrashIcon = _resolveComponent("TrashIcon");
      const _component_CalendarComponent = _resolveComponent("CalendarComponent");
      const _component_ButtonComponentNew = _resolveComponent("ButtonComponentNew");
      const _component_SelectMenu = _resolveComponent("SelectMenu");
      const _component_SaveIcon = _resolveComponent("SaveIcon");
      const _component_PopoverHelper = _resolveComponent("PopoverHelper");
      return _openBlock(), _createElementBlock("div", null, [_renderSlot(_ctx.$slots, "default", {
        date: _ctx.date
      }, () => [_createVNode(_component_PopoverHelper, {
        id: "date_picker",
        teleport: __props.teleport,
        class: "relative"
      }, {
        button: _withCtx(() => [_createVNode(_component_ButtonComponent, {
          onClick: _cache[0] || (_cache[0] = e => {
            __props.autoSetOnClick === true ? __props.entry ? typeof _ctx.date != 'string' && _ctx.date !== null ? _ctx.date = _ctx.$moment.utc().format('YYYY-MM-DD') : null : typeof _ctx.date == 'undefined' || _ctx.date === null || _ctx.date.length === 0 ? _ctx.date = {
              op: 'today'
            } : null : null;
            // $emit('click', e);
          }),
          variant: "none",
          ref_key: "reference",
          ref: reference,
          class: _normalizeClass([{
            'font-semibold': _ctx.$formattedDateOp(_ctx.date) == 'today' || _ctx.$formattedDateOp(_ctx.date) == 'yesterday' && !_ctx.statusIsDone(__props.entry?.status),
            '!text-neutral-700 hover:!text-black dark:!text-neutral-400 dark:hover:!text-neutral-300': _ctx.$formattedDateOp(_ctx.date) == 'today',
            '!text-red-500 hover:!text-red-600 dark:!text-red-600 dark:hover:!text-red-500': _ctx.$formattedDateOp(_ctx.date) == 'yesterday' && __props.entry?.status && !_ctx.statusIsDone(__props.entry?.status)
          }, "group inline-flex items-center gap-1 whitespace-nowrap text-neutral-500 hover:text-neutral-700 dark:hover:text-neutral-400"]),
          title: "Set schedule date",
          "data-table-column-target": ""
        }, {
          default: _withCtx(() => [_createVNode(_component_CalendarIcon, {
            size: "14"
          }), _ctx.date ? (_openBlock(), _createElementBlock("span", {
            key: 0,
            "data-date": _ctx.date,
            class: "uppercase"
          }, _toDisplayString(_ctx.displayText), 9, _hoisted_1)) : _createCommentVNode("", true), __props.entry?.schedule?.recurrence ? (_openBlock(), _createBlock(_component_Repeat2Icon, {
            key: 1,
            size: "16"
          })) : _createCommentVNode("", true)]),
          _: 1
        }, 8, ["class"])]),
        default: _withCtx(({
          close
        }) => [_createElementVNode("div", _hoisted_2, [typeof _ctx.date != 'object' || _ctx.date === null ? (_openBlock(), _createElementBlock("div", _hoisted_3, [_createVNode(_unref(PopoverButton), {
          onClick: _cache[1] || (_cache[1] = $event => _ctx.date = _ctx.$moment().format('YYYY-MM-DD')),
          class: "flex w-full cursor-pointer items-center justify-between gap-x-2 px-3 py-1 text-sm leading-6 text-gray-900 hover:bg-gray-50 dark:text-neutral-400 dark:hover:bg-neutral-950"
        }, {
          default: _withCtx(() => [_cache[19] || (_cache[19] = _createElementVNode("span", {
            style: {
              "font-weight": "bold"
            }
          }, " Today ", -1)), _createElementVNode("span", null, _toDisplayString(_ctx.$formattedDateOp(_ctx.$moment().format("YYYY-MM-DD"), false)), 1)]),
          _: 1
        }), _createVNode(_unref(PopoverButton), {
          onClick: _cache[2] || (_cache[2] = $event => _ctx.date = _ctx.$moment().add(1, 'days').format('YYYY-MM-DD')),
          class: "flex w-full cursor-pointer items-center justify-between gap-x-2 px-3 py-1 text-sm leading-6 text-gray-900 hover:bg-gray-50 dark:text-neutral-400 dark:hover:bg-neutral-950"
        }, {
          default: _withCtx(() => [_cache[20] || (_cache[20] = _createElementVNode("span", {
            style: {
              "font-weight": "bold"
            }
          }, " Tomorrow ", -1)), _createElementVNode("span", null, _toDisplayString(_ctx.$formattedDateOp(_ctx.$moment().add(1, "days").format("YYYY-MM-DD"), false)), 1)]),
          _: 1
        }), _createVNode(_unref(PopoverButton), {
          onClick: _cache[3] || (_cache[3] = $event => _ctx.date = _ctx.$moment().add(-1, 'days').format('YYYY-MM-DD')),
          class: "flex w-full cursor-pointer items-center justify-between gap-x-2 px-3 py-1 text-sm leading-6 text-gray-900 hover:bg-gray-50 dark:text-neutral-400 dark:hover:bg-neutral-950"
        }, {
          default: _withCtx(() => [_cache[21] || (_cache[21] = _createElementVNode("span", {
            style: {
              "font-weight": "bold"
            }
          }, " Yesterday ", -1)), _createElementVNode("span", null, _toDisplayString(_ctx.$formattedDateOp(_ctx.$moment().add(-1, "days").format("YYYY-MM-DD"), false)), 1)]),
          _: 1
        }), __props.entry || _ctx.date ? (_openBlock(), _createBlock(_unref(PopoverButton), {
          key: 0,
          onClick: _cache[4] || (_cache[4] = $event => _ctx.date = {
            op: 'null'
          }),
          class: "flex w-full cursor-pointer items-center justify-between gap-x-2 px-3 py-1 text-sm leading-6 text-gray-900 hover:bg-gray-50 dark:text-neutral-400 dark:hover:bg-neutral-950"
        }, {
          default: _withCtx(() => [_cache[22] || (_cache[22] = _createElementVNode("span", {
            style: {
              "font-weight": "bold"
            }
          }, " Unset ", -1)), _createVNode(_component_TrashIcon, {
            class: "h-5 w-5"
          })]),
          _: 1
        })) : _createCommentVNode("", true), _createVNode(_component_CalendarComponent, null, {
          default: _withCtx(({
            date: _date
          } = _ctx.slotProps) => [_createElementVNode("button", {
            onClick: _withModifiers($event => (_ctx.date = _date, close()), ["prevent"]),
            class: _normalizeClass({
              'font-bold text-black': _date == _ctx.$moment().format('YYYY-MM-DD'),
              'h-8 w-8 rounded-full bg-neutral-900 font-bold text-white': _date == __props.entry?.schedule?.date
            })
          }, _toDisplayString(_ctx.$moment(_date).format("DD")), 11, _hoisted_4)]),
          _: 2
        }, 1024), _cache[29] || (_cache[29] = _createElementVNode("hr", {
          class: "border-neutral-100 dark:border-neutral-800"
        }, null, -1)), _createVNode(_component_PopoverHelper, {
          teleport: true,
          placement: "right-start",
          onClose: _cache[13] || (_cache[13] = () => {
            if (_ctx.recurrence.op !== null) {
              _ctx.$emit('update:recurrence', _ctx.recurrence);
            }
          })
        }, {
          button: _withCtx(() => [_createVNode(_component_ButtonComponentNew, {
            class: _normalizeClass(_ctx.$style.popoverButton)
          }, {
            default: _withCtx(() => [!__props.entry?.schedule?.recurrence ? (_openBlock(), _createElementBlock("span", _hoisted_5, "Recurrence")) : (_openBlock(), _createElementBlock("span", _hoisted_6, _toDisplayString(_ctx.getRecurrenceText(__props.entry?.schedule?.recurrence)), 1)), _createVNode(_component_Repeat2Icon, {
              size: "20"
            })]),
            _: 1
          }, 8, ["class"])]),
          default: _withCtx(({
            close
          }) => [_createElementVNode("div", {
            class: _normalizeClass(_ctx.$style.popoverPanel)
          }, [_createElementVNode("div", _hoisted_7, [_createElementVNode("div", _hoisted_8, [_createVNode(_component_SelectMenu, {
            modelValue: _ctx.recurrence.op,
            "onUpdate:modelValue": _cache[5] || (_cache[5] = $event => _ctx.recurrence.op = $event),
            options: [{
              title: 'Every X Days',
              value: 'nextXDays'
            }, {
              title: 'Every X Weeks',
              value: 'nextXWeeks'
            }, {
              title: 'Every X Months',
              value: 'nextXMonths'
            }, {
              title: 'Every X Years',
              value: 'nextXYears'
            }, {
              title: 'On the following days ... ',
              value: 'onDays'
            }, {
              title: 'Custom ... ',
              value: 'customSettings'
            }],
            class: "flex-1"
          }, null, 8, ["modelValue"]), _ctx.recurrence.op && ['nextXDays', 'nextXWeeks', 'nextXMonths', 'nextXYears'].includes(_ctx.recurrence.op) ? _withDirectives((_openBlock(), _createElementBlock("input", {
            key: 0,
            "onUpdate:modelValue": _cache[6] || (_cache[6] = $event => _ctx.recurrence.x = $event),
            class: _normalizeClass(_ctx.$style.recurrenceXInput)
          }, null, 2)), [[_vModelText, _ctx.recurrence.x]]) : _createCommentVNode("", true)]), _ctx.recurrence.op === 'onDays' ? (_openBlock(), _createElementBlock("div", _hoisted_9, [(_openBlock(), _createElementBlock(_Fragment, null, _renderList([0, 1, 2, 3, 4, 5, 6], day => {
            return _createVNode(_component_ButtonComponentNew, {
              onClick: () => {
                if (_ctx.recurrence.x.includes(day)) {
                  if (_ctx.recurrence.x.length > 1) {
                    _ctx.recurrence.x.splice(_ctx.recurrence.x.indexOf(day), 1);
                  }
                } else {
                  _ctx.recurrence.x.push(day);
                  _ctx.recurrence.x.sort((a, b) => a - b);
                }
              },
              key: day,
              class: _normalizeClass({
                [_ctx.$style.recurrenceDayButton]: true,
                [_ctx.$style.recurrenceDayButtonActive]: _ctx.recurrence.x.includes(day)
              })
            }, {
              default: _withCtx(() => [_createElementVNode("span", null, _toDisplayString(_ctx.$moment().day(day).format("ddd")), 1)]),
              _: 2
            }, 1032, ["onClick", "class"]);
          }), 64))])) : _createCommentVNode("", true), _ctx.recurrence.op === 'customSettings' ? (_openBlock(), _createElementBlock("div", _hoisted_10, [_createElementVNode("div", _hoisted_11, [_cache[23] || (_cache[23] = _createTextVNode(" every ")), _withDirectives(_createElementVNode("input", {
            "onUpdate:modelValue": _cache[7] || (_cache[7] = $event => _ctx.recurrence.baseX = $event),
            class: _normalizeClass(_ctx.$style.recurrenceXInput)
          }, null, 2), [[_vModelText, _ctx.recurrence.baseX]]), _createVNode(_component_SelectMenu, {
            modelValue: _ctx.recurrence.base,
            "onUpdate:modelValue": _cache[8] || (_cache[8] = $event => _ctx.recurrence.base = $event),
            options: [{
              title: 'Day',
              value: 'day'
            }, {
              title: 'Week',
              value: 'week'
            }, {
              title: 'Month',
              value: 'month'
            }, {
              title: 'Year',
              value: 'year'
            }],
            class: "flex-1"
          }, null, 8, ["modelValue"])]), _ctx.recurrence.base === 'week' ? (_openBlock(), _createElementBlock("div", _hoisted_12, [(_openBlock(), _createElementBlock(_Fragment, null, _renderList([0, 1, 2, 3, 4, 5, 6], day => {
            return _createVNode(_component_ButtonComponentNew, {
              onClick: () => {
                if (_ctx.recurrence.x.includes(day)) {
                  if (_ctx.recurrence.x.length > 1) {
                    _ctx.recurrence.x.splice(_ctx.recurrence.x.indexOf(day), 1);
                  }
                } else {
                  _ctx.recurrence.x.push(day);
                  _ctx.recurrence.x.sort((a, b) => a - b);
                }
              },
              key: day,
              class: _normalizeClass({
                [_ctx.$style.recurrenceDayButton]: true,
                [_ctx.$style.recurrenceDayButtonActive]: _ctx.recurrence.x.includes(day)
              })
            }, {
              default: _withCtx(() => [_createElementVNode("span", null, _toDisplayString(_ctx.$moment().day(day).format("ddd")), 1)]),
              _: 2
            }, 1032, ["onClick", "class"]);
          }), 64))])) : _createCommentVNode("", true), _ctx.recurrence.base === 'month' ? (_openBlock(), _createElementBlock("div", _hoisted_13, [_createElementVNode("div", _hoisted_14, [_createElementVNode("div", null, [_withDirectives(_createElementVNode("input", {
            type: "radio",
            "onUpdate:modelValue": _cache[9] || (_cache[9] = $event => _ctx.recurrence.monthMode = $event),
            value: "date"
          }, null, 512), [[_vModelRadio, _ctx.recurrence.monthMode]]), _cache[24] || (_cache[24] = _createTextVNode(" Per Date "))]), _createElementVNode("div", null, [_withDirectives(_createElementVNode("input", {
            type: "radio",
            "onUpdate:modelValue": _cache[10] || (_cache[10] = $event => _ctx.recurrence.monthMode = $event),
            value: "week"
          }, null, 512), [[_vModelRadio, _ctx.recurrence.monthMode]]), _cache[25] || (_cache[25] = _createTextVNode(" Per Week "))])]), _ctx.recurrence.monthMode === 'date' ? (_openBlock(), _createElementBlock("div", _hoisted_15, [_createVNode(_component_SelectMenu, {
            modelValue: _ctx.recurrence.monthDay,
            "onUpdate:modelValue": _cache[11] || (_cache[11] = $event => _ctx.recurrence.monthDay = $event),
            options: Array.from({
              length: 28
            }, (_, i) => ({
              title: i + 1 + '.',
              value: i + 1
            }))
          }, null, 8, ["modelValue", "options"])])) : _createCommentVNode("", true), _ctx.recurrence.monthMode === 'week' ? (_openBlock(), _createElementBlock("div", _hoisted_16, [_createVNode(_component_SelectMenu, {
            modelValue: _ctx.recurrence.weekInMonth,
            "onUpdate:modelValue": _cache[12] || (_cache[12] = $event => _ctx.recurrence.weekInMonth = $event),
            options: Array.from({
              length: 4
            }, (_, i) => ({
              title: 'Week ' + (i + 1),
              value: i + 1
            }))
          }, null, 8, ["modelValue", "options"]), _createElementVNode("div", _hoisted_17, [(_openBlock(), _createElementBlock(_Fragment, null, _renderList([0, 1, 2, 3, 4, 5, 6], day => {
            return _createVNode(_component_ButtonComponentNew, {
              onClick: () => {
                _ctx.recurrence.dayInWeek = day;
              },
              key: day,
              class: _normalizeClass({
                [_ctx.$style.recurrenceDayButton]: true,
                [_ctx.$style.recurrenceDayButtonActive]: _ctx.recurrence.dayInWeek == day
              })
            }, {
              default: _withCtx(() => [_createElementVNode("span", null, _toDisplayString(_ctx.$moment().day(day).format("ddd")), 1)]),
              _: 2
            }, 1032, ["onClick", "class"]);
          }), 64))])])) : _createCommentVNode("", true)])) : _createCommentVNode("", true)])) : _createCommentVNode("", true), _createElementVNode("div", {
            class: _normalizeClass(_ctx.$style.recurrenceActionsContainer)
          }, [__props.entry?.schedule?.recurrence ? (_openBlock(), _createBlock(_component_ButtonComponentNew, {
            key: 0,
            onClick: $event => (_ctx.$emit('update:recurrence', null), close()),
            class: _normalizeClass(_ctx.$style.recurrenceUnsetButton)
          }, {
            default: _withCtx(() => [_createVNode(_component_TrashIcon, {
              size: "16"
            }), _cache[26] || (_cache[26] = _createElementVNode("span", null, " Unset ", -1))]),
            _: 2
          }, 1032, ["onClick", "class"])) : _createCommentVNode("", true), JSON.stringify(_ctx.recurrence) !== JSON.stringify(__props.entry?.schedule?.recurrence) && _ctx.recurrence.op ? (_openBlock(), _createBlock(_component_ButtonComponentNew, {
            key: 1,
            onClick: () => {
              _ctx.$emit('update:recurrence', _ctx.recurrence);
              close();
            },
            class: _normalizeClass(_ctx.$style.recurrenceSaveButton)
          }, {
            default: _withCtx(() => [_createVNode(_component_SaveIcon, {
              size: "16"
            }), _cache[27] || (_cache[27] = _createElementVNode("span", null, "Save", -1))]),
            _: 2
          }, 1032, ["onClick", "class"])) : _createCommentVNode("", true)], 2)]), _cache[28] || (_cache[28] = _createElementVNode("hr", null, null, -1)), (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.recurrenceOptions, (option, index) => {
            return _openBlock(), _createBlock(_unref(PopoverButton), {
              key: index,
              onClick: $event => _ctx.$emit('update:recurrence', option.value),
              class: _normalizeClass([_ctx.recurrence?.op == option.value.op && _ctx.recurrence?.x == option.value.x ? 'font-bold' : null, "flex w-full justify-between px-3 py-1 text-sm leading-6 text-gray-900 hover:bg-gray-50 dark:text-neutral-400 dark:hover:bg-neutral-950"])
            }, {
              default: _withCtx(() => [_createElementVNode("span", _hoisted_18, _toDisplayString(option.title), 1), _createElementVNode("span", _hoisted_19, " ↻ " + _toDisplayString(_ctx.interpretDate({
                date: option.value
              })), 1)]),
              _: 2
            }, 1032, ["onClick", "class"]);
          }), 128))], 2)]),
          _: 2
        }, 1024)])) : typeof _ctx.date == 'object' ? (_openBlock(), _createElementBlock("div", _hoisted_20, [_createElementVNode("div", _hoisted_21, [_ctx.date?.op ? (_openBlock(), _createElementBlock("span", {
          key: 0,
          style: _normalizeStyle([{
            color: _ctx.date.op == 'today' || _ctx.date.op == 'tomorrow' || _ctx.date.op.slice(0, 4) == 'next' && (typeof _ctx.date.x == 'undefined' || _ctx.date.x >= 0) || _ctx.date.op.slice(0, 4) == 'last' && (typeof _ctx.date.x == 'undefined' || _ctx.date.x <= 0) ? 'var(--color-dark-gray)' : 'var(--color-medium-gray)'
          }, {
            "font-size": "82%",
            "font-weight": "bold",
            "text-transform": "uppercase"
          }])
        }, " in ", 4)) : _createCommentVNode("", true), _createElementVNode("span", _hoisted_22, [_createElementVNode("button", {
          onClick: _cache[14] || (_cache[14] = _withModifiers($event => _ctx.modifyRelativeDate(-1, _ctx.date), ["prevent"]))
        }, " − "), _createElementVNode("span", _hoisted_23, _toDisplayString(_ctx.relativeTimespan(_ctx.date) ? Math.abs(_ctx.relativeTimespan(_ctx.date)[0]) : null), 1), _createElementVNode("button", {
          onClick: _cache[15] || (_cache[15] = _withModifiers($event => _ctx.modifyRelativeDate(1, _ctx.date), ["prevent"]))
        }, " + ")]), _createElementVNode("span", _hoisted_24, [_createElementVNode("button", {
          onClick: _cache[16] || (_cache[16] = _withModifiers($event => _ctx.modifyRelativeTimespan(-1, _ctx.date), ["prevent"]))
        }, " − "), _createElementVNode("button", _hoisted_25, _toDisplayString(_ctx.relativeTimespan(_ctx.date) ? _ctx.relativeTimespan(_ctx.date)[1] : null), 1), _createElementVNode("button", {
          onClick: _cache[17] || (_cache[17] = _withModifiers($event => _ctx.modifyRelativeTimespan(1, _ctx.date), ["prevent"]))
        }, " + ")]), _ctx.date?.op ? (_openBlock(), _createElementBlock("span", {
          key: 1,
          style: _normalizeStyle([{
            color: _ctx.date.op == 'yesterday' || _ctx.date.op.slice(0, 4) == 'next' && (typeof _ctx.date.x == 'undefined' || _ctx.date.x < 0) || _ctx.date.op.slice(0, 4) == 'last' && (typeof _ctx.date.x == 'undefined' || _ctx.date.x > 0) ? 'var(--color-dark-gray)' : 'var(--color-medium-gray)'
          }, {
            "font-size": "82%",
            "font-weight": "bold",
            "text-transform": "uppercase"
          }])
        }, " ago ", 4)) : _createCommentVNode("", true)]), __props.entry ? (_openBlock(), _createBlock(_component_ButtonComponent, {
          key: 0,
          onClick: _cache[18] || (_cache[18] = _withModifiers($event => [_ctx.date = __props.entry ? {
            op: 'null'
          } : {
            op: 'unset'
          }], ["prevent"])),
          title: "unset the schedule date"
        }, {
          default: _withCtx(() => [_createVNode(_component_TrashIcon, {
            class: "h-5 w-5"
          }), _cache[30] || (_cache[30] = _createElementVNode("span", null, " Unset ", -1))]),
          _: 1
        })) : _createCommentVNode("", true)])) : _createCommentVNode("", true)])]),
        _: 1
      }, 8, ["teleport"])])]);
    };
  }
});