import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  class: "flex flex-col"
};
const _hoisted_2 = {
  class: "mb-1 flex flex-wrap items-start gap-2"
};
const _hoisted_3 = {
  "data-handle": "",
  class: "flex cursor-move gap-1 self-center"
};
const _hoisted_4 = ["onClick"];
const _hoisted_5 = {
  class: "flex flex-wrap items-start gap-2"
};
const _hoisted_6 = {
  "data-handle": "",
  class: "pointer-events-none flex cursor-move gap-1 self-center opacity-0"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_ChevronsUpDownIcon = _resolveComponent("ChevronsUpDownIcon");
  const _component_ColorPicker = _resolveComponent("ColorPicker");
  const _component_TextInput = _resolveComponent("TextInput");
  const _component_StatusTypePicker = _resolveComponent("StatusTypePicker");
  const _component_TrashIcon = _resolveComponent("TrashIcon");
  const _component_draggable = _resolveComponent("draggable");
  const _component_PlusIcon = _resolveComponent("PlusIcon");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_draggable, {
    class: "list-group",
    modelValue: $options.statuses,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => $options.statuses = $event),
    group: "statuses",
    handle: "[data-handle]",
    onChange: $options.updatePositions,
    itemKey: "name"
  }, {
    item: _withCtx(({
      element
    }) => [_createElementVNode("div", _hoisted_2, [_createElementVNode("a", _hoisted_3, [_createVNode(_component_ChevronsUpDownIcon, {
      size: "20",
      class: "text-gray-900"
    })]), _createVNode(_component_ColorPicker, {
      modelValue: element.color,
      "onUpdate:modelValue": $event => element.color = $event,
      onUpdate: color => {
        element.color = color;
        $options.updateStatus(element);
      },
      hideLabel: true,
      class: "flex-1"
    }, null, 8, ["modelValue", "onUpdate:modelValue", "onUpdate"]), _createVNode(_component_TextInput, {
      modelValue: element.name,
      "onUpdate:modelValue": $event => element.name = $event,
      onUpdate: $event => $options.updateStatus(element),
      class: _normalizeClass(_ctx.$style.textInput)
    }, null, 8, ["modelValue", "onUpdate:modelValue", "onUpdate", "class"]), _createVNode(_component_StatusTypePicker, {
      modelValue: element.type,
      "onUpdate:modelValue": [$event => element.type = $event, type => {
        element.type = type;
        $options.updateStatus(element);
      }],
      options: ['open', 'custom', 'done'],
      color: "secondary"
    }, null, 8, ["modelValue", "onUpdate:modelValue"]), _createElementVNode("button", {
      onClick: $event => $options.deleteStatus(element),
      type: "button",
      class: "rounded-md bg-white px-2 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 dark:bg-black dark:text-neutral-100 dark:hover:bg-neutral-900"
    }, [_createVNode(_component_TrashIcon, {
      class: "h-4 w-4"
    })], 8, _hoisted_4)])]),
    _: 1
  }, 8, ["modelValue", "onChange"]), _createElementVNode("div", _hoisted_5, [_createElementVNode("a", _hoisted_6, [_createVNode(_component_ChevronsUpDownIcon, {
    size: "20",
    class: "text-gray-900"
  })]), _createVNode(_component_ColorPicker, {
    modelValue: $data.newStatus.color,
    "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => $data.newStatus.color = $event),
    onUpdate: _cache[2] || (_cache[2] = color => $data.newStatus.color = color),
    hideLabel: true,
    class: "flex-1"
  }, null, 8, ["modelValue"]), _createVNode(_component_TextInput, {
    modelValue: $data.newStatus.name,
    "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => $data.newStatus.name = $event),
    class: _normalizeClass(_ctx.$style.textInput),
    placeholder: "Add Status"
  }, null, 8, ["modelValue", "class"]), _createVNode(_component_StatusTypePicker, {
    modelValue: $data.newStatus.type,
    "onUpdate:modelValue": _cache[4] || (_cache[4] = $event => $data.newStatus.type = $event),
    options: ['open', 'custom', 'done'],
    color: "secondary"
  }, null, 8, ["modelValue"]), _createElementVNode("button", {
    onClick: _cache[5] || (_cache[5] = (...args) => $options.addStatus && $options.addStatus(...args)),
    type: "button",
    class: "rounded-md bg-white px-2 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 dark:bg-black dark:text-neutral-100 dark:hover:bg-neutral-900"
  }, [_createVNode(_component_PlusIcon, {
    class: "h-4 w-4"
  })])])]);
}