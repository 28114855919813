// import TemplateBuilder from "../builders/TemplateBuilder.vue";

import { Applicators } from "../mixins/Applicators";
import { Scheduling } from "../mixins/Scheduling";
import { Schema } from "../mixins/Schema";
import { Statusing } from "../mixins/Statusing";
import { Templating } from "../mixins/Templating";
import TextInput from "./TextInput.vue";
import TemplateBuilder from "../builders/TemplateBuilder.vue";
export default {
  // inject: ["position"],
  props: {
    modelValue: Object
  },
  components: {
    // eslint-disable-next-line vue/no-unused-components
    TextInput,
    TemplateBuilder
  },
  mixins: [Schema, Applicators, Statusing, Scheduling, Templating],
  data() {
    return {
      search: ""
    };
  },
  mounted() {
    this.$store.dispatch("retrieveTemplates");
    this.$nextTick(() => {
      this.initResizeObserver();
    });
  },
  beforeUnmount() {
    this.cleanupResizeObserver();
  },
  computed: {
    entry: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit("update:modelValue", value);
      }
    }
  },
  methods: {
    initResizeObserver() {
      this.resizeObserver = new ResizeObserver(entries => {
        for (let entry of entries) {
          const newHeight = entry.contentRect.height;
          if (this.$refs.outer) this.$refs.outer.$el.style.height = `${newHeight}px`;
        }
      });
      // if (this.$refs.inner)
      this.resizeObserver.observe(this.$refs.inner);
    },
    cleanupResizeObserver() {
      if (this.resizeObserver) {
        this.resizeObserver.disconnect();
      }
    },
    animationEnterBackdrop(el, done) {
      this.$anime({
        duration: 300,
        easing: "linear",
        targets: el,
        opacity: [0, 1],
        complete: done
      });
    },
    animationLeaveBackdrop(el, done) {
      this.$anime({
        duration: 300,
        easing: "linear",
        targets: el,
        opacity: [1, 0],
        complete: done
      });
    },
    animationEnter(el, done) {
      this.$anime({
        targets: el,
        opacity: [0, 1],
        translateY: [200, 0],
        complete: done
      });
    },
    animationLeave(el, done) {
      this.$anime({
        targets: el,
        opacity: [1, 0],
        translateY: [0, 200],
        complete: done
      });
    }
  }
};