import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, renderSlot as _renderSlot, createTextVNode as _createTextVNode, withModifiers as _withModifiers, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle } from "vue";
const _hoisted_1 = {
  class: "mb-2 flex justify-between gap-x-3 px-3"
};
const _hoisted_2 = {
  class: "isolate inline-flex -space-x-px rounded-md shadow-sm",
  "aria-label": "Pagination"
};
const _hoisted_3 = {
  class: "relative inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 dark:text-neutral-400 dark:ring-neutral-700"
};
const _hoisted_4 = {
  class: "isolate inline-flex -space-x-px rounded-md shadow-sm",
  "aria-label": "Pagination"
};
const _hoisted_5 = {
  class: "relative inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 dark:text-neutral-400 dark:ring-neutral-700"
};
const _hoisted_6 = {
  class: "grid grid-cols-7 justify-center gap-2 px-3 py-1"
};
const _hoisted_7 = {
  key: 0,
  class: "flex items-center justify-center dark:text-neutral-400"
};
const _hoisted_8 = {
  key: 1,
  class: "flex items-center justify-center dark:text-neutral-400"
};
const _hoisted_9 = {
  key: 2,
  class: "flex items-center justify-center dark:text-neutral-400"
};
const _hoisted_10 = {
  key: 3,
  class: "flex items-center justify-center dark:text-neutral-400"
};
const _hoisted_11 = {
  key: 4,
  class: "flex items-center justify-center dark:text-neutral-400"
};
const _hoisted_12 = {
  key: 5,
  class: "flex items-center justify-center dark:text-neutral-400"
};
const _hoisted_13 = {
  key: 6,
  class: "flex items-center justify-center dark:text-neutral-400"
};
const _hoisted_14 = ["onClick"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_ChevronLeftIcon = _resolveComponent("ChevronLeftIcon");
  const _component_ChevronRightIcon = _resolveComponent("ChevronRightIcon");
  return _openBlock(), _createElementBlock("div", null, [_createElementVNode("aside", _hoisted_1, [_createElementVNode("nav", _hoisted_2, [_createElementVNode("a", {
    onClick: _cache[0] || (_cache[0] = $event => $data.cursor = _ctx.$moment($data.cursor).add(-1, 'month').format('YYYY-MM-DD')),
    class: "relative inline-flex items-center rounded-l-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0 dark:text-neutral-400 dark:ring-neutral-700"
  }, [_cache[4] || (_cache[4] = _createElementVNode("span", {
    class: "sr-only"
  }, "Previous", -1)), _createVNode(_component_ChevronLeftIcon, {
    class: "h-5 w-5",
    "aria-hidden": "true"
  })]), _createElementVNode("a", _hoisted_3, _toDisplayString(_ctx.$moment($data.cursor).format("MMMM")), 1), _createElementVNode("a", {
    onClick: _cache[1] || (_cache[1] = $event => $data.cursor = _ctx.$moment($data.cursor).add(1, 'month').format('YYYY-MM-DD')),
    class: "relative inline-flex items-center rounded-r-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0 dark:text-neutral-400 dark:ring-neutral-700"
  }, [_cache[5] || (_cache[5] = _createElementVNode("span", {
    class: "sr-only"
  }, "Next", -1)), _createVNode(_component_ChevronRightIcon, {
    class: "h-5 w-5",
    "aria-hidden": "true"
  })])]), _createElementVNode("nav", _hoisted_4, [_createElementVNode("a", {
    onClick: _cache[2] || (_cache[2] = $event => $data.cursor = _ctx.$moment($data.cursor).add(-1, 'year').format('YYYY-MM-DD')),
    class: "relative inline-flex items-center rounded-l-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0 dark:text-neutral-400 dark:ring-neutral-700"
  }, [_cache[6] || (_cache[6] = _createElementVNode("span", {
    class: "sr-only"
  }, "Previous", -1)), _createVNode(_component_ChevronLeftIcon, {
    class: "h-5 w-5",
    "aria-hidden": "true"
  })]), _createElementVNode("a", _hoisted_5, _toDisplayString(_ctx.$moment($data.cursor).format("YYYY")), 1), _createElementVNode("a", {
    onClick: _cache[3] || (_cache[3] = $event => $data.cursor = _ctx.$moment($data.cursor).add(1, 'year').format('YYYY-MM-DD')),
    class: "relative inline-flex items-center rounded-r-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0 dark:text-neutral-400 dark:ring-neutral-700"
  }, [_cache[7] || (_cache[7] = _createElementVNode("span", {
    class: "sr-only"
  }, "Next", -1)), _createVNode(_component_ChevronRightIcon, {
    class: "h-5 w-5",
    "aria-hidden": "true"
  })])])]), _createElementVNode("div", _hoisted_6, [$props.mode != 'months' ? (_openBlock(), _createElementBlock("div", _hoisted_7, _cache[8] || (_cache[8] = [_createElementVNode("span", {
    class: "text-sm font-bold"
  }, "Mon", -1)]))) : _createCommentVNode("", true), $props.mode != 'months' ? (_openBlock(), _createElementBlock("div", _hoisted_8, _cache[9] || (_cache[9] = [_createElementVNode("span", {
    class: "text-sm font-bold"
  }, "Tue", -1)]))) : _createCommentVNode("", true), $props.mode != 'months' ? (_openBlock(), _createElementBlock("div", _hoisted_9, _cache[10] || (_cache[10] = [_createElementVNode("span", {
    class: "text-sm font-bold"
  }, "Wed", -1)]))) : _createCommentVNode("", true), $props.mode != 'months' ? (_openBlock(), _createElementBlock("div", _hoisted_10, _cache[11] || (_cache[11] = [_createElementVNode("span", {
    class: "text-sm font-bold"
  }, "Thu", -1)]))) : _createCommentVNode("", true), $props.mode != 'months' ? (_openBlock(), _createElementBlock("div", _hoisted_11, _cache[12] || (_cache[12] = [_createElementVNode("span", {
    class: "text-sm font-bold"
  }, "Fri", -1)]))) : _createCommentVNode("", true), $props.mode != 'months' ? (_openBlock(), _createElementBlock("div", _hoisted_12, _cache[13] || (_cache[13] = [_createElementVNode("span", {
    class: "text-sm font-bold"
  }, "Sat", -1)]))) : _createCommentVNode("", true), $props.mode != 'months' ? (_openBlock(), _createElementBlock("div", _hoisted_13, _cache[14] || (_cache[14] = [_createElementVNode("span", {
    class: "text-sm font-bold"
  }, "Sun", -1)]))) : _createCommentVNode("", true), (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.$moment($data.cursor).startOf('month').day() > 0 ? _ctx.$moment($data.cursor).startOf('month').day() - 1 : 6, i => {
    return _openBlock(), _createElementBlock("span", {
      key: i
    });
  }), 128)), (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.options, (option, index) => {
    return _openBlock(), _createElementBlock("div", {
      key: index,
      onClick: _withModifiers($event => [_ctx.edit = false, _ctx.value = option.date], ["prevent"]),
      style: _normalizeStyle((option.span ? 'grid-column-end: span ' + Math.min(7, option.span) + ';' : '') + ($props.mode == 'months' ? 'height:8rem' : '')),
      class: _normalizeClass([{
        'aaspect-square': $props.mode == 'days'
      }, "relative flex min-h-[2rem] items-center justify-center dark:text-neutral-400"])
    }, [_renderSlot(_ctx.$slots, "default", {
      date: option.date,
      span: option.span - 1
    }, () => [_createTextVNode(_toDisplayString(option.date), 1)])], 14, _hoisted_14);
  }), 128))])]);
}