import { defineAsyncComponent } from "vue";
import { EllipsisIcon, EyeIcon, EyeClosedIcon, GripVerticalIcon } from "lucide-vue-next";
export default {
  name: "OutputTableHeaderRow",
  components: {
    draggable: defineAsyncComponent(() => import("vuedraggable")),
    EllipsisIcon,
    EyeIcon,
    EyeClosedIcon,
    GripVerticalIcon
  },
  props: {
    modelValue: {
      type: Array,
      required: true
    },
    entry: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      isResizing: false,
      startX: 0,
      startWidth: 0,
      currentColumn: null
    };
  },
  computed: {
    tableColumns: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit("update:modelValue", value);
      }
    },
    availableColumns() {
      const baseColumns = [{
        field: "id",
        label: "ID",
        width: 80
      }, {
        field: "name",
        label: "Name",
        width: 120
      }, {
        field: "description",
        label: "Description",
        width: 150
      }, {
        field: "created_at",
        label: "Date Created",
        width: 100
      }, {
        field: "updated_at",
        label: "Date Updated",
        width: 100
      }, {
        field: "completed_at",
        label: "Date Completed",
        width: 100
      }, {
        field: "status",
        label: "Status",
        width: 80
      }, {
        field: "priority",
        label: "Priority",
        width: 100
      }, {
        field: "procrastination",
        label: "Procrastination",
        width: 100
      }, {
        field: "links",
        label: "Links",
        width: 150
      }, {
        field: "schedule",
        label: "Schedule",
        width: 150
      }, {
        field: "senses",
        label: "Senses",
        width: 100
      }, {
        field: "time_trackings",
        label: "Time Trackings",
        width: 150
      }, {
        field: "routine",
        label: "Routine",
        width: 150
      }, {
        field: "completed_count",
        label: "Completed Count",
        width: 50
      }
      // { field: "custom_fields", label: "Custom Fields", width: 100 },
      ];
      const customColumns = this.entry.custom_fields.map(field => ({
        field: "custom_field_" + field.id,
        label: field.name,
        width: 150
      }));
      return [...baseColumns, ...customColumns];
    }
  },
  methods: {
    startResizing(event, column) {
      this.isResizing = true;
      this.startX = event.pageX;
      this.startWidth = column.width;
      this.currentColumn = column;
      document.addEventListener("mousemove", this.handleMouseMove);
      document.addEventListener("mouseup", this.stopResizing);
    },
    handleMouseMove(event) {
      if (!this.isResizing || !this.currentColumn) return;
      const diff = event.pageX - this.startX;
      const newWidth = Math.max(50, this.startWidth + diff);
      this.currentColumn.width = newWidth;
    },
    stopResizing() {
      if (this.isResizing) {
        // Emit the final column widths
        this.$emit("update:modelValue", [...this.tableColumns]);
      }
      this.isResizing = false;
      this.currentColumn = null;
      document.removeEventListener("mousemove", this.handleMouseMove);
      document.removeEventListener("mouseup", this.stopResizing);
    },
    updatePositions() {
      this.$emit("update:modelValue", this.tableColumns);
    }
  },
  beforeUnmount() {
    document.removeEventListener("mousemove", this.handleMouseMove);
    document.removeEventListener("mouseup", this.stopResizing);
  }
};