import TimeTrackingsRow from "./TimeTrackingsRow.vue";
import { ChevronRightIcon, ChevronDownIcon } from "lucide-vue-next";
export default {
  props: {
    modelValue: {
      type: Array,
      required: true
    },
    entry: Object,
    user: {
      type: Object,
      required: true
    }
  },
  computed: {
    time_trackings: {
      get() {
        return this.modelValue;
      },
      set(time_trackings) {
        this.$emit("update:modelValue", time_trackings);
      }
    }
  },
  components: {
    ChevronDownIcon,
    ChevronRightIcon,
    TimeTrackingsRow
  },
  data() {
    return {
      open: false
    };
  }
};