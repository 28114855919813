import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = ["tabindex", "contenteditable", "placeholder", "textContent"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", {
    ref: "input",
    onKeydown: _cache[0] || (_cache[0] = (...args) => $options.onKeyDown && $options.onKeyDown(...args)),
    onKeyup: _cache[1] || (_cache[1] = (...args) => $options.onKeyUp && $options.onKeyUp(...args)),
    onInput: _cache[2] || (_cache[2] = (...args) => $options.onInput && $options.onInput(...args)),
    onBlur: _cache[3] || (_cache[3] = (...args) => $options.onBlur && $options.onBlur(...args)),
    onPaste: _cache[4] || (_cache[4] = (...args) => $options.pasteText && $options.pasteText(...args)),
    onFocus: _cache[5] || (_cache[5] = () => _ctx.$emit('onFocus')),
    tabindex: $props.disabled ? -1 : 0,
    contenteditable: !$props.disabled,
    placeholder: $props.placeholder,
    class: _normalizeClass(_ctx.$style.textInput
    /*{
    // 'cursor-text': !disabled,
    // 'text-neutral-900 dark:text-neutral-300': color == 'default',
    // [`text-${color}-500 dark:text-${color}-500`]: color != 'default',
    }*/),
    textContent: _toDisplayString($data.value)
  }, null, 42, _hoisted_1);
}