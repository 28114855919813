import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, Teleport as _Teleport } from "vue";
const _hoisted_1 = {
  class: "relative"
};
const _hoisted_2 = {
  key: 0,
  class: "flex justify-center"
};
const _hoisted_3 = {
  class: "text-sm text-neutral-700 dark:text-neutral-400"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_EntryDisplay = _resolveComponent("EntryDisplay");
  return _openBlock(), _createBlock(_Teleport, {
    to: "body"
  }, [_createVNode(_Transition, {
    "_enter-active-class": "duration-300 ease-out",
    "_enter-from-class": "opacity-0",
    "_enter-to-class": "opacity-100",
    "_leave-active-class": "duration-200 ease-in",
    "_leave-from-class": "opacity-100",
    "_leave-to-class": "opacity-0"
  }, {
    default: _withCtx(() => [$data.draggedEntriesInterpreted.length ? (_openBlock(), _createElementBlock("div", {
      key: 0,
      id: "draggable-wrapper",
      ref: "draggableWrapper",
      class: _normalizeClass([_ctx.$style.draggableWrapper, "pointer-events-none fixed left-0 top-0 z-[999999] flex w-64 flex-col gap-2 rounded-md bg-white p-2 shadow-xl will-change-transform dark:bg-black dark:ring-1 dark:ring-neutral-700"]),
      style: _normalizeStyle({
        transform: 'translate(' + $data.cursorPosition.x + 'px, ' + $data.cursorPosition.y + 'px)'
      })
    }, [_createElementVNode("div", _hoisted_1, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.draggedEntriesInterpreted.slice(0, 3), (_, index) => {
      return _openBlock(), _createBlock(_component_EntryDisplay, {
        key: index,
        modelValue: $data.draggedEntriesInterpreted[index],
        position: "draggable",
        display: {
          name: true,
          status: true,
          status_name: true,
          description: false,
          schedule: true,
          links: true,
          output: false,
          input: false,
          columns: false,
          leftover: false,
          routine: true,
          time_trackings: true,
          settings: false
        },
        disableViewportChecking: true,
        disableInitialization: true
      }, null, 8, ["modelValue"]);
    }), 128))]), $data.draggedEntriesInterpreted.length > 3 ? (_openBlock(), _createElementBlock("div", _hoisted_2, [_createElementVNode("span", _hoisted_3, "+" + _toDisplayString($data.draggedEntriesInterpreted.length - 3) + " more", 1)])) : _createCommentVNode("", true)], 6)) : _createCommentVNode("", true)]),
    _: 1
  })]);
}