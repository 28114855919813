import anime from "animejs/lib/anime.es.js";
export default {
  // inject: ["position"],
  props: {
    enter: Object,
    leave: Object,
    tag: {
      type: String,
      default: ""
    },
    appear: Boolean
  },
  computed: {
    disabled() {
      return false;
      // return ["draggable"].includes(this.position);
    }
  },
  // data() {
  //   return {
  //     initialAppearance: true,
  //     show: false,
  //   };
  // },
  // mounted() {
  //   this.$nextTick(() => {
  //     this.show = true;
  //   });
  //   setTimeout(() => {
  //     this.initialAppearance = false;
  //   }, 500);
  // },
  methods: {
    onEnter(el, done) {
      if (this.enter) {
        anime({
          ...{
            targets: el,
            // easing: "easeOutExpo",
            complete: () => {
              el.style.transform = "";
              done();
            },
            duration: typeof Cypress === "undefined" ? this.enter.duration ? this.enter.duration : 1000 : 0
          },
          ...this.enter
        });
      } else done();
    },
    onLeave(el, done) {
      if (this.leave) anime({
        ...{
          targets: el,
          // easing: "easeOutExpo",
          complete: () => {
            el.style.transform = "";
            done();
          },
          duration: typeof Cypress === "undefined" ? this.leave.duration ? this.leave.duration : 1000 : 0
        },
        ...this.leave
      });else done();
    }
  }
};