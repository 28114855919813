import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, createTextVNode as _createTextVNode, withModifiers as _withModifiers, resolveDirective as _resolveDirective, withDirectives as _withDirectives, unref as _unref, withCtx as _withCtx, createBlock as _createBlock, renderList as _renderList, Fragment as _Fragment, Transition as _Transition, Teleport as _Teleport } from "vue";
const _hoisted_1 = {
  key: 1,
  class: "status_display_tooltip pointer-events-none absolute bottom-0 left-0 z-10 w-max translate-y-full truncate rounded-md bg-white px-2 py-1 text-sm opacity-0 drop-shadow-md group-hover/status:block group-hover/status:opacity-100 group-hover/status:transition-opacity group-hover/status:delay-300 dark:bg-black dark:text-neutral-300 dark:ring-1 dark:ring-neutral-700"
};
const _hoisted_2 = {
  class: "flex flex-wrap items-center justify-center gap-2"
};
const _hoisted_3 = ["aria-label"];
const _hoisted_4 = {
  class: ""
};
const _hoisted_5 = {
  key: 1,
  class: "flex items-center gap-2"
};
const _hoisted_6 = ["aria-label"];
const _hoisted_7 = {
  key: 0,
  class: "mt-2 flex flex-wrap items-center justify-center gap-1 text-xs text-neutral-500"
};
const _hoisted_8 = {
  class: "max-h-64 overflow-y-auto overflow-x-hidden scrollbar-thin scrollbar-track-transparent scrollbar-thumb-neutral-300 dark:scrollbar-thumb-neutral-700"
};
const _hoisted_9 = {
  key: 0,
  class: "absolute inset-y-0 right-0 flex items-center pr-3 text-neutral-900"
};
const _hoisted_10 = {
  key: 0,
  class: "absolute inset-y-0 right-0 flex items-center pr-3 text-neutral-900"
};
import { Listbox, ListboxButton, ListboxOptions, ListboxOption } from "@headlessui/vue";
import { ref } from "vue";
import { useFloating, shift, flip } from "@floating-ui/vue";
import { Statusing } from "../mixins/Statusing";
import { Scheduling } from "../mixins/Scheduling";
import { Routining } from "../mixins/Routining";
const __default__ = {
  mixins: [Statusing, Scheduling, Routining],
  props: {
    modelValue: Object,
    entry: Object,
    full: Boolean,
    label: Boolean,
    statuses: {
      type: Array
    },
    teleport: {
      type: Boolean,
      default: false
    },
    disabled: Boolean,
    position: String,
    size: String,
    internalSettings: {
      type: Object,
      default: () => ({
        display: {
          label: false,
          settings: false
        }
      })
    }
  },
  data() {
    return {
      statusOverride: null,
      disableQuickSwap: false,
      skipQuickSwapTimeout: null,
      // search related
      showSearchInput: false,
      searchStatusQuery: ""
    };
  },
  computed: {
    status: {
      get() {
        return this.modelValue;
      },
      set(status) {
        if (this.entry) {
          this.setStatus(this.entry, status);
          if (!this.entry.temp) this.$store.dispatch("push", {
            event: "entry_update",
            params: {
              entry: this.entry
            },
            entry: this.entry
          });
        } else {
          this.$emit("update:modelValue", status);
        }
      }
    },
    quickStatusSwapEnabled() {
      return typeof Cypress == "undefined" && this.status && this.statuses?.length == 2;
    }
  },
  methods: {
    onMouseDown(e) {
      e.stopPropagation();
      e.preventDefault();
      if (e.button === 0 && this.quickStatusSwapEnabled) {
        this.mouseDown = true;
        this.skipQuickSwapTimeout = setTimeout(() => {
          if (this.mouseDown) {
            this.disableQuickSwap = true;
            this.$refs.reference?.$el?.click();
          }
        }, 500);
      }
    },
    onMouseUp(e) {
      e.stopPropagation();
      e.preventDefault();
      if (e.button === 0 && this.quickStatusSwapEnabled && !this.statusOverride) {
        this.mouseDown = false;
        if (this.skipQuickSwapTimeout) {
          clearTimeout(this.skipQuickSwapTimeout);
          if (!this.disableQuickSwap && !this.disabled) {
            this.statusOverride = this.statuses.find(s => s.id != this.status.id);
            setTimeout(() => {
              this.status = this.statuses.find(s => s.id != this.status.id);
              this.statusOverride = null;
            }, 500);
          }
          this.disableQuickSwap = false;
        } else {
          clearTimeout(this.skipQuickSwapTimeout);
        }
      }
    },
    animationEnterDropdown(el, done) {
      this.$anime({
        targets: el,
        opacity: [0, 1],
        translateY: [20, 0],
        complete: done
      });
    },
    animationLeaveDropdown(el, done) {
      this.$anime({
        targets: el,
        opacity: [1, -0.2],
        translateY: [0, 20],
        complete: done
      });
    },
    onMountSearch() {
      setTimeout(() => {
        this.$refs.search?.focus();
      }, 100);
    }
  }
};
export default /*@__PURE__*/Object.assign(__default__, {
  __name: 'StatusDisplay',
  setup(__props) {
    const reference = ref(null);
    const floating = ref(null);
    const {
      floatingStyles
    } = useFloating(reference, floating, {
      transform: false,
      placement: "bottom-start",
      middleware: [flip(), shift({
        crossAxis: true
      })]
    });
    return (_ctx, _cache) => {
      const _component_StatusIndicator = _resolveComponent("StatusIndicator");
      const _component_ChevronRightIcon = _resolveComponent("ChevronRightIcon");
      const _component_MouseIcon = _resolveComponent("MouseIcon");
      const _component_OverlayComponent = _resolveComponent("OverlayComponent");
      const _component_InputWithLabel = _resolveComponent("InputWithLabel");
      const _component_StatusOption = _resolveComponent("StatusOption");
      const _component_CheckIcon = _resolveComponent("CheckIcon");
      const _directive_touch = _resolveDirective("touch");
      return _openBlock(), _createElementBlock("div", {
        class: _normalizeClass([_ctx.$style.statusDisplay, "status_display"]),
        style: _normalizeStyle({
          '--ps-status-color': _ctx.$colors[_ctx.status?.color || 'neutral'][500]
        })
      }, [_createVNode(_unref(Listbox), {
        modelValue: _ctx.status,
        "onUpdate:modelValue": _cache[5] || (_cache[5] = $event => _ctx.status = $event),
        disabled: __props.disabled
      }, {
        default: _withCtx(({
          open
        }) => [_createVNode(_unref(ListboxButton), {
          ref_key: "reference",
          ref: reference,
          class: "w-full rounded-full focus:outline-none"
        }, {
          default: _withCtx(() => [_renderSlot(_ctx.$slots, "button", {}, () => [_withDirectives((_openBlock(), _createElementBlock("div", {
            onMousedown: _cache[0] || (_cache[0] = (...args) => _ctx.onMouseDown && _ctx.onMouseDown(...args)),
            onMouseup: _cache[1] || (_cache[1] = (...args) => _ctx.onMouseUp && _ctx.onMouseUp(...args)),
            onClick: _cache[2] || (_cache[2] = e => _ctx.quickStatusSwapEnabled ? [e.stopPropagation(), _ctx.$emit('click', e)] : null),
            onContextmenu: _cache[3] || (_cache[3] = _withModifiers(() => {}, ["stop", "prevent"])),
            class: "group/status relative flex items-center",
            "data-table-column-target": ""
          }, [_createVNode(_component_StatusIndicator, {
            status: _ctx.statusOverride || _ctx.status,
            size: __props.size
          }, null, 8, ["status", "size"]), __props.internalSettings.display.label ? (_openBlock(), _createElementBlock("span", {
            key: 0,
            class: _normalizeClass(_ctx.$style.statusLabel)
          }, _toDisplayString(_ctx.statusOverride?.name || _ctx.status?.name || "No status"), 3)) : _createCommentVNode("", true), __props.position != 'calendar' && !_ctx.statusOverride ? (_openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_ctx.status ? (_openBlock(), _createElementBlock("span", {
            key: 0,
            "aria-label": _ctx.status?.name || 'No status',
            class: _normalizeClass(_ctx.$style.tooltipDot)
          }, null, 10, _hoisted_3)) : _createCommentVNode("", true), _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.status?.name || "No status"), 1), _ctx.quickStatusSwapEnabled && !__props.disabled ? (_openBlock(), _createElementBlock("span", _hoisted_5, [_createVNode(_component_ChevronRightIcon, {
            class: "inline h-3 w-3"
          }), _createElementVNode("span", {
            "aria-label": __props.statuses.find(s => s.id != _ctx.status?.id).name || 'No status',
            class: _normalizeClass([_ctx.$style.tooltipDot]),
            style: _normalizeStyle({
              '--ps-status-color': _ctx.$colors[__props.statuses.find(s => s.id != _ctx.status?.id).color][500]
            })
          }, null, 14, _hoisted_6), _createTextVNode(" " + _toDisplayString(__props.statuses.find(s => s.id != _ctx.status?.id).name), 1)])) : _createCommentVNode("", true)]), _ctx.quickStatusSwapEnabled && !__props.disabled ? (_openBlock(), _createElementBlock("span", _hoisted_7, [_cache[6] || (_cache[6] = _createTextVNode(" Hold ")), _createVNode(_component_MouseIcon, {
            size: "16",
            strokeWidth: "1.5"
          }), _cache[7] || (_cache[7] = _createTextVNode(" for more options "))])) : _createCommentVNode("", true)])) : _createCommentVNode("", true)], 32)), [[_directive_touch, e => {
            if (_ctx.quickStatusSwapEnabled && !['mouseup', 'mousedown'].includes(e.type)) {
              _ctx.$refs.reference.$el.click();
            }
          }, "hold", {
            stop: true,
            prevent: true
          }], [_directive_touch, void 0, "press", {
            stop: true
          }], [_directive_touch, void 0, "release", {
            stop: true
          }]])])]),
          _: 3
        }, 512), (_openBlock(), _createBlock(_Teleport, {
          to: "body"
        }, [open ? (_openBlock(), _createBlock(_component_OverlayComponent, {
          key: 0
        })) : _createCommentVNode("", true), _createVNode(_Transition, {
          "enter-active-class": "transition-opacity duration-100",
          "enter-from-class": "opacity-0",
          "enter-to-class": "opacity-100",
          "leave-active-class": "transition-opacity duration-100",
          "leave-from-class": "opacity-100",
          "leave-to-class": "opacity-0"
        }, {
          default: _withCtx(() => [_createVNode(_unref(ListboxOptions), {
            ref_key: "floating",
            ref: floating,
            class: "ps_dropdown__menu",
            style: _normalizeStyle(_unref(floatingStyles))
          }, {
            default: _withCtx(() => [__props.statuses.length > 3 ? (_openBlock(), _createBlock(_component_InputWithLabel, {
              key: 0,
              ref: "search",
              modelValue: _ctx.searchStatusQuery,
              "onUpdate:modelValue": _cache[4] || (_cache[4] = $event => _ctx.searchStatusQuery = $event),
              placeholder: "Search...",
              class: "mb-2",
              onMounted: _ctx.onMountSearch,
              "data-delay": "0"
            }, null, 8, ["modelValue", "onMounted"])) : _createCommentVNode("", true), _createElementVNode("div", _hoisted_8, [_ctx.status && __props.statuses?.findIndex(s => s.id == _ctx.status?.id) == -1 ? (_openBlock(), _createBlock(_unref(ListboxOption), {
              key: 0,
              value: _ctx.status,
              as: "template",
              disabled: true
            }, {
              default: _withCtx(({
                active,
                selected
              }) => [_createElementVNode("li", {
                class: _normalizeClass([active ? 'bg-neutral-50 font-bold text-black dark:bg-neutral-900 dark:text-white' : 'text-neutral-800 dark:text-neutral-200', 'relative select-none rounded-md py-1.5 pr-10 transition-colors'])
              }, [_createVNode(_component_StatusOption, {
                modelValue: _ctx.status,
                class: _normalizeClass([[selected ? 'font-medium' : 'font-normal', 'block truncate'], "pointer-events-none pl-4 text-sm"])
              }, null, 8, ["modelValue", "class"]), selected ? (_openBlock(), _createElementBlock("span", _hoisted_9, [_createVNode(_component_CheckIcon, {
                size: "20",
                strokeWidth: "1.5"
              })])) : _createCommentVNode("", true)], 2)]),
              _: 1
            }, 8, ["value"])) : _createCommentVNode("", true), (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.statuses.filter(s => s.name.toLowerCase().includes(_ctx.searchStatusQuery.toLowerCase())), (option, index) => {
              return _openBlock(), _createBlock(_unref(ListboxOption), {
                key: option.id,
                value: option,
                as: "template",
                "data-delay": index * 100
              }, {
                default: _withCtx(({
                  active,
                  selected
                }) => [_createElementVNode("li", {
                  class: _normalizeClass([active ? 'bg-neutral-50 font-bold text-black dark:bg-neutral-900 dark:text-white' : 'text-neutral-800 dark:text-neutral-200', 'relative cursor-pointer select-none rounded-md py-1.5 pr-10 transition-colors'])
                }, [_createVNode(_component_StatusOption, {
                  modelValue: option,
                  class: _normalizeClass([[selected ? 'font-medium' : 'font-normal', 'block truncate'], "pointer-events-none pl-4 text-sm"])
                }, null, 8, ["modelValue", "class"]), selected ? (_openBlock(), _createElementBlock("span", _hoisted_10, [_createVNode(_component_CheckIcon, {
                  size: "20",
                  strokeWidth: "1.5"
                })])) : _createCommentVNode("", true)], 2)]),
                _: 2
              }, 1032, ["value", "data-delay"]);
            }), 128)), _ctx.status || !__props.entry ? (_openBlock(), _createBlock(_unref(ListboxOption), {
              key: 1,
              as: "template",
              value: null
            }, {
              default: _withCtx(({
                active,
                selected
              }) => [_createElementVNode("li", {
                class: _normalizeClass([active ? 'bg-neutral-50 font-bold text-black dark:bg-neutral-900 dark:text-white' : 'text-neutral-800 dark:text-neutral-200', 'relative cursor-pointer select-none rounded-md py-1.5 pr-10 transition-colors'])
              }, [_createElementVNode("div", {
                class: _normalizeClass([[selected ? 'font-medium' : 'font-normal', 'block truncate text-neutral-700'], "pointer-events-none flex items-center pl-4 text-sm"])
              }, _cache[8] || (_cache[8] = [_createElementVNode("span", {
                class: "ml-6 block truncate"
              }, " No status ", -1)]), 2)], 2)]),
              _: 1
            })) : _createCommentVNode("", true)])]),
            _: 1
          }, 8, ["style"])]),
          _: 1
        })]))]),
        _: 3
      }, 8, ["modelValue", "disabled"])], 6);
    };
  }
});