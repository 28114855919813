export default {
  props: {
    modelValue: Object,
    position: String
  },
  computed: {
    procrastination: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit("update:modelValue", value);
      }
    }
  }
};