import { resolveDynamicComponent as _resolveDynamicComponent, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue";
import { computed, shallowRef, onMounted } from "vue";
export default {
  __name: 'IconHelper',
  props: {
    name: {
      type: String,
      required: true
    },
    size: {
      type: null,
      default: 24
    },
    color: String,
    strokeWidth: {
      type: null,
      default: 2
    },
    defaultClass: String
  },
  setup(__props) {
    const props = __props;
    const icon = shallowRef(null);

    // Convert kebab-case to PascalCase
    const iconName = computed(() => props.name.charAt(0).toUpperCase() + props.name.slice(1).replace(/-([a-z0-9])/g, (match, letter) => letter.toUpperCase()));

    // Load icon when component is mounted
    onMounted(async () => {
      try {
        const module = await import("lucide-vue-next");
        icon.value = module[iconName.value];
      } catch (error) {
        console.error(`Failed to load icon: ${props.name}`, error);
      }
    });
    return (_ctx, _cache) => {
      return icon.value ? (_openBlock(), _createBlock(_resolveDynamicComponent(icon.value), {
        key: 0,
        size: __props.size,
        class: _normalizeClass(`text-${__props.color}-500`),
        "stroke-width": __props.strokeWidth,
        "default-class": __props.defaultClass
      }, null, 8, ["size", "class", "stroke-width", "default-class"])) : _createCommentVNode("", true);
    };
  }
};