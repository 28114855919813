import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot } from "vue";
const _hoisted_1 = {
  class: "ps_cover_display relative"
};
const _hoisted_2 = ["src", "alt"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", _hoisted_1, [$props.entry.cover?.type == 'external' ? (_openBlock(), _createElementBlock("img", {
    key: 0,
    src: $props.entry.cover.url,
    loading: "lazy",
    alt: $props.entry.name,
    class: "pointer-events-none h-full w-full object-cover object-center dark:brightness-50"
  }, null, 8, _hoisted_2)) : _createCommentVNode("", true), _renderSlot(_ctx.$slots, "default")]);
}