import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, unref as _unref, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementBlock as _createElementBlock, withModifiers as _withModifiers, mergeProps as _mergeProps, Transition as _Transition } from "vue";
const _hoisted_1 = {
  class: "mx-auto w-full sm:max-w-xl md:max-w-3xl md:px-8 lg:max-w-4xl xl:max-w-6xl"
};
const _hoisted_2 = {
  ref: "inner_tinted_wrapper"
};
const _hoisted_3 = {
  key: "settings",
  class: "mb-2 w-full px-5",
  "data-tour": "main_entry_toolbar"
};
const _hoisted_4 = {
  class: "capitalize"
};
const _hoisted_5 = {
  key: "senses",
  class: "mb-2 mr-2 inline-block"
};
const _hoisted_6 = {
  key: "templates",
  class: "mb-2 mr-2 inline-block"
};
const _hoisted_7 = {
  key: "settings",
  class: "mb-2 inline-block"
};
const _hoisted_8 = {
  ref: "inner"
};
const _hoisted_9 = {
  class: "flex flex-1 items-center justify-between"
};
const _hoisted_10 = {
  key: "add_input_output",
  class: "mx-auto flex h-12 w-12"
};
const _hoisted_11 = {
  class: "mt-5 flex justify-center"
};
import { addonBlueprint } from "@/addonBlueprint";
import draggable from "vuedraggable";
import { ChevronUpIcon, ChevronDownIcon } from "lucide-vue-next";
import { Routining } from "../mixins/Routining";
import { Scheduling } from "../mixins/Scheduling";
import { Statusing } from "../mixins/Statusing";
import { Touring } from "../mixins/Touring";
import TransitionGroupHelper from "@/components/helpers/TransitionGroupHelper.vue";
const __default__ = {
  components: {
    TransitionGroupHelper,
    ChevronUpIcon,
    ChevronDownIcon
  },
  emits: ["showSettings"],
  mixins: [Statusing, Scheduling, Routining, Touring],
  props: {
    modelValue: Object,
    position: String
  },
  data() {
    return {
      // linkedEntries: [],
      showSettings: !this.modelValue.input && !this.modelValue.output && this.modelValue.links.length <= 1 && !this.modelValue.status_id && !this.modelValue.schedule && !this.modelValue.priority && this.modelValue.time_trackings.length === 0,
      showTemplates: false,
      showSenseModal: this.$route.query.showModal === "true",
      showSettingsModal: false,
      isLazyLoaded: false,
      performanceRenderIndex: -1
    };
  },
  mounted() {
    this.checkGetParameters();
    this.initResizeObserver();
    // setInterval(() => {
    //   this.$store.dispatch("pull", {
    //     filters: [{ key: "id", op: "eq", val: this.entry.id }],
    //     source: {
    //       file: "SETTINGSS",
    //       entry: this.entry.name,
    //     },
    //   });
    // }, 1000);
    // this.linkedEntries = this.entry.links
    //   .map((id) => this.$store.getters.entries.find((e) => e.id == id))
    //   .filter((e) => e);
    // this.isLazyLoaded = true;
    this.performanceRenderIndex = 0;
    this.performantRender();
  },
  beforeUnmount() {
    this.cleanupResizeObserver();
  },
  watch: {
    linkedEntries: {
      deep: true,
      handler(n, o) {
        if (n.length != o.length) {
          this.performantRender();
        }
      }
    },
    entry: {
      deep: true,
      handler(n, o) {
        if (n?.id != o?.id || n.links.length != o.links.length) {
          this.performanceRenderIndex = 0;
          this.performantRender();
        }
        if (!this.showSettings || n.id != o.id) {
          this.showSettings = !this.modelValue.input && !this.modelValue.output && this.modelValue.links.length <= 1 && !this.modelValue.status_id;
          !this.modelValue.schedule && !this.modelValue.priority && this.modelValue.time_trackings.length === 0;
        }
        this.showTemplates = false;
        this.checkGetParameters();
      }
    }
  },
  computed: {
    dragOptions() {
      return {
        animation: 200,
        group: "description",
        disabled: false,
        ghostClass: "ghost"
      };
    },
    entry: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit("update:modelValue", value);
      }
    },
    entries() {
      return this.$store.getters.entries?.filter(e => e.deleted_at === null);
    },
    linkedEntries() {
      if (this.position == "modal") {
        return [] /*this.entry.links
                  .map((link) => this.entries.find((e) => e.id == link.id))
                  .filter((e) => e && e.id != this.$route.params.id)*/;
      } else {
        return this.entry.links.map(link => this.entries.find(e => e.id == link.id)).filter(e => e);
      }
    },
    statuses() {
      return this.getAvailableStatuses(this.entry);
    },
    status: {
      get() {
        return this.getStatusById(this.entry.status_id);
      },
      set(status) {
        this.setStatus(this.entry, status);
      }
    },
    links: {
      get() {
        return this.entry.links;
      },
      set(links) {
        // eslint-disable-next-line vue/no-mutating-props
        this.entry.links = links;
        this.$store.dispatch("push", {
          event: "entry_update",
          params: {
            entry: this.entry
          },
          entry: this.entry
        });
      }
    },
    hasPanelContent() {
      return (
        // this.showTemplates ||
        this.entry.output || this.entry.input || this.linkedEntries.length > 0
      );
    },
    output: {
      get() {
        return this.entry.output;
      },
      set(output) {
        // eslint-disable-next-line vue/no-mutating-props
        this.entry.output = output;
        this.$store.dispatch("push", {
          event: "entry_update",
          params: {
            entry: this.entry
          },
          entry: this.entry
        });
      }
    },
    input: {
      get() {
        return this.entry.input;
      },
      set(input) {
        // eslint-disable-next-line vue/no-mutating-props
        this.entry.input = input;
        this.$store.dispatch("push", {
          event: "entry_update",
          params: {
            entry: this.entry
          },
          entry: this.entry
        });
      }
    }
  },
  methods: {
    performantRender() {
      // if (this) return;
      if (this.linkedEntries.length > this.performanceRenderIndex) {
        // this.performanceRenderIndex = this.linkedEntries.length;
        this.$nextTick(() => {
          if (this.linkedEntries.length > this.performanceRenderIndex) {
            this.performanceRenderIndex++;
            this.performantRender();
          }
        });
      } else {
        this.performanceRenderIndex = this.linkedEntries.length;
      }
    },
    checkGetParameters() {
      // console.log(this.$route.query);
      if (this.$route.query.sensesSettings == 1) {
        this.showSenseModal = true;
        this.$router.replace({
          query: {
            ...this.$route.query,
            sensesSettings: undefined
          }
        });
      }
    },
    initResizeObserver() {
      this.resizeObserver = new ResizeObserver(entries => {
        window.requestAnimationFrame(() => {
          for (let entry of entries) {
            const newHeight = entry.contentRect.height;
            if (this.$refs.outer_tinted_wrapper) {
              this.$refs.outer_tinted_wrapper.style.height = `${newHeight}px`;
            }
          }
        });
      });
      if (this.$refs.inner_tinted_wrapper) {
        this.resizeObserver.observe(this.$refs.inner_tinted_wrapper);
      }
      this.resizeObserver2 = new ResizeObserver(entries => {
        window.requestAnimationFrame(() => {
          for (let entry of entries) {
            const newHeight = entry.contentRect.height;
            if (this.$refs.outer) {
              this.$refs.outer.$el.style.height = `${newHeight}px`;
            }
          }
        });
      });
      if (this.$refs.inner) {
        this.resizeObserver2.observe(this.$refs.inner);
      }
    },
    cleanupResizeObserver() {
      if (this.resizeObserver) {
        this.resizeObserver.disconnect();
      }
      if (this.resizeObserver2) {
        this.resizeObserver2.disconnect();
      }
    },
    updatePositions(event) {
      if (event.moved) {
        const newLinks = [...this.entry.links];
        const {
          oldIndex,
          newIndex
        } = event.moved;
        const [movedItem] = newLinks.splice(oldIndex, 1);
        newLinks.splice(newIndex, 0, movedItem);
        newLinks.forEach((link, index) => {
          link.position = index;
        });
        this.entry.links = newLinks;
        this.$store.dispatch("push", {
          event: "entry_update",
          params: {
            entry: this.entry
          },
          entry: this.entry
        });
      }
    },
    getLinkSettings(linkedEntryId) {
      const link = this.entry.links.find(l => l.id === linkedEntryId);
      return link?.settings || {};
    },
    toggleCollapse(linkedEntry) {
      const i = this.entry.links.findIndex(link => link.id === linkedEntry.id);
      if (i !== -1) {
        const updatedLinks = [...this.entry.links];
        updatedLinks[i] = {
          ...updatedLinks[i],
          settings: {
            ...updatedLinks[i].settings,
            collapsed: !updatedLinks[i].settings?.collapsed
          }
        };
        this.entry.links = updatedLinks;
        this.entry.links.forEach((link, index) => {
          link.position = index;
        });
        this.$store.dispatch("push", {
          event: "entry_update",
          params: {
            entry: this.entry
          },
          entry: this.entry,
          undo: true
        });
      }
    }
  }
};
export default /*@__PURE__*/Object.assign(__default__, {
  __name: 'EntrySettings',
  setup(__props) {
    return (_ctx, _cache) => {
      const _component_TourHelper = _resolveComponent("TourHelper");
      const _component_CoverSettings = _resolveComponent("CoverSettings");
      const _component_CoverDisplay = _resolveComponent("CoverDisplay");
      const _component_ButtonComponentNew = _resolveComponent("ButtonComponentNew");
      const _component_EntryDisplay = _resolveComponent("EntryDisplay");
      const _component_DescriptionSettings = _resolveComponent("DescriptionSettings");
      const _component_StatusSettings = _resolveComponent("StatusSettings");
      const _component_ScheduleSettings = _resolveComponent("ScheduleSettings");
      const _component_PrioritySettings = _resolveComponent("PrioritySettings");
      const _component_TimeTrackingsSettings = _resolveComponent("TimeTrackingsSettings");
      const _component_OutputSettings = _resolveComponent("OutputSettings");
      const _component_InputSettings = _resolveComponent("InputSettings");
      const _component_RepeatIcon = _resolveComponent("RepeatIcon");
      const _component_ButtonComponent = _resolveComponent("ButtonComponent");
      const _component_RoutineSettings = _resolveComponent("RoutineSettings");
      const _component_RadioIcon = _resolveComponent("RadioIcon");
      const _component_PuzzleIcon = _resolveComponent("PuzzleIcon");
      const _component_SettingsDisplay = _resolveComponent("SettingsDisplay");
      const _component_NativeTransitionGroupHelper = _resolveComponent("NativeTransitionGroupHelper");
      const _component_OutputDisplay = _resolveComponent("OutputDisplay");
      const _component_InputDisplay = _resolveComponent("InputDisplay");
      const _component_ArrowUpIcon = _resolveComponent("ArrowUpIcon");
      const _component_MenuItem = _resolveComponent("MenuItem");
      const _component_Link2OffIcon = _resolveComponent("Link2OffIcon");
      const _component_ArrowDownIcon = _resolveComponent("ArrowDownIcon");
      const _component_EntryDisplayLinked = _resolveComponent("EntryDisplayLinked");
      const _component_PlusIcon = _resolveComponent("PlusIcon");
      const _component_PanelHelper = _resolveComponent("PanelHelper");
      const _component_Link2Icon = _resolveComponent("Link2Icon");
      const _component_LinksPopover = _resolveComponent("LinksPopover");
      const _component_RestDisplay = _resolveComponent("RestDisplay");
      const _component_TemplateWrapper = _resolveComponent("TemplateWrapper");
      const _component_TitleHelper = _resolveComponent("TitleHelper");
      const _component_SensesSettings = _resolveComponent("SensesSettings");
      const _component_ModalHelper = _resolveComponent("ModalHelper");
      const _component_SettingsSettings = _resolveComponent("SettingsSettings");
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", {
        class: _normalizeClass(_ctx.$style.entrySettingsInner),
        "data-tour": "main_entry_settings",
        ref: "outer_tinted_wrapper",
        style: {
          "transition": "height 0.8s cubic-bezier(0.16, 1, 0.3, 1)"
        }
      }, [_createElementVNode("div", _hoisted_2, [_createVNode(_component_TourHelper, {
        hook: "entry_settings",
        class: "absolute right-0 top-0 mr-2 mt-2",
        "auto-start": "",
        showSettings: _ctx.showSettings,
        onShowSettings: _cache[0] || (_cache[0] = $event => _ctx.showSettings = $event)
      }, null, 8, ["showSettings"]), _createVNode(TransitionGroupHelper, {
        enter: {
          opacity: [0, 1],
          translateY: [50, 0]
        },
        leave: {
          opacity: [1, -0.2],
          translateY: [0, 50]
        }
      }, {
        default: _withCtx(() => [_createElementVNode("div", {
          key: "header",
          class: _normalizeClass(_ctx.$style.entryDisplay_wrapper)
        }, [_ctx.entry.cover ? (_openBlock(), _createBlock(_component_CoverDisplay, {
          key: 0,
          modelValue: _ctx.entry.cover,
          "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => _ctx.entry.cover = $event),
          entry: _ctx.entry,
          class: "group/cover mx-5 mb-4 h-48 overflow-hidden rounded-2xl shadow-lg md:h-[30vh]"
        }, {
          default: _withCtx(() => [_createVNode(_component_CoverSettings, {
            modelValue: _ctx.entry.cover,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => _ctx.entry.cover = $event),
            key: "cover",
            color: "secondary",
            entry: _ctx.entry,
            class: "absolute right-2 top-2 opacity-0 transition-opacity duration-200 ease-in-out group-hover/cover:opacity-100"
          }, null, 8, ["modelValue", "entry"])]),
          _: 1
        }, 8, ["modelValue", "entry"])) : _createCommentVNode("", true), _createVNode(_component_EntryDisplay, {
          id: "header",
          renderType: "detail",
          modelValue: _ctx.entry,
          position: __props.position,
          display: {
            links: true,
            output_settings: true
          },
          size: "4xl",
          class: _normalizeClass({
            [`${_ctx.$style.entryDisplay}`]: true
            // [`${$style.entryDisplay_smaller}`]: entry.name.length > 30,
          }),
          disableViewportChecking: true
        }, {
          settings: _withCtx(() => [_createVNode(_component_ButtonComponentNew, {
            onClick: _cache[3] || (_cache[3] = $event => _ctx.showSettings = !_ctx.showSettings),
            title: "Settings",
            shortcode: "e",
            class: _normalizeClass([_ctx.$style.buttonSettings, "duration-500 lg:mt-1"]),
            style: _normalizeStyle({
              '--ps-button-bg-color': _ctx.showSettings ? 'var(--ps-base-background-color)' : 'var(--ps-base-primary-color)',
              '--ps-button-bg-color-hover': _ctx.showSettings ? 'var(--ps-base-secondary-color)' : 'var(--ps-base-secondary-color)',
              '--ps-button-color': _ctx.showSettings ? 'var(--ps-base-primary-color)' : 'var(--ps-base-background-color)',
              '--ps-button-color-hover': _ctx.showSettings ? 'var(--ps-base-background-color)' : 'var(--ps-base-background-color)'
            })
          }, {
            default: _withCtx(() => [_createVNode(_unref(ChevronDownIcon), {
              size: "20",
              class: _normalizeClass([{
                'rotate-180': _ctx.showSettings
              }, "transition-transform"])
            }, null, 8, ["class"])]),
            _: 1
          }, 8, ["class", "style"])]),
          _: 1
        }, 8, ["modelValue", "position", "class"])], 2), _ctx.showSettings || _ctx.$Cypress ? (_openBlock(), _createElementBlock("div", _hoisted_3, [_createVNode(_component_NativeTransitionGroupHelper, {
          "enter-active-class": "transition-all duration-500 ease-spring",
          "enter-from-class": "opacity-0 translate-y-5",
          "enter-to-class": "opacity-100 translate-y-0",
          "leave-active-class": "transition-all duration-500 absolute ease-spring pointer-events-none",
          "leave-from-class": "opacity-100 translate-y-0",
          "leave-to-class": "opacity-0 translate-y-5"
        }, {
          default: _withCtx(() => [!_ctx.entry.description ? (_openBlock(), _createBlock(_component_DescriptionSettings, {
            key: "description",
            modelValue: _ctx.entry,
            "onUpdate:modelValue": _cache[4] || (_cache[4] = $event => _ctx.entry = $event),
            entry: _ctx.entry,
            variant: "round",
            color: "white",
            class: "mb-2 mr-2 inline-block"
          }, null, 8, ["modelValue", "entry"])) : _createCommentVNode("", true), !_ctx.entry.status_id ? (_openBlock(), _createBlock(_component_StatusSettings, {
            key: "status",
            modelValue: _ctx.status,
            "onUpdate:modelValue": _cache[5] || (_cache[5] = $event => _ctx.status = $event),
            entry: _ctx.entry,
            full: true,
            statuses: _ctx.statuses,
            variant: "round",
            color: "white",
            class: "mb-2 mr-2 inline-block"
          }, null, 8, ["modelValue", "entry", "statuses"])) : _createCommentVNode("", true), !_ctx.entry.schedule ? (_openBlock(), _createBlock(_component_ScheduleSettings, {
            key: "schedule",
            modelValue: _ctx.entry.schedule,
            "onUpdate:modelValue": _cache[6] || (_cache[6] = $event => _ctx.entry.schedule = $event),
            entry: _ctx.entry,
            variant: "round",
            color: "white",
            class: "mb-2 mr-2 inline-block"
          }, null, 8, ["modelValue", "entry"])) : _createCommentVNode("", true), !_ctx.entry.priority ? (_openBlock(), _createBlock(_component_PrioritySettings, {
            key: "priority",
            modelValue: _ctx.entry.priority,
            "onUpdate:modelValue": _cache[7] || (_cache[7] = $event => _ctx.entry.priority = $event),
            entry: _ctx.entry,
            variant: "round",
            color: "white",
            class: "mb-2 mr-2 inline-block"
          }, null, 8, ["modelValue", "entry"])) : _createCommentVNode("", true), !_ctx.entry.time_trackings.length ? (_openBlock(), _createBlock(_component_TimeTrackingsSettings, {
            key: "time_trackings",
            modelValue: _ctx.entry.time_trackings,
            "onUpdate:modelValue": _cache[8] || (_cache[8] = $event => _ctx.entry.time_trackings = $event),
            entry: _ctx.entry,
            variant: "round",
            color: "white",
            class: "mb-2 mr-2 inline-block"
          }, null, 8, ["modelValue", "entry"])) : _createCommentVNode("", true), !_ctx.entry.output ? (_openBlock(), _createBlock(_component_OutputSettings, {
            key: "output",
            modelValue: _ctx.entry.output,
            "onUpdate:modelValue": _cache[9] || (_cache[9] = $event => _ctx.entry.output = $event),
            entry: _ctx.entry,
            variant: "round",
            color: "white",
            class: "mb-2 mr-2 inline-block"
          }, null, 8, ["modelValue", "entry"])) : _createCommentVNode("", true), !_ctx.entry.input ? (_openBlock(), _createBlock(_component_InputSettings, {
            key: "input",
            modelValue: _ctx.entry.input,
            "onUpdate:modelValue": _cache[10] || (_cache[10] = $event => _ctx.entry.input = $event),
            entry: _ctx.entry,
            variant: "round",
            color: "white",
            class: "mb-2 mr-2 inline-block"
          }, null, 8, ["modelValue", "entry"])) : _createCommentVNode("", true), !_ctx.entry.cover ? (_openBlock(), _createBlock(_component_CoverSettings, {
            modelValue: _ctx.entry.cover,
            "onUpdate:modelValue": _cache[11] || (_cache[11] = $event => _ctx.entry.cover = $event),
            key: "cover",
            entry: _ctx.entry,
            variant: "round",
            color: "white",
            class: "mb-2 mr-2 inline-block"
          }, null, 8, ["modelValue", "entry"])) : _createCommentVNode("", true), !_ctx.entry.routine ? (_openBlock(), _createBlock(_component_RoutineSettings, {
            modelValue: _ctx.entry.routine,
            "onUpdate:modelValue": _cache[12] || (_cache[12] = $event => _ctx.entry.routine = $event),
            key: "routine",
            entry: _ctx.entry,
            variant: "round",
            color: "white",
            class: "mb-2 mr-2 inline-block"
          }, {
            button_routine_configure: _withCtx(({
              routine
            }) => [_createVNode(_component_ButtonComponent, {
              color: "neutral",
              colorWeight: "900"
            }, {
              default: _withCtx(() => [_createVNode(_component_RepeatIcon, {
                size: "20"
              }), _createTextVNode(" " + _toDisplayString(routine.target) + "x ", 1), _createElementVNode("span", _hoisted_4, _toDisplayString(routine.frequency), 1)]),
              _: 2
            }, 1024)]),
            _: 1
          }, 8, ["modelValue", "entry"])) : _createCommentVNode("", true), !_ctx.entry.senses.length && __props.position != 'modal' ? (_openBlock(), _createElementBlock("div", _hoisted_5, [_createVNode(_component_ButtonComponent, {
            onClick: _cache[13] || (_cache[13] = $event => _ctx.showSenseModal = true),
            variant: "round",
            color: "white",
            title: "Senses"
          }, {
            default: _withCtx(() => [_createVNode(_component_RadioIcon, {
              size: "20"
            })]),
            _: 1
          })])) : _createCommentVNode("", true), _createElementVNode("div", _hoisted_6, [_createVNode(_component_ButtonComponent, {
            onClick: _cache[14] || (_cache[14] = $event => _ctx.showTemplates = !_ctx.showTemplates),
            variant: "round",
            color: "white",
            title: "Show Templates"
          }, {
            default: _withCtx(() => [_createVNode(_component_PuzzleIcon, {
              size: "20",
              class: "transition-transform"
            })]),
            _: 1
          })]), __props.position != 'modal' ? (_openBlock(), _createElementBlock("div", _hoisted_7, [_createVNode(_component_SettingsDisplay, {
            modelValue: _ctx.entry,
            "onUpdate:modelValue": _cache[15] || (_cache[15] = $event => _ctx.entry = $event),
            onClick: _cache[16] || (_cache[16] = $event => _ctx.showSettingsModal = true),
            color: "primary"
          }, null, 8, ["modelValue"])])) : _createCommentVNode("", true)]),
          _: 1
        })])) : _createCommentVNode("", true), _createElementVNode("div", {
          key: "panel",
          class: _normalizeClass(["flex", {
            'h-9': !_ctx.hasPanelContent,
            'h-full': _ctx.hasPanelContent
          }])
        }, [_createVNode(_component_PanelHelper, {
          class: _normalizeClass(['mx-auto', _ctx.$style.entrySettingsPanel, !_ctx.hasPanelContent ? 'w-12 !rounded-full' : 'w-full']),
          ref: "outer"
        }, {
          default: _withCtx(() => [_createElementVNode("div", _hoisted_8, [_createVNode(_Transition, {
            "enter-active-class": "transition-all duration-500 delay-300",
            "enter-from-class": "opacity-0 translate-y-5",
            "enter-to-class": "opacity-100 translate-y-0"
          }, {
            default: _withCtx(() => [_ctx.hasPanelContent ? (_openBlock(), _createBlock(_unref(draggable), _mergeProps({
              key: 0
            }, _ctx.dragOptions, {
              modelValue: _ctx.linkedEntries.slice(0, _ctx.performanceRenderIndex),
              handle: "[data-handle]",
              onChange: _ctx.updatePositions,
              "item-key": "id",
              key: "content",
              class: "flex w-full flex-col gap-y-4 divide-y p-5 dark:divide-neutral-700"
            }), {
              header: _withCtx(() => [_ctx.entry.output && _ctx.performanceRenderIndex >= 0 ? (_openBlock(), _createBlock(_component_OutputDisplay, {
                id: "output",
                key: _ctx.entry.output.id,
                modelValue: _ctx.entry.output,
                "onUpdate:modelValue": _cache[17] || (_cache[17] = $event => _ctx.entry.output = $event),
                entry: _ctx.entry,
                display: {
                  links: _ctx.entry.output.type != 'gallery',
                  output: _ctx.entry.output.type != 'gallery'
                },
                filter: e => e.id != _ctx.entry.id,
                inputs: _ctx.linkedEntries,
                sorting: _ctx.entry.output.sorting,
                editable: true,
                position: __props.position,
                "data-tour": "main_entry_output"
              }, null, 8, ["modelValue", "entry", "display", "filter", "inputs", "sorting", "position"])) : _createCommentVNode("", true), !_ctx.entry.output && _ctx.entry.input ? (_openBlock(), _createBlock(_component_InputDisplay, {
                id: "input",
                key: "input",
                modelValue: _ctx.entry.input,
                "onUpdate:modelValue": _cache[18] || (_cache[18] = $event => _ctx.entry.input = $event),
                entry: _ctx.entry,
                schema: _ctx.entry.input.schema,
                color: _ctx.entry.color
              }, null, 8, ["modelValue", "entry", "schema", "color"])) : _createCommentVNode("", true)]),
              item: _withCtx(({
                element: linkedEntry,
                index
              }) => [_createVNode(_component_EntryDisplayLinked, {
                entry: linkedEntry,
                parent: _ctx.entry,
                settings: _ctx.entry.links.find(link => link.id == linkedEntry.id)?.settings,
                index: index,
                linkedEntries: _ctx.linkedEntries,
                position: __props.position,
                outputFilter: e => e.id != _ctx.entry.id,
                class: _normalizeClass([{
                  '!pt-0': !_ctx.entry.output && !_ctx.entry.input && index == 0
                }, "pt-4"]),
                onToggleCollapse: $event => _ctx.toggleCollapse(linkedEntry)
              }, {
                contextmenu: _withCtx(() => [index > 0 ? (_openBlock(), _createBlock(_component_MenuItem, {
                  key: 0
                }, {
                  default: _withCtx(({
                    active
                  }) => [_createVNode(_component_ButtonComponent, {
                    onClick: _withModifiers(() => {
                      const i = _ctx.entry.links.findIndex(link => link.id === linkedEntry.id);
                      if (i > 0) {
                        const item = _ctx.entry.links.splice(i, 1)[0];
                        _ctx.entry.links.splice(i - 1, 0, item);
                      }
                      _ctx.entry.links.forEach((link, index) => {
                        link.position = index;
                      });
                      _ctx.$store.dispatch('push', {
                        event: 'entry_update',
                        params: {
                          entry: _ctx.entry
                        },
                        entry: _ctx.entry
                      });
                    }, ["stop"]),
                    variant: "menu",
                    color: "white",
                    size: "sm",
                    active: active,
                    class: "w-full rounded-md",
                    title: "Move this entry up"
                  }, {
                    default: _withCtx(() => [_createVNode(_component_ArrowUpIcon, {
                      size: "16",
                      class: _normalizeClass({
                        'text-neutral-500': active
                      })
                    }, null, 8, ["class"]), _cache[27] || (_cache[27] = _createElementVNode("div", {
                      class: "flex flex-1 items-center justify-between"
                    }, [_createElementVNode("span", null, " Move up ")], -1))]),
                    _: 2
                  }, 1032, ["onClick", "active"])]),
                  _: 2
                }, 1024)) : _createCommentVNode("", true), _createVNode(_component_MenuItem, null, {
                  default: _withCtx(({
                    active
                  }) => [_createVNode(_component_ButtonComponent, {
                    onClick: _withModifiers(() => {
                      const i = _ctx.entry.links.findIndex(link => link.id === linkedEntry.id);
                      if (i > -1) {
                        _ctx.entry.links.splice(i, 1);
                      }
                      _ctx.entry.links.forEach((link, index) => {
                        link.position = index;
                      });
                      _ctx.$store.dispatch('push', {
                        event: 'entry_update',
                        params: {
                          entry: _ctx.entry
                        },
                        entry: _ctx.entry
                      });
                    }, ["stop"]),
                    variant: "menu",
                    color: "white",
                    size: "sm",
                    active: active,
                    class: "w-full rounded-md",
                    title: "Unlink"
                  }, {
                    default: _withCtx(() => [_createVNode(_component_Link2OffIcon, {
                      size: "16",
                      class: _normalizeClass({
                        'text-neutral-500': active
                      })
                    }, null, 8, ["class"]), _cache[28] || (_cache[28] = _createElementVNode("div", {
                      class: "flex flex-1 items-center justify-between"
                    }, [_createElementVNode("span", null, " Unlink ")], -1))]),
                    _: 2
                  }, 1032, ["onClick", "active"])]),
                  _: 2
                }, 1024), index < _ctx.entry.links.length - 1 ? (_openBlock(), _createBlock(_component_MenuItem, {
                  key: 1
                }, {
                  default: _withCtx(({
                    active
                  }) => [_createVNode(_component_ButtonComponent, {
                    onClick: _withModifiers(() => {
                      const i = _ctx.entry.links.findIndex(link => link.id === linkedEntry.id);
                      if (i < _ctx.entry.links.length - 1) {
                        const item = _ctx.entry.links.splice(i, 1)[0];
                        _ctx.entry.links.splice(i + 1, 0, item);
                      }
                      _ctx.entry.links.forEach((link, index) => {
                        link.position = index;
                      });
                      _ctx.$store.dispatch('push', {
                        event: 'entry_update',
                        params: {
                          entry: _ctx.entry
                        },
                        entry: _ctx.entry
                      });
                    }, ["stop"]),
                    variant: "menu",
                    color: "white",
                    size: "sm",
                    active: active,
                    class: "w-full rounded-md",
                    title: "Move this entry down"
                  }, {
                    default: _withCtx(() => [_createVNode(_component_ArrowDownIcon, {
                      size: "16",
                      class: _normalizeClass({
                        'text-neutral-500': active
                      })
                    }, null, 8, ["class"]), _cache[29] || (_cache[29] = _createElementVNode("div", {
                      class: "flex flex-1 items-center justify-between"
                    }, [_createElementVNode("span", null, " Move down ")], -1))]),
                    _: 2
                  }, 1032, ["onClick", "active"])]),
                  _: 2
                }, 1024)) : _createCommentVNode("", true), _createVNode(_component_MenuItem, null, {
                  default: _withCtx(({
                    active
                  }) => [_createVNode(_component_ButtonComponent, {
                    onClick: _withModifiers($event => _ctx.toggleCollapse(linkedEntry), ["stop"]),
                    variant: "menu",
                    color: "white",
                    size: "sm",
                    active: active,
                    class: "w-full rounded-md",
                    title: "Toggle collapse"
                  }, {
                    default: _withCtx(() => [_ctx.entry.links.find(l => l.id === linkedEntry.id)?.settings?.collapsed ? (_openBlock(), _createBlock(_unref(ChevronDownIcon), {
                      key: 0,
                      size: "16",
                      class: _normalizeClass({
                        'text-neutral-500': active
                      })
                    }, null, 8, ["class"])) : (_openBlock(), _createBlock(_unref(ChevronUpIcon), {
                      key: 1,
                      size: "16",
                      class: _normalizeClass({
                        'text-neutral-500': active
                      })
                    }, null, 8, ["class"])), _createElementVNode("div", _hoisted_9, [_createElementVNode("span", null, _toDisplayString(_ctx.entry.links.find(l => l.id === linkedEntry.id)?.settings?.collapsed ? "Expand" : "Collapse"), 1)])]),
                    _: 2
                  }, 1032, ["onClick", "active"])]),
                  _: 2
                }, 1024), _cache[30] || (_cache[30] = _createElementVNode("hr", null, null, -1))]),
                _: 2
              }, 1032, ["entry", "parent", "settings", "index", "linkedEntries", "position", "outputFilter", "class", "onToggleCollapse"])]),
              _: 1
            }, 16, ["modelValue", "onChange"])) : (_openBlock(), _createElementBlock("div", _hoisted_10, [_createVNode(_component_ButtonComponentNew, {
              onClick: _cache[19] || (_cache[19] = $event => {
                _ctx.output = _unref(addonBlueprint)('output', _ctx.entry, this);
                _ctx.input = _unref(addonBlueprint)('input', _ctx.entry, this);
              }),
              title: "Add Input & Output",
              shortcode: "+",
              class: _normalizeClass(_ctx.$style.addInputOutputButton)
            }, {
              default: _withCtx(() => [_createVNode(_component_PlusIcon, {
                size: "28"
              })]),
              _: 1
            }, 8, ["class"])]))]),
            _: 1
          })], 512)]),
          _: 1
        }, 8, ["class"])], 2)]),
        _: 1
      })], 512)], 2), _createElementVNode("div", _hoisted_11, [_createVNode(_component_LinksPopover, {
        modelValue: _ctx.links.map(link => link.id),
        "onAdd:linkId": _cache[20] || (_cache[20] = id => {
          _ctx.links = [..._ctx.links, {
            id: id,
            position: _ctx.links.length,
            settings: {}
          }];
        }),
        "data-tour": "main_entry_links"
      }, {
        button: _withCtx(() => [_createVNode(_component_ButtonComponentNew, {
          title: "Add Links",
          shortcode: "b",
          class: _normalizeClass(_ctx.$style.addLinksButton)
        }, {
          default: _withCtx(() => [_createVNode(_component_Link2Icon, {
            size: "20"
          })]),
          _: 1
        }, 8, ["class"])]),
        _: 1
      }, 8, ["modelValue"])]), _createVNode(_Transition, {
        "enter-active-class": "transition-all duration-[800ms] delay-[400ms]",
        "enter-from-class": "opacity-0 translate-y-5",
        "enter-to-class": "opacity-100 translate-y-0",
        "leave-active-class": "transition-all duration-[800ms]",
        "leave-to-class": "opacity-0 translate-y-5",
        "leave-from-class": "opacity-100 translate-y-0"
      }, {
        default: _withCtx(() => [_createVNode(_component_RestDisplay, {
          modelValue: _ctx.entry,
          "onUpdate:modelValue": _cache[21] || (_cache[21] = $event => _ctx.entry = $event),
          class: "mt-2 py-5",
          position: __props.position
        }, null, 8, ["modelValue", "position"])]),
        _: 1
      }), _ctx.showTemplates ? (_openBlock(), _createBlock(_component_TemplateWrapper, {
        key: 0,
        modelValue: _ctx.entry,
        "onUpdate:modelValue": _cache[22] || (_cache[22] = $event => _ctx.entry = $event),
        onClose: _cache[23] || (_cache[23] = $event => _ctx.showTemplates = false)
      }, null, 8, ["modelValue"])) : _createCommentVNode("", true), _createVNode(_component_ModalHelper, {
        show: _ctx.showSenseModal,
        onClose: _cache[25] || (_cache[25] = $event => _ctx.showSenseModal = false)
      }, {
        default: _withCtx(() => [_createVNode(_component_TitleHelper, null, {
          default: _withCtx(() => [_createTextVNode(" Configure Senses for " + _toDisplayString(_ctx.$clearMarkdown(_ctx.entry.name)), 1)]),
          _: 1
        }), _createVNode(_component_SensesSettings, {
          modelValue: _ctx.entry.senses,
          "onUpdate:modelValue": _cache[24] || (_cache[24] = $event => _ctx.entry.senses = $event),
          entry: _ctx.entry
        }, null, 8, ["modelValue", "entry"])]),
        _: 1
      }, 8, ["show"]), _createVNode(_component_ModalHelper, {
        show: _ctx.showSettingsModal,
        onClose: _cache[26] || (_cache[26] = $event => _ctx.showSettingsModal = false)
      }, {
        default: _withCtx(() => [_createVNode(_component_TitleHelper, null, {
          default: _withCtx(() => [_createTextVNode(" Configure Settings for " + _toDisplayString(_ctx.$clearMarkdown(_ctx.entry.name)), 1)]),
          _: 1
        }), (_openBlock(), _createBlock(_component_SettingsSettings, {
          key: _ctx.entry.id,
          value: _ctx.entry.settings,
          entry: _ctx.entry
        }, null, 8, ["value", "entry"]))]),
        _: 1
      }, 8, ["show"])]);
    };
  }
});