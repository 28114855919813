import "core-js/modules/es.array.push.js";
import { Schema } from "./mixins/Schema";
import { Demo } from "./mixins/Demo";
export default {
  name: "StartComponent",
  mixins: [Schema, Demo],
  computed: {
    token() {
      return this.$store.getters.token;
    },
    user() {
      return this.$store.getters.user;
    },
    space() {
      return this.$store.getters.space;
    },
    entries() {
      return this.$store.getters.entries.filter(e => e.deleted_at === null);
    },
    anchors() {
      return this.entries?.filter(e => e.anchor).sort((a, b) => {
        if (a.anchor.position > b.anchor.position) return 1;
        if (a.anchor.position < b.anchor.position) return -1;
        return 0;
      });
    }
  },
  data() {
    return {
      anchorsPulled: false
    };
  },
  mounted() {
    // console.log("Start: mounted");
    if (this.entries.length) {
      // console.log("entries found, redirecting...");
      this.redirect();
    } else {
      // console.log("pulling data...");
      this.pullData();
    }
  },
  watch: {
    anchorsPulled() {
      // console.log("from anchorsPulled...");
      if (this.anchorsPulled && this.entries.length == 0) {
        this.redirect();
      }
    },
    entries() {
      this.redirect();
    }
  },
  methods: {
    pullData() {
      // console.log("pullData");
      this.anchorsPulled = false;
      this.$store.dispatch("pull", {
        filters: [{
          key: ["anchors", "placement"],
          op: "eq",
          val: "left-sidebar"
        }],
        sorting: [{
          field: ["anchor", "position"],
          dir: 1,
          placement: "left-sidebar"
        }],
        cb: () => {
          // console.log("pul done, callback, setting anchorsPulled = true");
          this.anchorsPulled = true;
          // this.redirect();

          this.$store.dispatch("pull", {
            filters: [{
              key: "id",
              op: "is set"
            }],
            limit: 1
          });
        }
      });
    },
    redirect() {
      // setTimeout(() => {
      if (!this.token) this.$router.push({
        name: "login"
      });
      if (this.entries !== null) {
        if (this.anchors.length) {
          // console.log("going to first anchor...");
          let first = this.anchors.sort((a, b) => {
            if (a.anchor.position > b.anchor.position) return 1;
            if (a.anchor.position < b.anchor.position) return -1;
            return 0;
          })[0];
          if (first) {
            this.$router.push({
              name: "entry",
              params: {
                id: first.id
              }
            });
          }
        } else if (this.entries.length) {
          // console.log("going to first entry...");
          this.$router.push({
            name: "entry",
            params: {
              id: this.entries[0].id
            }
          });
        } else if (typeof Cypress == "undefined") {
          // console.log("redirect to onboarding...");
          this.$router.push({
            name: "onboarding"
          });

          // } else {
          //   console.log("pulling at least one entry...");
          //   this.$store.dispatch("pull", {
          //     filters: [{ key: "id", op: "is set" }],
          //     limit: 1,
          //     cb: () => {
          //       console.log("pull done");
          //       if (this.entries.length) {
          //         console.log("redirect");
          //         this.$router.push({
          //           name: "entry",
          //           params: { id: this.entries[0].id },
          //         });
          //       } else {
          //         console.log(
          //           "nope, still no entries, starting onboarding timeout",
          //         );
          //         setTimeout(() => {
          //           console.log("finally. gpong to onboarding...");
          //           this.$router.push({
          //             name: "onboarding",
          //           });
          //         }, 500);
          //       }
          //     },
          //   });
          //   //this.$store.dispatch("retrieveSpace");
          // }
        }
      }
      // }, 0);
    }
  }
};