import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withModifiers as _withModifiers, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue";
const _hoisted_1 = {
  key: 0,
  class: "relative leading-none"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_CircleHelpIcon = _resolveComponent("CircleHelpIcon");
  const _component_ButtonComponent = _resolveComponent("ButtonComponent");
  return $options.nextTour && !$props.autoStart ? (_openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("span", {
    class: _normalizeClass([{
      'animate-ping': !$data.active
    }, "absolute inline-flex h-full w-full rounded-full bg-neutral-400 opacity-100"])
  }, null, 2), _createVNode(_component_ButtonComponent, {
    onClick: _withModifiers($options.onClick, ["stop"]),
    color: "secondary",
    variant: $props.variant,
    size: $props.size,
    title: $options.nextTour.name,
    class: _normalizeClass({
      'animate-pulse': $data.active
    })
  }, {
    default: _withCtx(() => [_cache[0] || (_cache[0] = _createElementVNode("span", {
      class: "sr-only"
    }, "Help", -1)), _createVNode(_component_CircleHelpIcon, {
      size: $props.size == 'xs' ? 16 : $props.size == 'sm' ? 20 : 24
    }, null, 8, ["size"])]),
    _: 1
  }, 8, ["onClick", "variant", "size", "title", "class"])])) : _createCommentVNode("", true);
}