import { Applicators } from "@/components/mixins/Applicators";
import { Filtering } from "@/components/mixins/Filtering";
import { Searching } from "@/components/mixins/Searching";
import { Statusing } from "@/components/mixins/Statusing";
import { Scheduling } from "@/components/mixins/Scheduling";
import { Schema } from "@/components/mixins/Schema";
export default {
  mixins: [Schema, Statusing, Scheduling, Applicators, Filtering, Searching],
  props: {
    entry: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      query: "",
      currentSearchResults: []
    };
  },
  methods: {
    handleSendToSelect(selectedEntry) {
      if (!selectedEntry.input) return;

      // Apply the selected entry's input schema to current entry
      this.applyInput({
        ...this.inputSchema(selectedEntry.input.schema, this.entry),
        input_id: selectedEntry.input.id
      }, this.entry, true);

      // Emit close event to close the popover
      this.$emit("close");
    }
  },
  mounted() {
    this.$refs.searchInput.focus();
  }
};