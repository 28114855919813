export default {
  props: {
    modelValue: Object
  },
  data() {
    return {
      priorities: [1, 2, 3, 4]
    };
  },
  computed: {
    priority: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit("update:modelValue", value);
      }
    }
  }
};