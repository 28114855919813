import { addonBlueprint } from "@/addonBlueprint";
import moment from "moment";
import { nanoid } from "nanoid";

export const Schema = {
  computed: {
    space() {
      return this.$store.getters.space;
    }
  },
  methods: {

    outputSchema(entry) {
      return entry.output
        ?.filters /* || [{ key: "links", op: "any", val: [entry.id] }]*/;
    },
    inputSchema(schema, target) {
      if (!schema) return null;

      schema = JSON.parse(JSON.stringify(schema));

      if (schema) {
        if (schema.links) {
          switch (schema.links.op) {
            case 'add':
              schema.links = [...new Set([...target.links.map(l => l.id), ...schema.links.val])];
              break;
            case 'remove':
              schema.links = target.links.map(l => l.id).filter(l => !schema.links.val.includes(l))
              break;
            case 'set':
              schema.links = schema.links.val
              break;
          }
        }

        if (schema.status) {
          if (this.overrideStatuses?.length && this.overrideStatuses.find((s) => s.id == schema.status))
            this.setStatus(schema, this.overrideStatuses.find((s) => s.id == schema.status))
          else
            this.setStatus(schema, this.$store.getters.statuses.find(status => status.id == schema.status))
          delete schema.status
        }

        if (schema.schedule) {
          var schedule = { ...schema.schedule };
          if (schedule.date && schedule.date.op) {
            schedule.date = this.interpretDate(schedule/*, relativeBase*/);
          }
          if (schedule.time && schedule.time.op) {
            schedule.time = this.interpretTime(schedule/*, relativeBase*/);
          }

          schema.schedule = {
            ...addonBlueprint("schedule", target, this),
            ...target?.schedule,
            ...schedule,
          };
        }

        if (schema.anchors) {
          var anchors = [...schema.anchors];
          anchors = anchors.map(anchor => {
            if (anchor.position && anchor.position.op) {
              if (anchor.position.op == 'append')
                anchor.position = Math.max(
                  ...this.$store.getters.entries
                    .filter(e => e.deleted_at === null && e.anchors?.some(a => a.placement === anchor.placement))
                    .flatMap(e => e.anchors)
                    .filter(a => a.placement === anchor.placement)
                    .map(a => a.position),
                  -1,
                ) + 1;
            }
            return { ...addonBlueprint("anchor", target, this), ...anchor };
          });
          schema.anchors = anchors;
        }

        // if (schema.links) {
        //   schema.links = schema.links;
        // }

        if (schema.output) {
          schema.output = {
            ...addonBlueprint("output", target),
            ...target?.output,
            ...schema.output,
          };
        }

        if (schema.input) {
          schema.input = {
            ...addonBlueprint("input", target),
            ...target?.input,
            ...schema.input,
          };
        }


        if (schema.description) {
          schema.description = {
            ...addonBlueprint("description", target),
            ...target?.description,
            ...schema.description,
          };
        }

        if (schema.priority) {
          schema.priority = {
            ...addonBlueprint("priority", target),
            ...target?.priority,
            ...schema.priority,
          };
        }
        if (schema.procrastination) {
          schema.procrastination = {
            ...addonBlueprint("procrastination", target),
            ...target?.procrastination,
            ...schema.procrastination,
          };
        }
        if (schema.routine) {
          schema.routine = {
            ...addonBlueprint("routine", target),
            ...target?.routine,
            ...schema.routine,
          };
        }

        if (schema.statuses) {
          schema.statuses = schema.statuses.map(s => {
            // if (!s.id) { s.id = nanoid() }
            // if (!s.entry_id) { s.entry_id = target.id }
            // if (!s.space_id) { s.space_id = target.space_id }
            return { ...addonBlueprint('status', target), ...s }
          });
        }

        if (schema.custom_fields) {
          schema.custom_fields = schema.custom_fields.map(cf => {
            // if (!cf.id) { cf.id = nanoid() }
            // if (!cf.entry_id) { cf.entry_id = target.id }
            // if (!cf.space_id) { cf.space_id = target.space_id }
            return { ...addonBlueprint('custom_field', target), ...cf }
          });
        }
        if (schema.custom_values) {
          schema.custom_values = schema.custom_values.map(cv => { if (!cv.id) { cv.id = nanoid(); } return cv });
        }
        if (schema.senses) {
          schema.senses = schema.senses.map(s => { return { ...addonBlueprint('sense', target), ...s } });
        }
        if (schema.time_tracking) {
          const time_tracking = addonBlueprint('time_tracking', target, this)

          // if (schema.time_tracking.start_at?.op && schema.time_tracking.start_at.op == 'now') {
          //   schema.time_tracking.start_at = moment.utc().format("YYYY-MM-DD HH:mm:ss");
          // }

          time_tracking.start_at = schema.time_tracking.start_at
          delete schema.time_tracking
          schema.time_trackings = [time_tracking];
        }
      }
      // if (schema.ai) {
      //   delete schema.ai
      // }

      return schema;
    },
    entrySchema(input = {}, id = null) {
      if (id === null) id = nanoid();
      var entry = {
        id: id,
        space_id: this.$store.getters.space.id,
        name: null,
        color: null,
        created_at: moment.utc().format("YYYY-MM-DD HH:mm:ss"),
        updated_at: moment.utc().format("YYYY-MM-DD HH:mm:ss"),
        deleted_at: null,
        status_id: null,
        links: [],
        statuses: [],
        backlinks: [],
        description: null,
        priority: null,
        schedule: null,
        anchors: [],
        output: null,
        input: null,
        procrastination: null,
        routine: null,

        senses: [],
        custom_fields: [],
        custom_values: [],
        time_trackings: [],
      };

      // return this.inputSchema(input, entry);
      return { ...entry, ...this.inputSchema(input, entry) };
    },
  },
};
