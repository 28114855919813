import "core-js/modules/es.array.push.js";
import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withModifiers as _withModifiers, createBlock as _createBlock, vShow as _vShow, withDirectives as _withDirectives, unref as _unref, Transition as _Transition, normalizeStyle as _normalizeStyle, Teleport as _Teleport } from "vue";
const _hoisted_1 = {
  class: "min-h-8"
};
const _hoisted_2 = {
  type: "text",
  ref: "mobile-suggestion-fix",
  class: "absolute h-0 w-0 opacity-0"
};
const _hoisted_3 = {
  class: "flex flex-col gap-4 p-3"
};
const _hoisted_4 = {
  key: 0,
  class: "rounded-md bg-neutral-50 p-4 dark:bg-neutral-900"
};
const _hoisted_5 = {
  class: "flex"
};
const _hoisted_6 = {
  class: "flex-shrink-0"
};
const _hoisted_7 = {
  class: "ml-3 flex-1 md:flex md:justify-between"
};
const _hoisted_8 = {
  class: "text-sm text-neutral-700 dark:text-neutral-300"
};
const _hoisted_9 = {
  class: "font-bold"
};
const _hoisted_10 = {
  key: 0,
  class: "flex items-center gap-1"
};
const _hoisted_11 = {
  key: 2,
  class: "flex items-center gap-0.5 text-red-500"
};
const _hoisted_12 = {
  key: 1,
  class: "flex items-center gap-1"
};
const _hoisted_13 = {
  key: 0,
  class: "absolute"
};
const _hoisted_14 = {
  key: 1
};
import { ref } from "vue";
// eslint-disable-next-line no-unused-vars
import { autoUpdate, useFloating, offset } from "@floating-ui/vue";
import { addonBlueprint } from "@/addonBlueprint";
import moment from "moment";
import { nanoid } from "nanoid";
import { Schema } from "../mixins/Schema";
import { Scheduling } from "../mixins/Scheduling";
import { Statusing } from "../mixins/Statusing";
import { SearchIcon, LoaderCircleIcon, SparklesIcon } from "lucide-vue-next";
import { Searching } from "../mixins/Searching";
import { Applicators } from "../mixins/Applicators";
import { DragAndDrop } from "../mixins/DragAndDrop";
import { TimeTrackingsMixin } from "../timetrackings/mixins/TimeTrackingsMixin";
import { Filtering } from "../mixins/Filtering";
import { Markdowning } from "../mixins/Markdowning";
const __default__ = {
  inject: {
    overrideEntries: {
      default: null
    }
  },
  props: {
    focusOnMount: Boolean,
    modelValue: Object,
    entry: Object,
    output: Object,
    schema: {
      type: Object,
      default: () => {
        return {};
      }
    },
    editable: {
      type: Boolean,
      default: true
    },
    disabled: Boolean,
    display: Object,
    position: {
      type: String,
      default: () => {
        return "center";
      }
    },
    renderType: {
      type: String,
      default: () => {
        return "list";
      }
    },
    color: {
      type: String,
      default: () => {
        return "neutral";
      }
    }
  },
  mixins: [Schema, Statusing, Scheduling, Searching, Applicators, DragAndDrop, TimeTrackingsMixin, Filtering,
  // To be able to filter the current search to show the number in the search button
  Markdowning],
  data() {
    return {
      isHovered: false,
      hasActivePopover: false,
      editing: false,
      isFocusOnName: false,
      newEntry: this.inputSchemaWithId(this.schema),
      scheduleTimer: null,
      focusIndex: 0,
      registeredInputId: null,
      addonBlueprint: addonBlueprint,
      nameAsText: "",
      // SEARCH RELATED

      searchPullDataTimeout: null,
      showSearch: false,
      // searchFocusIndex: -1,
      currentSearchResults: [],
      // AI RELATED
      aiIsRequired: false,
      aiStatus: "idle",
      aiIsApplied: false,
      entriesToCreateInContext: []
    };
  },
  computed: {
    computedDisabled() {
      return this.disabled || this.position == "template";
    },
    computedDisplay() {
      return {
        ...this.display,
        ...{
          output: false,
          output_count: false,
          input: false
        }
      };
    },
    input: {
      get() {
        return this.modelValue;
      },
      set(val) {
        this.$emit("update:modelValue", val);
      }
    },
    overrides() {
      const overrides = {};
      Object.keys(this.schema).forEach(key => {
        if (JSON.stringify(this.schema[key]) !== JSON.stringify(this.entry.input.schema[key])) {
          overrides[key] = this.schema[key];
        }
      });
      return overrides;
    },
    entryDisplayIsDisabled() {
      return this.position == "template";
    },
    isSearchAvailable() {
      return this.position != "left" && this.nameAsText.length > 2;
    },
    searchResults() {
      if (this.isSearchAvailable) {
        return this.filterEntries([{
          key: "name",
          op: "any",
          val: this.nameAsText.toLowerCase()
        }]);
      }
      return [];
    }
  },
  mounted: function () {
    this.manageInputRegister();
    this.checkIfAiIsRequired();
    if (this.focusOnMount) setTimeout(() => {
      this.editing = true;
    }, 10);
  },
  beforeUnmount() {
    this.manageInputRegister(false);
  },
  watch: {
    newEntry: function (n, o) {
      if (n.id != o.id) {
        this.checkIfAiIsRequired();
      }
    },
    // eslint-disable-next-line no-unused-vars
    nameAsText: function (n) {
      this.aiIsApplied = false;
      this.aiStatus = "idle";
      if (n.length > 0) {
        if (this.searchPullDataTimeout) clearTimeout(this.searchPullDataTimeout);
        this.searchPullDataTimeout = setTimeout(() => {
          this.pullData();
        }, 2000);
      } else {
        if (this.searchPullDataTimeout) clearTimeout(this.searchPullDataTimeout);
      }
    },
    isSearchAvailable: function (n) {
      if (n === false) this.showSearch = false;
    },
    space: function () {
      this.newEntry = this.inputSchemaWithId(this.schema);
    },
    "$route.params.id": {
      handler(n, o) {
        if (n != o) {
          this.resetEntry();
        }
      },
      immediate: true
    },
    editing: function (n) {
      // Select the input field when editing
      if (n) {
        if (this.editing && this.disabled) {
          this.editing = false;
        }
        this.$store.getters.registeredInputs.forEach(input => {
          if (input.entry.id != this.entry?.id) {
            input.obj.editing = false;
          }
        });
        this.$nextTick(() => {
          if (this.editing) {
            this.$el.querySelector("[contenteditable='true']")?.focus();
            this.$el.scrollIntoView({
              behavior: "smooth",
              block: "center"
            });
          }
        });
      } else {
        // Blur any active selection when editing is turned off
        window.getSelection()?.removeAllRanges();
        // Remove focus from any active element
        document.activeElement?.blur();
      }
    },
    // entries: function () {
    //   this.newEntry = this.inputSchemaWithId(this.schema);
    // },
    entry: function (n, o) {
      if (JSON.stringify(n) != JSON.stringify(o)) {
        // console.log("entry watcher editing false", o, n);
        // this.editing = false;
        this.newEntry = this.inputSchemaWithId(this.schema);
        this.manageInputRegister();
      }
    },
    input: function () {
      if (this.input) {
        this.newEntry = this.inputSchemaWithId(this.schema);
      }
    },
    schema: {
      handler() {
        // if (JSON.stringify(n) != JSON.stringify(o)) {
        this.newEntry = this.inputSchemaWithId(this.schema);
        // }
      },
      deep: true
    }
    // "$store.getters.clock": function () {
    //   this.scheduleUpdater();
    // },
    // showSearch: function (n) {
    //   console.log("showSearch", n);
    // },
    // searchFocusIndex: function (n, o) {
    //   /**
    //    * We want to bring the new focused
    //    */
    //   this.$nextTick(() => {
    //     const element =
    //       this.$refs.search_results?.$refs.output?.$refs["entry_" + n];
    //     if (element && element[0]) {
    //       element[0].scrollIntoView({
    //         /**
    //          * If the difference between the old and new index is 1, scroll smoothly.
    //          * Otherwise, we move fast fro top to bottom or vice versa and just jump to the element.
    //          */
    //         behavior: Math.abs(n - o) == 1 ? "smooth" : "auto",
    //         block: "center",
    //         inline: "center",
    //       });
    //     }
    //   });
    // },
  },
  methods: {
    pullData() {
      this.$store.dispatch("pull", {
        filters: [{
          key: "name",
          op: "any",
          val: this.nameAsText.toLowerCase()
        }],
        source: {
          file: "InputDisplay",
          entry: this.entry
        }
      });
    },
    handleOnEntrySelect(entry) {
      this.applyInput(this.inputSchema(this.schema, entry), entry, true);
      // this.newEntry.name = entry.name;
      this.resetEntry();
    },
    clearNameInputTextField() {
      if (this.$refs.entry_display?.$refs.name_display) {
        this.$refs.entry_display.$refs.name_display.makeEmpty();
      }
    },
    createEntry() {
      if (this.aiIsRequired && !this.aiIsApplied && this.aiStatus == "idle") {
        this.retrieveSuggestions();
        return;
      }
      if (this.aiStatus == "loading") {
        this.$store.dispatch("abortApiCall");
      }
      const regex = /Mobi|Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i;
      if (this.editing && regex.test(navigator.userAgent)) {
        /**
         * This is a hack to fix the issue where
         * the keyboard suggestions do not reset
         * on mobile devices after creating an entry.
         */
        const focus = this.$el.querySelector('[contenteditable="true"]:focus');
        if (focus) {
          this.$refs["mobile-suggestion-fix"].focus();
          // setTimeout(() => {
          this.$el.querySelector('[contenteditable="true"]')?.focus();
          this.$el.scrollIntoView({
            behavior: "smooth",
            block: "center"
          });
          // }, 200);
        }
      }
      const newEntry = JSON.parse(JSON.stringify(this.newEntry));
      delete newEntry.temp;

      // for the name to clear the highlights and update the entry
      this.$refs.entry_display.$refs.name_display.updateName(); // @todo this goes better

      newEntry.name = this.nameAsText;
      newEntry.created_at = moment().utc().format("YYYY-MM-DD HH:mm:ss");
      if (newEntry.time_trackings.length) newEntry.time_trackings = [];
      const emitEntry = {
        ...newEntry
      };

      // Seriously I like the push more, but its anti-pattern
      this.$store.dispatch("addEntry", newEntry);
      // console.log("newEntry", newEntry.name);
      // this.$store.getters.entries.push(this.newEntry);
      // console.log(this.newEntry);
      this.$store.dispatch("push", {
        event: "entry_create",
        params: {
          entry: newEntry,
          skip_ai: this.aiIsApplied
        },
        entry: newEntry
        // cb: () => this.$emit("created", emitEntry),
      });
      if (this.schema.time_tracking && this.schema.time_tracking.start_at?.op && this.schema.time_tracking.start_at.op == "now") this.startTimeTracking(newEntry);
      this.resetEntry();
      this.$emit("created", emitEntry);
      this.entriesToCreateInContext.forEach(entry => {
        delete entry.temp;
        entry.links.push({
          id: newEntry.id,
          position: entry.links.length,
          settings: {}
        });
        this.$store.dispatch("addEntry", entry);
        this.$store.dispatch("push", {
          event: "entry_create",
          params: {
            entry: entry
          },
          entry: entry
        });
      });

      // In case the input is on the left side, we want to close the input after creating an entry
      if (this.position == "left") {
        this.editing = false;
        this.showSearch = false;
      } else {
        this.$nextTick(() => {
          if (this.editing) {
            this.$el.scrollIntoView({
              behavior: "smooth",
              block: "center"
            });
            this.$el.querySelector('[contenteditable="true"]')?.focus();
          }
        });
      }
    },
    resetEntry() {
      this.newEntry = this.inputSchemaWithId(this.schema);
      this.clearNameInputTextField();
      setTimeout(() => {
        /**
         * This is a hack to fix the issue where
         * the input field does not clear after creating an entry.
         * This is only an issue on mobile devices.
         */
        this.clearNameInputTextField();
      }, 0);
      this.nameAsText = "";
      this.nameAsHtml = "";
      this.showSearch = false;
    },
    /**
     * Updates the schedule of the new entry based on the schema's schedule.
     * If the schema has a schedule date and time, it interprets them and sets them on the new entry.
     */
    scheduleUpdater() {
      // Check if the schema and new entry have a schedule
      if (this.schema?.schedule && this.newEntry?.schedule) {
        // Check if the schema has a schedule date and the new entry has a schedule date
        if (this.schema.schedule.date?.op && this.newEntry.schedule.date) {
          // Interpret the date from the schema and set it on the new entry
          this.newEntry.schedule.date = this.interpretDate(this.schema.schedule);
        }
        // Check if the schema has a schedule time and the new entry has a schedule time
        if (this.schema.schedule.time?.op && this.newEntry.schedule.time) {
          // Interpret the time from the schema and set it on the new entry
          this.newEntry.schedule.time = this.interpretTime(this.schema.schedule);
        }
      }

      // if (this.schema?.time_tracking && this.newEntry?.time_trackings) {
      //   console.log("hi");
      //   if (
      //     this.schema.time_tracking.start_at?.op &&
      //     this.newEntry.time_trackings.length == 1
      //   ) {
      //     this.newEntry.time_trackings[0].start_at = this.$moment
      //       .utc()
      //       .format("YYYY-MM-DD HH:mm:ss");
      //     console.log(this.newEntry.time_trackings[0].start_at);
      //   }
      // }
    },
    onInputUpdate() {
      this.newEntry = this.inputSchemaWithId(this.schema);
    },
    inputSchemaWithId(schema) {
      let newEntry = {
        temp: true,
        id: nanoid(),
        space_id: this.space?.id || this.$store.getters.user?.current_space_id,
        user_id: this.$store.getters.user?.id,
        input_id: this.input?.id,
        status_id: null,
        name: null,
        description: null,
        anchors: [],
        statuses: [],
        custom_fields: [],
        custom_values: [],
        time_trackings: [],
        senses: [],
        links: [],
        // This will be populated with {id, settings} objects
        backlinks: [],
        settings: {},
        created_at: moment().utc().format("YYYY-MM-DD HH:mm:ss"),
        updated_at: moment().utc().format("YYYY-MM-DD HH:mm:ss"),
        completed_at: null,
        completed_count: 0,
        deleted_at: null
      };
      newEntry = {
        ...newEntry,
        ...this.inputSchema(schema, newEntry)
      };

      // Convert any existing links to the new format
      if (newEntry.links) {
        newEntry.links = newEntry.links.map((linkId, index) => ({
          id: linkId,
          position: index,
          settings: {}
        }));
      }
      return newEntry;
    },
    manageInputRegister(add = true) {
      // if (this.entry.id) {
      if (this.registeredInputId) {
        this.$store.dispatch("unregisterInput", {
          id: this.registeredInputId,
          el: this.$el,
          entry: this.entry,
          schema: this.schema,
          position: this.position
        });
        this.registeredInputId = null;
      }
      if (add) {
        this.registeredInputId = this.$nanoid();
        this.$store.dispatch("registerInput", {
          id: this.registeredInputId,
          el: this.$el,
          obj: this,
          entry: this.entry,
          schema: this.schema,
          position: this.position
        });
      }
      // }
    },
    checkIfAiIsRequired() {
      this.aiStatus = "idle";
      this.aiIsRequired = this.$store.getters.space?.ai_enabled && this.$store.getters.space?.ai_requests_count_this_month < this.$store.getters.space?.ai_requests_quota && this.input.settings?.ai && Object.values(this.input.settings?.ai).length;
      this.aiIsApplied = false;
    },
    retrieveSuggestions() {
      this.aiStatus = "loading";
      this.$store.dispatch("retrieveEntriesInContext", {
        entry: {
          ...this.newEntry,
          ...{
            name: this.nameAsText
          }
        },
        input: this.input,
        output: this.output
      }).then(data => {
        if (data && data.length) {
          data.forEach(entry => {
            if (entry.id == this.newEntry.id) this.newEntry = {
              ...this.newEntry,
              ...entry
            };
          });
          data = data.filter(entry => entry.id != this.newEntry.id);
          this.entriesToCreateInContext = data;
          this.aiStatus = "idle";
          this.aiIsApplied = true;
        }
      }).catch(() => {
        this.aiStatus = "error";
      });
    }
  }
};
export default /*@__PURE__*/Object.assign(__default__, {
  __name: 'InputDisplay',
  setup(__props) {
    const reference = ref(null);
    const search_results = ref(null);
    const {
      floatingStyles
    } = useFloating(reference, search_results, {
      placement: "bottom-start",
      whileElementsMounted: autoUpdate,
      middleware: [offset(10)]
    });
    return (_ctx, _cache) => {
      const _component_PlusIcon = _resolveComponent("PlusIcon");
      const _component_Settings2Icon = _resolveComponent("Settings2Icon");
      const _component_ButtonComponentNew = _resolveComponent("ButtonComponentNew");
      const _component_InfoIcon = _resolveComponent("InfoIcon");
      const _component_InputSettings = _resolveComponent("InputSettings");
      const _component_PopoverHelper = _resolveComponent("PopoverHelper");
      const _component_TourHelper = _resolveComponent("TourHelper");
      const _component_EntryDisplay = _resolveComponent("EntryDisplay");
      const _component_TriangleAlertIcon = _resolveComponent("TriangleAlertIcon");
      const _component_OverlayComponent = _resolveComponent("OverlayComponent");
      const _component_SearchResults = _resolveComponent("SearchResults");
      const _component_TextCursorInputIcon = _resolveComponent("TextCursorInputIcon");
      const _component_ButtonComponent = _resolveComponent("ButtonComponent");
      return __props.schema ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        onClick: _cache[14] || (_cache[14] = $event => !_ctx.computedDisabled && (_ctx.editing = true)),
        onMouseenter: _cache[15] || (_cache[15] = $event => _ctx.isHovered = true),
        onMouseleave: _cache[16] || (_cache[16] = $event => _ctx.isHovered = false),
        class: _normalizeClass({
          [_ctx.$style.inputDisplay]: true,
          [_ctx.$style.inputDisplay_disabled]: __props.disabled,
          [_ctx.$style.inputDisplay_editing]: _ctx.editing,
          [_ctx.$style.inputDisplayWithSettings]: _ctx.isHovered || _ctx.hasActivePopover
        })
      }, [_createElementVNode("div", _hoisted_1, [_createElementVNode("input", _hoisted_2, null, 512), !_ctx.editing && 3 > 4 ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: _normalizeClass(_ctx.$style.createEntryIndicator)
      }, [_createElementVNode("div", null, [_createVNode(_component_PlusIcon, {
        size: "16"
      })])], 2)) : _createCommentVNode("", true), _withDirectives(_createVNode(_component_EntryDisplay, {
        ref: "entry_display",
        modelValue: _ctx.newEntry,
        placeholder: 'Add to ' + (__props.entry && __props.entry.name ? _ctx.clearMarkdown(__props.entry.name).length > 40 ? _ctx.clearMarkdown(__props.entry.name).slice(0, 37) + '...' : _ctx.clearMarkdown(__props.entry.name) : 'Add Entry'),
        display: _ctx.computedDisplay,
        renderType: __props.renderType,
        output: __props.output,
        position: __props.position,
        disabled: _ctx.entryDisplayIsDisabled,
        editable: __props.position != 'template',
        "onLive:name": _cache[4] || (_cache[4] = name => _ctx.nameAsText = name),
        "onUpdate:name": _cache[5] || (_cache[5] = name => _ctx.nameAsText = name),
        onOnFocus: _cache[6] || (_cache[6] = $event => !_ctx.computedDisabled && (_ctx.editing = true) && (_ctx.isFocusOnName = true)),
        onOnBlur: _cache[7] || (_cache[7] = $event => _ctx.isFocusOnName = false),
        onClick: _cache[8] || (_cache[8] = $event => !_ctx.computedDisabled && (_ctx.editing = true)),
        "data-input": __props.entry.id,
        "data-tour": "input_display"
      }, {
        settings: _withCtx(() => [__props.editable ? (_openBlock(), _createBlock(_component_PopoverHelper, {
          key: 0,
          id: "input_builder",
          teleport: true,
          canOverflow: true,
          onOpen: _cache[2] || (_cache[2] = $event => _ctx.hasActivePopover = true),
          onClose: _cache[3] || (_cache[3] = $event => _ctx.hasActivePopover = false),
          class: _normalizeClass(_ctx.$style.inputSettings)
        }, {
          button: _withCtx(() => [_createVNode(_component_ButtonComponentNew, {
            class: _normalizeClass([_ctx.$style.settingsButton, "ps_input_display_settings_button"]),
            title: "Input Settings",
            "data-tour": "input_settings"
          }, {
            default: _withCtx(() => [_createVNode(_component_Settings2Icon, {
              size: "18",
              strokeWidth: "1.5"
            })]),
            _: 1
          }, 8, ["class"])]),
          default: _withCtx(() => [_createElementVNode("div", _hoisted_3, [Object.keys(_ctx.overrides).length > 0 ? (_openBlock(), _createElementBlock("div", _hoisted_4, [_createElementVNode("div", _hoisted_5, [_createElementVNode("div", _hoisted_6, [_createVNode(_component_InfoIcon, {
            size: "20",
            class: "text-neutral-400 dark:text-neutral-600"
          })]), _createElementVNode("div", _hoisted_7, [_createElementVNode("p", _hoisted_8, [_cache[18] || (_cache[18] = _createTextVNode(" The following fields have been overridden: ")), (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(Object.keys(_ctx.overrides), key => {
            return _openBlock(), _createElementBlock("span", {
              key: key,
              class: "text-xs font-bold uppercase"
            }, _toDisplayString(key), 1);
          }), 128))])])])])) : _createCommentVNode("", true), _createVNode(_component_InputSettings, {
            id: "input_builder_inner",
            modelValue: _ctx.input,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => _ctx.input = $event),
            entry: __props.entry,
            onClick: _cache[1] || (_cache[1] = _withModifiers(() => {}, ["stop"])),
            class: "w-64"
          }, null, 8, ["modelValue", "entry"])])]),
          _: 1
        }, 8, ["class"])) : _createCommentVNode("", true), __props.entry.id && __props.entry.id == _ctx.$route.params.id && __props.editable && __props.position == 'center' ? (_openBlock(), _createBlock(_component_TourHelper, {
          key: 1,
          hook: "input_settings",
          size: "sm",
          variant: "minimal",
          class: "ml-1.5 mt-1 inline-block aspect-square h-5"
        })) : _createCommentVNode("", true)]),
        _: 1
      }, 8, ["modelValue", "placeholder", "display", "renderType", "output", "position", "disabled", "editable", "data-input"]), [[_vShow, _ctx.editing || 4 > 3]]), _createVNode(_Transition, {
        "enter-active-class": "duration-200 ease-out",
        "enter-from-class": "transform opacity-0 translate-y-2",
        "enter-to-class": "opacity-100 translate-y-0"
      }, {
        default: _withCtx(() => [_ctx.editing ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: _normalizeClass([_ctx.$style.toolbar, "input_display_toolbar"])
        }, [_createElementVNode("div", {
          ref_key: "reference",
          ref: reference,
          class: _normalizeClass(_ctx.$style.toolbarSearch)
        }, [_createVNode(_Transition, {
          "enter-active-class": "duration-200 transition-opacity ease-out delay-500",
          "enter-from-class": "opacity-0",
          "enter-to-class": "opacity-100",
          "leave-active-class": "duration-200 transform-opacity ease-in",
          "leave-from-class": "opacity-100",
          "leave-to-class": "opacity-0"
        }, {
          default: _withCtx(() => [_ctx.isSearchAvailable && _ctx.searchResults.length ? (_openBlock(), _createBlock(_component_ButtonComponentNew, {
            key: 0,
            onClick: _cache[9] || (_cache[9] = $event => _ctx.showSearch = !_ctx.showSearch),
            title: "Show existing entries",
            shortcode: "s",
            shortcodeMeta: true,
            class: _normalizeClass(_ctx.$style.searchButton)
          }, {
            default: _withCtx(() => [_createVNode(_unref(SearchIcon), {
              size: "16"
            }), _createElementVNode("span", {
              class: _normalizeClass(_ctx.$style.searchButtonText)
            }, [_createElementVNode("span", _hoisted_9, _toDisplayString(_ctx.searchResults.length), 1), _createElementVNode("span", null, _toDisplayString(_ctx.searchResults.length == 1 ? " Entry" : " Entries") + " found ", 1)], 2)]),
            _: 1
          }, 8, ["class"])) : _createCommentVNode("", true)]),
          _: 1
        })], 2), _createElementVNode("div", {
          class: _normalizeClass([_ctx.$style.toolbarButtons, "input_display_toolbar_buttons"])
        }, [_createVNode(_component_ButtonComponentNew, {
          onClick: _cache[10] || (_cache[10] = _withModifiers($event => (_ctx.resetEntry(), _ctx.editing = false, _ctx.$emit('onCancel')), ["stop"])),
          class: _normalizeClass(_ctx.$style.cancelButton),
          title: "Cancel",
          shortcode: "Escape"
        }, {
          default: _withCtx(() => _cache[19] || (_cache[19] = [_createTextVNode(" Cancel ")])),
          _: 1
        }, 8, ["class"]), _createVNode(_component_ButtonComponentNew, {
          onClick: _cache[11] || (_cache[11] = _withModifiers(e => {
            _ctx.createEntry();
            if ((e?.isTrusted || _ctx.$Cypress) && (!_ctx.aiIsRequired || _ctx.aiIsApplied)) {
              _ctx.editing = false;
            }
          }, ["stop"])),
          shortcode: !_ctx.showSearch && _ctx.isFocusOnName ? 'Enter' : null,
          title: "Create Entry",
          class: _normalizeClass(_ctx.$style.createButton)
        }, {
          default: _withCtx(() => [_ctx.aiIsRequired && !_ctx.aiIsApplied ? (_openBlock(), _createElementBlock("span", _hoisted_10, [_ctx.aiStatus === 'idle' ? (_openBlock(), _createElementBlock(_Fragment, {
            key: 0
          }, [_createVNode(_unref(SparklesIcon), {
            size: "16"
          }), _cache[20] || (_cache[20] = _createTextVNode(" AI "))], 64)) : _createCommentVNode("", true), _ctx.aiStatus === 'loading' ? (_openBlock(), _createElementBlock(_Fragment, {
            key: 1
          }, [_createVNode(_unref(LoaderCircleIcon), {
            size: "16",
            class: "animate-spin"
          }), _cache[21] || (_cache[21] = _createTextVNode(" Loading "))], 64)) : _ctx.aiStatus === 'error' ? (_openBlock(), _createElementBlock("span", _hoisted_11, [_createVNode(_component_TriangleAlertIcon, {
            size: "16"
          }), _cache[22] || (_cache[22] = _createTextVNode(" Error "))])) : _createCommentVNode("", true)])) : (_openBlock(), _createElementBlock("span", _hoisted_12, [_ctx.aiIsRequired ? (_openBlock(), _createBlock(_unref(SparklesIcon), {
            key: 0,
            size: "16"
          })) : _createCommentVNode("", true), _cache[23] || (_cache[23] = _createTextVNode(" Add Entry "))]))]),
          _: 1
        }, 8, ["shortcode", "class"])], 2)], 2)) : _createCommentVNode("", true)]),
        _: 1
      })]), (_openBlock(), _createBlock(_Teleport, {
        to: "body"
      }, [_createVNode(_Transition, {
        "enter-active-class": "duration-200 ease-out",
        "enter-from-class": "transform opacity-0 translate-y-2",
        "enter-to-class": "opacity-100 translate-y-0",
        "leave-active-class": "duration-200 ease-in",
        "leave-from-class": "opacity-100",
        "leave-to-class": "transform opacity-0 translate-y-2"
      }, {
        default: _withCtx(() => [_ctx.showSearch ? (_openBlock(), _createElementBlock("div", _hoisted_13, [_createVNode(_component_OverlayComponent, {
          onClick: _cache[12] || (_cache[12] = $event => _ctx.showSearch = false)
        }), _createVNode(_component_SearchResults, {
          ref_key: "search_results",
          ref: search_results,
          query: _ctx.nameAsText,
          limit: 5,
          displayFields: {
            status: true,
            procrastination: false,
            priority: true,
            name: true,
            links: true,
            description: false,
            schedule: false,
            output: false,
            input: false,
            settings: false,
            routine: false,
            time_trackings: false,
            custom_fields: false
          },
          onOnEntrySelect: _ctx.handleOnEntrySelect,
          onOnSearchResultsChange: _cache[13] || (_cache[13] = $event => _ctx.currentSearchResults = $event),
          class: "z-20 max-h-64 w-64 overflow-y-auto rounded-lg bg-white px-2 shadow dark:bg-black dark:shadow-none dark:ring-1 dark:ring-neutral-700",
          style: _normalizeStyle(_unref(floatingStyles))
        }, null, 8, ["query", "onOnEntrySelect", "style"])])) : _createCommentVNode("", true)]),
        _: 1
      })]))], 34)) : (_openBlock(), _createElementBlock("div", _hoisted_14, [_createVNode(_component_ButtonComponent, {
        onClick: _cache[17] || (_cache[17] = $event => _ctx.input = _unref(addonBlueprint)('input', __props.entry, this)),
        class: "w-full"
      }, {
        default: _withCtx(() => [_createVNode(_component_TextCursorInputIcon, {
          size: "20"
        }), _cache[24] || (_cache[24] = _createElementVNode("span", null, " Add Input ", -1))]),
        _: 1
      })]));
    };
  }
});