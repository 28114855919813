import { filterItemsByFilter } from "../../filterResolving";

const OPERATORS = {
  eq: { title: 'Equals', value: 'eq' },
  neq: { title: 'Does not equal', value: 'neq' },
  gt: { title: 'Is greater than', value: 'gt' },
  gte: { title: 'Is greater than or equal', value: 'gte' },
  lt: { title: 'Is less than', value: 'lt' },
  lte: { title: 'Is less than or equal', value: 'lte' },
  any: { title: 'Contains', value: 'any' },
  not_any: { title: 'Does not contain', value: 'not any' },
  is_set: { title: 'Is set', value: 'is set' },
  is_not_set: { title: 'Is not set', value: 'is not set' },
};


export const Filtering = {
  data() {
    return {

      keys: [
        { title: "Name", value: "name" },
        { title: "Status", value: "status" },
        { title: "Schedule Date", value: ["schedule", "date"] },
        { title: "Schedule Time", value: ["schedule", "time"] },
        { title: "Priority", value: "priority" },
        { title: "Anchor", value: "anchor" },
        { title: "Links", value: "links" },
        { title: "Description", value: "description" },
        { title: "Procrastination", value: "procrastination" },
        { title: "Date Created", value: ["date", "created"] },
        { title: "Date Updated", value: ["date", "updated"] },
        { title: "Completed at", value: "completed_at" },
        { title: "Recurrence", value: ["schedule", "recurrence"] },
        { title: "Duration", value: ["schedule", "duration"] },
        { title: "Routine", value: "routine" },

        // "status",

        // ["schedule", "date"],
        // ["schedule", "time"],

        // /*["completion", "completed"],
        // ["completion", "completed_at"],*/

        // "priority",
        // "anchor",
        // "links",
        // "description",
        // "procrastination",
        // ["date", "created"],
        // ["date", "updated"],
        // "completed_at",
        // // ["schedule", "duration"],
        // ["schedule", "recurrence"],

        // "routine",
      ],
    }
  },
  methods: {
    filterEntries(filters, entries) {
      if (filters && filters.length) {
        if (!entries)
          entries = this.$store.getters.entries.filter(
            (e) => e.deleted_at === null
          );
        filters.forEach((f) => {
          entries = entries.filter(filterItemsByFilter(f));
        });
      } else {
        entries = [];
      }
      return entries;
    },

    getKeys(pointer) {
      return [
        ...new Set(
          this.keys
            .filter((k) =>
              pointer ? typeof k == "object" && k[0] == pointer : true,
            )
            .map((k) => (typeof k == "object" ? (pointer ? k[1] : k[0]) : k)),
        ),
      ];
    },
    keyToHtml(key) {
      if (typeof key == "object") {
        return key.join(" · ").replace("_", " ");
      }
      return key.replace("_", " ");
    },
    opToSign(op) {
      switch (op) {
        case "is set":
          return "is set";
        case "is not set":
          return "is not set";
        case "gt":
          return "is greater than";
        case "gte":
          return "is greater than or equal";
        case "eq":
          return "is";
        case "neq":
          return "is not";
        case "lte":
          return "is lower than or equal";
        case "lt":
          return "is lower than";
        case "any":
          return "does contain";
        case "not any":
          return "does not contain";
      }
      return op;
    },
    ops(key) {
      var attribute = null;
      if (key && typeof key == "object") {
        [key, attribute] = key;
      }
      switch (key) {
        case "name":
          return [OPERATORS.eq, OPERATORS.neq, OPERATORS.any, OPERATORS.not_any];
        case "status":
          return [OPERATORS.is_set, OPERATORS.is_not_set, OPERATORS.any, OPERATORS.not_any];
        case "description":
          return [OPERATORS.is_set, OPERATORS.is_not_set, OPERATORS.eq, OPERATORS.neq, OPERATORS.any, OPERATORS.not_any];
        case "schedule":
          if (attribute == "recurrence") {
            return [OPERATORS.is_set, OPERATORS.is_not_set, OPERATORS.eq, OPERATORS.neq];
          }
          return [
            OPERATORS.is_set,
            OPERATORS.is_not_set,
            OPERATORS.gt,
            OPERATORS.gte,
            OPERATORS.eq,
            OPERATORS.neq,
            OPERATORS.lte,
            OPERATORS.lt,
          ];
        case "date":
          return [
            OPERATORS.is_set,
            OPERATORS.is_not_set,
            OPERATORS.gt,
            OPERATORS.gte,
            OPERATORS.eq,
            OPERATORS.neq,
            OPERATORS.lte,
            OPERATORS.lt,
          ];
        case "time":
          return [
            OPERATORS.is_set,
            OPERATORS.is_not_set,
            OPERATORS.gt,
            OPERATORS.gte,
            OPERATORS.eq,
            OPERATORS.neq,
            OPERATORS.lte,
            OPERATORS.lt,
          ];
        case "completed_at":
          return [
            OPERATORS.is_set,
            OPERATORS.is_not_set,
            OPERATORS.gt,
            OPERATORS.gte,
            OPERATORS.eq,
            OPERATORS.neq,
            OPERATORS.lte,
            OPERATORS.lt,
          ];
        case "links":
          return [OPERATORS.is_set, OPERATORS.is_not_set, OPERATORS.any, OPERATORS.not_any];
        case "anchor":
          return [OPERATORS.is_set, OPERATORS.is_not_set];
        case "procrastination":
          return [
            OPERATORS.is_set,
            OPERATORS.is_not_set,
            OPERATORS.gt,
            OPERATORS.gte,
            OPERATORS.eq,
            OPERATORS.neq,
            OPERATORS.lte,
            OPERATORS.lt,
          ];
        case "routine":
          return [OPERATORS.is_set, OPERATORS.is_not_set];
      }

      return [
        OPERATORS.is_set,
        OPERATORS.is_not_set,
        OPERATORS.gt,
        OPERATORS.gte,
        OPERATORS.eq,
        OPERATORS.neq,
        OPERATORS.lte,
        OPERATORS.lt,
        OPERATORS.any,
      ];
    },
    vals(key, op) {
      var attribute = null;
      if (key && typeof key == "object") {
        [key, attribute] = key;
      }
      switch (key) {
        case "count":
          return ["TextPicker"];
        case "links":
          if (["any", "not any"].includes(op)) return ["LinksBuilder"];
          break;
        case "status":
          if (["any", "not any"].includes(op))
            return ["StatusBuilder", { statuses: this.statuses }];
          break;
        case "schedule":
          if (["gt", "gte", "eq", "neq", "lte", "lt"].includes(op)) {
            if (attribute == 'duration') {

              return ["TextPicker"];
            }
            if (attribute == "recurrence") {
              return [
                "OptionPicker",
                {
                  options: [
                    [{ op: "nextXDays", x: 1 }],
                    [{ op: "nextXDays", x: 2 }],
                    [{ op: "nextXWeeks", x: 0.5 }],
                    [{ op: "nextXWeeks", x: 1 }],
                    [{ op: "nextXWeeks", x: 2 }],
                    [{ op: "nextXMonths", x: 1 }],
                    [{ op: "nextXMonths", x: 2 }],
                    [{ op: "nextXMonths", x: 3 }],
                    [{ op: "nextXYears", x: 0.5 }],
                    [{ op: "nextXYears", x: 1 }],
                  ],
                  render: (v) =>
                    this.relativeDateToText(v[0])
                      ? "Every " + this.relativeDateToText(v[0])
                      : null,
                },
              ];
            }

            return attribute == "date" ? ["DatePickerBuilder"] : ["TimePickerBuilder"];
          }
          break;
        case "date":
          if (["gt", "gte", "eq", "neq", "lte", "lt"].includes(op))
            return ["DatePickerBuilder", { teleport: true }];
          break;
        case "completed_at":
          if (["gt", "gte", "eq", "neq", "lte", "lt"].includes(op))
            return ["DatePickerBuilder"];
          break;
        // case "completion":
        //   switch (attribute) {
        //     case "completed":
        //       if (["eq", "neq"].includes(op))
        //         return [
        //           "OptionPicker",
        //           {
        //             options: [[0], [1]],
        //             render: (v) => (v[0] ? "completed" : "uncompleted"),
        //           },
        //         ];
        //       break;
        //     case "completed_at":
        //       if (["gt", "gte", "eq", "neq", "lte", "lt"].includes(op))
        //         return ["DatePickerBuilder"];
        //       /*return [
        //           [{ op: "today" }],
        //           [{ op: "tomorrow" }],
        //           [{ op: "yesterday" }],
        //         ];*/
        //       break;
        //   }
        //   break;
        case "name":
        case "description":
          if (!["is set", "is not set"].includes(op)) return ["TextPicker"];
          break;
        case "procrastination":
        case "priority":
          if (["gt", "gte", "eq", "neq", "lte", "lt"].includes(op))
            return ["TextPicker"];
          break;
      }
      return [];
    },
    valToHtml(key, val) {
      var attribute = null;
      if (key && typeof key == "object") {
        // eslint-disable-next-line no-unused-vars
        [key, attribute] = key;
      }

      switch (key) {
        case "schedule":
        case "date":
          return this.$formattedDateOp(val[0]);
        // case "completion":
        //   switch (attribute) {
        //     case "completed":
        //       if (val[0] == 0) {
        //         return "uncomplete";
        //       }
        //       if (val[0] == 1) {
        //         return "complete";
        //       }
        //       break;
        //     case "completed_at":
        //       return this.$formattedDateOp(val[0]);
        //   }
        //   break;
        case "links":
          return val
            .map((link) => this.entries.find((entry) => entry.id == link).name)
            .join(", ");
      }

      return val;
    },

    getUnit(key) {

      var attribute = null;
      if (key && typeof key == "object") {
        [key, attribute] = key;
      }

      switch (key) {
        case "schedule":
          if (attribute == 'duration')
            return "minutes";
          return;
        case "count":
        default:
          return "entries";
      }
    },
  },
};
