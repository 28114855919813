import { Addon } from "../mixins/Addon";
import moment from "moment";
import DateTimePicker from "../builders/DateTimePicker.vue";
export default {
  name: "CompletionCompletedAt",
  props: {
    id: String
  },
  mixins: [Addon],
  components: {
    DateTimePicker
  },
  data() {
    return {
      entry_quick_fix: this.modelValue,
      moment: moment,
      edit: false
    };
  }
};