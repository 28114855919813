export default {
  mounted() {
    window.addEventListener("keyup", this.handleKeyup);
    //document.body.style.overflowY = "hidden";
  },
  beforeUnmount() {
    window.removeEventListener("keyup", this.handleKeyup);
    //document.body.style.overflowY = "auto";
  },
  methods: {
    handleKeyup(e) {
      if (e.key == "Escape") {
        this.$emit("click", e);
      }
    }
  }
};