import { addonBlueprint } from "@/addonBlueprint";
import draggable from "vuedraggable";
import { Addon } from "../mixins/Addon";
export default {
  mixins: [Addon],
  computed: {
    space() {
      return this.$store.getters.space;
    },
    senses: {
      get() {
        return this.modelValue;
      },
      set(senses) {
        // eslint-disable-next-line vue/no-mutating-props
        this.entry.senses = senses;
        this.$store.dispatch("push", {
          event: "entry_update",
          params: {
            entry: this.entry
          },
          entry: this.entry
        });
      }
    }
  },
  components: {
    draggable
  },
  data() {
    return {
      open: false,
      senseInConfig: null,
      newSense: addonBlueprint("sense", this.entry, this)
    };
  },
  methods: {
    updatePositions: function () {
      this.senses = this.senses.map((s, i) => {
        s.position = i;
        return s;
      });
    },
    addSense() {
      // Set position of new status
      this.newSense.position = this.senses.length;
      this.senses = [...this.senses, this.newSense];

      //this.space.statuses.push(this.newSense);
      this.newSense = addonBlueprint("sense", this.entry, this);
    },
    updateSense(sense) {
      this.$store.dispatch("push", {
        event: "sense_update",
        params: {
          sense: sense
        },
        entry: this.entry
      });
    },
    deleteSense(sense) {
      this.$store.dispatch("push", {
        event: "sense_delete",
        params: {
          id: sense.id
        },
        entry: this.entry
      });
      this.senses = this.senses.filter(s => s.id !== sense.id);
      this.$nextTick(() => {
        this.updatePositions();
      });
    }
  }
};