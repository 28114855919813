import { unref as _unref, renderSlot as _renderSlot, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, withModifiers as _withModifiers, normalizeStyle as _normalizeStyle, Transition as _Transition, Teleport as _Teleport, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode } from "vue";
import { Listbox, ListboxLabel, ListboxButton, ListboxOptions } from "@headlessui/vue";
import { ref, useSlots } from "vue";
import { useFloating, shift, flip } from "@floating-ui/vue";
import OverlayComponent from "../helpers/OverlayComponent.vue";
const __default__ = {
  // inject: ["position"],
  props: {
    modelValue: null,
    teleport: Boolean
  },
  computed: {
    modelValueComputed: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit("update:modelValue", value);
      }
    }
  }
};
export default /*@__PURE__*/Object.assign(__default__, {
  __name: 'ListboxHelper',
  setup(__props) {
    const slots = useSlots();
    const reference = ref(null);
    const floating = ref(null);
    const {
      floatingStyles
    } = useFloating(reference, floating, {
      placement: "bottom-start",
      middleware: [flip(), shift({
        crossAxis: true
      })]
    });

    // this const will be avaiable on template

    return (_ctx, _cache) => {
      return _openBlock(), _createBlock(_unref(Listbox), {
        as: "div",
        modelValue: _ctx.modelValueComputed,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => _ctx.modelValueComputed = $event)
      }, {
        default: _withCtx(({
          open
        }) => [_unref(slots).label ? (_openBlock(), _createBlock(_unref(ListboxLabel), {
          key: 0,
          class: "block text-sm font-medium leading-6 text-gray-900"
        }, {
          default: _withCtx(() => [_renderSlot(_ctx.$slots, "label")]),
          _: 3
        })) : _createCommentVNode("", true), _createElementVNode("div", {
          ref_key: "reference",
          ref: reference,
          class: _normalizeClass(["relative", [_unref(slots).label ? 'mt-2' : '']])
        }, [_createVNode(_unref(ListboxButton), {
          class: "flex w-full"
        }, {
          default: _withCtx(() => [_renderSlot(_ctx.$slots, "button")]),
          _: 3
        }), open ? (_openBlock(), _createBlock(_Teleport, {
          key: 0,
          disabled: !__props.teleport,
          to: "body"
        }, [_createVNode(OverlayComponent, {
          onClick: _withModifiers($event => open = false, ["stop"])
        }, null, 8, ["onClick"]), _createVNode(_Transition, {
          "leave-active-class": "transition ease-in duration-100",
          "leave-from-class": "opacity-100",
          "leave-to-class": "opacity-0"
        }, {
          default: _withCtx(() => [_createVNode(_unref(ListboxOptions), {
            style: _normalizeStyle(_unref(floatingStyles)),
            ref_key: "floating",
            ref: floating,
            class: "z-40 max-h-60 min-w-fit overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-neutral-200 focus:outline-none sm:text-sm dark:bg-black dark:ring-neutral-700"
          }, {
            default: _withCtx(() => [_renderSlot(_ctx.$slots, "default")]),
            _: 3
          }, 8, ["style"])]),
          _: 3
        })], 8, ["disabled"])) : _createCommentVNode("", true)], 2)]),
        _: 3
      }, 8, ["modelValue"]);
    };
  }
});