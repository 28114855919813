import { defineAsyncComponent } from "vue";
import { Scheduling } from "../mixins/Scheduling";
import { Statusing } from "../mixins/Statusing";
import { addonBlueprint } from "@/addonBlueprint";
export default {
  components: {
    ScheduleDatePicker: defineAsyncComponent(() => import("@/components/schedule/partials/ScheduleDatePicker.vue")),
    ScheduleTimePicker: defineAsyncComponent(() => import("@/components/schedule/partials/ScheduleTimePicker.vue"))
  },
  mixins: [Scheduling, Statusing],
  props: {
    modelValue: Object,
    entry: Object,
    otherentries: Array,
    disabled: Boolean
  },
  computed: {
    schedule: {
      get() {
        return this.entry.schedule;
      },
      set(value) {
        // eslint-disable-next-line vue/no-mutating-props
        this.entry.schedule = value;
        if (!this.entry.temp) this.$store.dispatch("push", {
          event: "entry_update",
          params: {
            entry: this.entry
          },
          entry: this.entry
        });
      }
    }
  },
  watch: {
    entry: function (n, o) {
      if (JSON.stringify(n) != JSON.stringify(o)) {
        this.date = this.entry.schedule?.date;
        this.time = this.entry.schedule?.time;
      }
    },
    "entry.schedule": function (n, o) {
      if (JSON.stringify(n) != JSON.stringify(o)) {
        this.date = this.entry.schedule?.date;
        this.time = this.entry.schedule?.time;
      }
    },
    "entry.schedule.date": function (n, o) {
      if (JSON.stringify(n) != JSON.stringify(o)) {
        this.date = this.entry.schedule?.date;
      }
    },
    "entry.schedule.time": function (n, o) {
      if (JSON.stringify(n) != JSON.stringify(o)) {
        this.time = this.entry.schedule?.time;
      }
    }
  },
  methods: {
    createSchedule() {
      this.schedule = addonBlueprint("schedule", this.entry, this);
    },
    updateDate(val) {
      //this.updateObject(this.entry, (entry) => {
      if (this.entry.schedule === null) return;
      if (val.op && val.op === "null" && this.entry.schedule?.time === null) {
        // eslint-disable-next-line vue/no-mutating-props
        this.entry.schedule = null;
      } else {
        // TODO need to move this part over to Addon Mixin updateAddon()
        this.setEntrySchedule(this.entry, {
          date: this.interpretDate({
            date: val
          }),
          time: this.entry.schedule?.time
        });
        //});
      }
      if (!this.entry.temp) this.$store.dispatch("push", {
        event: "entry_update",
        params: {
          entry: this.entry
        },
        entry: this.entry
      });
    },
    updateTime(val) {
      if (this.entry.schedule === null) return;
      if ((val === null || val && val.op && val.op === "null") && this.entry.schedule?.date === null) {
        // eslint-disable-next-line vue/no-mutating-props
        this.entry.schedule = null;
      } else {
        // TODO need to move this part over to Addon Mixin updateAddon()
        this.setEntrySchedule(this.entry, {
          date: this.entry.schedule?.date,
          time: this.interpretTime({
            time: val
          })
        });
      }
      if (!this.entry.temp) this.$store.dispatch("push", {
        event: "entry_update",
        params: {
          entry: this.entry
        },
        entry: this.entry
      });
    }
  }
};