import "core-js/modules/es.array.push.js";
import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_LinksBuilder = _resolveComponent("LinksBuilder");
  return _openBlock(), _createBlock(_component_LinksBuilder, {
    modelValue: $options.links.map(link => link.id),
    "onUpdate:modelValue": _cache[0] || (_cache[0] = val => {
      // add new links
      val.forEach(id => {
        if (!$options.links.some(link => link.id === id)) {
          $options.links.push({
            id: id,
            settings: {}
          });
        }
      });
      // remove links
      $options.links = $options.links.filter(link => val.includes(link.id));
      $options.links.forEach((link, index) => {
        link.position = index;
      });
    }),
    entry: _ctx.entry,
    variant: _ctx.variant,
    size: _ctx.size,
    color: _ctx.color,
    colorWeight: _ctx.colorWeight
  }, null, 8, ["modelValue", "entry", "variant", "size", "color", "colorWeight"]);
}