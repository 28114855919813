import { Applicators } from "@/components/mixins/Applicators";
import { Schema } from "@/components/mixins/Schema";
import { Statusing } from "@/components/mixins/Statusing";
import { Scheduling } from "@/components/mixins/Scheduling";
import { Templating } from "@/components/mixins/Templating";
import { Addon } from "@/components/mixins/Addon";
export default {
  mixins: [Schema, Applicators, Statusing, Scheduling, Templating, Addon],
  mounted() {
    this.$store.dispatch("retrieveTemplates");
  }
};