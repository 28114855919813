import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  class: "ps_dropdown__menu"
};
const _hoisted_2 = {
  class: "flex flex-1 items-center justify-between"
};
const _hoisted_3 = {
  class: "flex flex-1 items-center justify-between"
};
const _hoisted_4 = {
  class: "w-48 p-2"
};
const _hoisted_5 = {
  class: "flex flex-1 items-center justify-between"
};
const _hoisted_6 = {
  class: "w-96 max-w-full p-2"
};
const _hoisted_7 = {
  class: "flex flex-1 items-center justify-between"
};
const _hoisted_8 = {
  key: 2,
  class: "whitespace-nowrap"
};
const _hoisted_9 = {
  key: 3,
  class: "whitespace-nowrap"
};
const _hoisted_10 = {
  class: "flex basis-full flex-col gap-1 rounded-lg bg-gray-50 p-2 dark:bg-neutral-950"
};
const _hoisted_11 = {
  key: 0,
  class: "flow-root rounded-md"
};
const _hoisted_12 = {
  class: "flow-root rounded-md"
};
const _hoisted_13 = {
  class: "block select-text font-mono text-xs text-gray-500"
};
const _hoisted_14 = {
  class: "flow-root rounded-md"
};
const _hoisted_15 = {
  class: "block select-text font-mono text-xs text-gray-500"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_ClockIcon = _resolveComponent("ClockIcon");
  const _component_ButtonComponent = _resolveComponent("ButtonComponent");
  const _component_MenuItem = _resolveComponent("MenuItem");
  const _component_ScheduleSettings = _resolveComponent("ScheduleSettings");
  const _component_CircleIcon = _resolveComponent("CircleIcon");
  const _component_CircleDashedIcon = _resolveComponent("CircleDashedIcon");
  const _component_ChevronRightIcon = _resolveComponent("ChevronRightIcon");
  const _component_StatusSettings = _resolveComponent("StatusSettings");
  const _component_Link2Icon = _resolveComponent("Link2Icon");
  const _component_LinksSettings = _resolveComponent("LinksSettings");
  const _component_PopoverHelper = _resolveComponent("PopoverHelper");
  const _component_FlagIcon = _resolveComponent("FlagIcon");
  const _component_PrioritySettings = _resolveComponent("PrioritySettings");
  const _component_SwatchBookIcon = _resolveComponent("SwatchBookIcon");
  const _component_ColorSettings = _resolveComponent("ColorSettings");
  const _component_TextCursorInputIcon = _resolveComponent("TextCursorInputIcon");
  const _component_SendTo = _resolveComponent("SendTo");
  const _component_PuzzleIcon = _resolveComponent("PuzzleIcon");
  const _component_TemplateSettings = _resolveComponent("TemplateSettings");
  const _component_SidebarIcon = _resolveComponent("SidebarIcon");
  const _component_AnchorSettings = _resolveComponent("AnchorSettings");
  const _component_CopyPlusIcon = _resolveComponent("CopyPlusIcon");
  const _component_CopyMinusIcon = _resolveComponent("CopyMinusIcon");
  const _component_FilesIcon = _resolveComponent("FilesIcon");
  const _component_TrashIcon = _resolveComponent("TrashIcon");
  const _component_MenuItems = _resolveComponent("MenuItems");
  const _component_Menu = _resolveComponent("Menu");
  const _component_CompletedAtComponent = _resolveComponent("CompletedAtComponent");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_Menu, {
    as: "div"
  }, {
    default: _withCtx(() => [_createVNode(_component_MenuItems, {
      ref: "items",
      static: "",
      class: _normalizeClass([_ctx.$style.content, "focus-visible:outline-none"])
    }, {
      default: _withCtx(() => [_renderSlot(_ctx.$slots, "default"), _createVNode(_component_ScheduleSettings, {
        modelValue: $options.entry.schedule,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => $options.entry.schedule = $event),
        entry: $options.entry,
        variant: "menu",
        class: "w-full"
      }, {
        button_date: _withCtx(({
          date
        }) => [_createTextVNode(_toDisplayString(date), 1)]),
        button_open: _withCtx(() => [_createVNode(_component_MenuItem, null, {
          default: _withCtx(({
            active
          }) => [_createVNode(_component_ButtonComponent, {
            variant: "menu",
            size: "sm",
            color: active ? 'neutral' : 'white',
            colorWeight: "100",
            active: active,
            class: "w-full rounded-md"
          }, {
            default: _withCtx(() => [_createVNode(_component_ClockIcon, {
              size: "16",
              class: _normalizeClass({
                'text-indigo-500': active
              })
            }, null, 8, ["class"]), _cache[10] || (_cache[10] = _createElementVNode("span", {
              class: "whitespace-nowrap"
            }, " Schedule ", -1))]),
            _: 2
          }, 1032, ["color", "active"])]),
          _: 1
        })]),
        _: 1
      }, 8, ["modelValue", "entry"]), _createVNode(_component_StatusSettings, {
        modelValue: $options.status,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => $options.status = $event),
        entry: $options.entry,
        statuses: _ctx.getAvailableStatuses(this.entry),
        class: "w-full"
      }, {
        button: _withCtx(() => [_createVNode(_component_MenuItem, null, {
          default: _withCtx(({
            active
          }) => [_createVNode(_component_ButtonComponent, {
            variant: "menu",
            color: "white",
            size: "sm",
            active: active,
            class: "group/button w-full rounded-md"
          }, {
            default: _withCtx(() => [$options.status ? (_openBlock(), _createBlock(_component_CircleIcon, {
              key: 0,
              color: $options.status.color,
              size: "16",
              strokeWidth: "2.5"
            }, null, 8, ["color"])) : (_openBlock(), _createBlock(_component_CircleDashedIcon, {
              key: 1,
              size: "16"
            })), _createElementVNode("div", _hoisted_2, [_cache[11] || (_cache[11] = _createElementVNode("span", null, "Status", -1)), _createVNode(_component_ChevronRightIcon, {
              size: "12"
            })])]),
            _: 2
          }, 1032, ["active"])]),
          _: 1
        })]),
        _: 1
      }, 8, ["modelValue", "entry", "statuses"]), _createVNode(_component_PopoverHelper, {
        placement: "right",
        teleport: true
      }, {
        button: _withCtx(() => [_createVNode(_component_MenuItem, null, {
          default: _withCtx(({
            active
          }) => [_createVNode(_component_ButtonComponent, {
            variant: "menu",
            color: "white",
            size: "sm",
            active: active,
            class: "w-full rounded-md"
          }, {
            default: _withCtx(() => [_createVNode(_component_Link2Icon, {
              size: "16",
              class: _normalizeClass({
                'text-sky-500': active
              })
            }, null, 8, ["class"]), _createElementVNode("div", _hoisted_3, [_cache[12] || (_cache[12] = _createElementVNode("span", null, "Links", -1)), _createVNode(_component_ChevronRightIcon, {
              size: "12"
            })])]),
            _: 2
          }, 1032, ["active"])]),
          _: 1
        })]),
        default: _withCtx(() => [_createElementVNode("div", _hoisted_4, [_createVNode(_component_LinksSettings, {
          modelValue: $options.entry.links,
          "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => $options.entry.links = $event),
          entry: $options.entry,
          class: "flex-col !items-stretch gap-y-1",
          size: "sm",
          color: "white"
        }, null, 8, ["modelValue", "entry"])])]),
        _: 1
      }), _cache[23] || (_cache[23] = _createElementVNode("hr", null, null, -1)), _createVNode(_component_PrioritySettings, {
        modelValue: $options.entry.priority,
        "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => $options.entry.priority = $event),
        entry: $options.entry
      }, {
        button: _withCtx(() => [_createVNode(_component_MenuItem, null, {
          default: _withCtx(({
            active
          }) => [_createVNode(_component_ButtonComponent, {
            variant: "menu",
            size: "sm",
            color: active ? 'neutral' : 'white',
            colorWeight: "100",
            active: active,
            class: "w-full rounded-md"
          }, {
            default: _withCtx(() => [_createVNode(_component_FlagIcon, {
              size: "16",
              class: _normalizeClass({
                [`text-${$options.entry.priority?.level == 1 ? 'red' : $options.entry.priority?.level == 2 ? 'orange' : $options.entry.priority?.level == 3 ? 'yellow' : 'neutral'}-500`]: active
              })
            }, null, 8, ["class"]), _cache[13] || (_cache[13] = _createElementVNode("span", {
              class: "whitespace-nowrap"
            }, " Priority ", -1))]),
            _: 2
          }, 1032, ["color", "active"])]),
          _: 1
        })]),
        _: 1
      }, 8, ["modelValue", "entry"]), _createVNode(_component_ColorSettings, {
        modelValue: $options.entry.color,
        "onUpdate:modelValue": _cache[4] || (_cache[4] = $event => $options.entry.color = $event),
        entry: $options.entry
      }, {
        button: _withCtx(() => [_createVNode(_component_MenuItem, null, {
          default: _withCtx(({
            active
          }) => [_createVNode(_component_ButtonComponent, {
            variant: "menu",
            size: "sm",
            color: active ? 'neutral' : 'white',
            active: active,
            class: "w-full rounded-md"
          }, {
            default: _withCtx(() => [_createVNode(_component_SwatchBookIcon, {
              size: "16",
              class: _normalizeClass({
                [`text-${$options.entry.color || 'neutral'}-500`]: active
              })
            }, null, 8, ["class"]), _cache[14] || (_cache[14] = _createElementVNode("span", {
              class: "whitespace-nowrap"
            }, " Color ", -1))]),
            _: 2
          }, 1032, ["color", "active"])]),
          _: 1
        })]),
        _: 1
      }, 8, ["modelValue", "entry"]), _cache[24] || (_cache[24] = _createElementVNode("hr", {
        class: "-mx-2 my-2 dark:border-neutral-700"
      }, null, -1)), _createVNode(_component_PopoverHelper, {
        placement: "bottom",
        teleport: true
      }, {
        button: _withCtx(() => [_createVNode(_component_MenuItem, null, {
          default: _withCtx(({
            active
          }) => [_createVNode(_component_ButtonComponent, {
            variant: "menu",
            color: "white",
            size: "sm",
            active: active,
            class: "w-full rounded-md"
          }, {
            default: _withCtx(() => [_createVNode(_component_TextCursorInputIcon, {
              size: "16",
              class: _normalizeClass({
                'text-neutral-500': active
              })
            }, null, 8, ["class"]), _createElementVNode("div", _hoisted_5, [_cache[15] || (_cache[15] = _createElementVNode("span", null, " Send to ", -1)), _createVNode(_component_ChevronRightIcon, {
              size: "12"
            })])]),
            _: 2
          }, 1032, ["active"])]),
          _: 1
        })]),
        default: _withCtx(() => [_createElementVNode("div", _hoisted_6, [_createVNode(_component_SendTo, {
          entry: $options.entry,
          onClose: _cache[5] || (_cache[5] = $event => _ctx.$emit('close'))
        }, null, 8, ["entry"])])]),
        _: 1
      }), _createVNode(_component_TemplateSettings, {
        onClose: _cache[6] || (_cache[6] = () => {
          this.$emit('close');
        }),
        entry: $options.entry,
        size: "sm",
        class: "w-full"
      }, {
        button: _withCtx(() => [_createVNode(_component_MenuItem, null, {
          default: _withCtx(({
            active
          }) => [_createVNode(_component_ButtonComponent, {
            variant: "menu",
            color: "white",
            size: "sm",
            active: active,
            class: "w-full rounded-md"
          }, {
            default: _withCtx(() => [_createVNode(_component_PuzzleIcon, {
              size: "16",
              class: _normalizeClass({
                'text-purple-500': active
              })
            }, null, 8, ["class"]), _createElementVNode("div", _hoisted_7, [_cache[16] || (_cache[16] = _createElementVNode("span", null, "Templates", -1)), _createVNode(_component_ChevronRightIcon, {
              size: "12"
            })])]),
            _: 2
          }, 1032, ["active"])]),
          _: 1
        })]),
        _: 1
      }, 8, ["entry"]), _cache[25] || (_cache[25] = _createElementVNode("hr", {
        class: "-mx-2 my-2 dark:border-neutral-700"
      }, null, -1)), _createVNode(_component_AnchorSettings, {
        modelValue: $options.entry.anchors,
        "onUpdate:modelValue": _cache[7] || (_cache[7] = $event => $options.entry.anchors = $event),
        entry: $options.entry,
        class: "flex flex-col !gap-0"
      }, {
        button_create_left: _withCtx(() => [_createVNode(_component_MenuItem, null, {
          default: _withCtx(({
            active
          }) => [_createVNode(_component_ButtonComponent, {
            variant: "menu",
            size: "sm",
            color: active ? 'neutral' : 'white',
            active: active,
            class: "w-full rounded-md"
          }, {
            default: _withCtx(() => [_createVNode(_component_SidebarIcon, {
              size: "16",
              class: _normalizeClass({
                'text-indigo-500': active
              })
            }, null, 8, ["class"]), _cache[17] || (_cache[17] = _createElementVNode("span", {
              class: "whitespace-nowrap"
            }, " Add to Left Sidebar ", -1))]),
            _: 2
          }, 1032, ["color", "active"])]),
          _: 1
        })]),
        button_create_right: _withCtx(() => [_createVNode(_component_MenuItem, null, {
          default: _withCtx(({
            active
          }) => [_createVNode(_component_ButtonComponent, {
            variant: "menu",
            size: "sm",
            color: active ? 'neutral' : 'white',
            active: active,
            class: "w-full rounded-md"
          }, {
            default: _withCtx(() => [_createVNode(_component_SidebarIcon, {
              size: "16",
              class: _normalizeClass({
                'text-indigo-500': active,
                'scale-x-[-1] transform': true
              })
            }, null, 8, ["class"]), _cache[18] || (_cache[18] = _createElementVNode("span", {
              class: "whitespace-nowrap"
            }, " Add to Right Sidebar ", -1))]),
            _: 2
          }, 1032, ["color", "active"])]),
          _: 1
        })]),
        button_delete_left: _withCtx(() => [_createVNode(_component_MenuItem, null, {
          default: _withCtx(({
            active
          }) => [_createVNode(_component_ButtonComponent, {
            variant: "menu",
            size: "sm",
            color: active ? 'neutral' : 'white',
            active: active,
            class: "w-full rounded-md"
          }, {
            default: _withCtx(() => [_createVNode(_component_SidebarIcon, {
              size: "16",
              class: _normalizeClass({
                'text-indigo-500': active
              })
            }, null, 8, ["class"]), _cache[19] || (_cache[19] = _createElementVNode("span", {
              class: "whitespace-nowrap"
            }, " Remove from Left Sidebar ", -1))]),
            _: 2
          }, 1032, ["color", "active"])]),
          _: 1
        })]),
        button_delete_right: _withCtx(() => [_createVNode(_component_MenuItem, null, {
          default: _withCtx(({
            active
          }) => [_createVNode(_component_ButtonComponent, {
            variant: "menu",
            size: "sm",
            color: active ? 'neutral' : 'white',
            active: active,
            class: "w-full rounded-md"
          }, {
            default: _withCtx(() => [_createVNode(_component_SidebarIcon, {
              size: "16",
              class: _normalizeClass({
                'text-indigo-500': active
              })
            }, null, 8, ["class"]), _cache[20] || (_cache[20] = _createElementVNode("span", {
              class: "whitespace-nowrap"
            }, " Remove from Right Sidebar ", -1))]),
            _: 2
          }, 1032, ["color", "active"])]),
          _: 1
        })]),
        _: 1
      }, 8, ["modelValue", "entry"]), _cache[26] || (_cache[26] = _createElementVNode("hr", {
        class: "-mx-2 my-2 dark:border-neutral-700"
      }, null, -1)), _createVNode(_component_MenuItem, {
        as: "div"
      }, {
        default: _withCtx(({
          active
        }) => [_createVNode(_component_ButtonComponent, {
          variant: "menu",
          color: "white",
          size: "sm",
          active: active,
          onClick: _cache[8] || (_cache[8] = $event => (_ctx.$store.dispatch('selected', $options.entry), _ctx.$emit('close'))),
          class: "w-full rounded-md"
        }, {
          default: _withCtx(() => [_ctx.$store.getters.selected.findIndex(e => e.id == $options.entry.id) === -1 ? (_openBlock(), _createBlock(_component_CopyPlusIcon, {
            key: 0,
            size: "16",
            class: _normalizeClass({
              'text-amber-500': active
            })
          }, null, 8, ["class"])) : (_openBlock(), _createBlock(_component_CopyMinusIcon, {
            key: 1,
            size: "16",
            class: _normalizeClass({
              'text-amber-500': active
            })
          }, null, 8, ["class"])), _ctx.$store.getters.selected.findIndex(e => e.id == $options.entry.id) === -1 ? (_openBlock(), _createElementBlock("span", _hoisted_8, "Select Entry")) : (_openBlock(), _createElementBlock("span", _hoisted_9, "Unselect Entry"))]),
          _: 2
        }, 1032, ["active"])]),
        _: 1
      }), _createVNode(_component_MenuItem, {
        as: "div"
      }, {
        default: _withCtx(({
          active
        }) => [_createVNode(_component_ButtonComponent, {
          variant: "menu",
          color: "white",
          size: "sm",
          active: active,
          onClick: $options.duplicateEntry,
          class: "w-full rounded-md"
        }, {
          default: _withCtx(() => [_createVNode(_component_FilesIcon, {
            size: 16,
            class: _normalizeClass({
              'text-blue-500': active
            })
          }, null, 8, ["class"]), _cache[21] || (_cache[21] = _createElementVNode("span", {
            class: "whitespace-nowrap"
          }, "Duplicate Entry", -1))]),
          _: 2
        }, 1032, ["active", "onClick"])]),
        _: 1
      }), _createVNode(_component_MenuItem, {
        as: "div"
      }, {
        default: _withCtx(({
          active
        }) => [_createVNode(_component_ButtonComponent, {
          variant: "menu",
          color: "white",
          size: "sm",
          active: active,
          onClick: $options.deleteEntry,
          class: "w-full rounded-md"
        }, {
          default: _withCtx(() => [_createVNode(_component_TrashIcon, {
            size: 16,
            class: _normalizeClass({
              'text-red-500': active
            })
          }, null, 8, ["class"]), _cache[22] || (_cache[22] = _createElementVNode("span", {
            class: "whitespace-nowrap"
          }, "Delete Entry", -1))]),
          _: 2
        }, 1032, ["active", "onClick"])]),
        _: 1
      })]),
      _: 3
    }, 8, ["class"])]),
    _: 3
  }), _createElementVNode("div", _hoisted_10, [$options.entry.completed_at ? (_openBlock(), _createElementBlock("div", _hoisted_11, [_cache[27] || (_cache[27] = _createElementVNode("span", {
    class: "flex items-center"
  }, [_createElementVNode("span", {
    class: "text-xs font-medium text-gray-900 dark:text-neutral-100"
  }, " Completed ")], -1)), _createVNode(_component_CompletedAtComponent, {
    modelValue: $options.entry,
    "onUpdate:modelValue": _cache[9] || (_cache[9] = $event => $options.entry = $event),
    class: "font-mono text-xs"
  }, null, 8, ["modelValue"])])) : _createCommentVNode("", true), _createElementVNode("div", _hoisted_12, [_cache[28] || (_cache[28] = _createElementVNode("span", {
    class: "flex items-center"
  }, [_createElementVNode("span", {
    class: "text-xs font-medium text-gray-900 dark:text-neutral-100"
  }, " Updated ")], -1)), _createElementVNode("span", _hoisted_13, _toDisplayString($data.moment.utc($options.entry.updated_at).local().format("YYYY-MM-DD HH:mm:ss")), 1)]), _createElementVNode("div", _hoisted_14, [_cache[29] || (_cache[29] = _createElementVNode("span", {
    class: "flex items-center"
  }, [_createElementVNode("span", {
    class: "text-xs font-medium text-gray-900 dark:text-neutral-100"
  }, " Created ")], -1)), _createElementVNode("span", _hoisted_15, _toDisplayString($data.moment.utc($options.entry.created_at).local().format("YYYY-MM-DD HH:mm:ss")), 1)])])]);
}