import TimeTrackingSettings from "@/components/timetrackings/TimeTrackingSettings.vue";
import { TrashIcon } from "lucide-vue-next";
import { TimeTrackingsMixin } from "../mixins/TimeTrackingsMixin";
export default {
  mixins: [TimeTrackingsMixin],
  components: {
    TimeTrackingSettings,
    TrashIcon
  },
  props: {
    time_tracking: {
      type: Object,
      required: true
    },
    entry: Object
  },
  computed: {
    // time_tracking: {
    //   get() {
    //     return this.modelValue;
    //   },
    //   set(value) {
    //     console.log("TimeTrackingsRow time_tracking::set STOP", value);
    //     // this.$emit("update:modelValue", value);
    //   },
    // },
    duration() {
      const startTime = this.$moment.utc(this.time_tracking.start_at);
      const endTime = this.$moment.utc(this.time_tracking.end_at);
      const elapsedTime = this.$moment.duration(endTime.diff(startTime));
      const days = Math.floor(elapsedTime.asDays());
      const hours = elapsedTime.hours();
      const minutes = elapsedTime.minutes();
      const seconds = elapsedTime.seconds();
      return (days ? days + "d " : "") + (hours || days ? hours + "h " : "") + minutes + "m " + seconds + "s";
    }
  }
};