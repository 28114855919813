import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
const _hoisted_1 = ["data-priority"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_FlagIcon = _resolveComponent("FlagIcon");
  const _component_PriorityMenu = _resolveComponent("PriorityMenu");
  const _component_MenuHelper = _resolveComponent("MenuHelper");
  return _openBlock(), _createBlock(_component_MenuHelper, {
    class: "ps_priority_display"
  }, {
    button: _withCtx(() => [_cache[2] || (_cache[2] = _createElementVNode("span", {
      class: "sr-only"
    }, "Set priority", -1)), _createElementVNode("div", {
      class: _normalizeClass(["inline-flex items-center gap-x-0.5 rounded-md bg-neutral-50 px-1 py-0.5 text-sm font-semibold text-neutral-900", {
        'bg-red-100 text-red-600 dark:bg-red-950 dark:text-red-400': $options.priority?.level === 1,
        'bg-orange-100 text-orange-600 dark:bg-orange-950 dark:text-orange-400': $options.priority?.level === 2,
        'bg-yellow-100 text-yellow-600 dark:bg-yellow-950 dark:text-yellow-400': $options.priority?.level === 3,
        'bg-neutral-100 text-neutral-600 dark:bg-neutral-950 dark:text-neutral-400': $options.priority?.level === 4,
        '!text-xs': $props.position == 'calendar'
      }]),
      "data-priority": $options.priority?.level,
      "data-table-column-target": ""
    }, [_createVNode(_component_FlagIcon, {
      class: "h-3 w-3"
    }), _createTextVNode(" " + _toDisplayString($options.priority?.level || " "), 1)], 10, _hoisted_1)]),
    default: _withCtx(() => [_createVNode(_component_PriorityMenu, {
      "onUpdate:modelValue": [_cache[0] || (_cache[0] = value => _ctx.$emit('update:modelValue', value)), _cache[1] || (_cache[1] = $event => $options.priority = $event)],
      modelValue: $options.priority
    }, null, 8, ["modelValue"])]),
    _: 1
  });
}