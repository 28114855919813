import { Crud } from "./mixins/Crud";
export default {
  provide() {
    return {
      position: "shell"
    };
  },
  props: {
    sidebarDesktopOpen: Boolean,
    scrollY: Number
  },
  mixins: [Crud],
  computed: {
    mainFrame() {
      return document.querySelector("#main");
    },
    token() {
      return this.$store.getters.token;
    },
    user() {
      return this.$store.getters.user;
    },
    entries() {
      return this.$store.getters.entries.filter(e => e.deleted_at === null);
    },
    entry() {
      return this.entries.find(e => e.id == this.$route.params.id);
    },
    space() {
      return this.$store.getters.space;
    },
    isShowTimeTrackings() {
      return this.entries.filter(e => {
        return (e.time_trackings.length && e.time_trackings.filter(tt => tt.user_id == this.user.id && this.$moment.utc(tt.start_at).format("YYYY-MM-DD") == this.$moment().format("YYYY-MM-DD"))).length;
      }).length;
    }
  },
  created() {},
  data() {
    return {
      navigator: navigator,
      // scrollY: 0,
      helpItems: [{
        url: "https://help.pensive.io",
        title: "Help Section"
      },
      // {
      //   url: "https://help.pensive.io/best-practices",
      //   title: "Best Practices",
      // },
      {
        url: "https://forms.gle/B5hi53AM9PZsR4jz5",
        title: "Report a Bug"
      }, {
        url: "https://forms.gle/PQXmMKPwGQQhAin7A",
        title: "Request a Feature"
      }, {
        url: "https://pensive.io/whats-new",
        title: "Whats new in v." + this.$version
      }]
    };
  },
  mounted() {
    this.pullData();
    // if (this.mainFrame)
    //   this.mainFrame.addEventListener("scroll", this.onScroll);
    // this.onScroll();
    this.$nextTick(() => {
      this.$emit("mounted");
    });
  },
  beforeUnmount() {
    // if (this.mainFrame)
    //   this.mainFrame.removeEventListener("scroll", this.onScroll);
  },
  methods: {
    pullData() {
      this.$store.dispatch("pull", {
        filters: [{
          key: ["time_trackings", "start_at"],
          op: "eq",
          val: [this.$moment().format("YYYY-MM-DD")]
        }]
      });
    },
    // onScroll() {
    //   if (this.mainFrame) this.scrollY = this.mainFrame.scrollTop;
    // },
    animateEnter(el, done) {
      this.$anime({
        targets: el,
        translateY: [-100, 0],
        opacity: [0, 1],
        complete: done
      });
    },
    animateLeave(el, done) {
      this.$anime({
        targets: el,
        translateY: [0, -100],
        opacity: [1, 0],
        complete: done
      });
    }
  }
};