import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  class: "px-6 py-24 sm:py-32 lg:px-8"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", _hoisted_1, _cache[0] || (_cache[0] = [_createElementVNode("div", {
    class: "mx-auto max-w-2xl text-center"
  }, [_createElementVNode("h2", {
    class: "text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl"
  }, " One moment please "), _createElementVNode("p", {
    class: "mt-6 text-lg leading-8 text-gray-600"
  }, " We are getting everything ready. ")], -1)]));
}