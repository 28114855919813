import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, unref as _unref, createElementVNode as _createElementVNode, withKeys as _withKeys, normalizeStyle as _normalizeStyle, Transition as _Transition, Teleport as _Teleport, createBlock as _createBlock } from "vue";
const _hoisted_1 = {
  key: 0
};
const _hoisted_2 = {
  class: "relative bg-white p-5 dark:bg-black"
};
const _hoisted_3 = {
  class: "mt-2"
};
import { PopoverButton } from "@headlessui/vue";
import { Popover, PopoverPanel } from "@headlessui/vue";
import { ref } from "vue";
import { useFloating, shift, flip } from "@floating-ui/vue";
import { addonBlueprint } from "@/addonBlueprint";
import { Addon } from "../mixins/Addon";
const __default__ = {
  mixins: [Addon],
  watch: {
    modelValue: {
      immediate: true,
      handler() {
        if (this.modelValue) this.cover = this.modelValue;else this.cover = addonBlueprint("cover", this.entry);
      }
    },
    entry: function () {
      if (this.modelValue) this.cover = this.modelValue;else this.cover = addonBlueprint("cover", this.entry);
    }
  },
  data() {
    return {
      cover: null
    };
  },
  mounted() {
    if (this.modelValue) this.cover = this.modelValue;else this.cover = addonBlueprint("cover", this.entry);
  }
};
export default /*@__PURE__*/Object.assign(__default__, {
  __name: 'CoverSettings',
  setup(__props) {
    const reference = ref(null);
    const floating = ref(null);
    const {
      floatingStyles
    } = useFloating(reference, floating, {
      middleware: [flip(), shift({
        crossAxis: true
      })]
    });
    return (_ctx, _cache) => {
      const _component_ImageIcon = _resolveComponent("ImageIcon");
      const _component_ButtonComponent = _resolveComponent("ButtonComponent");
      return _openBlock(), _createElementBlock("div", null, [_createVNode(_unref(Popover), {
        class: "relative"
      }, {
        default: _withCtx(({
          open
        }) => [_createVNode(_unref(PopoverButton), {
          ref_key: "reference",
          ref: reference,
          class: "flex"
        }, {
          default: _withCtx(() => [_renderSlot(_ctx.$slots, "button", {
            open: open
          }, () => [_createVNode(_component_ButtonComponent, {
            size: _ctx.size,
            variant: _ctx.variant,
            color: _ctx.color
          }, {
            default: _withCtx(() => [_createVNode(_component_ImageIcon, {
              size: "20"
            }), _ctx.variant != 'round' ? (_openBlock(), _createElementBlock("span", _hoisted_1, " Cover ")) : _createCommentVNode("", true)]),
            _: 1
          }, 8, ["size", "variant", "color"])])]),
          _: 2
        }, 1536), (_openBlock(), _createBlock(_Teleport, {
          to: "body"
        }, [_createVNode(_Transition, {
          "enter-active-class": "transition ease-out duration-100",
          "enter-from-class": "transform opacity-0 scale-95",
          "enter-to-class": "transform opacity-100 scale-100",
          "leave-active-class": "transition ease-in duration-75",
          "leave-from-class": "transform opacity-100 scale-100",
          "leave-to-class": "transform opacity-0 scale-95"
        }, {
          default: _withCtx(() => [_createVNode(_unref(PopoverPanel), {
            class: "absolute z-[100000] mt-3 w-screen max-w-sm -translate-x-1/2 transform px-4 sm:px-0 lg:max-w-3xl"
          }, {
            default: _withCtx(() => [_createElementVNode("div", {
              ref_key: "floating",
              ref: floating,
              style: _normalizeStyle(_unref(floatingStyles)),
              class: "z-[100000] overflow-hidden rounded-lg shadow-lg ring-1 ring-black/5 dark:ring-neutral-700"
            }, [_createElementVNode("div", _hoisted_2, [_cache[3] || (_cache[3] = _createElementVNode("label", {
              for: "email",
              class: "block text-sm font-medium leading-6 text-gray-900 dark:text-neutral-300"
            }, "URL", -1)), _createElementVNode("div", _hoisted_3, [_createElementVNode("input", {
              type: "text",
              class: "block w-full rounded-md border-0 py-1.5 text-neutral-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-black sm:text-sm sm:leading-6 dark:bg-black dark:text-neutral-300 dark:ring-neutral-700 dark:focus:ring-white",
              placeholder: "",
              onKeyup: _cache[0] || (_cache[0] = _withKeys(event => event.target.blur(), ["enter"])),
              onBlur: _cache[1] || (_cache[1] = event => {
                if (event.target.value.length) {
                  // eslint-disable-next-line vue/no-mutating-props
                  _ctx.entry.cover = {
                    ..._ctx.cover,
                    ...{
                      url: event.target.value
                    }
                  };
                  _ctx.$store.dispatch('push', {
                    event: 'entry_update',
                    params: {
                      entry: _ctx.entry
                    },
                    entry: _ctx.entry
                  });
                }
                event.target.value = '';
              })
            }, null, 32)]), _cache[4] || (_cache[4] = _createElementVNode("p", {
              class: "mt-2 text-sm text-neutral-500",
              id: "email-description"
            }, " Works with any image from the web. ", -1)), _ctx.cover?.url ? (_openBlock(), _createElementBlock("button", {
              key: 0,
              onClick: _cache[2] || (_cache[2] = $event => (
              // eslint-disable-next-line vue/no-mutating-props
              _ctx.entry.cover = null, _ctx.$store.dispatch('push', {
                event: 'entry_update',
                params: {
                  entry: _ctx.entry
                },
                entry: _ctx.entry
              }))),
              type: "button",
              class: "rounded bg-white px-2 py-1 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 dark:bg-black dark:text-neutral-300 dark:ring-neutral-700 dark:hover:bg-neutral-900"
            }, " Remove ")) : _createCommentVNode("", true)])], 4)]),
            _: 1
          })]),
          _: 1
        })]))]),
        _: 3
      })]);
    };
  }
});