import { Analytics } from "@/components/mixins/Analytics";
import { Lazying } from "./mixins/Lazying";
import { Markdowning } from "./mixins/Markdowning";
export default {
  mixins: [Analytics, Lazying, Markdowning],
  watch: {
    entry_by_route: function () {
      if (this.entry_by_route) {
        this._trackEvent("Navigation", "Entry Modal", "Open Modal");
        document.title = `${this.clearMarkdown(this.entry_by_route.name) || "Unnamed"} · Pensive`;
        this.entry_by_route_cache = this.entry_by_route;
        this.dialogIsOpen = true;
      } else {
        setTimeout(() => {
          this.dialogIsOpen = false;
        }, 400);
      }
    },
    "entry_by_route.name": function () {
      if (this.entry_by_route) document.title = `${this.clearMarkdown(this.entry_by_route.name) || "Unnamed"} · Pensive`;
    },
    "$route.params.id": function () {
      this.pullData();
    },
    "$store.getters.timestamp": function () {
      this.$onIdle(() => {
        this.pullData();
      });
    }
  },
  computed: {
    user() {
      return this.$store.getters.user;
    },
    entry_by_route_before() {
      return this.entries?.find(e => e.id == this.$route.params.id);
    },
    entry_by_route() {
      return this.entries?.find(e => e.id == this.$route.params.id2);
    },
    entries() {
      return this.$store.getters.entries?.filter(e => e.deleted_at === null);
    },
    defaultOutput() {
      return [{
        key: "links",
        op: "any",
        val: [this.entry_by_route.id]
      }];
    },
    outputIsModified() {
      return this.entry_by_route.output && JSON.stringify(this.defaultOutput) != JSON.stringify(this.entry_by_route.output.filters);
    },
    linkedEntries() {
      return this.entry_by_route.links.map(link => this.entries.find(e => e.id == link.id)).filter(e => e);
    },
    cssProps() {
      return {
        "--ps-output-display-entry-display-background-color-hover": this.$store.getters.dragged.length ? "transparent" : null
      };
    }
  },
  data() {
    return {
      window: window,
      schema: null,
      showSettings: false,
      showTemplates: false,
      showEntryModal: true,
      isOpen: true,
      entry_by_route_cache: null,
      dialogIsOpen: false,
      // UX
      scrollTopOnPress: 0,
      scrollingIsMade: false
    };
  },
  created: function () {
    this.pullData();
    if (this.entry_by_route) {
      document.title = `${this.clearMarkdown(this.entry_by_route.name) || "Unnamed"} · Pensive`;
      this.entry_by_route_cache = this.entry_by_route;
    }
    this.dialogIsOpen = this.entry_by_route ? true : false;
  },
  methods: {
    openFullPage() {
      this._trackEvent("Navigation", "Entry Modal", "Open Full Page");
      this.$router.replace({
        name: "entry",
        params: {
          id: this.$route.params.id2
        }
      });
    },
    updateEntry() {
      this.$store.dispatch("push", {
        event: "entry_update",
        params: {
          entry: this.entry_by_route
        },
        entry: this.entry_by_route
      });
    },
    pullData() {
      if (this.$route.name == "entry") {
        if (this.entry_by_route?.deleted_at === null) {
          this.$store.dispatch("pull", {
            filters: [{
              key: "id",
              op: "eq",
              val: this.entry_by_route.id
            }]
          });
        } else {
          // this.$router.push({ name: "home" });
        }
      }
    },
    slugify: function (title) {
      var slug = title.toLowerCase();
      slug = slug.replace(/\s*$/g, "");
      slug = slug.replace(/\s+/g, "-");
      return slug;
    }
  }
};