import { customFieldBlueprint } from "@/addonBlueprint";
import draggable from "vuedraggable";
import { Addon } from "../mixins/Addon";
export default {
  components: {
    draggable
  },
  mixins: [Addon],
  computed: {
    space() {
      return this.$store.getters.space;
    },
    custom_fields: {
      get() {
        return this.modelValue;
      },
      set(custom_fields) {
        // eslint-disable-next-line vue/no-mutating-props
        this.entry.custom_fields = custom_fields;
        this.$store.dispatch("push", {
          event: "entry_update",
          params: {
            entry: this.entry
          },
          entry: this.entry
        });
      }
    }
  },
  data() {
    return {
      newCF: customFieldBlueprint(this.entry, this)
    };
  },
  methods: {
    updatePositions: function () {
      this.custom_fields = this.custom_fields.map((cf, i) => {
        cf.position = i;
        return cf;
      });
    },
    addCustomField() {
      if (this.newCF.name.length === 0) {
        return;
      }

      // Set position of new status
      this.newCF.position = this.custom_fields.length;
      this.custom_fields = [...this.custom_fields, this.newCF];

      //this.space.statuses.push(this.newCF);
      this.newCF = customFieldBlueprint(this.entry, this);
    },
    updateCustomField(custom_field) {
      this.$store.dispatch("push", {
        event: "custom_field_update",
        params: {
          custom_field: custom_field
        },
        entry: this.entry
      });

      // this.space.statuses = this.space.statuses.map((s) => {
      //   if (s.id === status.id) {
      //     return status;
      //   }
      //   return s;
      // });
    },
    deleteCustomField(custom_field) {
      // this.$store.dispatch("push", {
      //   event: "custom_field_delete",
      //   params: { id: custom_field.id },
      //   entry: this.entry,
      // });
      this.custom_fields = this.custom_fields.filter(cf => cf.id !== custom_field.id).map((cf, i) => {
        cf.position = i;
        return cf;
      });
      // Find all entries with that status and remove
      // TODO: this needs to be adjusted for cfs
      // this.$store.getters.entries
      //   .filter((e) => e.status_id === status.id)
      //   .forEach((e) => {
      //     e.status_id = null;
      //     this.$store.dispatch("push", {
      //       event: "entry_update",
      //       params: { entry: e },
      //       entry: e,
      //     });
      //   });

      //this.statuses = this.statuses.filter((s) => s.id !== status.id);
    }
  }
};