// import EntryComponent from "../entries/EntryComponent_Deprecated.vue";
import { Applicators } from "../mixins/Applicators";
import { Scheduling } from "../mixins/Scheduling";
import { Schema } from "../mixins/Schema";
import { Statusing } from "../mixins/Statusing";
export default {
  mixins: [Schema, Applicators, Statusing, Scheduling],
  data() {
    return {
      draggedEntriesInterpreted: [],
      cursorPosition: {
        x: 0,
        y: 0
      },
      transitioningCursorPosition: {
        x: 0,
        y: 0
      }
    };
  },
  watch: {
    "$store.getters.dragged.length": function (n, o) {
      if (n > 0 && o == 0) {
        this.interpretEntries();
        if (navigator.userAgent.includes("Mobile")) {
          this.$nextTick(() => {
            this.updateCursorPosition({
              type: "touchmove",
              touches: [{
                pageX: this.$cursorPosition.x,
                pageY: this.$cursorPosition.y
              }]
            });
          });
        }
      }
      if (n == 0) {
        this.draggedEntriesInterpreted = [];
      }
    },
    "$store.getters.schema": function () {
      if (this.$store.getters.dragged.length) {
        if (this.interprationTimeout) clearTimeout(this.interprationTimeout);
        this.interprationTimeout = setTimeout(() => {
          this.interpretEntries();
        }, 50);
      }
    }
  },
  computed: {
    // dragged() {
    //   return this.$store.getters.dragged
    //     ? JSON.parse(JSON.stringify(this.$store.getters.dragged))?.map(
    //         (entry) =>
    //           this.$store.getters.schema &&
    //           entry.id != this.$store.getters.hovered.id
    //             ? this.applyInput(
    //                 this.inputSchema(this.$store.getters.schema, entry),
    //                 entry,
    //               )
    //             : entry,
    //       )
    //     : [];
    // },
  },
  mounted() {
    document.addEventListener("mousemove", this.updateCursorPosition);
    document.addEventListener("touchmove", this.updateCursorPosition, {
      passive: true
    });
  },
  beforeUnmount() {
    document.removeEventListener("mousemove", this.updateCursorPosition);
    document.removeEventListener("touchmove", this.updateCursorPosition, {
      passive: true
    });
  },
  methods: {
    interpretEntries() {
      this.draggedEntriesInterpreted = this.$store.getters.dragged ? JSON.parse(JSON.stringify(this.$store.getters.dragged))?.map(entry => this.$store.getters.schema && entry.id != this.$store.getters.hovered.id ? this.applyInput(this.inputSchema(this.$store.getters.schema, entry), entry) : entry) : [];
    },
    updateCursorPosition(e) {
      // console.log("updateCursorPosition", e);
      if (e.type == "touchmove") {
        if (e.touches && e.touches.length) e = e.touches[0];
        this.cursorPosition.x = e.clientX - this.$refs.draggableWrapper?.clientWidth / 2;
        this.cursorPosition.y = e.clientY - this.$refs.draggableWrapper?.clientHeight - 10;
        if (this.cursorPosition.y < this.$refs.draggableWrapper?.clientHeight - 10) this.cursorPosition.y += this.$refs.draggableWrapper?.clientHeight + 20;
      } else {
        this.cursorPosition.x = e.clientX + 10;
        this.cursorPosition.y = e.clientY + 10;
      }
      if (this.cursorPosition.x < 10) this.cursorPosition.x = 10;
      if (this.cursorPosition.y < 10) this.cursorPosition.y = 10;
      if (this.cursorPosition.x + this.$refs.draggableWrapper?.clientWidth > window.innerWidth - 10) this.cursorPosition.x = window.innerWidth - this.$refs.draggableWrapper?.clientWidth - 10;
      if (this.cursorPosition.y + this.$refs.draggableWrapper?.clientHeight > window.innerHeight + window.scrollY - 10) this.cursorPosition.y = window.innerHeight + window.scrollY - this.$refs.draggableWrapper?.clientHeight - 10;
    }
  }
};