import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_Link2Icon = _resolveComponent("Link2Icon");
  const _component_ButtonComponentNew = _resolveComponent("ButtonComponentNew");
  const _component_InputWithLabel = _resolveComponent("InputWithLabel");
  const _component_SearchResults = _resolveComponent("SearchResults");
  const _component_PopoverHelper = _resolveComponent("PopoverHelper");
  return _openBlock(), _createBlock(_component_PopoverHelper, {
    teleport: true,
    placement: "bottom-center"
  }, {
    button: _withCtx(() => [_renderSlot(_ctx.$slots, "button", {}, () => [_createVNode(_component_ButtonComponentNew, {
      title: "Add link",
      class: _normalizeClass(_ctx.$style.button),
      "data-table-column-target": ""
    }, {
      default: _withCtx(() => [_createVNode(_component_Link2Icon, {
        size: "20"
      })]),
      _: 1
    }, 8, ["class"])])]),
    default: _withCtx(({
      close
    }) => [_createElementVNode("div", {
      class: _normalizeClass(_ctx.$style.popover)
    }, [_createVNode(_component_InputWithLabel, {
      ref: "input",
      modelValue: $data.searchQuery,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => $data.searchQuery = $event),
      placeholder: "Search...",
      onMounted: _cache[1] || (_cache[1] = $event => ($data.searchQuery = '', _ctx.$refs.input.focus()))
    }, null, 8, ["modelValue"]), _createVNode(_component_SearchResults, {
      query: $data.searchQuery,
      filtersOnEmpty: [{
        key: 'anchor',
        op: 'is set'
      }],
      filterSearchResults: e => !$options.links.includes(e.id),
      onOnEntrySelect: entry => {
        $options.onSearchSelect(entry);
        close();
      },
      class: "max-h-64 overflow-y-auto overflow-x-hidden px-2"
    }, null, 8, ["query", "filterSearchResults", "onOnEntrySelect"])], 2)]),
    _: 3
  });
}