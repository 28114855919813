import "core-js/modules/es.array.push.js";
import { getDateSpan } from "@/datesHelper";
import moment from "moment";
export default {
  data() {
    return {
      date: moment().startOf("month")
    };
  },
  watch: {
    date() {
      this.scrollDaysInMonthToRight();
    }
  },
  computed: {
    entries() {
      return this.$store.getters.entries.filter(entry => entry.routine);
    },
    daysInMonth() {
      return this.date.daysInMonth();
    }
  },
  mounted() {
    this.scrollDaysInMonthToRight();
  },
  methods: {
    getSpansOfEntry(entry) {
      const frequency = entry.routine.frequency;
      const target = entry.routine.target || 1; // Default to 1 if no target is set
      const spans = [];
      let currentDate = moment().startOf("month");
      const endDate = moment().endOf("month");
      while (currentDate.isBefore(endDate)) {
        const spanStart = getDateSpan(frequency, currentDate);
        spans.push(spanStart);
        switch (frequency) {
          case "daily":
            currentDate.add(target, "days");
            break;
          case "weekly":
            currentDate.add(target, "weeks");
            break;
          case "monthly":
            currentDate.add(target, "months");
            break;
          default:
            currentDate.add(1, "days");
          // Fallback to daily
        }
      }
      return spans.map(span => moment(span).daysInMonth());
    },
    scrollDaysInMonthToRight() {
      this.$nextTick(() => {
        const daysInMonthElement = this.$refs.daysInMonth;
        if (daysInMonthElement) {
          // Get the current day of the month
          const currentDay = moment().date();
          // Calculate cell width (including gap)
          const cellWidth = daysInMonthElement.scrollWidth / this.daysInMonth;
          // Calculate position to center current day
          const scrollPosition = (currentDay - 1) * cellWidth - (daysInMonthElement.clientWidth - cellWidth) / 2;
          daysInMonthElement.scrollLeft = Math.max(0, scrollPosition);
        }
      });
    }
  }
};