import { Addon } from "../mixins/Addon";
import { Scheduling } from "../mixins/Scheduling";
import { Statusing } from "../mixins/Statusing";
import { Crud } from "../mixins/Crud";
import { addonBlueprint } from "@/addonBlueprint";
export default {
  name: "ScheduleComponent",
  mixins: [Addon, Scheduling, Statusing, Crud],
  props: {
    otherentries: Array
  },
  data() {
    return {
      date: this.entry.schedule?.date,
      time: this.entry.schedule?.time
    };
  },
  watch: {
    entry: function (n, o) {
      if (JSON.stringify(n) != JSON.stringify(o)) {
        this.date = this.entry.schedule?.date;
        this.time = this.entry.schedule?.time;
      }
    },
    "entry.schedule": function (n, o) {
      if (JSON.stringify(n) != JSON.stringify(o)) {
        this.date = this.entry.schedule?.date;
        this.time = this.entry.schedule?.time;
      }
    },
    "entry.schedule.date": function (n, o) {
      if (JSON.stringify(n) != JSON.stringify(o)) {
        this.date = this.entry.schedule?.date;
      }
    },
    "entry.schedule.time": function (n, o) {
      if (JSON.stringify(n) != JSON.stringify(o)) {
        this.time = this.entry.schedule?.time;
      }
    }
  },
  computed: {
    schedule: {
      get() {
        return this.modelValue;
      },
      set(schedule) {
        this.entry.schedule = schedule;
        if (!this.entry.temp) this.$store.dispatch("push", {
          event: "entry_update",
          params: {
            entry: this.entry
          },
          entry: this.entry
        });
      }
    }
  },
  methods: {
    createSchedule(date) {
      var schedule = addonBlueprint("schedule", this.entry, this);
      schedule.date = date;
      this.schedule = schedule;
    },
    updateDate(val) {
      if (this.entry.schedule === null) return;
      if (val.op && val.op === "null" && this.entry.schedule?.time === null) {
        this.schedule = null;
      } else {
        // TODO need to move this part over to Addon Mixin updateAddon()
        this.setEntrySchedule(this.entry, {
          date: this.interpretDate({
            date: val
          }),
          time: this.entry.schedule?.time
        });
        //});
      }
      !this.entry.temp ? this.$store.dispatch("push", {
        event: "entry_update",
        params: {
          entry: this.entry
        },
        entry: this.entry
      }) : null;
    },
    updateTime(val) {
      if (this.entry.schedule === null) return;
      if ((val === null || val && val.op && val.op === "null") && this.entry.schedule?.date === null) {
        this.schedule = null;
      } else {
        // TODO need to move this part over to Addon Mixin updateAddon()
        this.setEntrySchedule(this.entry, {
          date: this.entry.schedule?.date,
          time: this.interpretTime({
            time: val
          })
        });
      }
      !this.entry.temp ? this.$store.dispatch("push", {
        event: "entry_update",
        params: {
          entry: this.entry
        },
        entry: this.entry
      }) : null;
    }
  }
};