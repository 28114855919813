import { TrashIcon } from "lucide-vue-next";
export default {
  inheritAttrs: false,
  components: {
    TrashIcon
  },
  props: {
    modelValue: null
  },
  computed: {
    date: {
      get() {
        return this.modelValue;
      },
      set(val) {
        this.$emit("update:modelValue", val);
      }
    }
  }
};