import { createElementVNode as _createElementVNode, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString } from "vue";
const _hoisted_1 = {
  class: "fixed inset-0 z-10 w-screen overflow-y-auto overflow-x-hidden",
  style: {
    "scrollbar-gutter": "stable"
  }
};
const _hoisted_2 = {
  class: "flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0"
};
const _hoisted_3 = {
  ref: "inner",
  class: "relative"
};
const _hoisted_4 = {
  class: "flex items-center gap-4"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_ArrowLeftIcon = _resolveComponent("ArrowLeftIcon");
  const _component_ButtonComponent = _resolveComponent("ButtonComponent");
  const _component_XIcon = _resolveComponent("XIcon");
  const _component_DialogTitle = _resolveComponent("DialogTitle");
  const _component_TemplateBuilder = _resolveComponent("TemplateBuilder");
  const _component_DialogPanel = _resolveComponent("DialogPanel");
  const _component_Dialog = _resolveComponent("Dialog");
  return _openBlock(), _createBlock(_component_Dialog, {
    as: "div",
    open: true,
    onClose: _cache[2] || (_cache[2] = $event => _ctx.$emit('close')),
    class: "relative z-[999] h-px w-px"
  }, {
    default: _withCtx(() => [_createVNode(_Transition, {
      onEnter: $options.animationEnterBackdrop,
      onLeave: $options.animationLeaveBackdrop,
      css: false,
      appear: ""
    }, {
      default: _withCtx(() => _cache[3] || (_cache[3] = [_createElementVNode("div", {
        class: "fixed inset-0 backdrop-blur"
      }, null, -1)])),
      _: 1
    }, 8, ["onEnter", "onLeave"]), _createVNode(_Transition, {
      onEnter: $options.animationEnter,
      onLeave: $options.animationLeave,
      css: true,
      appear: ""
    }, {
      default: _withCtx(() => [_createElementVNode("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createVNode(_component_DialogPanel, {
        ref: "outer",
        class: "relative box-content max-w-[90vw] transform overflow-visible rounded-lg bg-white px-4 pb-4 pt-2 text-left shadow-xl ring-1 ring-transparent transition-all sm:my-8 sm:w-full sm:px-6 sm:pb-6 md:max-w-3xl lg:max-w-4xl dark:bg-black dark:ring-neutral-700",
        style: {
          "transition": "height 1s cubic-bezier(0.16, 1, 0.3, 1)"
        }
      }, {
        default: _withCtx(() => [_createElementVNode("div", _hoisted_3, [_createVNode(_component_DialogTitle, {
          as: "h3",
          class: "sticky top-0 z-10 -mx-4 flex justify-between rounded-b bg-white px-4 py-4 dark:bg-black"
        }, {
          default: _withCtx(() => [_createElementVNode("div", _hoisted_4, [_ctx.$refs.template_builder?.selectedTemplate ? (_openBlock(), _createBlock(_component_ButtonComponent, {
            key: 0,
            onClick: _cache[0] || (_cache[0] = $event => _ctx.$refs.template_builder.selectedTemplate = null),
            color: "secondary",
            size: "sm",
            class: "!px-1"
          }, {
            default: _withCtx(() => [_createVNode(_component_ArrowLeftIcon, {
              size: "20"
            })]),
            _: 1
          })) : _createCommentVNode("", true), _cache[4] || (_cache[4] = _createElementVNode("span", {
            class: "text-xl font-semibold"
          }, _toDisplayString(
          //$refs.template_builder?.selectedTemplate?.name ||
          "Templates"), -1))]), _createVNode(_component_ButtonComponent, {
            onClick: _cache[1] || (_cache[1] = $event => _ctx.$emit('close')),
            color: "secondary",
            size: "sm",
            class: "!px-1"
          }, {
            default: _withCtx(() => [_createVNode(_component_XIcon, {
              size: "20"
            })]),
            _: 1
          })]),
          _: 1
        }), _createVNode(_component_TemplateBuilder, {
          ref: "template_builder",
          modelValue: $options.entry
        }, null, 8, ["modelValue"])], 512)]),
        _: 1
      }, 512)])])]),
      _: 1
    }, 8, ["onEnter", "onLeave"])]),
    _: 1
  });
}