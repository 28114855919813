import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue";
const _hoisted_1 = {
  class: "flex flex-col gap-y-1"
};
const _hoisted_2 = {
  class: "flex flex-wrap items-start gap-2"
};
const _hoisted_3 = {
  "data-handle": "",
  class: "flex cursor-move gap-1 self-center"
};
const _hoisted_4 = {
  key: 0,
  class: "basis-full rounded-md p-2 ring-1 ring-neutral-200"
};
const _hoisted_5 = {
  key: 0,
  class: "flex items-center gap-2"
};
const _hoisted_6 = {
  "data-handle": "",
  class: "hidden cursor-move gap-1 self-center"
};
const _hoisted_7 = {
  key: 0,
  class: "basis-full rounded-md p-2 ring-1 ring-neutral-200"
};
const _hoisted_8 = {
  key: 0,
  class: "flex items-center gap-2"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_ChevronsUpDownIcon = _resolveComponent("ChevronsUpDownIcon");
  const _component_TextInput = _resolveComponent("TextInput");
  const _component_OptionPicker = _resolveComponent("OptionPicker");
  const _component_TrashIcon = _resolveComponent("TrashIcon");
  const _component_ButtonComponent = _resolveComponent("ButtonComponent");
  const _component_LabelHelper = _resolveComponent("LabelHelper");
  const _component_draggable = _resolveComponent("draggable");
  const _component_PlusIcon = _resolveComponent("PlusIcon");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_draggable, {
    class: "list-group flex flex-col gap-y-1",
    modelValue: $options.custom_fields,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => $options.custom_fields = $event),
    group: "custom_fields",
    handle: "[data-handle]",
    onChange: $options.updatePositions,
    itemKey: "name"
  }, {
    item: _withCtx(({
      element
    }) => [_createElementVNode("div", _hoisted_2, [_createElementVNode("a", _hoisted_3, [_createVNode(_component_ChevronsUpDownIcon, {
      size: "20",
      class: "text-neutral-900 dark:text-neutral-400"
    })]), _createVNode(_component_TextInput, {
      modelValue: element.name,
      "onUpdate:modelValue": $event => element.name = $event,
      onUpdate: $event => $options.updateCustomField(element),
      class: _normalizeClass(_ctx.$style.textInput)
    }, null, 8, ["modelValue", "onUpdate:modelValue", "onUpdate", "class"]), _createVNode(_component_OptionPicker, {
      modelValue: element.type,
      "onUpdate:modelValue": [$event => element.type = $event, type => {
        element.type = type;
        if (['rating'].includes(type)) {
          element.config = {
            count: 5,
            char: '⭐️'
          };
        } else {
          element.config = {};
        }
        $options.updateCustomField(element);
      }],
      options: ['text', 'number', 'rating', 'date'],
      size: _ctx.size,
      variant: _ctx.variant,
      color: _ctx.color
    }, null, 8, ["modelValue", "onUpdate:modelValue", "size", "variant", "color"]), _createVNode(_component_ButtonComponent, {
      onClick: $event => $options.deleteCustomField(element),
      size: _ctx.size,
      variant: _ctx.variant,
      color: _ctx.color
    }, {
      default: _withCtx(() => [_createVNode(_component_TrashIcon, {
        class: "h-5"
      })]),
      _: 2
    }, 1032, ["onClick", "size", "variant", "color"]), ['rating'].includes(element.type) ? (_openBlock(), _createElementBlock("div", _hoisted_4, [element.config ? (_openBlock(), _createElementBlock("div", _hoisted_5, [_createVNode(_component_LabelHelper, null, {
      default: _withCtx(() => _cache[7] || (_cache[7] = [_createTextVNode("Emoji")])),
      _: 1
    }), _createVNode(_component_TextInput, {
      modelValue: element.config.char,
      "onUpdate:modelValue": [$event => element.config.char = $event, char => {
        element.config = {
          ...element.config,
          char: char
        };
        $options.updateCustomField(element);
      }],
      class: "w-12 text-xs"
    }, null, 8, ["modelValue", "onUpdate:modelValue"]), _createVNode(_component_LabelHelper, null, {
      default: _withCtx(() => _cache[8] || (_cache[8] = [_createTextVNode("How many")])),
      _: 1
    }), _createVNode(_component_OptionPicker, {
      modelValue: element.config.count.toString(),
      "onUpdate:modelValue": count => {
        element.config = {
          ...element.config,
          count: count
        };
        $options.updateCustomField(element);
      },
      options: ['3', '5', '10']
    }, null, 8, ["modelValue", "onUpdate:modelValue"])])) : _createCommentVNode("", true)])) : _createCommentVNode("", true)])]),
    _: 1
  }, 8, ["modelValue", "onChange"]), (_openBlock(), _createElementBlock("div", {
    key: $data.newCF.id,
    class: "flex flex-wrap items-start gap-2"
  }, [_createElementVNode("a", _hoisted_6, [_createVNode(_component_ChevronsUpDownIcon, {
    size: "20",
    class: "text-gray-900"
  })]), _createVNode(_component_TextInput, {
    modelValue: $data.newCF.name,
    "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => $data.newCF.name = $event),
    onOnEnter: $options.addCustomField,
    clearOnEnter: true,
    class: _normalizeClass(_ctx.$style.textInput),
    placeholder: "Custom Field Name"
  }, null, 8, ["modelValue", "onOnEnter", "class"]), _createVNode(_component_OptionPicker, {
    modelValue: $data.newCF.type,
    "onUpdate:modelValue": [_cache[2] || (_cache[2] = $event => $data.newCF.type = $event), _cache[3] || (_cache[3] = type => {
      $data.newCF.type = type;
      if (['rating'].includes(type)) {
        $data.newCF.config = {
          count: 5,
          char: '⭐️'
        };
      } else {
        $data.newCF.config = null;
      }
    })],
    options: ['text', 'number', 'rating', 'date'],
    size: _ctx.size,
    variant: _ctx.variant,
    color: _ctx.color
  }, null, 8, ["modelValue", "size", "variant", "color"]), _createVNode(_component_ButtonComponent, {
    onClick: $options.addCustomField,
    size: _ctx.size,
    variant: _ctx.variant,
    color: _ctx.color
  }, {
    default: _withCtx(() => [_createVNode(_component_PlusIcon, {
      class: "h-5"
    })]),
    _: 1
  }, 8, ["onClick", "size", "variant", "color"]), ['rating'].includes($data.newCF.type) ? (_openBlock(), _createElementBlock("div", _hoisted_7, [$data.newCF.config ? (_openBlock(), _createElementBlock("div", _hoisted_8, [_createVNode(_component_LabelHelper, null, {
    default: _withCtx(() => _cache[9] || (_cache[9] = [_createTextVNode("Emoji")])),
    _: 1
  }), _createVNode(_component_TextInput, {
    modelValue: $data.newCF.config.char,
    "onUpdate:modelValue": [_cache[4] || (_cache[4] = $event => $data.newCF.config.char = $event), _cache[5] || (_cache[5] = char => {
      $data.newCF.config = {
        ...$data.newCF.config,
        char: char
      };
    })],
    class: "w-12 text-xs"
  }, null, 8, ["modelValue"]), _createVNode(_component_LabelHelper, null, {
    default: _withCtx(() => _cache[10] || (_cache[10] = [_createTextVNode("How many")])),
    _: 1
  }), _createVNode(_component_OptionPicker, {
    modelValue: $data.newCF.config.count.toString(),
    "onUpdate:modelValue": _cache[6] || (_cache[6] = count => {
      $data.newCF.config = {
        ...$data.newCF.config,
        count: count
      };
    }),
    options: ['3', '5', '10']
  }, null, 8, ["modelValue"])])) : _createCommentVNode("", true)])) : _createCommentVNode("", true)]))]);
}