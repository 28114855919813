import { resolveComponent as _resolveComponent, withModifiers as _withModifiers, normalizeClass as _normalizeClass, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_ScheduleDatePicker = _resolveComponent("ScheduleDatePicker");
  const _component_ScheduleTimePicker = _resolveComponent("ScheduleTimePicker");
  return _openBlock(), _createElementBlock("section", {
    class: _normalizeClass(_ctx.$style.scheduleDisplay)
  }, [$props.entry && $props.entry.schedule ? (_openBlock(), _createElementBlock("div", {
    key: 0,
    onClick: _cache[4] || (_cache[4] = _withModifiers(() => {}, ["stop"])),
    class: _normalizeClass(["flex flex-wrap items-center gap-x-2 gap-y-1 text-neutral-500 dark:text-neutral-400", {
      'pointer-events-none': $props.disabled
    }])
  }, [_createVNode(_component_ScheduleDatePicker, {
    key: "date",
    ref: "date",
    entry: $props.entry,
    modelValue: $options.schedule.date,
    teleport: true,
    onUpdate: $options.updateDate,
    "onUpdate:recurrence": _cache[0] || (_cache[0] = value => {
      $options.schedule = {
        ...$options.schedule,
        recurrence: value
      };
    }),
    onClick: _cache[1] || (_cache[1] = _withModifiers(e => _ctx.$emit('click', e) // @note for input display to recognize click
    , ["stop"])),
    class: _normalizeClass(_ctx.$style.datePicker)
  }, null, 8, ["entry", "modelValue", "onUpdate", "class"]), _createVNode(_component_ScheduleTimePicker, {
    key: "time",
    ref: "time",
    entry: $props.entry,
    otherentries: $props.otherentries,
    modelValue: $options.schedule.time,
    teleport: true,
    "onUpdate:time": $options.updateTime,
    "onUpdate:duration": _cache[2] || (_cache[2] = value => {
      $options.schedule = {
        ...$options.schedule,
        duration: value
      };
    }),
    onClick: _cache[3] || (_cache[3] = _withModifiers(e => _ctx.$emit('click', e) // @note for input display to recognize click
    , ["stop"])),
    class: _normalizeClass(_ctx.$style.timePicker)
  }, null, 8, ["entry", "otherentries", "modelValue", "onUpdate:time", "class"])], 2)) : (_openBlock(), _createElementBlock("button", {
    key: 1,
    type: "button",
    onClick: _cache[5] || (_cache[5] = _withModifiers((...args) => $options.createSchedule && $options.createSchedule(...args), ["stop"])),
    "data-table-column-target": ""
  }))], 2);
}