export default {
  props: {
    modelValue: null,
    placeholder: String,
    label: String,
    type: {
      type: String,
      default: "text"
    },
    autocomplete: {
      type: String,
      default: "off"
    },
    disabled: Boolean,
    required: Boolean
  },
  mounted() {
    this.$emit("mounted");
  },
  computed: {
    input: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit("update:modelValue", value);
      }
    }
  },
  methods: {
    focus() {
      this.$el.querySelector("input").focus();
    }
  }
};