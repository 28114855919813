export default {
  props: {
    output: Object,
    entry: Object
  },
  computed: {
    tableColumns() {
      return this.output?.settings?.table?.columns;
    }
  }
};