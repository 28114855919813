
/*import "normalize.css"*/
import './tooltips.css'

// eslint-disable-next-line no-unused-vars
import { createApp, defineAsyncComponent, h, nextTick } from "vue";
import { store } from "./store";
import * as Sentry from "@sentry/vue";
import { createRouter, createWebHashHistory, createWebHistory } from "vue-router";
import { createRouterScroller } from 'vue-router-better-scroller'
import Vue3TouchEvents from "vue3-touch-events";

// import dotenv from 'dotenv';

// dotenv.config();

import App from "./App.vue";
import routes from "./routes";

import moment from 'moment/moment';
import 'moment-duration-format';
import { Capacitor } from '@capacitor/core';

const WebviewConfiguratorPlugin = Capacitor.isPluginAvailable('WebviewConfiguratorPlugin');
import { setBackForwardNavigationGestures, setWebviewBounce } from "capacitor-plugin-ios-webview-configurator";
if (WebviewConfiguratorPlugin) {
  setBackForwardNavigationGestures(true)
  setWebviewBounce(true);
}

const isLocalhost = window.location.hostname == 'localhost';
const isElectron = navigator.userAgent.includes('Electron');

const enableAnalytics = (!isLocalhost || isElectron) && typeof Cypress === 'undefined';

if (enableAnalytics) {
  var _paq = window._paq = window._paq || [];

  /* tracker methods like "setCustomDimension" should be called before "trackPageView" */
  //window._paq.push(["trackPageView"]);
  //window._paq.push(["enableLinkTracking"]);
  (function () {
    var u = "https://stats.marcoplain.com/";
    _paq.push(["setTrackerUrl", u + "matomo.php"]);
    _paq.push(["setSiteId", "4"]);
    var d = document,
      g = d.createElement("script"),
      s = d.getElementsByTagName("script")[0];
    g.async = true;
    g.src = u + "matomo.js";
    s.parentNode.insertBefore(g, s);
  })();
}

window.popStateDetected = false
window.addEventListener('popstate', () => {
  window.popStateDetected = true
  setTimeout(() => {
    window.popStateDetected = false
  }, 1000)
})

const router = createRouter({
  history: isElectron && typeof Cypress == 'undefined' ? createWebHashHistory() : createWebHistory(isLocalhost ? "/" : "/"),
  routes,
  // scrollBehavior(to, from, savedPosition) {

  //   if (to.params.id2)
  //     return false;

  //   // if ((to.params.id2 || from.params.id2) && to.params.id != from.params.id2) {
  //   //   console.log("Lol")
  //   //   return false;
  //   // }
  //   if (savedPosition)
  //     return savedPosition
  //   return { top: 0 }
  // }
});

let startTime

// eslint-disable-next-line no-unused-vars
router.beforeEach((to, from) => {

  if ((from.name === undefined && to.name === 'entry' && isLocalhost)) {
    app.config.globalProperties.$shouldTransition = true;
  } else {
    app.config.globalProperties.$shouldTransition = true;
  }

  if (from.name == 'login' && to.name == 'home' && app.config.globalProperties.$redirectAfterAuthTo) {
    const redirect = app.config.globalProperties.$redirectAfterAuthTo;
    app.config.globalProperties.$redirectAfterAuthTo = null;
    return redirect;
  }

  // console.trace();
  // console.log("ROUTE CHANGED", from.name, to.name)


  store.dispatch('initialized', false)
  startTime = performance.now()
  if (to.meta.requiresAuth && !store.getters.loggedIn) {
    app.config.globalProperties.$redirectAfterAuthTo = to;
    return {
      path: "/login",
    };
  }
  if (to.meta.requiresVisitor && store.getters.loggedIn) {
    return {
      path: "/",
    };
  }
  // If the route has a title, set it as the page title of the document/page
  if (to.meta.title) {
    document.title = `${to.meta.title} · Pensive`
  }
});

const onIdle = function (cb = () => { }) {
  if ("requestIdleCallback" in window) {
    window.requestIdleCallback(cb);
  } else {
    setTimeout(() => {
      nextTick(cb);
    }, 300);
  }
}

router.afterEach(() => {
  nextTick(() => {
    onIdle(() => {
      store.dispatch('initialized', true)
    })
    const endTime = performance.now()
    // eslint-disable-next-line no-unused-vars
    const timeTaken = endTime - startTime
    // console.log(`Navigation took ${Math.round(timeTaken)} ms`)
  })
})

const app = createApp(App);

app.use(router);
app.use(createRouterScroller({
  selectors: {
    // eslint-disable-next-line no-unused-vars
    '#main': function ({ to, from, type, savedPosition, element }) {
      if (to.params.id2) {
        return false;
      }
      if (type === 'push' || (from.params.id2 && !to.params.id2 && from.params.id != to.params.id)) {
        return { top: 0 }
      }
      else if (type === 'history') {
        return savedPosition
      }
    }
  },
}))

// app.use(createRouterScroller({
//   selectors: {
//     //'window': true,
//     // custom handler for scrolling on `body`
//     body({ to, from, type, savedPosition, element }) {

//       console.log(type, savedPosition)
//       // navigation triggered by RouteLink or router.push
//       if (type === 'push') {

//         if (to.name == 'entry2' && from.name == 'entry') {
//           return false;
//         }

//         return { top: 0 } // disable scroll restoration
//       }

//       // navigation triggered by browser back/forward, or router.back()
//       else if (type === 'history') {
//         console.log("history", from.name, to.name)
//         /**
//          * There might be the case with $router.replace
//          * that has the type history, but we don't want to
//          * restore the position in that case
//          */
//         if (from.name == 'entry2' && to.name == 'entry') {
//           console.log("up you go", element)
//           // element.scrollTo({
//           //   top: 0,
//           //   left: 0,
//           //   behavior: 'smooth',
//           // })
//           return { top: 0 }
//         }

//         //return savedPosition
//         // custom handling
//         // element.scrollTo({
//         //   ...savedPosition,
//         //   behavior: 'smooth',
//         // })
//       }
//     },
//   },
// }))
if (!isLocalhost)
  Sentry.init({
    app,
    dsn: "https://d96f8e7adb4288cc38876400b3fb5046@o4507725461651456.ingest.de.sentry.io/4507725463683152",
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
// else {
//   console.log("STORE")
//   window.__store__ = store;
// }

app.use(store);
app.use(Vue3TouchEvents);

import VueShepherdPlugin from 'vue-shepherd';
app.use(VueShepherdPlugin);

// import { Markdown } from "tiptap-markdown";
// Markdown.configure({
//   html: true,                  // Allow HTML input/output
//   tightLists: true,            // No <p> inside <li> in markdown output
//   tightListClass: 'tight',     // Add class to <ul> allowing you to remove <p> margins when tight
//   bulletListMarker: '-',       // <li> prefix in markdown output
//   linkify: true,              // Create links from "https://..." text
//   breaks: true,               // New lines (\n) in markdown input are converted to <br>
//   transformPastedText: false,  // Allow to paste markdown text in the editor
//   transformCopiedText: false,  // Copied text is transformed to markdown
// })


// GLOBAL METHODS

import { formattedDateOp } from './datesHelper';
app.config.globalProperties.$formattedDateOp = formattedDateOp

import { resolveField } from './filterResolving';
app.config.globalProperties.$resolveField = resolveField

app.config.globalProperties.$slugify = function (title) {
  if (!title) return '';
  if (typeof title.toLowerCase !== 'function') {
    console.error("Title::toLowerCase is not a function", title, typeof title);
  }
  var slug = title.toLowerCase();
  slug = slug.replace(/\s*$/g, "");
  slug = slug.replace(/\s+/g, "-");
  return slug;
}

app.config.globalProperties.$window = window;
app.config.globalProperties.$document = document;
app.config.globalProperties.$console = console;
app.config.globalProperties.$setTimeout = setTimeout;

app.config.globalProperties.$clearMarkdown = function (text) {
  if (!text) return text;
  return (
    text
      // Remove markdown bold (**) and italic (*) syntax
      .replace(/\*\*/g, "")
      .replace(/\*/g, "")
      // Remove markdown links [text](url)
      .replace(/\[([^\]]+)\]\([^)]+\)/g, "$1")
      // Remove HTML-style links <url>
      .replace(/<[^>]+>/g, "")
  );
};

moment.locale('en-gb', {
  week: {
    dow: 1,
  },
})

app.config.globalProperties.$moment = moment;
app.config.globalProperties.$anime = anime;

import colors from "tailwindcss/colors";
app.config.globalProperties.$colors = colors;

const merge = require('deepmerge')
app.config.globalProperties.$merge = merge;

app.config.globalProperties.$nanoid = nanoid;

app.config.globalProperties.$cursorPosition = {
  x: screen.width / 2,
  y: screen.height / 2,
};
document.onmousemove = function (event) {
  app.config.globalProperties.$cursorPosition = {
    x: event.pageX - window.scrollX,
    y: event.pageY - window.scrollY,
  };
}
document.ontouchstart = function (event) {
  const touch = event.touches[0];
  app.config.globalProperties.$cursorPosition = {
    x: touch.pageX - window.scrollX,
    y: touch.pageY - window.scrollY,
  };
}
document.ontouchmove = function (event) {
  const touch = event.touches[0];
  app.config.globalProperties.$cursorPosition = {
    x: touch.pageX - window.scrollX,
    y: touch.pageY - window.scrollY,
  };
}


app.config.globalProperties.$windowSize = {
  width: window.innerWidth,
  height: window.innerHeight,
};

window.onresize = function () {
  app.config.globalProperties.$windowSize = {
    width: window.innerWidth,
    height: window.innerHeight,
  };
}
app.config.globalProperties.$scroll = {
  x: window.scrollX,
  y: window.scrollY,
};

window.onscroll = function () {
  app.config.globalProperties.$scroll = {
    x: window.scrollX,
    y: window.scrollY,
  };
}

app.config.globalProperties.$h = h;
app.config.globalProperties.$onIdle = onIdle
// MOUNTIN
import './app.scss'
import { nanoid } from 'nanoid';
import anime from 'animejs';

app.config.globalProperties.$version = /*"0.13.0-RC9";*/ process.env.VUE_APP_VERSION;

app.config.globalProperties.$metaKey = navigator.platform.includes('Mac') ? '⌘' : 'Ctrl+';

// isMobile wasnt reactive. I replaced every ocurrence with a "navigator.userAgent.includes("Mobile")" check
// app.config.globalProperties.$isMobile = navigator.userAgent.includes('Mobile');
// app.config.globalProperties.$navigator = navigator;

app.config.globalProperties.$isLocalhost = isLocalhost;
app.config.globalProperties.$isElectron = isElectron;
app.config.globalProperties.$enableAnalytics = enableAnalytics;
app.config.globalProperties.$Cypress = typeof Cypress !== 'undefined';

// app.config.performance = true;
app.mount("#app");