import "core-js/modules/es.array.push.js";
import throttle from "lodash.throttle";
export default {
  provide() {
    return {
      depth: 0,
      display: {}
    };
  },
  data() {
    return {
      scrolling: false,
      scrollingYElement: null,
      scrollingXElement: null,
      scrollingX: false,
      scrollAmount: 0,
      scrollAmountX: 0,
      clockInterval: null,
      postDropActions: () => {},
      cursorPosition: {
        x: 0,
        y: 0
      },
      renderOutputTimeout: null
    };
  },
  watch: {
    "user.settings.prefers_color_scheme": function () {
      this.checkDarkMode();
    },
    "$store.getters.mouseTarget": {
      handler: function (n, o) {
        o;
        n;
        if (o?.el) {
          o.obj.renderDnDBeforeEdge = false;
          o.obj.renderDnDAfterEdge = false;
          o.obj.renderDnDLeftEdge = false;
          o.obj.renderDnDRightEdge = false;
        }
        this.onMouseMove({
          // @note This will remove the issue with touchmove not firing after mouseTarget changed
          clientX: this.cursorPosition.x,
          clientY: this.cursorPosition.y
        }, true /* in order to skip scroll */);
      },
      deep: true
    }
  },
  computed: {
    user() {
      return this.$store.getters.user;
    },
    token() {
      return this.$store.getters.token;
    },
    cssProps() {
      return {
        "--ps-output-display-entry-display-background-color-hover": this.$store.getters.dragged.length ? "transparent" : null
      };
    }
  },
  mounted() {
    // Bind the onMouseMove method to the Vue instance and register it
    // this.boundOnMouseMove = this.onMouseMove.bind(this);
    document.addEventListener("mouseup", this.onMouseUp);
    document.addEventListener("touchend", this.onMouseUp);
    this.throttledOnMouseMove = throttle(this.onMouseMove, 200);
    document.addEventListener("mousemove", this.throttledOnMouseMove);
    document.addEventListener("touchmove", this.throttledOnMouseMove, {
      passive: true
    });
    this.checkDarkMode();
    window.matchMedia("(prefers-color-scheme: dark)").addEventListener("change", this.checkDarkMode);
    setTimeout(() => {
      this.clockInterval = setInterval(() => {
        this.$store.dispatch("clock");
      }, 1000);
    }, 1000 - new Date().getMilliseconds());
    this.checkWindowWidth();
  },
  beforeUnmount() {
    // Unregister the event listener
    document.removeEventListener("mouseup", this.onMouseUp);
    document.removeEventListener("touchend", this.onMouseUp);
    document.removeEventListener("mousemove", this.throttledOnMouseMove);
    document.removeEventListener("touchmove", this.throttledOnMouseMove);
    window.matchMedia("(prefers-color-scheme: dark)").removeEventListener("change", this.checkDarkMode);
    clearInterval(this.clockInterval);
  },
  methods: {
    checkDarkMode() {
      switch (this.user?.settings.prefers_color_scheme) {
        case "dark":
          return document.documentElement.classList.add("dark");
        case "light":
          return document.documentElement.classList.remove("dark");
        default:
          if (window.matchMedia("(prefers-color-scheme: dark)").matches) {
            return document.documentElement.classList.add("dark");
          } else {
            return document.documentElement.classList.remove("dark");
          }
      }
    },
    calcWindowWidth() {
      const width = window.innerWidth;
      if (width < 640 && this.$store.getters.widthSize !== "xs") {
        this.$store.commit("setWidthSize", "xs");
      } else if (width >= 640 && width < 768 && this.$store.getters.widthSize !== "sm") {
        this.$store.commit("setWidthSize", "sm");
      } else if (width >= 768 && width < 1024 && this.$store.getters.widthSize !== "md") {
        this.$store.commit("setWidthSize", "md");
      } else if (width >= 1024 && width < 1280 && this.$store.getters.widthSize !== "lg") {
        this.$store.commit("setWidthSize", "lg");
      } else if (width >= 1280 && this.$store.getters.widthSize !== "xl") {
        this.$store.commit("setWidthSize", "xl");
      }
    },
    checkWindowWidth() {
      this.calcWindowWidth();
      window.addEventListener("resize", this.calcWindowWidth);
    },
    getParent(obj) {
      let parent = obj.$parent;
      while (parent) {
        if (!parent) return;
        if (typeof parent.setSchema === "function") {
          return parent;
        }
        parent = parent.$parent;
      }
    },
    // eslint-disable-next-line no-unused-vars
    onMouseUp(e) {
      if (this.$store.getters.dragged.length) {
        this.$store.dispatch("mouseTarget", null);
        if (this.renderOutputTimeout) {
          clearTimeout(this.renderOutputTimeout);
          this.renderOutputTimeout = null;
        }
        // setTimeout(() => {
        // need to delay this to allow DropApplicators to fire first. (dont know why the order is that way)
        if (this.postDropActions) this.postDropActions();
        // }, 0);

        this.stopScrollingY();
        this.stopScrollingX();
      }
    },
    onMouseMove(e, skipScroll = false) {
      // console.log("onMouseMove", e);

      // if (e.touches && e.touches.length) e = e.touches[0];
      if (e.type == "touchmove" && e.touches && e.touches.length) this.cursorPosition = {
        x: e.touches[0].clientX,
        y: e.touches[0].clientY
      };else if (e.clientX && e.clientY) this.cursorPosition = {
        x: e.clientX,
        y: e.clientY
      }; // @note This will remove the issue with touchmove not firing after mouseTarget changed

      // console.log("onMouseMove", e);
      if (this.$store.getters.dragged.length && this.$store.getters.mouseTarget?.el) {
        if (this.renderOutputTimeout) {
          clearTimeout(this.renderOutputTimeout);
          this.renderOutputTimeout = null;
        }
        const {
          el,
          obj
        } = this.$store.getters.mouseTarget;
        if (this.$store.getters.dragged.map(e => e.id).includes(obj.entry?.id)) {
          obj.renderDnDBeforeEdge = false;
          obj.renderDnDAfterEdge = false;
          obj.renderDnDLeftEdge = false;
          obj.renderDnDRightEdge = false;
          return;
        }
        const EDGE_DISTANCE = 10;
        if (typeof el.getBoundingClientRect === "function") {
          const rect = el.getBoundingClientRect();
          let x, y;
          if (e.type == "touchmove" && e.touches && e.touches.length) {
            x = e.touches[0].clientX - rect.left;
            y = e.touches[0].clientY - rect.top;
          } else {
            x = e.clientX - rect.left;
            y = e.clientY - rect.top;
          }
          const parent = this.getParent(obj);
          if (!obj.disableEdgeDetection) {
            const orientation = parent?.orientation || "vertical";
            if (obj.entry?.input || obj.dropSchema) {
              if (orientation == "vertical") {
                obj.renderDnDBeforeEdge = y < EDGE_DISTANCE;
                obj.renderDnDAfterEdge = y > rect.height - EDGE_DISTANCE;
              } else {
                obj.renderDnDBeforeEdge = x < EDGE_DISTANCE;
                obj.renderDnDAfterEdge = x > rect.width - EDGE_DISTANCE;
              }
            } else {
              if (orientation == "vertical") {
                obj.renderDnDBeforeEdge = y < rect.height / 2;
                obj.renderDnDAfterEdge = y >= rect.height / 2;
              } else {
                obj.renderDnDBeforeEdge = x < rect.width / 2;
                obj.renderDnDAfterEdge = x >= rect.width / 2;
              }
            }
          } else {
            obj.renderDnDBeforeEdge = false;
            obj.renderDnDAfterEdge = false;
          }
          if (!parent || !parent.dropSchema) {
            obj.renderDnDBeforeEdge = false;
            obj.renderDnDAfterEdge = false;
          }
          if (obj.renderDnDBeforeEdge || obj.renderDnDAfterEdge) {
            let dropIndex = obj.dropIndex;
            if (obj.renderDnDAfterEdge) {
              dropIndex = dropIndex + 1;
            }
            if (parent && typeof parent.setSchema === "function") {
              parent.setSchema({
                dropIndex
              });
            }
            this.postDropActions = () => {
              // console.log("postDropActions", dropIndex);
              if (parent && typeof parent.postDropActions === "function") parent.postDropActions({
                dropIndex
              });
            };
          } else {
            obj.setSchema();
            this.postDropActions = () => {};
            if (typeof obj.toggleDisplayOutput !== "undefined") {
              this.startRenderOutputTimeout(obj);
            }
          }
        }
      }
      if (skipScroll) return;
      this.scrollAmount = 0;
      this.scrollAmountX = 0;
      if (this.$store.getters.dragged.length) {
        var target = e.target;
        if (e.type == "touchmove") {
          if (e.touches && e.touches.length) e = e.touches[0];
          target = document.elementFromPoint(e.clientX, e.clientY);
        }
        const edgeThreshold = 100; // Pixels from the edge of the window
        const maxScrollAmount = 20; // Maximum pixels to scroll each animation frame

        const scrollableXParent = this.scrollingXElement ? [this.scrollingXElement] : this.findScrollableXParents(target);
        if (!scrollableXParent.length) {
          this.stopScrollingX();
        }
        while (scrollableXParent.length) {
          const currentXParent = scrollableXParent.shift();
          const rect = currentXParent.getBoundingClientRect();
          const distanceFromRightEdge = rect.left + rect.width - e.clientX;
          const distanceFromLeftEdge = e.clientX - rect.left;
          const isWithinBounds = e.clientX >= rect.left && e.clientX <= rect.right && e.clientY >= rect.top && e.clientY <= rect.bottom;
          if (distanceFromLeftEdge < edgeThreshold && isWithinBounds) {
            // Check if we can scroll left (scrollLeft > 0)
            if (currentXParent.scrollLeft > 0) {
              this.scrollAmountX = -maxScrollAmount * (1 - distanceFromLeftEdge / edgeThreshold);
            }
          } else if (distanceFromRightEdge < edgeThreshold && isWithinBounds) {
            // Check if we can scroll right (scrollWidth - scrollLeft > clientWidth)
            if (currentXParent.scrollWidth - currentXParent.scrollLeft > currentXParent.clientWidth) {
              this.scrollAmountX = maxScrollAmount * (1 - distanceFromRightEdge / edgeThreshold);
            }
          }
          if (this.scrollAmountX !== 0) {
            this.startScrollingX(currentXParent);
            break;
          } else {
            this.stopScrollingX();
          }
        }
        const scrollableYParents = this.scrollingYElement ? [this.scrollingYElement] : this.findScrollableYParents(target);
        if (!scrollableYParents.length) {
          this.stopScrollingY();
        }
        while (scrollableYParents.length) {
          const currentYParent = scrollableYParents.shift();
          const rect = currentYParent.getBoundingClientRect();
          const distanceFromBottomEdge = rect.top + rect.height - e.clientY;
          const distanceFromTopEdge = e.clientY - rect.top;
          const isWithinBounds = e.clientX >= rect.left && e.clientX <= rect.right && e.clientY >= rect.top && e.clientY <= rect.bottom;
          if (distanceFromTopEdge < edgeThreshold && isWithinBounds) {
            // Check if we can scroll up (scrollTop > 0)
            if (currentYParent.scrollTop > 0) {
              // Increase scroll speed as the mouse gets closer to the top edge
              this.scrollAmount = -maxScrollAmount * (1 - distanceFromTopEdge / edgeThreshold);
            }
          } else if (distanceFromBottomEdge < edgeThreshold && isWithinBounds) {
            // Check if we can scroll down (scrollHeight - scrollTop > clientHeight)
            if (currentYParent.scrollHeight - currentYParent.scrollTop > currentYParent.clientHeight) {
              // Increase scroll speed as the mouse gets closer to the bottom edge
              this.scrollAmount = maxScrollAmount * (1 - distanceFromBottomEdge / edgeThreshold);
            }
          }
          if (this.scrollAmount !== 0) {
            this.startScrollingY(currentYParent);
            break;
          } else {
            this.stopScrollingY();
          }
        }
      }
    },
    startRenderOutputTimeout(obj) {
      if (this.renderOutputTimeout) {
        clearTimeout(this.renderOutputTimeout);
      }
      this.renderOutputTimeout = setTimeout(() => {
        obj.toggleDisplayOutput(obj.entry);
      }, 1000);
    },
    findScrollableXParents(element) {
      if (!element) return [];
      let parent = element.parentElement;
      const parents = [];
      while (parent) {
        if (parent.id === "entry-modal-page-wrapper") {
          parents.push(parent);
        }
        if (parent.scrollWidth > parent.clientWidth + 10) {
          parents.push(parent);
        }
        parent = parent.parentElement;
      }
      return parents;
    },
    findScrollableYParents(element) {
      if (!element) return [];
      let parent = element.parentElement;
      const parents = [];
      while (parent) {
        if (parent.id === "entry-modal-page-wrapper") {
          parents.push(parent);
        }
        const style = window.getComputedStyle(parent);
        const overflowY = style.overflowY;
        if ((overflowY === "auto" || overflowY === "scroll") && parent.scrollHeight > parent.clientHeight) {
          parents.push(parent);
        }
        parent = parent.parentElement;
      }
      return parents;
    },
    startScrollingY(element) {
      if (this.scrolling) return;
      this.scrolling = true;
      this.scrollingYElement = element;
      const scroll = () => {
        if (!this.scrolling) return;
        if (this.$route.params.id2) {
          document.querySelector("#entry-modal-page-wrapper").scrollBy(0, this.scrollAmount);
        } else {
          this.scrollingYElement.scrollBy(0, this.scrollAmount);
        }
        requestAnimationFrame(scroll);
      };
      requestAnimationFrame(scroll);
    },
    startScrollingX(element) {
      if (this.scrollingX) return;
      this.scrollingX = true;
      this.scrollingXElement = element;
      const scroll = () => {
        if (!this.scrollingX) return;
        if (this.$route.params.id2 && 3 > 4) {
          document.querySelector("#entry-modal-page-wrapper").scrollBy(this.scrollAmountX, 0);
        } else {
          this.scrollingXElement.scrollBy(this.scrollAmountX, 0);
        }
        requestAnimationFrame(scroll);
      };
      requestAnimationFrame(scroll);
    },
    stopScrollingY() {
      this.scrolling = false;
      this.scrollingYElement = null;
      this.scrollAmount = 0;
    },
    stopScrollingX() {
      this.scrollingX = false;
      this.scrollingXElement = null;
      this.scrollAmountX = 0;
    }
  }
};