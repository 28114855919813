import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createBlock as _createBlock } from "vue";
const _hoisted_1 = {
  class: "flex gap-x-2.5"
};
const _hoisted_2 = {
  class: "!ml-auto"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_OutputCount = _resolveComponent("OutputCount");
  const _component_TimeTrackingsToday = _resolveComponent("TimeTrackingsToday");
  return _openBlock(), _createElementBlock("div", null, [_renderSlot(_ctx.$slots, "cover"), _createElementVNode("div", _hoisted_1, [_ctx.$slots.before ? (_openBlock(), _createElementBlock("div", {
    key: 0,
    class: _normalizeClass([_ctx.$style.alignHeight])
  }, [_renderSlot(_ctx.$slots, "before")], 2)) : _createCommentVNode("", true), $options.isRenderLeftColumn /* && isLazyMounted*/ ? (_openBlock(), _createElementBlock("div", {
    key: 1,
    class: _normalizeClass({
      [_ctx.$style.leftColumn]: true
      // 'pt-0.5': ['sm', 'md'].includes(size), // in case name is bigger
      // 'pt-1': ['lg', 'xl', '2xl'].includes(size), // in case name is bigger
      // 'pt-3': ['4xl'].includes(size), // in case name is bigger
    })
  }, [$props.computedDisplay.output &&
  // !['gallery'].includes(renderType) &&
  $props.entry.output ? (_openBlock(), _createElementBlock("div", {
    key: 0,
    class: _normalizeClass(_ctx.$style.renderOutput)
  }, [_renderSlot(_ctx.$slots, "toggleOutput")], 2)) : _createCommentVNode("", true), _createElementVNode("div", {
    class: _normalizeClass(_ctx.$style.statusAndSensesWrapper)
  }, [_renderSlot(_ctx.$slots, "status"), _renderSlot(_ctx.$slots, "senses")], 2)], 2)) : _createCommentVNode("", true), _createElementVNode("div", {
    key: "center",
    class: _normalizeClass(_ctx.$style.centerColumn)
  }, [_createElementVNode("div", {
    class: _normalizeClass(_ctx.$style.centerColumn_firstRow)
  }, [_renderSlot(_ctx.$slots, "priority"), _renderSlot(_ctx.$slots, "name"), _renderSlot(_ctx.$slots, "links"), _renderSlot(_ctx.$slots, "procrastination")], 2), _renderSlot(_ctx.$slots, "description"), $props.computedDisplay.schedule && ($props.entry.schedule || _ctx.$slots.schedule) || $props.computedDisplay.time_trackings && ($props.entry.time_trackings?.length || _ctx.$slots.time_trackings) || $props.computedDisplay.routine && ($props.entry.routine || _ctx.$slots.routine) ? (_openBlock(), _createElementBlock("div", {
    key: 0,
    class: _normalizeClass(_ctx.$style.centerColumn_thirdRow)
  }, [_renderSlot(_ctx.$slots, "schedule"), _renderSlot(_ctx.$slots, "time_trackings"), _createElementVNode("div", _hoisted_2, [_renderSlot(_ctx.$slots, "routine")])], 2)) : _createCommentVNode("", true), _renderSlot(_ctx.$slots, "custom_fields")], 2), _createElementVNode("div", {
    class: _normalizeClass(_ctx.$style.rightColumn)
  }, [_renderSlot(_ctx.$slots, "top-right"), $props.computedDisplay.output_count && $props.entry.output ? (_openBlock(), _createBlock(_component_OutputCount, {
    key: 0,
    entry: $props.entry,
    class: _normalizeClass([_ctx.$style.alignHeight, "w-5 text-center text-sm text-neutral-500 group-hover:opacity-0"])
  }, null, 8, ["entry", "class"])) : _createCommentVNode("", true), $props.computedDisplay.time_trackings_today && $props.entry.time_trackings ? (_openBlock(), _createBlock(_component_TimeTrackingsToday, {
    key: 1,
    modelValue: $props.entry.time_trackings,
    entry: $props.entry,
    class: "self-center text-center text-sm text-neutral-500"
  }, null, 8, ["modelValue", "entry"])) : _createCommentVNode("", true), _renderSlot(_ctx.$slots, "settings")], 2)])]);
}