export default {
  props: {
    date: Object,
    format: {
      type: String,
      default: "YYYY-MM-DD"
    },
    highlightToday: {
      type: Boolean,
      default: true
    }
  }
};