import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, normalizeClass as _normalizeClass, unref as _unref, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withModifiers as _withModifiers } from "vue";
const _hoisted_1 = {
  class: "uppercase"
};
const _hoisted_2 = {
  style: {
    "color": "var(--color-medium-gray)"
  }
};
const _hoisted_3 = {
  style: {
    "color": "var(--color-medium-gray)"
  }
};
const _hoisted_4 = {
  key: 0
};
const _hoisted_5 = {
  class: "justfy-between flex gap-4 px-4 py-3"
};
const _hoisted_6 = {
  style: {
    "display": "flex",
    "gap": "0.5rem"
  }
};
const _hoisted_7 = {
  style: {
    "font-weight": "bold"
  }
};
const _hoisted_8 = {
  style: {
    "display": "flex",
    "gap": "0.5rem"
  }
};
const _hoisted_9 = {
  style: {
    "font-weight": "bold",
    "min-width": "5rem",
    "display": "inline-block",
    "text-align": "center",
    "text-transform": "uppercase"
  }
};
import { Popover, PopoverButton, PopoverPanel, PopoverOverlay } from "@headlessui/vue";
import { ref } from "vue";
import { useFloating, shift, flip, autoUpdate } from "@floating-ui/vue";
import moment from "moment";
import { Scheduling } from "@/components/mixins/Scheduling";
const __default__ = {
  mixins: [Scheduling /*, EditUtilities */],
  props: {
    modelValue: String,
    entry: Object,
    otherentries: Array,
    teleport: Boolean
  },
  data() {
    return {
      //   moment: moment,
      internalTime: null,
      internalDuration: null
      //   now: null,
    };
  },
  watch: {
    // "entry.schedule.time": function (n) {
    //   // this.value = n;
    //   // this.cursor = n;
    // },
  },
  mounted() {
    this.internalTime = this.time;
    this.internalDuration = this.duration;

    // this.interval = setInterval(() => {
    //   this.now = moment.utc();
    // }, 1000);
  },
  beforeUnmount() {
    // clearInterval(this.interval);
  },
  computed: {
    schedule: {
      get() {
        return this.entry.schedule;
      },
      set(value) {
        // eslint-disable-next-line vue/no-mutating-props
        this.entry.schedule = value;
        if (!this.entry.temp) this.$store.dispatch("push", {
          event: "entry_update",
          params: {
            entry: this.entry
          },
          entry: this.entry
        });
      }
    },
    time: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.schedule = {
          ...this.schedule,
          time: value
        };
        this.$emit("update:time", value);
      }
    },
    duration: {
      get() {
        return this.entry?.schedule?.duration;
      },
      set(value) {
        this.schedule = {
          ...this.schedule,
          duration: value
        };
      }
    },
    displayText() {
      if (this.time && this.duration) return this.valToHtml(this.time) + " - " + this.valToHtml(this.time, "HH:mm", {
        key: "seconds",
        val: this.duration
      });
      if (!this.time && this.duration) return this.duration / 60 + " min.";
      if (this.time || !this.duration) return this.valToHtml(this.time);
      return "Set Time";
    },
    date() {
      return moment().format("YYYY-MM-DD");
    }
  },
  methods: {
    timePart(part, time) {
      if (time == null) return "--";
      switch (part) {
        case "H":
          return moment.utc(this.date + " " + time).local().format("HH");
        case "M":
          return moment.utc(this.date + " " + time).local().format("mm");
        case "S":
          return moment.utc(this.date + " " + time).local().format("ss");
      }
    },
    modifyRelativeDate(dir, time) {
      switch (dir) {
        case 1:
          switch (time.op) {
            case "now":
              time.op = "nextXHours";
              time.x = 1;
              break;
            default:
              time.x = typeof time.x != "undefined" ? time.op.slice(0, 4) == "next" ? time.x + 1 : time.x - 1 : 0;
              if (time.x < 0) {
                time.op = time.op.slice(0, 4) == "next" ? "last" + time.op.slice(4) : "next" + time.op.slice(4);
                time.x = 1;
              }
          }
          break;
        case -1:
          switch (time.op) {
            case "now":
              time.op = "lastXHours";
              time.x = 1;
              break;
            default:
              time.x = typeof time.x != "undefined" ? time.op.slice(0, 4) == "next" ? time.x - 1 : time.x + 1 : 0;
              if (time.x < 0) {
                time.op = time.op.slice(0, 4) == "next" ? "last" + time.op.slice(4) : "next" + time.op.slice(4);
                time.x = 1;
              }
          }
          break;
      }
      if ((time.op == "nextXHours" || time.op == "lastXHours") && time.x == 0) {
        delete time.x;
        time.op = "now";
      }
      if (time.op == "nextXHours" && time.x >= 24) {
        time.x = 0;
      }
      if (time.op == "lastXHours" && time.x >= 24) {
        time.x = 0;
      }
      if (time.op == "nextXMinutes" && time.x >= 60) {
        time.x = 0;
      }
      if (time.op == "lastXMinutes" && time.x >= 60) {
        time.x = 0;
      }
      if (time.op == "nextXSeconds" && time.x >= 60) {
        time.x = 0;
      }
      if (time.op == "lastXSeconds" && time.x >= 60) {
        time.x = 0;
      }
      //this.$emit("update", this.value);
    },
    modifyRelativeTimespan(dir, time) {
      if (time !== null) {
        switch (dir) {
          case 1:
            switch (time.op) {
              case "nextXSeconds":
                time.op = "nextXMinutes";
                if (typeof time.x == "undefined") time.x = 1;
                break;
              case "nextXMinutes":
                time.op = "nextXHours";
                if (typeof time.x == "undefined") time.x = 1;
                break;
              case "lastXSeconds":
                time.op = "lastXMinutes";
                if (typeof time.x == "undefined") time.x = 1;
                break;
              case "lastXMinutes":
                time.op = "lastXHours";
                if (typeof time.x == "undefined") time.x = 1;
                break;
            }
            break;
          case -1:
            switch (time.op) {
              case "now":
                time.op = "nextXMinutes";
                if (typeof time.x == "undefined") time.x = 0;
                break;
              case "nextXMinutes":
                time.op = "nextXSeconds";
                if (typeof time.x == "undefined") time.x = 1;
                break;
              case "nextXHours":
                time.op = "nextXMinutes";
                if (typeof time.x == "undefined") time.x = 1;
                break;
              case "lastXMinutes":
                time.op = "lastXSeconds";
                if (typeof time.x == "undefined") time.x = 1;
                break;
              case "lastXHours":
                time.op = "lastXMinutes";
                if (typeof time.x == "undefined") time.x = 1;
                break;
            }
            break;
        }
        if ((time.op == "nextXHours" || time.op == "lastXHours") && time.x == 0) {
          delete time.x;
          time.op = "now";
        }
      }
      //this.$emit("update", this.value);
    },
    relativeTimespan(time) {
      if (time === null) {
        return [0, "hours"];
      }
      switch (time.op) {
        case "now":
          return [0, "hours"];
        case "lastXHours":
        case "nextXHours":
          return [time.x, "hour" + (time.x != 1 ? "s" : "")];
        case "lastXMinutes":
        case "nextXMinutes":
          return [time.x, "minute" + (time.x != 1 ? "s" : "")];
        case "lastXSeconds":
        case "nextXSeconds":
          return [time.x, "second" + (time.x != 1 ? "s" : "")];
      }
    },
    valToHtml(val, format = "HH:mm", mod) {
      if (val) {
        if (val.op == "null") return "unset time";
        if (val.op == "now") {
          return "now";
        }
        if (val.op == "nextXHours") {
          if (val.x == 0) return "now";
          return "in " + val.x + " hour" + (val.x != 1 ? "s" : "");
        }
        if (val.op == "nextXMinutes") {
          if (val.x == 0) return "now";
          return "in " + val.x + " minute" + (val.x != 1 ? "s" : "");
        }
        if (val.op == "nextXSeconds") {
          if (val.x == 0) return "now";
          return "in " + val.x + " second" + (val.x != 1 ? "s" : "");
        }
        if (val.op == "lastXHours") {
          if (val.x == 0) return "now";
          return val.x + " hour" + (val.x != 1 ? "s" : "") + " ago";
        }
        if (val.op == "lastXMinutes") {
          if (val.x == 0) return "now";
          return val.x + " minute" + (val.x != 1 ? "s" : "") + " ago";
        }
        if (val.op == "lastXSeconds") {
          if (val.x == 0) return "now";
          return val.x + " second" + (val.x != 1 ? "s" : "") + " ago";
        }
        /*
                  @note this shows in completedAt Component: "TODAY now" - I don't like that.
                  if (format == "HH:mm" && val == moment().utc().format("HH:mm:ss")) {
                    return "now";
                  }*/
        var datetime = moment.utc(moment.utc().format("YYYY-MM-DD " + val));
        if (datetime.format("ss") != "00") format = format + ":ss";
        if (mod) {
          datetime = datetime.add(mod.val, mod.key);
        }
        return datetime.local().format(format);
      }
      return;
      // return "--:--";
    }
  }
};
export default /*@__PURE__*/Object.assign(__default__, {
  __name: 'ScheduleTimePicker',
  setup(__props) {
    const reference = ref(null);
    const floating = ref(null);
    const {
      floatingStyles
    } = useFloating(reference, floating, {
      placement: "top-start",
      whileElementsMounted: autoUpdate,
      middleware: [flip(), shift({
        crossAxis: true
      })]
    });
    return (_ctx, _cache) => {
      const _component_ClockIcon = _resolveComponent("ClockIcon");
      const _component_ButtonComponent = _resolveComponent("ButtonComponent");
      const _component_ScheduleTimeAndDurationSettings = _resolveComponent("ScheduleTimeAndDurationSettings");
      const _component_TrashIcon = _resolveComponent("TrashIcon");
      const _component_SaveIcon = _resolveComponent("SaveIcon");
      const _component_XIcon = _resolveComponent("XIcon");
      const _component_PopoverHelper = _resolveComponent("PopoverHelper");
      return _openBlock(), _createElementBlock("div", null, [_createVNode(_component_PopoverHelper, {
        id: "schedule_time_picker",
        teleport: __props.teleport,
        class: "relative"
      }, {
        button: _withCtx(() => [_createVNode(_component_ButtonComponent, {
          variant: "none",
          ref_key: "reference",
          ref: reference,
          class: "group inline-flex flex-wrap items-center gap-x-1 text-left text-neutral-500 hover:text-neutral-700 dark:hover:text-neutral-400",
          title: "Set schedule time"
        }, {
          default: _withCtx(() => [_createVNode(_component_ClockIcon, {
            size: "14"
          }), _createElementVNode("span", _hoisted_1, _toDisplayString(_ctx.displayText), 1)]),
          _: 1
        }, 512)]),
        default: _withCtx(() => [_createElementVNode("div", {
          class: _normalizeClass([_ctx.$style.popover, "overflow-hidden rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5 dark:bg-black dark:ring-neutral-700"])
        }, [_createVNode(_component_ScheduleTimeAndDurationSettings, {
          modelValue: _ctx.schedule,
          entry: __props.entry,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = value => _ctx.schedule = value),
          class: _normalizeClass(_ctx.$style.timeAndDurationSettings)
        }, null, 8, ["modelValue", "entry", "class"]), _createVNode(_unref(PopoverButton), {
          onClick: _cache[1] || (_cache[1] = $event => _ctx.time = _ctx.interpretTime({
            time: _ctx.$moment().utc().format('HH:mm:00')
          })),
          class: "flex w-full cursor-pointer items-center justify-between gap-x-2 px-3 py-1 text-sm leading-6 text-gray-900 hover:bg-gray-50 dark:text-neutral-400 dark:hover:bg-neutral-950"
        }, {
          default: _withCtx(() => [_cache[9] || (_cache[9] = _createElementVNode("span", {
            style: {
              "font-weight": "bold"
            }
          }, " Now ", -1)), _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.interpretTime({
            time: _ctx.$moment().format("HH:mm")
          })), 1)]),
          _: 1
        }), _createVNode(_unref(PopoverButton), {
          onClick: _cache[2] || (_cache[2] = $event => _ctx.time = _ctx.interpretTime({
            time: _ctx.$moment().utc().add(1, 'hour').format('HH:mm:00')
          })),
          class: "flex w-full cursor-pointer items-center justify-between gap-x-2 px-3 py-1 text-sm leading-6 text-gray-900 hover:bg-gray-50 dark:text-neutral-400 dark:hover:bg-neutral-950"
        }, {
          default: _withCtx(() => [_cache[10] || (_cache[10] = _createElementVNode("span", {
            style: {
              "font-weight": "bold"
            }
          }, " In 1 Hour ", -1)), _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.interpretTime({
            time: _ctx.$moment().add(1, "hour").format("HH:mm")
          })), 1)]),
          _: 1
        }), _createElementVNode("div", {
          class: _normalizeClass(_ctx.$style.utilitiesRow)
        }, [_createVNode(_unref(PopoverButton), {
          onClick: _cache[3] || (_cache[3] = $event => _ctx.time = null),
          class: _normalizeClass(_ctx.$style.buttonDelete)
        }, {
          default: _withCtx(() => [_createVNode(_component_TrashIcon, {
            size: "18"
          })]),
          _: 1
        }, 8, ["class"]), _createVNode(_unref(PopoverButton), {
          class: _normalizeClass(_ctx.$style.buttonSave)
        }, {
          default: _withCtx(() => [_createVNode(_component_SaveIcon, {
            size: "18"
          })]),
          _: 1
        }, 8, ["class"])], 2), 3 > 4 && typeof _ctx.time == 'object' ? (_openBlock(), _createElementBlock("div", _hoisted_4, [_createElementVNode("div", _hoisted_5, [_ctx.value?.op ? (_openBlock(), _createElementBlock("span", {
          key: 0,
          style: _normalizeStyle([{
            color: _ctx.value.op == 'now' || _ctx.value.op.slice(0, 4) == 'next' && (typeof _ctx.value.x == 'undefined' || _ctx.value.x >= 0) || _ctx.value.op.slice(0, 4) == 'last' && (typeof _ctx.value.x == 'undefined' || _ctx.value.x <= 0) ? 'var(--color-dark-gray)' : 'var(--color-medium-gray)'
          }, {
            "font-size": "82%",
            "font-weight": "bold",
            "text-transform": "uppercase"
          }])
        }, " in ", 4)) : _createCommentVNode("", true), _createElementVNode("span", null, [_createElementVNode("span", _hoisted_6, [_createVNode(_component_ButtonComponent, {
          onClick: _cache[4] || (_cache[4] = _withModifiers($event => _ctx.modifyRelativeDate(-1, _ctx.value), ["prevent"]))
        }, {
          default: _withCtx(() => _cache[11] || (_cache[11] = [_createTextVNode(" − ")])),
          _: 1
        }), _createElementVNode("span", _hoisted_7, _toDisplayString(_ctx.relativeTimespan(_ctx.value) ? Math.abs(_ctx.relativeTimespan(_ctx.value)[0]) : null), 1), _createVNode(_component_ButtonComponent, {
          onClick: _cache[5] || (_cache[5] = _withModifiers($event => _ctx.modifyRelativeDate(1, _ctx.value), ["prevent"]))
        }, {
          default: _withCtx(() => _cache[12] || (_cache[12] = [_createTextVNode(" + ")])),
          _: 1
        })])]), _createElementVNode("span", null, [_createElementVNode("span", _hoisted_8, [_createVNode(_component_ButtonComponent, {
          onClick: _cache[6] || (_cache[6] = _withModifiers($event => _ctx.modifyRelativeTimespan(-1, _ctx.value), ["prevent"]))
        }, {
          default: _withCtx(() => _cache[13] || (_cache[13] = [_createTextVNode(" − ")])),
          _: 1
        }), _createElementVNode("span", _hoisted_9, _toDisplayString(_ctx.relativeTimespan(_ctx.value) ? _ctx.relativeTimespan(_ctx.value)[1] : null), 1), _createVNode(_component_ButtonComponent, {
          onClick: _cache[7] || (_cache[7] = _withModifiers($event => _ctx.modifyRelativeTimespan(1, _ctx.value), ["prevent"]))
        }, {
          default: _withCtx(() => _cache[14] || (_cache[14] = [_createTextVNode(" + ")])),
          _: 1
        })])]), _ctx.value?.op ? (_openBlock(), _createElementBlock("span", {
          key: 1,
          style: _normalizeStyle([{
            color: _ctx.value.op.slice(0, 4) == 'next' && (typeof _ctx.value.x == 'undefined' || _ctx.value.x < 0) || _ctx.value.op.slice(0, 4) == 'last' && (typeof _ctx.value.x == 'undefined' || _ctx.value.x > 0) ? 'var(--color-dark-gray)' : 'var(--color-medium-gray)'
          }, {
            "font-size": "82%",
            "font-weight": "bold",
            "text-transform": "uppercase"
          }])
        }, " ago ", 4)) : _createCommentVNode("", true)]), _createVNode(_unref(PopoverButton), {
          onClick: _cache[8] || (_cache[8] = $event => [_ctx.value = __props.entry ? {
            op: 'null'
          } : {
            op: 'unset'
          }]),
          class: "flex w-full cursor-pointer items-center gap-x-2 px-3 py-1 text-sm leading-6 text-gray-900 hover:bg-gray-50 dark:text-neutral-400 dark:hover:bg-neutral-950"
        }, {
          default: _withCtx(() => [_createVNode(_component_XIcon, {
            size: "16"
          }), _cache[15] || (_cache[15] = _createTextVNode(" Unset "))]),
          _: 1
        })])) : _createCommentVNode("", true)], 2)]),
        _: 1
      }, 8, ["teleport"])]);
    };
  }
});