import { createElementVNode as _createElementVNode, unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx } from "vue";
const _hoisted_1 = {
  class: "mx-auto w-full sm:max-w-xl md:max-w-2xl md:px-8 lg:max-w-3xl xl:max-w-5xl"
};
const _hoisted_2 = {
  class: "flex flex-col gap-4"
};
const _hoisted_3 = {
  class: "py-5"
};
const _hoisted_4 = {
  class: ""
};
const _hoisted_5 = {
  class: "flow-root"
};
const _hoisted_6 = {
  class: "isolate -mt-16 grid max-w-sm grid-cols-1 gap-y-16 divide-y divide-neutral-100 sm:mx-auto lg:-mx-8 lg:mt-0 lg:max-w-none lg:grid-cols-3 lg:divide-x lg:divide-y-0 xl:-mx-4 dark:divide-neutral-700"
};
const _hoisted_7 = ["id"];
const _hoisted_8 = {
  key: 0,
  class: "mt-6 flex items-baseline gap-x-1"
};
const _hoisted_9 = {
  class: "text-5xl font-bold tracking-tight text-neutral-900 dark:text-neutral-300"
};
const _hoisted_10 = ["disabled", "href", "aria-describedby"];
const _hoisted_11 = {
  key: 0
};
const _hoisted_12 = {
  key: 1
};
const _hoisted_13 = ["disabled", "aria-describedby"];
const _hoisted_14 = {
  class: "mt-10 text-sm font-semibold leading-6 text-neutral-900 dark:text-neutral-300"
};
const _hoisted_15 = {
  role: "list",
  class: "mt-6 space-y-3 text-sm leading-6 text-neutral-600 dark:text-neutral-400"
};
import { StripeCheckout } from "@vue-stripe/vue-stripe";
import axios from "axios";
import moment from "moment";
const __default__ = {
  name: "SpacePage",
  data() {
    this.publishableKey = process.env.NODE_ENV === "production" ? "pk_live_51MjPE1DONrNfdT6AfWgTdMSh6KRj05vePn6AY5Uq6IlCB45ERT7K5GJ7T8lcudkwDRNN4b2nonEj37XKiA8Ocr090005ihksck" : "pk_test_51MjPE1DONrNfdT6ATalHegQHUkO9XKz5sbJjPUeNMQDRZopnqV977FJhJByr7KyDJ9yMMJ3MLfKULNWZkH7SIWPX00eDL1Gy74";
    return {
      statuses: this.$store.getters.space?.statuses,
      moment: moment,
      showApiToken: false,
      sessionId: null,
      portalURL: null,
      loadingSubscriptions: true,
      showCheckout: false
    };
  },
  mounted() {
    // if (process.env.NODE_ENV !== "production" || this.user.verified) {
    axios.get("stripe/session").then(response => {
      this.sessionId = response.data.id;
    });
    axios.get("stripe/portal").then(response => {
      this.portalURL = response.data.url;
    });
    this.$store.dispatch("retrieveSubscriptions").then(() => {
      this.loadingSubscriptions = false;
    });
    // }
  },
  watch: {
    space: {
      handler() {
        this.statuses = this.$store.getters.space.statuses;
      },
      deep: true
    }
  },
  computed: {
    space() {
      return this.$store.getters.space;
    },
    user() {
      return this.$store.getters.user;
    }
  },
  methods: {
    submit() {
      // You will be redirected to Stripe's secure checkout page
      this.showCheckout = true;
      this.$nextTick(() => {
        this.$refs.checkoutRef.redirectToCheckout();
      });
    }
  }
};
export default /*@__PURE__*/Object.assign(__default__, {
  setup(__props) {
    const tiers = [{
      name: "Early Bird",
      id: "free_2023-12-30",
      stripe_product_id: null,
      href: "#",
      price: {
        monthly: "€0"
      },
      description: "Enjoy all of Pensive's features for free. Let it make you more productive.",
      features: ["Unlimited Entries", "Unlimited Statuses", "Unlimited Custom Fields", "Access to Schedules", "Access to Routines", "Access to Senses"]
    }, {
      name: "Supporter",
      id: "pro_2023-12-30",
      stripe_product_id: "prod_NUkkggOaQ2kHhf",
      href: "#",
      price: {
        monthly: "€5"
      },
      description: "Want to support Pensive? Here's a way to do it.",
      features: ["Everything from Free", "Support my work with a monthly coffee ☕️"]
    }];
    return (_ctx, _cache) => {
      const _component_CircleCheckIcon = _resolveComponent("CircleCheckIcon");
      const _component_PanelHelper = _resolveComponent("PanelHelper");
      return _openBlock(), _createElementBlock("div", null, [_createElementVNode("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createVNode(_component_PanelHelper, null, {
        default: _withCtx(() => [_cache[2] || (_cache[2] = _createElementVNode("div", {
          class: "px-4 sm:px-0"
        }, [_createElementVNode("h3", {
          class: "text-base font-semibold leading-7 text-neutral-900 dark:text-neutral-200"
        }, " Subscription Settings "), _createElementVNode("p", {
          class: "mt-1 max-w-2xl text-sm leading-6 text-neutral-500"
        })], -1)), _ctx.showCheckout ? (_openBlock(), _createBlock(_unref(StripeCheckout), {
          key: 0,
          ref: "checkoutRef",
          pk: _ctx.publishableKey,
          "session-id": _ctx.sessionId
        }, null, 8, ["pk", "session-id"])) : _createCommentVNode("", true), _createElementVNode("div", _hoisted_3, [_createElementVNode("div", _hoisted_4, [_createElementVNode("div", _hoisted_5, [_createElementVNode("div", _hoisted_6, [(_openBlock(), _createElementBlock(_Fragment, null, _renderList(tiers, tier => {
          return _createElementVNode("div", {
            key: tier.id,
            class: "pt-16 lg:px-8 lg:pt-0 xl:px-14"
          }, [_createElementVNode("h3", {
            id: tier.id,
            class: "text-base font-semibold leading-7 text-neutral-900 dark:text-neutral-300"
          }, _toDisplayString(tier.name), 9, _hoisted_7), tier.price ? (_openBlock(), _createElementBlock("p", _hoisted_8, [_createElementVNode("span", _hoisted_9, _toDisplayString(tier.price.monthly), 1), _cache[1] || (_cache[1] = _createElementVNode("span", {
            class: "text-sm font-semibold leading-6 text-neutral-600 dark:text-neutral-500"
          }, " /month ", -1))])) : _createCommentVNode("", true), tier.stripe_product_id && _ctx.space.active_subscription.stripe_product_id == tier.stripe_product_id ? (_openBlock(), _createElementBlock("a", {
            key: 1,
            disabled: _ctx.portalURL == null,
            href: _ctx.portalURL,
            "aria-describedby": tier.id,
            class: _normalizeClass([{
              'cursor-pointer bg-neutral-600': _ctx.portalURL != null,
              'pointer-events-none cursor-default bg-neutral-200': _ctx.portalURL == null
            }, "mt-10 block w-full rounded-md px-3 py-2 text-center text-sm font-semibold leading-6 text-white shadow-sm hover:bg-neutral-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-neutral-600 disabled:cursor-default"])
          }, [_ctx.portalURL ? (_openBlock(), _createElementBlock("span", _hoisted_11, "Manage Subscription")) : (_openBlock(), _createElementBlock("span", _hoisted_12, "Loading..."))], 10, _hoisted_10)) : tier.stripe_product_id ? (_openBlock(), _createElementBlock("button", {
            key: 2,
            disabled: _ctx.space.active_subscription.stripe_product_id == tier.stripe_product_id,
            onClick: _cache[0] || (_cache[0] = (...args) => _ctx.submit && _ctx.submit(...args)),
            "aria-describedby": tier.id,
            class: "mt-10 block w-full rounded-md bg-neutral-600 px-3 py-2 text-center text-sm font-semibold leading-6 text-white shadow-sm hover:bg-neutral-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-neutral-600 disabled:cursor-default disabled:bg-neutral-200"
          }, " Subscribe ", 8, _hoisted_13)) : _createCommentVNode("", true), _createElementVNode("p", _hoisted_14, _toDisplayString(tier.description), 1), _createElementVNode("ul", _hoisted_15, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(tier.features, feature => {
            return _openBlock(), _createElementBlock("li", {
              key: feature,
              class: "flex gap-x-3"
            }, [_createVNode(_component_CircleCheckIcon, {
              size: "20",
              class: "flex-none text-neutral-600",
              "aria-hidden": "true"
            }), _createTextVNode(" " + _toDisplayString(feature), 1)]);
          }), 128))])]);
        }), 64))])])])])]),
        _: 1
      })])])]);
    };
  }
});