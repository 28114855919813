import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_TextInput = _resolveComponent("TextInput");
  return _openBlock(), _createBlock(_component_TextInput, {
    modelValue: $data.value,
    "onUpdate:modelValue": [_cache[0] || (_cache[0] = $event => $data.value = $event), _cache[1] || (_cache[1] = val => _ctx.$emit('update:modelValue', [val]))],
    clearOnEnter: false,
    blurOnEnter: true,
    placeholder: "Enter text...",
    class: _normalizeClass(_ctx.$style.textPicker)
  }, null, 8, ["modelValue", "class"]);
}