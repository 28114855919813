import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, createBlock as _createBlock } from "vue";
const _hoisted_1 = {
  key: 0
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_SettingsIcon = _resolveComponent("SettingsIcon");
  const _component_ButtonComponentNew = _resolveComponent("ButtonComponentNew");
  return _openBlock(), _createBlock(_component_ButtonComponentNew, {
    class: _normalizeClass(_ctx.$style.button),
    shortcode: "s"
  }, {
    default: _withCtx(() => [_createVNode(_component_SettingsIcon, {
      size: "20"
    }), _ctx.variant != 'round' ? (_openBlock(), _createElementBlock("span", _hoisted_1, "Settings")) : _createCommentVNode("", true)]),
    _: 1
  }, 8, ["class"]);
}