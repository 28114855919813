import { renderSlot as _renderSlot, createCommentVNode as _createCommentVNode, TransitionGroup as _TransitionGroup, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return $options.disabled ? _renderSlot(_ctx.$slots, "default", {
    key: 0
  }) : (_openBlock(), _createBlock(_TransitionGroup, {
    key: 1,
    appear: $props.appear,
    name: "list",
    onEnter: $options.onEnter,
    onLeave: $options.onLeave
  }, {
    default: _withCtx(() => [_renderSlot(_ctx.$slots, "default")]),
    _: 3
  }, 8, ["appear", "onEnter", "onLeave"]));
}