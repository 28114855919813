import { Extension } from '@tiptap/core';
import { Plugin, PluginKey } from '@tiptap/pm/state';
import { Decoration, DecorationSet } from '@tiptap/pm/view';
import * as chrono from 'chrono-node';
import moment from 'moment';

// Unified date parsing function with support for English and German locales
function parseDateWithLocales(text) {
    // Try parsing with English (default)
    const matchesEnglish = chrono.parse(text);
    if (matchesEnglish.length > 0) {
        return matchesEnglish;
    }

    // Fallback to German locale
    const matchesGerman = chrono.de.parse(text);
    if (matchesGerman.length > 0) {
        return matchesGerman;
    }

    // No matches found
    return [];
}

export const DateHighlighter = Extension.create({
    name: 'dateHighlighter',

    addStorage() {
        return {
            isProcessingDates: false,
            isTyping: false,
        };
    },

    addCommands() {
        return {
            removeHighlightedDates: () => ({ tr, state, dispatch }) => {
                this.storage.isProcessingDates = false;
                this.storage.isTyping = false;

                const { doc } = state;
                let transaction = tr;

                doc.descendants((node, pos) => {
                    if (node.isText) {
                        const text = node.text;
                        if (!text) return;

                        const matches = parseDateWithLocales(text);
                        if (matches.length > 0) {
                            const match = matches[matches.length - 1];
                            const from = pos + match.index;
                            const to = from + match.text.length;
                            transaction = transaction.delete(from, to);
                        }
                    }
                });

                if (dispatch) {
                    dispatch(transaction);
                }

                return true;
            },
        };
    },

    addKeyboardShortcuts() {
        return {
            ' ': () => {
                this.storage.isTyping = true;
                return false; // Let the key event continue
            },
            Backspace: () => {
                this.storage.isTyping = true;
                return false;
            },
            Delete: () => {
                this.storage.isTyping = true;
                return false;
            },
        };
    },

    addProseMirrorPlugins() {
        let editor = this.editor;
        let lastDateInfo = null; // Add this to track the last emitted date

        return [
            new Plugin({
                key: new PluginKey('dateHighlighter'),
                props: {
                    decorations: (state) => {
                        if (!editor?.isFocused || !this.storage.isProcessingDates || !this.storage.isTyping) {
                            return DecorationSet.empty;
                        }

                        const { doc } = state;
                        const decorations = [];
                        let dateInfo = null;

                        doc.descendants((node, pos) => {
                            if (node.isText) {
                                const text = node.text;
                                if (!text) return;

                                try {
                                    const matches = parseDateWithLocales(text);

                                    if (matches.length > 0) {
                                        const match = matches[matches.length - 1];
                                        const from = pos + match.index;
                                        const to = from + match.text.length;

                                        const parsedDate = moment(match.date());
                                        const hasTime = match.start.isCertain('hour');

                                        dateInfo = {
                                            text: match.text,
                                            date: parsedDate.utc().format('YYYY-MM-DD'),
                                            time: hasTime ? parsedDate.utc().format('HH:mm:ss') : null,
                                            start: match.start,
                                            end: match.end,
                                            index: match.index,
                                        };

                                        decorations.push(
                                            Decoration.inline(from, to, {
                                                class: 'date-highlight',
                                            })
                                        );
                                    } else if (lastDateInfo) {
                                        // If we had a date before but not anymore, clear it
                                        dateInfo = {
                                            date: null,
                                            time: null
                                        };
                                    }
                                } catch (error) {
                                    console.error('Error parsing date:', error);
                                }
                            }
                        });

                        if (editor) {
                            if (dateInfo || lastDateInfo) {
                                editor.emit('nlp:date', dateInfo || { date: null, time: null });
                                lastDateInfo = dateInfo;
                            }
                        }

                        return DecorationSet.create(doc, decorations);
                    },
                },
            }),
        ];
    },
});
