import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_DatePicker = _resolveComponent("DatePicker");
  const _component_TimePicker = _resolveComponent("TimePicker");
  return _openBlock(), _createElementBlock("section", {
    class: _normalizeClass(_ctx.$style.dateTimePicker)
  }, [_createVNode(_component_DatePicker, {
    ref: "date",
    entry: $props.entry,
    modelValue: $data.date,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => $data.date = $event),
    disabled: $props.disabled
  }, null, 8, ["entry", "modelValue", "disabled"]), _createVNode(_component_TimePicker, {
    ref: "time",
    entry: $props.entry,
    modelValue: $data.time,
    "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => $data.time = $event),
    disabled: $props.disabled,
    class: _normalizeClass(_ctx.$style.timePicker)
  }, null, 8, ["entry", "modelValue", "disabled", "class"])], 2);
}