export default {
  name: "TextInput",
  props: {
    modelValue: null,
    placeholder: String,
    disabled: Boolean,
    blurOnEnter: Boolean,
    clearOnEnter: Boolean,
    shiftEnter: Boolean,
    noEnterOnBlurWhileOnMobile: Boolean,
    // For Search Component
    validation: {
      type: Function,
      default: v => v
    },
    color: {
      type: String,
      default: "default"
    }
  },
  data() {
    return {
      value: this.modelValue,
      value_emitted: null
    };
  },
  mounted() {
    this.$emit("mounted");
  },
  watch: {
    modelValue: function () {
      // console.log("TextInput::watch::modelValue", n, o);
      this.value = this.modelValue;
      this.value_emitted = "";
      this.$nextTick(() => {
        if (this.$refs.input) this.$refs.input.innerText = this.value;
      });
    }
  },
  methods: {
    onKeyUp(e) {
      // console.log("TextInput::onKeyUpCallback");
      if (this.$refs.input) {
        this.$emit("onKeyUp", this.$refs.input.innerText, e);
      }
    },
    onInput(e) {
      // this.value = e.target.innerText;
      // console.log("TextInput::onInput", this.value);
      this.$emit("onInput", e.target.innerText);
    },
    onKeyDown(e) {
      this.$emit("onKeyDown", this.$refs.input.innerText, e);
      if (e.key == "ArrowDown") e.preventDefault();
      if (e.key == "ArrowUp") e.preventDefault();
      if (e.key == "Escape") {
        this.$refs.input.innerText = "";
        this.setText();
        this.onBlur();
      }
      if (e.keyCode == 13 && (!e.shiftKey || !this.shiftEnter)) {
        e.preventDefault();
        if (this.blurOnEnter) this.$refs.input.blur();
      }

      /* Any Shortcut except Ctrl + V */
      const isValidShortcut = e.ctrlKey && e.keyCode != 86;

      /* Backspace - Delete - Arrow Keys - Ctrl - Shift */
      const isValidKeyCode = [8, 16, 17, 37, 38, 39, 40, 46].includes(e.keyCode);
      const maxLength = 255;
      const text = e.srcElement.innerText;
      if (text.length >= maxLength && !isValidKeyCode && !isValidShortcut) {
        e.preventDefault();
      }
    },
    updateText(e) {
      // console.log("TextInput::updateText");
      this.$emit("onKeyUp", this.$refs.input.innerText, e);
      if (e.keyCode == 13 && (!e.shiftKey || !this.shiftEnter)) {
        this.setText();
        e.preventDefault();
        this.$emit("onEnter", this.value);
        if (this.blurOnEnter) this.$refs.input.blur();
        if (this.clearOnEnter) {
          // this.$nextTick(() => {
          if (this.$refs.input) this.$refs.input.innerText = "";
          this.value_emitted = "";
          this.value = "";
          // });
        }
      }
    },
    onBlur() {
      this.setText();
      this.$emit("onBlur", this.value);
      // const regex =
      //   /Mobi|Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i;
      // if (!this.noEnterOnBlurWhileOnMobile && regex.test(navigator.userAgent)) {
      //   if (this.value) {
      //     this.$emit("onEnter", this.value);
      //     if (this.clearOnEnter) {
      //       if (this.$refs.input) this.$refs.input.innerText = "";
      //     }
      //   }
      // }
    },
    setText() {
      if (this.$refs.input) {
        this.value = this.$refs.input.innerText.trim();
        this.value = this.validation(this.value);
        /**
         * In case the validation changed the value,
         * we need to reflect it here
         */
        this.$refs.input.innerText = this.value;
        if (this.value != this.modelValue) {
          if (this.value != this.value_emitted) {
            this.value_emitted = this.value;
            this.$emit("update:modelValue", this.value);
            this.$emit("update", this.value);
          }
        }
      }
    },
    pasteText(e) {
      e.preventDefault();
      var text = e.clipboardData.getData("text/plain");
      document.execCommand("insertText", false, text.replace(/\n/g, " ").slice(0, 255));
      this.$nextTick(() => {
        this.updateText(e);
      });
    }
  }
};