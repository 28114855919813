import "core-js/modules/es.array.push.js";
import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveDynamicComponent as _resolveDynamicComponent, mergeProps as _mergeProps, withModifiers as _withModifiers } from "vue";
const _hoisted_1 = {
  class: "flex flex-col gap-5"
};
const _hoisted_2 = {
  class: "flex flex-col gap-x-2 text-xs dark:text-neutral-400"
};
const _hoisted_3 = {
  class: "flex justify-between"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_FilterIcon = _resolveComponent("FilterIcon");
  const _component_SectionHeader = _resolveComponent("SectionHeader");
  const _component_FilterBuilder = _resolveComponent("FilterBuilder");
  const _component_SelectMenu = _resolveComponent("SelectMenu");
  const _component_TrashIcon = _resolveComponent("TrashIcon");
  const _component_ButtonComponentNew = _resolveComponent("ButtonComponentNew");
  const _component_PlusIcon = _resolveComponent("PlusIcon");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", {
    class: _normalizeClass(_ctx.$style.senseBuilder_content)
  }, [_createVNode(_component_SectionHeader, {
    url: "https://help.pensive.io/senses"
  }, {
    title: _withCtx(() => [_createVNode(_component_FilterIcon, {
      size: "16"
    }), _cache[4] || (_cache[4] = _createTextVNode(" Sense Filters "))]),
    _: 1
  }), _createVNode(_component_FilterBuilder, {
    modelValue: $options.sense.filters,
    "onUpdate:modelValue": [_cache[0] || (_cache[0] = $event => $options.sense.filters = $event), _cache[1] || (_cache[1] = filters => {
      $options.sense = {
        ...$options.sense,
        filters
      };
    })]
  }, null, 8, ["modelValue"]), _createVNode(_component_SectionHeader, {
    url: "https://help.pensive.io/senses"
  }, {
    title: _withCtx(() => _cache[5] || (_cache[5] = [_createTextVNode(" Triggers ")])),
    _: 1
  }), _createElementVNode("div", _hoisted_2, [_cache[6] || (_cache[6] = _createElementVNode("span", null, [_createElementVNode("strong", null, "Possible Values:")], -1)), (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.options, (key, index) => {
    return _openBlock(), _createElementBlock("span", {
      key: index,
      class: "uppercase"
    }, _toDisplayString(key.title) + ": " + _toDisplayString(_ctx.filterEntries($options.filters).map(e => key.value == "count" ? 1 : _ctx.$resolveField(e, key.value) || 0).reduce((a, b) => a + b, 0)) + " " + _toDisplayString(_ctx.getUnit(key.value)), 1);
  }), 128))]), (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.sense.aggregates, (o, k) => {
    return _openBlock(), _createElementBlock("fieldset", {
      key: k,
      class: _normalizeClass(_ctx.$style.filterRow)
    }, [_createElementVNode("div", {
      class: _normalizeClass([_ctx.$style.filterRow_left, "flex items-center gap-2"])
    }, [_createElementVNode("span", {
      class: _normalizeClass([{
        'bg-red-400': _ctx.getAggregatedResults([o], $options.children).filter(a => !a.ok).length,
        'bg-green-400': !_ctx.getAggregatedResults([o], $options.children).filter(a => !a.ok).length
      }, "order-last ml-auto inline-block h-2 w-2 flex-shrink-0 rounded-full md:order-first md:ml-0"]),
      "aria-hidden": "true"
    }, null, 2), _createVNode(_component_SelectMenu, {
      modelValue: o.key,
      "onUpdate:modelValue": [$event => o.key = $event, key => {
        o.key = key;
        _ctx.$emit('update:modelValue', $options.sense);
      }],
      options: $data.options,
      ref_for: true,
      ref: 'key_select_' + k,
      color: "secondary"
    }, null, 8, ["modelValue", "onUpdate:modelValue", "options"]), o.key || o.op ? (_openBlock(), _createBlock(_component_SelectMenu, {
      key: 0,
      modelValue: o.op,
      "onUpdate:modelValue": [$event => o.op = $event, op => {
        o.op = op;
        _ctx.$emit('update:modelValue', $options.sense);
      }],
      options: _ctx.ops(o.key) /*.filter((op) =>
                               ['gt', 'gte', 'eq', 'neq', 'lte', 'lt'].includes(op),
                               )
                               */,

      render: val => _ctx.opToSign(val),
      color: "secondary"
    }, null, 8, ["modelValue", "onUpdate:modelValue", "options", "render"])) : _createCommentVNode("", true), _ctx.vals(o.key, o.op).length && ['DatePicker', 'TimePicker'].includes(_ctx.vals(o.key, o.op)[0]) ? (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.vals(o.key, o.op)[0]), _mergeProps({
      key: 1,
      ref_for: true
    }, _ctx.vals(o.key, o.op)[1], {
      modelValue: o.val[0],
      "onUpdate:modelValue": [$event => o.val[0] = $event, val => {
        o.val = [val];
        _ctx.$emit('update:modelValue', $options.sense);
      }],
      color: "secondary",
      class: "rounded border px-2 py-1 text-xs dark:border-neutral-700"
    }), null, 16, ["modelValue", "onUpdate:modelValue"])) : _createCommentVNode("", true), _ctx.vals(o.key, o.op).length && !['DatePicker', 'TimePicker'].includes(_ctx.vals(o.key, o.op)[0]) ? (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.vals(o.key, o.op)[0]), _mergeProps({
      key: 2,
      ref_for: true
    }, _ctx.vals(o.key, o.op)[1], {
      modelValue: o.val,
      "onUpdate:modelValue": [$event => o.val = $event, val => {
        o.val = val;
        _ctx.$emit('update:modelValue', $options.sense);
      }],
      class: {
        'rounded border px-2 py-1 text-xs dark:border-neutral-700': _ctx.vals(o.key, o.op)[0] == 'LinksBuilder'
      },
      color: "secondary"
    }), null, 16, ["modelValue", "onUpdate:modelValue", "class"])) : _createCommentVNode("", true)], 2), _createElementVNode("div", {
      class: _normalizeClass(_ctx.$style.filterRow_right)
    }, [_createVNode(_component_ButtonComponentNew, {
      onClick: $event => [$options.sense.aggregates.splice(k, 1), _ctx.$emit('update:modelValue', $options.sense)],
      color: "secondary",
      size: "sm",
      class: _normalizeClass(_ctx.$style.buttonRemove),
      style: {
        // '--ps-button-color': 'var(--ps-color-red-500)',
        '--ps-button-color-hover': 'var(--ps-color-red-600)'
      }
    }, {
      default: _withCtx(() => [_createVNode(_component_TrashIcon, {
        size: "16",
        "stroke-width": "1.5"
      })]),
      _: 2
    }, 1032, ["onClick", "class"])], 2)], 2);
  }), 128)), _createElementVNode("fieldset", _hoisted_3, [_createVNode(_component_ButtonComponentNew, {
    onClick: _cache[2] || (_cache[2] = _withModifiers($event => ($options.aggregates.push({
      key: null,
      op: null,
      val: []
    }), _ctx.$emit('update:modelValue', $options.sense)), ["stop"])),
    class: _normalizeClass(_ctx.$style.buttonAdd)
  }, {
    default: _withCtx(() => [_createVNode(_component_PlusIcon, {
      size: "16"
    }), _cache[7] || (_cache[7] = _createTextVNode(" Add "))]),
    _: 1
  }, 8, ["class"]), _createVNode(_component_ButtonComponentNew, {
    onClick: _cache[3] || (_cache[3] = _withModifiers($event => _ctx.$emit('delete:modelValue', $options.sense), ["stop"])),
    class: _normalizeClass([_ctx.$style.buttonDelete])
  }, {
    default: _withCtx(() => [_createVNode(_component_TrashIcon, {
      size: "16"
    }), _cache[8] || (_cache[8] = _createTextVNode(" Delete Sense "))]),
    _: 1
  }, 8, ["class"])])], 2)]);
}