import { createElementVNode as _createElementVNode, renderSlot as _renderSlot, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue";
const _hoisted_1 = {
  class: "relative"
};
const _hoisted_2 = {
  class: "relative flex items-center justify-between"
};
const _hoisted_3 = {
  class: "flex items-center gap-1.5 bg-white pr-2 text-xs uppercase text-neutral-500 dark:bg-black"
};
const _hoisted_4 = {
  key: 0,
  class: "flex gap-2 bg-white pl-2 dark:bg-black dark:text-neutral-500"
};
const _hoisted_5 = ["href"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_CircleHelpIcon = _resolveComponent("CircleHelpIcon");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_cache[0] || (_cache[0] = _createElementVNode("div", {
    class: "absolute inset-0 flex items-center",
    "aria-hidden": "true"
  }, [_createElementVNode("div", {
    class: "w-full border-t border-neutral-300 dark:border-neutral-700"
  })], -1)), _createElementVNode("div", _hoisted_2, [_createElementVNode("span", _hoisted_3, [_renderSlot(_ctx.$slots, "title")]), $props.url ? (_openBlock(), _createElementBlock("div", _hoisted_4, [_createElementVNode("a", {
    href: $props.url,
    target: "_blank"
  }, [_createVNode(_component_CircleHelpIcon, {
    size: "20"
  })], 8, _hoisted_5)])) : _createCommentVNode("", true)])]);
}