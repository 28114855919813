// import { Viewporting } from "../mixins/Viewporting";
export default {
  props: {
    visible: Boolean
  },
  watch: {
    visible: {
      handler: function (n) {
        if (n === false) this.$refs.boundaries.style.minHeight = this.$refs.boundaries.getBoundingClientRect().height + "px";
        // else this.$refs.boundaries.style.minHeight = null;
      },
      immetiate: true
    }
  }
  // mixins: [Viewporting],
};