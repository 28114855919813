export default {
  props: {
    output: Object,
    entry: Object,
    computedDisplay: Object
  },
  computed: {
    isRenderLeftColumn() {
      return this.computedDisplay.output && /*!["gallery"].includes(this.renderType) && */
      this.entry.output || this.computedDisplay.status && this.entry.status_id /*this.renderType != "tiles" &&*/ || this.computedDisplay.senses && this.entry.senses?.length;
    }
  }
};