import { Switch,
//   SwitchDescription,
SwitchGroup, SwitchLabel } from "@headlessui/vue";
export default {
  components: {
    Switch,
    // SwitchDescription,
    SwitchGroup,
    SwitchLabel
  },
  props: {
    label: {
      type: String,
      required: false
    },
    modelValue: Boolean
  }
};