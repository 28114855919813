import "core-js/modules/es.array.push.js";
import moment from "moment";
import { Statusing } from "../../mixins/Statusing";
import { Templating } from "../../mixins/Templating";
import { Schema } from "../../mixins/Schema";

// import EntryComponent from "./EntryComponent.vue";

export default {
  inject: {
    overrideEntries: {
      default: null
    }
  },
  // provide() {
  //   // use function syntax so that we can access `this`
  //   return {
  //     position: "contextmenu",
  //   };
  // },
  mixins: [Statusing, Templating, Schema /*, Rendering */],
  props: {
    modelValue: Object,
    position: String,
    hide: {
      type: Array,
      default() {
        return [];
      }
    },
    otherentries: [],
    // fixes console warning
    shareNode: null
  },
  data() {
    return {
      moment: moment,
      showSenseModal: false
    };
  },
  mounted() {
    // Focus the first menu item when the component is mounted
    this.$nextTick(() => {
      this.$refs.items.$el.focus();
    });
  },
  computed: {
    render() {
      return [
        // // this.module("status"),
        // this.module("color"),
        // this.module("description"),
        // this.module("schedule"),
        // this.module("time_trackings"),
        // this.module("priority"),
        // this.module("routine"),
        // this.module("anchor"),
        // this.module("links"),
        //this.module("templates"),
      ];
    },
    user() {
      return this.$store.getters.user;
    },
    entry: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit("update:modelValue", value);
      }
    },
    status: {
      get() {
        return this.getStatusById(this.modelValue.status_id);
      },
      set() {
        //this.setStatus(this.entry, status);
        // if (!this.entry.temp)
        //   this.$store.dispatch("push", {
        //     event: "entry_update",
        //     params: { entry: this.entry },
        //     entry: this.entry,
        //   });
      }
    }
  },
  methods: {
    duplicateEntry() {
      const newId = this.$nanoid();
      const entry = {
        ...this.entrySchema({
          id: newId
        }),
        ...this.getCleanSchema(this.entry, newId)
      };
      entry.name = entry.name + " (Copy)";
      this.$store.getters.entries.push(entry);
      this.$store.dispatch("push", {
        event: "entry_create",
        params: {
          entry: entry
        },
        entry: entry
      });
    },
    deleteEntry() {
      this.entry.deleted_at = moment.utc().format("YYYY-MM-DD HH:mm:ss");
      this.$store.getters.entries.filter(e => e.links.includes(this.entry.id)).forEach(e => {
        e.links.splice(e.links.findIndex(l => l == this.entry.id), 1);
      });
      if (!this.entry.temp) this.$store.dispatch("push", {
        event: "entry_delete",
        params: {
          id: this.entry.id
        },
        entry: this.entry
      });
      if (this.$route.params.id == this.entry.id) this.$router.back();
    },
    undeleteEntry() {
      this.entry.deleted_at = null;
      if (!this.entry.temp) this.$store.dispatch("push", {
        event: "entry_update",
        params: {
          entry: this.entry
        },
        entry: this.entry
      });
    }
  }
};