import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, withModifiers as _withModifiers } from "vue";
const _hoisted_1 = {
  class: "flex flex-col gap-2"
};
const _hoisted_2 = {
  class: "flex items-center justify-between gap-2"
};
const _hoisted_3 = {
  key: 0,
  class: "flex flex-col gap-2"
};
const _hoisted_4 = {
  class: "flex items-center gap-2"
};
const _hoisted_5 = ["onClick"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_SelectMenu = _resolveComponent("SelectMenu");
  const _component_TextInput = _resolveComponent("TextInput");
  const _component_DatePicker = _resolveComponent("DatePicker");
  const _component_TrashIcon = _resolveComponent("TrashIcon");
  const _component_ButtonComponent = _resolveComponent("ButtonComponent");
  const _component_CalendarComponent = _resolveComponent("CalendarComponent");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createVNode(_component_SelectMenu, {
    modelValue: $options.date.op,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = val => $options.date = {
      ...$options.date,
      op: val
    }),
    options: [{
      title: 'No Date',
      value: null
    }, {
      title: 'Today',
      value: 'today'
    }, {
      title: 'Tomorrow',
      value: 'tomorrow'
    }, {
      title: 'Yesterday',
      value: 'yesterday'
    }, {
      title: 'Next ...',
      value: 'nextXDays'
    }, {
      title: 'Next ...',
      value: 'nextXWeeks',
      hidden: true
    }, {
      title: 'Next ...',
      value: 'nextXMonths',
      hidden: true
    }, {
      title: 'Next ...',
      value: 'nextXYears',
      hidden: true
    }, {
      title: 'Last ...',
      value: 'lastXDays'
    }, {
      title: 'Last ...',
      value: 'lastXWeeks',
      hidden: true
    }, {
      title: 'Last ...',
      value: 'lastXMonths',
      hidden: true
    }, {
      title: 'Last ...',
      value: 'lastXYears',
      hidden: true
    }, {
      title: 'Fixed Date',
      value: 'dateX'
    }],
    class: "flex-1"
  }, null, 8, ["modelValue"]), $options.date?.op?.includes('nextX') || $options.date?.op?.includes('lastX') ? (_openBlock(), _createBlock(_component_TextInput, {
    key: 0,
    modelValue: $options.date.x,
    "onUpdate:modelValue": [_cache[1] || (_cache[1] = $event => $options.date.x = $event), _cache[2] || (_cache[2] = val => {
      $options.date = {
        ...$options.date,
        x: val
      };
    })],
    validation: val => val.replace(/[^\d]/g, ''),
    class: _normalizeClass([_ctx.$style.textInput, "w-20 text-sm"])
  }, null, 8, ["modelValue", "validation", "class"])) : _createCommentVNode("", true), $options.date?.op?.includes('next') ? (_openBlock(), _createBlock(_component_SelectMenu, {
    key: 1,
    modelValue: $options.date.op,
    "onUpdate:modelValue": _cache[3] || (_cache[3] = val => $options.date = {
      ...$options.date,
      op: val
    }),
    options: [{
      title: 'Days',
      value: 'nextXDays'
    }, {
      title: 'Weeks',
      value: 'nextXWeeks'
    }, {
      title: 'Months',
      value: 'nextXMonths'
    }, {
      title: 'Years',
      value: 'nextXYears'
    }],
    class: "flex-1"
  }, null, 8, ["modelValue"])) : _createCommentVNode("", true), $options.date?.op?.includes('last') ? (_openBlock(), _createBlock(_component_SelectMenu, {
    key: 2,
    modelValue: $options.date.op,
    "onUpdate:modelValue": _cache[4] || (_cache[4] = val => $options.date = {
      ...$options.date,
      op: val
    }),
    options: [{
      title: 'Days',
      value: 'lastXDays'
    }, {
      title: 'Weeks',
      value: 'lastXWeeks'
    }, {
      title: 'Months',
      value: 'lastXMonths'
    }, {
      title: 'Years',
      value: 'lastXYears'
    }],
    class: "flex-1"
  }, null, 8, ["modelValue"])) : _createCommentVNode("", true), $options.date.op === 'dateX' ? (_openBlock(), _createBlock(_component_DatePicker, {
    key: 3,
    modelValue: $options.date.x,
    "onUpdate:modelValue": _cache[5] || (_cache[5] = $event => $options.date.x = $event),
    class: _normalizeClass(_ctx.$style.textInput)
  }, null, 8, ["modelValue", "class"])) : _createCommentVNode("", true)]), $props.showRecurrence && $props.entry?.schedule?.recurrence ? (_openBlock(), _createElementBlock("div", _hoisted_3, [_createElementVNode("div", _hoisted_4, [_cache[8] || (_cache[8] = _createElementVNode("span", {
    class: "text-sm text-neutral-500"
  }, "Recurrence:", -1)), _createVNode(_component_SelectMenu, {
    modelValue: $options.recurrence,
    "onUpdate:modelValue": _cache[6] || (_cache[6] = $event => $options.recurrence = $event),
    options: $data.recurrenceOptions,
    class: "flex-1"
  }, null, 8, ["modelValue", "options"]), _createVNode(_component_ButtonComponent, {
    onClick: _cache[7] || (_cache[7] = $event => _ctx.$emit('update:recurrence', null)),
    size: "xs",
    color: "secondary",
    title: "Unset recurrence"
  }, {
    default: _withCtx(() => [_createVNode(_component_TrashIcon, {
      class: "h-4 w-4"
    })]),
    _: 1
  })])])) : _createCommentVNode("", true), $props.showCalendar ? (_openBlock(), _createBlock(_component_CalendarComponent, {
    key: 1,
    class: "mt-2"
  }, {
    default: _withCtx(({
      date: _date
    } = _ctx.slotProps) => [_createElementVNode("button", {
      onClick: _withModifiers($event => $options.date = _date, ["prevent"]),
      class: _normalizeClass({
        'font-bold text-black dark:text-white': _date == _ctx.$moment().format('YYYY-MM-DD'),
        'h-8 w-8 rounded-full bg-neutral-900 font-bold text-white': _date == $props.entry?.schedule?.date
      })
    }, _toDisplayString(_ctx.$moment(_date).format("DD")), 11, _hoisted_5)]),
    _: 1
  })) : _createCommentVNode("", true)]);
}