import "core-js/modules/es.array.push.js";
import { EditUtilities } from "../mixins/EditUtilities";
export default {
  mixins: [EditUtilities],
  data() {
    return {
      cursor: null,
      showLinkSearch: false,
      fields: [{
        title: "Name",
        value: "name"
      }, {
        title: "Status",
        value: "status"
      }, {
        title: "Created",
        value: ["date", "created"]
      }, {
        title: "Updated",
        value: ["date", "updated"]
      }, {
        title: "Completed at",
        value: "completed_at"
      }, {
        title: "Completed count",
        value: "completed_count"
      }, {
        title: "Date",
        value: ["schedule", "date"]
      }, {
        title: "Time",
        value: ["schedule", "time"]
      }, {
        title: "Priority",
        value: "priority"
      }, {
        title: "Procrastination",
        value: "procrastination"
      }]
    };
  },
  methods: {
    handleRowAdd() {
      this.value.push({
        field: null,
        dir: 1
      });
    }
  }
};