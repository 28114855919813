import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_TimerIcon = _resolveComponent("TimerIcon");
  const _component_SectionHeader = _resolveComponent("SectionHeader");
  const _component_TimeTrackingsWrapper = _resolveComponent("TimeTrackingsWrapper");
  const _component_PopoverHelper = _resolveComponent("PopoverHelper");
  return _openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.$style.timeTrackingDisplay)
  }, [_createVNode(_component_PopoverHelper, {
    teleport: true,
    placement: "bottom-start"
  }, {
    button: _withCtx(() => [_createElementVNode("div", {
      class: _normalizeClass(_ctx.$style.popoverButton)
    }, _toDisplayString(_ctx.$moment.utc($props.entry.time_trackings.filter(tt => _ctx.$moment.utc(tt.start_at).format("YYYY-MM-DD") == $options.date).map(tt => {
      return (tt.end_at ? _ctx.$moment.utc(tt.end_at).unix() : _ctx.$moment.utc(_ctx.current).unix()) - _ctx.$moment.utc(tt.start_at).unix();
    }).reduce((a, b) => a + b, 0) * 1000).format("H[h] m[m] s[s]", {
      stopTrim: "m"
    })), 3)]),
    default: _withCtx(() => [_createElementVNode("div", {
      class: _normalizeClass(_ctx.$style.popoverContent)
    }, [_createVNode(_component_SectionHeader, {
      url: "https://help.pensive.io/time-trackings"
    }, {
      title: _withCtx(() => [_createVNode(_component_TimerIcon, {
        size: "16"
      }), _cache[1] || (_cache[1] = _createTextVNode(" Time Trackings "))]),
      _: 1
    }), _createVNode(_component_TimeTrackingsWrapper, {
      modelValue: $options.time_trackings,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => $options.time_trackings = $event),
      entry: $props.entry,
      class: "min-w-64 overflow-y-auto"
    }, null, 8, ["modelValue", "entry"])], 2)]),
    _: 1
  })], 2);
}