import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue";
const _hoisted_1 = {
  class: "rounded-md bg-yellow-50 p-4 dark:bg-yellow-950"
};
const _hoisted_2 = {
  class: "flex"
};
const _hoisted_3 = {
  class: "flex-shrink-0"
};
const _hoisted_4 = {
  class: "ml-3"
};
const _hoisted_5 = {
  class: "mt-4"
};
const _hoisted_6 = {
  class: "-mx-2 -my-1.5 flex"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_TriangleAlertIcon = _resolveComponent("TriangleAlertIcon");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [_createVNode(_component_TriangleAlertIcon, {
    size: "20",
    class: "text-yellow-400 dark:text-yellow-600",
    "aria-hidden": "true"
  })]), _createElementVNode("div", _hoisted_4, [_cache[1] || (_cache[1] = _createElementVNode("h3", {
    class: "text-sm font-medium text-yellow-800 dark:text-yellow-500"
  }, " Email address unverified ", -1)), _cache[2] || (_cache[2] = _createElementVNode("div", {
    class: "mt-2 text-sm text-yellow-700"
  }, [_createElementVNode("p", null, "Please click on the link we've sent you.")], -1)), _createElementVNode("div", _hoisted_5, [_createElementVNode("div", _hoisted_6, [!$data.sent ? (_openBlock(), _createElementBlock("button", {
    key: 0,
    onClick: _cache[0] || (_cache[0] = (...args) => $options.resend && $options.resend(...args)),
    type: "button",
    class: "rounded-md bg-yellow-50 px-2 py-1.5 text-sm font-medium text-yellow-800 hover:bg-yellow-100 focus:outline-none focus:ring-2 focus:ring-yellow-600 focus:ring-offset-2 focus:ring-offset-yellow-50 dark:bg-yellow-900 dark:text-white"
  }, " Resend Email ")) : _createCommentVNode("", true)])])])])]);
}