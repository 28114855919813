import OnboardingComponent from "./helpers/OnboardingComponent.vue";
import { Touring } from "./mixins/Touring";
export default {
  mixins: [Touring],
  computed: {
    entries() {
      return this.$store.getters.entries.filter(e => e.deleted_at === null);
    }
  },
  components: {
    OnboardingComponent
  }
};