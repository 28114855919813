import "core-js/modules/es.array.push.js";
import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  class: "mx-auto w-full sm:max-w-xl md:max-w-2xl md:px-8 lg:max-w-3xl xl:max-w-5xl"
};
const _hoisted_2 = {
  class: "flex flex-col gap-4"
};
const _hoisted_3 = {
  class: "mt-6 border-t border-gray-100 dark:border-neutral-800"
};
const _hoisted_4 = {
  class: "divide-y divide-gray-100"
};
const _hoisted_5 = {
  class: "px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0"
};
const _hoisted_6 = {
  class: "mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0 dark:text-neutral-100"
};
const _hoisted_7 = {
  class: "divide-y divide-gray-100"
};
const _hoisted_8 = {
  class: "px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0"
};
const _hoisted_9 = {
  class: "mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0"
};
const _hoisted_10 = {
  class: "relative pt-1"
};
const _hoisted_11 = {
  class: "flex items-center justify-between"
};
const _hoisted_12 = {
  class: "text-sm font-medium text-gray-700 dark:text-neutral-100"
};
const _hoisted_13 = {
  class: "text-sm font-medium text-gray-500 dark:text-neutral-400"
};
const _hoisted_14 = {
  class: "flex h-2 rounded bg-neutral-200 dark:bg-neutral-800"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_ConstructionArea = _resolveComponent("ConstructionArea");
  const _component_SquarePenIcon = _resolveComponent("SquarePenIcon");
  const _component_ButtonComponent = _resolveComponent("ButtonComponent");
  const _component_PanelHelper = _resolveComponent("PanelHelper");
  const _component_StatusEditor = _resolveComponent("StatusEditor");
  return _openBlock(), _createElementBlock("div", null, [_createElementVNode("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createVNode(_component_ConstructionArea), _createVNode(_component_PanelHelper, null, {
    default: _withCtx(() => [_cache[5] || (_cache[5] = _createElementVNode("div", {
      class: "px-4 sm:px-0"
    }, [_createElementVNode("h3", {
      class: "text-base font-semibold leading-7 text-gray-900 dark:text-neutral-100"
    }, " Space Settings "), _createElementVNode("p", {
      class: "mt-1 max-w-2xl text-sm leading-6 text-gray-500 dark:text-neutral-100"
    })], -1)), _createElementVNode("div", _hoisted_3, [_createElementVNode("dl", _hoisted_4, [_createElementVNode("div", _hoisted_5, [_cache[3] || (_cache[3] = _createElementVNode("dt", {
      class: "text-sm font-medium leading-6 text-gray-900 dark:text-neutral-100"
    }, " Space Name ", -1)), _createElementVNode("dd", _hoisted_6, _toDisplayString($options.space.name), 1)])]), _createElementVNode("dl", _hoisted_7, [_createElementVNode("div", _hoisted_8, [_cache[4] || (_cache[4] = _createElementVNode("dt", {
      class: "text-sm font-medium leading-6 text-gray-900 dark:text-neutral-100"
    }, " Subscription ", -1)), _createElementVNode("dd", _hoisted_9, [_createVNode(_component_ButtonComponent, {
      color: "primary",
      onClick: _cache[0] || (_cache[0] = $event => _ctx.$router.push({
        name: 'subscription'
      }))
    }, {
      default: _withCtx(() => [_createTextVNode(_toDisplayString($options.space.active_subscription.name) + " ", 1), _createVNode(_component_SquarePenIcon, {
        size: "16"
      })]),
      _: 1
    })])])])])]),
    _: 1
  }), _createVNode(_component_PanelHelper, null, {
    default: _withCtx(() => [_cache[6] || (_cache[6] = _createElementVNode("div", {
      class: "px-4 sm:px-0"
    }, [_createElementVNode("h3", {
      class: "text-base font-semibold leading-7 text-gray-900 dark:text-neutral-100"
    }, " Space Statuses "), _createElementVNode("p", {
      class: "mt-1 max-w-2xl text-sm leading-6 text-gray-500"
    })], -1)), _createVNode(_component_StatusEditor, {
      modelValue: $data.statuses,
      "onUpdate:modelValue": [_cache[1] || (_cache[1] = $event => $data.statuses = $event), _cache[2] || (_cache[2] = statuses => {
        $options.space.statuses = statuses;
        _ctx.$store.dispatch('push', {
          event: 'space_update',
          params: {
            space: $options.space
          }
        });
      })]
    }, null, 8, ["modelValue"])]),
    _: 1
  }), $options.space.ai_enabled ? (_openBlock(), _createBlock(_component_PanelHelper, {
    key: 0
  }, {
    default: _withCtx(() => [_cache[7] || (_cache[7] = _createElementVNode("div", {
      class: "px-4 sm:px-0"
    }, [_createElementVNode("h3", {
      class: "text-base font-semibold leading-7 text-gray-900 dark:text-neutral-100"
    }, " AI Usage "), _createElementVNode("p", {
      class: "mt-1 max-w-2xl text-sm leading-6 text-gray-500"
    })], -1)), _createElementVNode("div", _hoisted_10, [_createElementVNode("div", _hoisted_11, [_createElementVNode("div", null, [_createElementVNode("span", _hoisted_12, " Requests this month: " + _toDisplayString($options.space.ai_requests_count_this_month) + " / " + _toDisplayString($options.space.ai_requests_quota), 1)]), _createElementVNode("div", _hoisted_13, _toDisplayString($options.space.ai_requests_quota), 1)]), _createElementVNode("div", _hoisted_14, [_createElementVNode("div", {
      class: "h-full rounded bg-neutral-800 dark:bg-neutral-200",
      style: _normalizeStyle({
        width: $options.space.ai_requests_count_this_month / $options.space.ai_requests_quota * 100 + '%'
      })
    }, null, 4)])])]),
    _: 1
  })) : _createCommentVNode("", true)])])]);
}