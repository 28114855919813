import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue";
const _hoisted_1 = {
  class: "flex grow flex-col"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_SwitchLabel = _resolveComponent("SwitchLabel");
  const _component_Switch = _resolveComponent("Switch");
  const _component_SwitchGroup = _resolveComponent("SwitchGroup");
  return _openBlock(), _createBlock(_component_SwitchGroup, {
    as: "div",
    class: "flex items-center justify-between gap-1.5"
  }, {
    default: _withCtx(() => [_createElementVNode("span", _hoisted_1, [_createVNode(_component_SwitchLabel, {
      as: "span",
      class: _normalizeClass(_ctx.$style.label),
      passive: ""
    }, {
      default: _withCtx(() => [_createTextVNode(_toDisplayString($props.label), 1)]),
      _: 1
    }, 8, ["class"])]), _createVNode(_component_Switch, {
      modelValue: $props.modelValue,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => _ctx.$emit('update:modelValue', !$props.modelValue)),
      class: _normalizeClass([$props.modelValue ? _ctx.$style.toggleEnabled : _ctx.$style.toggleDisabled, _ctx.$style.toggle])
    }, {
      default: _withCtx(() => [_createElementVNode("span", {
        "aria-hidden": "true",
        class: _normalizeClass([$props.modelValue ? 'translate-x-3.5' : 'translate-x-0.5', 'pointer-events-none inline-block h-3 w-3 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'])
      }, null, 2)]),
      _: 1
    }, 8, ["modelValue", "class"])]),
    _: 1
  });
}