import "core-js/modules/es.array.push.js";
import { Scheduling } from "../mixins/Scheduling";
import { Statusing } from "../mixins/Statusing";
import { Filtering } from "../mixins/Filtering";
import TextPicker from "@/components/builders/TextPicker.vue";
import LinksBuilder from "@/components/links/partials/LinksBuilder.vue";
import StatusBuilder from "@/components/builders/StatusBuilder.vue";
import OptionPicker from "@/components/builders/OptionPicker.vue";
import DatePickerBuilder from "@/components/builders/DatePickerBuilder.vue";
import TimePickerBuilder from "@/components/builders/TimePickerBuilder.vue";
export default {
  components: {
    TextPicker,
    LinksBuilder,
    StatusBuilder,
    OptionPicker,
    DatePickerBuilder,
    TimePickerBuilder
  },
  mixins: [Filtering, Scheduling, Statusing],
  props: {
    modelValue: Object,
    entry: Object
  },
  computed: {
    filters: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit("update:modelValue", value);
      }
    },
    statuses() {
      return this.getOwnAvailableStatuses(this.entry, true);
    }
  },
  methods: {
    handleRowAdd() {
      this.filters.push({
        key: null,
        op: null,
        val: []
      }), this.$emit("update:modelValue", this.filters);
    }
  }
};