import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createSlots as _createSlots } from "vue";
const _hoisted_1 = ["data-hour", "data-row"];
const _hoisted_2 = {
  class: "font-mono text-xs"
};
const _hoisted_3 = {
  class: "flex flex-col gap-y-2"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_OutputDisplay = _resolveComponent("OutputDisplay");
  const _component_PopoverHelper = _resolveComponent("PopoverHelper");
  const _component_TitleHelper = _resolveComponent("TitleHelper");
  return _openBlock(), _createElementBlock("div", {
    class: "grid justify-between gap-1",
    style: _normalizeStyle({
      gridTemplateColumns: 'repeat(' + $options.hours + ', minmax(0, 1fr))'
    })
  }, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.hours, hour => {
    return _openBlock(), _createElementBlock("div", {
      key: hour,
      class: "grid grid-rows-6 gap-1"
    }, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.rows, row => {
      return _openBlock(), _createBlock(_component_TitleHelper, {
        key: row,
        class: "relative aspect-square bg-neutral-100 dark:bg-neutral-900",
        placement: "top-center"
      }, _createSlots({
        default: _withCtx(() => [_createElementVNode("div", {
          ref_for: true,
          ref: "box",
          class: _normalizeClass(["relative aspect-square transform rounded-sm bg-neutral-700 text-xs dark:bg-neutral-400", {
            'animate-pulse': $data.animationDone && $options.isCurrentBlockAndHasActiveTimeTracking(hour, row)
          }]),
          style: _normalizeStyle([{
            "opacity": "0%"
          }, $data.animationDone ? {
            opacity: $options.getRowTimeTrackings(hour, row).map(tt => {
              const [start, end] = $options.getCutoffBoundariesOfTimeTracking(tt, hour, row);
              const duration = _ctx.$moment.duration(end.diff(start));
              return duration / 1000 / 60;
            }).reduce((a, b) => a + b, 0) / $options.minutesPerRow
          } : null]),
          "data-hour": hour,
          "data-row": row
        }, [$options.getRowTimeTrackings(hour, row).length && $data.isInitialized ? (_openBlock(), _createBlock(_component_PopoverHelper, {
          key: 0,
          teleport: true,
          class: "h-full w-full"
        }, {
          button: _withCtx(() => _cache[0] || (_cache[0] = [_createElementVNode("div", {
            class: "h-full w-full"
          }, null, -1)])),
          default: _withCtx(() => [_createVNode(_component_OutputDisplay, {
            modelValue: $options.getOutputForTimeSpan(_ctx.$moment($props.date).hour($options.minHour - 1 + hour).minute((row - 1) * 10).second(0).format('YYYY-MM-DD HH:mm:ss'), _ctx.$moment($props.date).hour($options.minHour - 1 + hour).minute((row - 1) * 10 + 9).second(59).format('YYYY-MM-DD HH:mm:ss')),
            display: {
              senses: false,
              name: true,
              status: false,
              links: false,
              description: false,
              schedule: false,
              output: false,
              input: false,
              columns: false,
              leftover: false,
              time_trackings: false,
              routine: false
            },
            editable: false,
            sorting: [{
              field: 'updated_at',
              dir: -1
            }],
            class: "max-h-96 w-96 overflow-y-auto p-5 text-sm"
          }, {
            "entry-top-right": _withCtx(({
              entry
            }) => [_createElementVNode("span", _hoisted_2, _toDisplayString($options.getDurationOfEntryInsideRow(entry, hour, row).format("mm:ss")), 1)]),
            _: 2
          }, 1032, ["modelValue"])]),
          _: 2
        }, 1024)) : _createCommentVNode("", true)], 14, _hoisted_1)]),
        _: 2
      }, [$data.isInitialized ? {
        name: "title",
        fn: _withCtx(() => [_createElementVNode("div", _hoisted_3, [_createElementVNode("span", null, _toDisplayString(_ctx.$moment($props.date).hour($options.minHour - 1 + hour).minute((row - 1) * $options.minutesPerRow).format("HH:mm")) + " - " + _toDisplayString(_ctx.$moment($props.date).hour($options.minHour - 1 + hour).minute((row - 1) * $options.minutesPerRow + $options.minutesPerRow).format("HH:mm")), 1), _createElementVNode("span", null, _toDisplayString(Math.round($options.getTrackedDurationInRow(hour, row) / 60 * 10) / 10) + " Minutes tracked ", 1)])]),
        key: "0"
      } : undefined]), 1024);
    }), 128))]);
  }), 128))], 4);
}