import moment from "moment";
import { Addon } from "../mixins/Addon";
// import { Rendering } from "../mixins/Rendering";
import { Statusing } from "../mixins/Statusing";
export default {
  provide() {
    // use function syntax so that we can access `this`
    return {
      position: "modal",
      renderType: "board"
    };
  },
  mixins: [Addon, /*Rendering,*/Statusing],
  props: {
    show: Boolean
  },
  watch: {
    "$route.params.id": {
      handler() {
        this.$emit("hide");
      }
    },
    show() {
      this.showContent = false;
      this.open = this.show;
      if (this.open) {
        this.$nextTick(() => {
          this.showContent = true;
        });
      }
    }
  },
  data() {
    return {
      window: window,
      open: this.show,
      moment: moment,
      showContent: false
    };
  }
};