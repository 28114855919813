const moment = require("moment");

export function filterItemsByFilter(filter) {
  return function (entry) {
    const key = filter["key"];

    if (key === null)
      return true;

    var field = resolveField(entry, key);
    const operation = filter["op"];

    if (
      /*field === false ||*/ field === null &&
      operation != "is not set" &&
      operation != "not any"
    ) {
      //console.log(field, operation, "out.")
      return false;
    }

    // There is the possibility that val is not set.
    var value = [];
    if (typeof filter["val"] != "object") {
      // The next lines checks for a given time object and will convert the time to local time.
      if (typeof key === "string" && key[0] == "schedule" && key[1] == "time") {
        filter["val"] = moment
          .utc(moment().format("YYYY-MM-DD") + " " + filter["val"])
          .local()
          .format("HH:mm:ss");
      }

      value[0] = resolveValue(filter["val"], operation);
    } else {
      value = [...(filter["val"] || [])];
      value.forEach((v, k) => {
        // The next lines checks for a given time object and will convert the time to local time.
        if (
          typeof key === "string" &&
          key[0] == "schedule" &&
          key[1] == "time"
        ) {
          v = moment
            .utc(moment().format("YYYY-MM-DD") + " " + v)
            .local()
            .format("HH:mm:ss");
        }

        value[k] = resolveValue(v, operation);
      });
    }

    if (typeof value == "object") {
      let result = false;
      switch (operation) {
        // NOT ANY - moved out of applyCondition due to its nature of completing every value-array element in one run, not separate.
        case "not any":
          if (typeof field == "string" && typeof value == "string" /* todo second part had to be added ... */) {
            return (
              value && field.toLowerCase().indexOf(value.toLowerCase()) === -1
            );
          } else {
            if (field === null) {
              field = [];
            }
            return value === null || value.length === 0
              ? field.length == 0
              : typeof field == "undefined" ||
              !value.filter((v) => field.includes(v)).length;
          }
        case "ra":

          if (Array.isArray(field) && field.every(Array.isArray)) {
            field.filter((f) => {
              result = result || applyCondition(f, operation, value);
            })
          } else {
            console.log("UNHANDLED RANGE OPERATION", field, operation, value)
          }
          break;
        default:
          if (value.length) {
            value.forEach((v) => {
              result = result || applyCondition(field, operation, v);
            });
          } else {
            result = result || applyCondition(field, operation, null);
          }
          break;
      }
      //console.log(result);
      return result;
    } else {
      return applyCondition(field, operation, value);
    }
  };
}

export function resolveField(entry, field) {
  var key = null;
  var attribute = null;
  if (field && typeof field == "object") {
    [key, attribute] = field;
  } else {
    key = field;
  }

  switch (key) {
    case "id":
      return entry.id;
    case "name":
      return entry.name;
    case "status":
      return entry.status_id ? [entry.status_id] : null;
    case "description":
      return entry.description?.content;
    case "anchor":
    case "anchors":
      if (attribute) {
        // Handle nested attributes like [anchor, placement]
        return entry.anchors?.map(anchor => anchor[attribute]);
      }
      // Return all anchor values if no specific attribute requested
      return entry.anchors;
    case "labels":
      return entry.labels;
    case "links":
      return entry.links?.map(link => link.id);
    case "backlinks":
      return entry.backlinks;
    case "date":
      switch (attribute) {
        case "created":
          if (entry.created_at) {
            return entry.created_at ? moment(entry.created_at).unix() : null;
          }
          return null;
        case "updated":
          if (entry.updated_at) {
            return entry.updated_at ? moment(entry.updated_at).unix() : null;
          }
          return null;
      }
      break;
    case "completed_at":
      return entry.completed_at && entry.completed_at
        ? moment
          .utc(moment(entry.completed_at).format("YYYY-MM-DD"))
          .unix()
        : null;
    case "schedule":
      switch (attribute) {
        case "date":
          if (entry.schedule && entry.schedule[attribute]) {
            return entry.schedule[attribute]
              ? moment.utc(entry.schedule[attribute] + " 00:00:00").unix()
              : null;
          }
          return null;
        case "time":
          if (entry.schedule && entry.schedule[attribute]) {
            return moment
              .utc(
                (entry.schedule?.date || moment().format("YYYY-MM-DD")) +
                ` ${entry.schedule[attribute]}`
              )
              .unix();
          }
          return null;
        case "recurrence":
          if (entry.schedule && entry.schedule[attribute]) {
            return entry.schedule[attribute]
              ? resolveValue(entry.schedule[attribute])
              : null;
          }
          return null;
        case "duration":
          if (entry.schedule && entry.schedule[attribute]) {
            return entry.schedule[attribute] / 60;
          }
          return null;
      }
      break;
    // case "completion":
    //   switch (attribute) {
    //     case "completed":
    //       return entry.completion?.completed;
    //     case "completed_at":
    //       return entry.completion && entry.completion.completed_at
    //         ? moment
    //           .utc(moment(entry.completion.completed_at).format("YYYY-MM-DD"))
    //           .unix()
    //         : null;
    //   }
    //   break;
    case "priority":
      return entry.priority?.level;
    case "procrastination":
      return entry.procrastination?.count;
    case "time_trackings":
      switch (attribute) {
        case "start_at":
          return entry.time_trackings?.map(tt => moment.utc(tt.start_at).format("YYYY-MM-DD HH:mm:ss"));
        case "end_at":
          return entry.time_trackings?.map(tt => moment.utc(tt.end_at).format("YYYY-MM-DD HH:mm:ss"));
        case "range":
          return entry.time_trackings?.map(tt => [tt.start_at, tt.end_at]);
        case "user":
          return entry.time_trackings?.map(tt => tt.user_id);
      }
      return entry.time_trackings
    default:
      return entry[key];
  }
  /*field = field.split(".").reduce(function (prev, curr) {
        return prev ? prev[curr] : null;
    }, entry || self);*/
}

export function applyCondition(field, op, value) {
  switch (op) {
    /*case "not any":
      if (typeof field == "string") {
        return value && field.toLowerCase().indexOf(value.toLowerCase()) === -1;
      } else {
        /*if (typeof field == "undefined") return false;
        console.log(
          "FIELD:",
          field,
          typeof field,
          "VALUE",
          value,
          typeof field == "undefined" || !field.includes(value)
        );
        return (
          (value === null ? field.length == 0 : typeof field == "undefined") ||
          !field.includes(value)
        );
      }*/

    // case "is set":
    //   console.log(field, op, value)
    //   return typeof field != "undefined" && field !== null;

    case "is set":
      if (typeof field != "undefined" && field !== null && typeof field.length != 'undefined') {
        /**
         * In case of the field being an array, we need to check if it is empty.
         * This happens in links for example
         */
        return field.length > 0;
      } else {
        /**
         * Otherwise we just check if the field is set.
         */
        return typeof field != "undefined" && field !== null;
      }
    // case "is not set":
    //   return typeof field == "undefined" && field !== null;
    case "is not set":
      if (typeof field != "undefined" && field !== null && typeof field.length != 'undefined') {
        /**
         * In case of the field being an array, we need to check if it is empty.
         * This happens in links for example
         */
        return field.length === 0;
      } else {
        /**
         * Otherwise we just check if the field is not set.
         */
        return typeof field == "undefined" || field === null;
      }
    case "gt":
      return typeof field != "undefined" && field !== null && field > value;
    case "gte":
      return typeof field != "undefined" && field !== null && field >= value;
    case "eq":
      // console.log(field, value)
      if (typeof field == "string") {
        return value && field.toLowerCase().indexOf(value.toLowerCase()) != -1;
      } else if (typeof field == "object") {
        return field.includes(value);
      } else {
        return typeof field != "undefined" && field == value;
      }
    case "neq":
      if (typeof field == "string") {
        return value && field.toLowerCase().indexOf(value.toLowerCase()) == -1;
      } else {
        return typeof field != "undefined" && field != value;
      }
    case "lte":
      return typeof field != "undefined" && field !== null && field <= value;
    case "lt":
      return typeof field != "undefined" && field !== null && field < value;
    case "any":
      if (value === null) {
        return field.length > 0;
      }
      if (typeof field == "string") {
        return value && field.toLowerCase().indexOf(value.toLowerCase()) !== -1;
      } else {
        if (field && typeof field.val != 'undefined')
          field = field.val

        // For links array, we're working with IDs
        if (Array.isArray(field)) {
          return typeof field != "undefined" && field.includes(value);
        }

        return false;
      }
    case "ra":
      return field[0] <= value[1] && (field[1] >= value[0] || field[1] == null);

  }
}

export function resolveValue(field, op) {


  var time = op == "lte" || op == "gt" ? " 23:59:59" : " 00:00:00";

  if (op == "eq" || op == "neq") {
    time == " 00:00:00";
  }

  if (field && typeof field == "object" && field.op) {
    switch (field.op) {
      case "now":
        return moment.utc().unix();
      case "nextXHours":
        return moment.utc().add(field.x, "hours").unix();
      case "nextXMinutes":
        return moment.utc().add(field.x, "minutes").unix();
      case "nextXSeconds":
        return moment.utc().add(field.x, "seconds").unix();
      case "lastXHours":
        return moment.utc().add(-field.x, "hours").unix();
      case "lastXMinutes":
        return moment.utc().add(-field.x, "minutes").unix();
      case "lastXSeconds":
        return moment.utc().add(-field.x, "seconds").unix();
      case "timeX":
        var timeSplitted = field.x.split(":");
        return moment.utc().set({ hour: timeSplitted[0], minute: timeSplitted[1], second: timeSplitted[2] }).unix();
      case "today":
        return moment.utc(moment().format("YYYY-MM-DD" + time)).unix();
      case "tomorrow":
        return moment
          .utc(moment().format("YYYY-MM-DD" + time))
          .add(1, "day")
          .unix();
      case "yesterday":
        return moment
          .utc(moment().format("YYYY-MM-DD" + time))
          .add(-1, "day")
          .unix();
      case "nextXDays":
        return moment
          .utc(moment().format("YYYY-MM-DD" + time))
          .add(field.x, "day")
          .unix();
      case "nextXWeeks":
        return moment
          .utc(moment().format("YYYY-MM-DD" + time))
          .add(field.x, "week")
          .unix();
      case "nextXMonths":
        return moment
          .utc(moment().format("YYYY-MM-DD" + time))
          .add(field.x, "month")
          .unix();
      case "nextXYears":
        return moment
          .utc(moment().format("YYYY-MM-DD" + time))
          .add(field.x, "year")
          .unix();
      case "lastXDays":
        return moment
          .utc(moment().format("YYYY-MM-DD" + time))
          .add(-field.x, "day")
          .unix();
      case "lastXWeeks":
        return moment
          .utc(moment().format("YYYY-MM-DD" + time))
          .add(-field.x, "week")
          .unix();
      case "lastXMonths":
        return moment
          .utc(moment().format("YYYY-MM-DD" + time))
          .add(-field.x, "month")
          .unix();
      case "lastXYears":
        return moment
          .utc(moment().format("YYYY-MM-DD" + time))
          .add(-field.x, "year")
          .unix();
      case "dateX":
        return moment.utc(field.x).unix();
      case "null":
        return "null";
    }
  }

  return field;
}
