import "core-js/modules/es.array.push.js";
import { renderSlot as _renderSlot, unref as _unref, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle } from "vue";
const _hoisted_1 = ["disabled"];
const _hoisted_2 = ["innerHTML"];
const _hoisted_3 = {
  key: 0,
  class: "inline-flex h-5 w-5 items-center justify-center rounded border border-neutral-400 bg-white font-semibold text-neutral-900 dark:border-neutral-600 dark:bg-black dark:text-neutral-200"
};
import { ref } from "vue";
import { useFloating, shift, flip, autoUpdate, offset } from "@floating-ui/vue";

// import { CircleHelp } from "lucide-vue-next";
const __default__ = {
  components: {
    // CircleHelp,
  },
  props: {
    disabled: Boolean,
    color: {
      type: String,
      default: "neutral"
    },
    colorWeight: {
      type: String,
      default: "500"
    },
    size: {
      type: String,
      default: "md"
    },
    variant: {
      type: String,
      default: "default"
    },
    active: Boolean,
    nested: Boolean,
    title: String,
    shortcode: String
  },
  mounted() {
    if (this.shortcode) window.addEventListener("keydown", this.onShortcode);
  },
  beforeUnmount() {
    if (this.shortcode) window.removeEventListener("keydown", this.onShortcode);
  },
  methods: {
    onShortcode(event) {
      const element = this.$el;
      const isVisible = !!(element.offsetWidth || element.offsetHeight || element.getClientRects().length);
      const isNotObscured = !this.isElementObscured(element);
      if (event.target.tagName !== "INPUT" && event.target.isContentEditable !== true && isVisible && isNotObscured && !this.disabled && !event.repeat) {
        if (event.key === this.shortcode) {
          event.preventDefault();
          event.stopPropagation();
          this.$el.click();
        }
      }
    },
    isElementObscured(element) {
      const rect = element.getBoundingClientRect();
      const centerX = rect.left + rect.width / 2;
      const centerY = rect.top + rect.height / 2;
      const topElement = document.elementFromPoint(centerX, centerY);
      return topElement !== element && !element.contains(topElement);
    }
  },
  data() {
    return {
      navigator: navigator,
      help: false
    };
  },
  computed: {
    colorClasses() {
      const classes = [];
      if (this.color == "primary") {
        classes.push("bg-neutral-900 text-white dark:bg-neutral-100 dark:text-black ");
        if (!this.disabled) classes.push("hover:bg-neutral-950 dark:hover:bg-neutral-50");
      } else if (this.color == "secondary") {
        classes.push("bg-white text-black dark:bg-black dark:text-neutral-200 ring-1 ring-neutral-200 dark:ring-neutral-700");
        if (!this.disabled) classes.push("hover:bg-neutral-50 dark:hover:bg-neutral-950");
      } else if (this.color == "white") {
        classes.push("bg-white text-black dark:bg-black dark:text-white ");
        if (!this.disabled) classes.push("hover:bg-neutral-50 hover:ring-1 hover:ring-neutral-200 dark:hover:bg-neutral-950");
      } else if (this.color == "black") {
        classes.push("bg-black text-white dark:bg-white dark:text-black");
        if (!this.disabled) classes.push("hover:bg-neutral-950 hover:ring-1 hover:ring-neutral-200 dark:hover:bg-neutral-50");
      } else {
        classes.push(`bg-${this.color}-${this.colorWeight}`);
        classes.push(`text-${this.colorWeight >= 500 ? "white" : "black"} dark:text-${this.color}-100`);
        if (!this.disabled) classes.push(`hover:bg-${this.color}-${this.colorWeight >= 500 ? "600" : "400"} hover:ring-1 hover:ring-${this.color}-200 dark:hover:bg-${this.color}-${this.colorWeight >= 500 ? "900" : "700"}`);
      }
      return classes.join(" ");
    },
    classes() {
      switch (this.variant) {
        case "menu":
          switch (this.size) {
            // case "xs":
            //   return `inline-flex items-center gap-x-1 text-xs font-semibold text-${this.color}-500 hover:text-${this.color}-700 _dark:bg-neutral-950 dark:text-neutral-300 dark:hover:text-neutral-200`;
            case "sm":
              return `inline-flex items-center gap-x-2 px-2 py-1 text-sm text-${this.color}-600 hover:text-${this.color}-800 _dark:bg-neutral-950 dark:text-neutral-300 dark:hover:text-neutral-200 ${this.active ? `bg-${this.color == "white" ? "neutral" : this.color}-100 dark:bg-${this.color == "white" ? "neutral" : this.color}-900` : null}`;
            case "md":
            default:
              return `inline-flex items-center gap-x-2 px-2 py-1 text-md text-${this.color}-600 hover:text-${this.color}-800 _dark:bg-neutral-950 dark:text-neutral-300 dark:hover:text-neutral-200 ${this.active ? `bg-${this.color == "white" ? "neutral" : this.color}-100 dark:bg-${this.color == "white" ? "neutral" : this.color}-900` : null}`;
          }
        case "minimal":
          switch (this.size) {
            case "xs":
              return `inline-flex items-center gap-x-1 text-xs font-semibold text-${this.color}-500 hover:text-${this.color}-700 _dark:bg-neutral-950 dark:text-neutral-300 dark:hover:text-neutral-200`;
            case "sm":
              return `inline-flex items-center gap-x-1 text-sm font-semibold text-${this.color}-500 hover:text-${this.color}-700 _dark:bg-neutral-950 dark:text-neutral-300 dark:hover:text-neutral-200`;
            case "md":
            default:
              return `inline-flex items-center gap-x-2 text-md font-semibold text-${this.color}-500 hover:text-${this.color}-700 _dark:bg-neutral-950 dark:text-neutral-300 dark:hover:text-neutral-200`;
          }
        case "round":
          switch (this.size) {
            case "xs":
              return `${this.colorClasses} inline-flex items-center gap-x-2
                rounded-full p-0.5
                text-white shadow-sm hover:bg-${this.color}-500
                focus-visible:outline focus-visible:outline-2
                focus-visible:outline-offset-2
                focus-visible:outline-${this.color}-900`;
            case "sm":
              return `${this.colorClasses} inline-flex items-center gap-x-2
                rounded-full p-1
                shadow-sm hover:bg-${this.color}-500
                focus-visible:outline focus-visible:outline-2
                focus-visible:outline-offset-2
                focus-visible:outline-${this.color}-900`;
            case "lg":
              return `${this.colorClasses} inline-flex items-center gap-x-2
              rounded-full p-2
              shadow-sm hover:bg-${this.color}-500
              focus-visible:outline focus-visible:outline-2
              focus-visible:outline-offset-2
              focus-visible:outline-${this.color}-900`;
            case "xl":
              return `${this.colorClasses} inline-flex items-center gap-x-2
              rounded-full p-3
              shadow-sm hover:bg-${this.color}-500
              focus-visible:outline focus-visible:outline-2
              focus-visible:outline-offset-2
              focus-visible:outline-${this.color}-900`;
            case "md":
            default:
              return `${this.colorClasses} inline-flex items-center gap-x-2
              rounded-full  p-1.5
               shadow-sm
              focus-visible:outline focus-visible:outline-2
              focus-visible:outline-offset-2
              focus-visible:outline-${this.color}-600`;
          }
        case "default":
          switch (this.size) {
            case "xs":
              return `${this.colorClasses} inline-flex items-center gap-x-2 self-start rounded px-2 py-1 text-xs font-semibold 
               focus-visible:outline focus-visible:outline-2
                focus-visible:outline-offset-2
                focus-visible:outline-${this.color}-600`;
            case "sm":
              return `${this.colorClasses} inline-flex items-center gap-x-2 self-start rounded px-2 py-1 text-sm font-semibold
               focus-visible:outline focus-visible:outline-2
                focus-visible:outline-offset-2
                focus-visible:outline-${this.color}-600`;
            case "lg":
              return `${this.colorClasses} inline-flex items-center gap-x-2 self-start rounded-md px-3 py-2 text-sm font-semibold shadow-sm
              focus-visible:outline focus-visible:outline-2
                focus-visible:outline-offset-2
                focus-visible:outline-${this.color}-600`;
            case "md":
            default:
              return `${this.colorClasses} inline-flex items-center gap-x-2 self-start rounded-md px-2.5 py-1.5 text-sm font-semibold  shadow-sm
               focus-visible:outline focus-visible:outline-2
                focus-visible:outline-offset-2
                focus-visible:outline-${this.color}-600`;
          }
      }
      return "";
    }
  }
};
export default /*@__PURE__*/Object.assign(__default__, {
  __name: 'ButtonComponent',
  setup(__props) {
    const reference = ref(null);
    const floating = ref(null);
    const {
      floatingStyles
    } = useFloating(reference, floating, {
      placement: "bottom-center",
      whileElementsMounted: autoUpdate,
      middleware: [offset(2), flip(), shift({
        crossAxis: true
      })]
    });
    return (_ctx, _cache) => {
      return _openBlock(), _createElementBlock("button", {
        ref_key: "reference",
        ref: reference,
        type: "button",
        disabled: __props.disabled,
        class: _normalizeClass([_ctx.classes + (__props.nested ? ' group/nested' : ' group/button'), "relative align-baseline transition-colors duration-200 ease-in-out focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-neutral-900 focus-visible:ring-offset-1 dark:focus-visible:ring-neutral-100"]),
        "data-hide-from-share": ""
      }, [_renderSlot(_ctx.$slots, "default"), _renderSlot(_ctx.$slots, "title", {}, () => [__props.title && !_ctx.navigator.userAgent.includes('Mobile') ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        ref_key: "floating",
        ref: floating,
        style: _normalizeStyle(_unref(floatingStyles)),
        class: _normalizeClass([__props.nested ? 'group-hover/nested:opacity-100 group-hover/nested:transition-opacity group-hover/nested:delay-300' : 'group-hover/button:opacity-100 group-hover/button:transition-opacity group-hover/button:delay-300', "pointer-events-none z-10 mx-auto flex w-max translate-y-full items-center gap-2 text-ellipsis whitespace-nowrap rounded-md bg-white px-2 py-1 text-left text-xs text-neutral-700 opacity-0 shadow-md dark:bg-black dark:text-neutral-300 dark:ring-1 dark:ring-neutral-700 dark:drop-shadow-none"])
      }, [_createElementVNode("span", {
        innerHTML: __props.title
      }, null, 8, _hoisted_2), __props.shortcode ? (_openBlock(), _createElementBlock("kbd", _hoisted_3, _toDisplayString(__props.shortcode), 1)) : _createCommentVNode("", true)], 6)) : _createCommentVNode("", true)])], 10, _hoisted_1);
    };
  }
});