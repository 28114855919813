export default {
  name: "SpacePage",
  data() {
    return {
      statuses: this.$store.getters.space?.statuses
    };
  },
  watch: {
    space: {
      handler() {
        this.statuses = this.$store.getters.space.statuses;
      },
      deep: true
    }
  },
  computed: {
    space() {
      return this.$store.getters.space;
    }
  }
};