import { Touring } from "../mixins/Touring";
export default {
  mixins: [Touring],
  props: {
    autoStart: {
      type: Boolean,
      default: false
    },
    hook: String,
    variant: {
      type: String,
      default: "round"
    },
    size: {
      type: String,
      default: "md"
    },
    showSettings: Boolean
  },
  data() {
    return {
      active: false
    };
  },
  mounted() {
    if (this.autoStart && this.nextTour && !this.$Cypress) {
      setTimeout(() => {
        this.active = true;
        this.startTour(this.nextTour);
      }, 2500);
    }
  },
  computed: {
    nextTour() {
      return this.tours?.find(t => t.hook == this.hook);
    }
  },
  methods: {
    onClick() {
      this.active = true;
      this.startTour(this.nextTour);
    }
  }
};