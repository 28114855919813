import "core-js/modules/es.array.push.js";
import { Lazying } from "./mixins/Lazying";
import { Touring } from "./mixins/Touring";
import throttle from "lodash.throttle";
import PullToRefresh from "pulltorefreshjs";
export default {
  provide() {
    return {
      observeIntersection: () => document.getElementById("main")
    };
  },
  mixins: [Lazying, Touring],
  created() {
    this.lastReload = this.$moment();
    this.detectFocusOut();
    if (!this.user && this.entries.length) {
      this.$store.dispatch("set", {
        key: "entries",
        val: []
      });
    }
  },
  data() {
    return {
      lastReload: null,
      showNavbar: true,
      scrollY: 0,
      windowWidth: window.innerWidth,
      leftSidebarOpen: window.innerWidth >= 768 ? this.$store.getters.user ? this.$store.getters.user?.settings?.display?.left_sidebar : false : false,
      rightSidebarOpen: window.innerWidth >= 768 ? this.$store.getters.user ? this.$store.getters.user?.settings?.display?.right_sidebar : false : false,
      showCommandPalette: false,
      time_trackings: [],
      // OBSERVERS
      resizeObserverHeader: null,
      resizeObserverFooter: null,
      cursorIsOnLeftEdge: false,
      cursorIsOnRightEdge: false,
      showLeftIndicator: false,
      showRightIndicator: false,
      cursorTimeout: null,
      showLeftBox: false,
      showRightBox: false,
      leftSidebarWidth: 288,
      // Default width (72 * 4)
      rightSidebarWidth: 288,
      isResizingLeft: false,
      isResizingRight: false
    };
  },
  mounted() {
    // this.delayedShowSidebar = this.showSidebar;
    if (this.$enableAnalytics) {
      window._paq.push(["setUserId", this.user?.email]);
      if (this.$route && this.$route.href) {
        window._paq.push(["setDocumentTitle", this.getDocumentTitle()]);
        window._paq.push(["setCustomUrl", this.$route.href]);
      }
      window._paq.push(["trackPageView"]);
    }
    if (this.token) {
      this.$store.dispatch("retrieveUser");
      this.$store.dispatch("retrieveTours");
    }
    window.addEventListener("resize", this.onWindowResize);
    window.addEventListener("mousemove", this.onMouseMove);
    document.addEventListener("mouseleave", this.onMouseLeave);
    window.addEventListener("touchmove", this.onTouchMove, {
      passive: true
    });
    window.addEventListener("keydown", this.handleKeyDown);
    window.addEventListener("blur", this.onWindowBlur);
    document.addEventListener("visibilitychange", this.visibilityChange);
    this.debouncedOnScroll = throttle(this.onScroll, 200);
    this.$refs.main.addEventListener("scroll", this.debouncedOnScroll);
    this.$onIdle(() => {
      this.$store.dispatch("initialized", true);
    });
    this.initPTR();
    this.versionCheck();
    window.addEventListener("mousemove", this.handleResize);
    window.addEventListener("mouseup", this.stopResizing);
  },
  beforeUnmount() {
    if (this.resizeObserverHeader) this.resizeObserverHeader.disconnect();
    if (this.resizeObserverFooter) this.resizeObserverFooter.disconnect();
    window.removeEventListener("focus", this.onWindowFocusChange);
    window.removeEventListener("blur", this.onWindowFocusChange);
    window.removeEventListener("pageshow", this.onWindowFocusChange);
    window.removeEventListener("pagehide", this.onWindowFocusChange);
    window.removeEventListener("resize", this.onWindowResize);
    window.removeEventListener("mousemove", this.onMouseMove);
    document.removeEventListener("mouseleave", this.onMouseLeave);
    window.removeEventListener("blur", this.onWindowBlur);
    window.removeEventListener("touchmove", this.onTouchMove, {
      passive: true
    });
    window.removeEventListener("keydown", this.handleKeyDown);
    document.removeEventListener("visibilitychange", this.visibilityChange);
    this.$refs.main.removeEventListener("scroll", this.debouncedOnScroll);
    this.destroyPTR();
    window.removeEventListener("mousemove", this.handleResize);
    window.removeEventListener("mouseup", this.stopResizing);
  },
  watch: {
    // showSidebar: function (n) {
    //   // this.delayedShowSidebar = n;
    //   // if (n)
    //   //   setTimeout(() => {
    //   //     this.delayedShowSidebar = n;
    //   //   }, 200);
    //   // else this.delayedShowSidebar = n;
    // },
    // entry: function (n, o) {
    //   // console.log(this.entry.name);
    //   // // add entry color to body class
    //   // document.body.classList.remove("from-black");
    //   // document.body.classList.remove("from-white");
    //   // document.body.classList.remove("from-" + o.color + "-50");
    //   // document.body.classList.add("from-" + n.color + "-50");
    //   // remove body class
    // },
    token: function () {
      if (this.token) {
        this.$store.dispatch("retrieveUser");
        this.$store.dispatch("retrieveTours");
      }
    },
    $route: function (to) {
      if (this.$enableAnalytics && to && to.href) {
        window._paq.push(["setDocumentTitle", this.getDocumentTitle()]);
        window._paq.push(["setCustomUrl", to.href]);
        window._paq.push(["trackPageView"]);
      }

      // Only handle left sidebar on mobile
      if (window.innerWidth < 768) {
        this.leftSidebarOpen = false;
        this.rightSidebarOpen = false;
      }

      // Reset selected entries
      if (this.$store.getters.selected.length) {
        this.$store.dispatch("selected", null);
      }
    },
    user: {
      handler(newUser) {
        if (newUser) {
          if (this.$enableAnalytics) {
            window._paq.push(["setUserId", newUser?.email]);
          }
          this.leftSidebarOpen = window.innerWidth >= 768 ? newUser?.settings?.display?.left_sidebar : false;
          this.rightSidebarOpen = window.innerWidth >= 768 ? newUser?.settings?.display?.right_sidebar : false;

          // Set sidebar widths from user settings if they exist
          this.leftSidebarWidth = newUser?.settings?.display?.left_sidebar_width || 288;
          this.rightSidebarWidth = newUser?.settings?.display?.right_sidebar_width || 288;
        }
      },
      immediate: true
    },
    "$store.getters.dragged": {
      handler: function (n, o) {
        if (n.length > 0) {
          this.destroyPTR();
        } else {
          this.initPTR();
        }
        o.forEach(entry => {
          document.querySelectorAll(`[data-entry-id="${entry.id}"] [data-draggable-placeholder]`).forEach(el => el.style.setProperty("display", "none"));
        });
        n.forEach(entry => {
          document.querySelectorAll(`[data-entry-id="${entry.id}"] [data-draggable-placeholder]`).forEach(el => el.style.setProperty("display", "block"));
        });
      },
      deep: true
    },
    "$store.getters.timestamp": function () {
      this.$onIdle(() => {
        this.versionCheck();
      });
    }
  },
  computed: {
    showSidebar() {
      return this.$route.meta.showSidebar || this.$Cypress;
    },
    showFooter() {
      return this.$route.meta.showSidebar || this.$Cypress;
    },
    focus() {
      return this.$store.getters.focus;
    },
    token() {
      return this.$store.getters.token;
    },
    user() {
      return this.$store.getters.user;
    },
    entries() {
      return this.$store.getters.entries.filter(e => e.deleted_at === null);
    },
    entry() {
      return this.entries.find(e => e.id == this.$route.params.id);
    },
    entry2() {
      return this.entries.find(e => e.id == this.$route.params.id2);
    },
    space() {
      return this.$store.getters.space;
    },
    anchors() {
      return this.entries?.filter(e => e.anchors.find(a => a.placement == "left-sidebar")).map(e => e.anchors.find(a => a.placement == "left-sidebar")).sort((a, b) => {
        if (a.position > b.position) return 1;
        if (a.position < b.position) return -1;
        return 0;
      });
    },
    isDesktop() {
      return this.windowWidth >= 768;
    }
  },
  methods: {
    handleSwipe(direction, event) {
      if (event.type == "touchend") {
        switch (direction) {
          case "right":
            this.leftSidebarOpen = true;
            break;
          case "left":
            this.rightSidebarOpen = true;
            break;
        }
      }
    },
    versionCheck() {
      this.$store.dispatch("versionCheck").then(() => {
        // if (this.$store.getters.version != this.$version) {
        //   this.$store.dispatch("destroyData");
        //   // this.$router.push({ name: "logout" });
        //   // window.location.reload();
        // }
      });
    },
    // animationEnterApplicationHeader(el, done) {
    //   this.$anime({
    //     targets: el,
    //     translateY: [-100, 0],
    //     opacity: [0, 1],
    //     delay: 1000,
    //     complete: done,
    //   });
    // },
    // animationEnterApplicationFooter(el, done) {
    //   this.$anime({
    //     targets: el,
    //     translateY: [100, 0],
    //     opacity: [0, 1],
    //     delay: 1500,
    //     complete: done,
    //   });
    // },
    // animationEnterApplicationSidebar(el, done) {
    //   this.$anime({
    //     targets: el,
    //     translateX: [-100, 0],
    //     opacity: [0, 1],
    //     delay: 250,
    //     complete: done,
    //   });
    // },
    onMouseMove(e) {
      if (!navigator.userAgent.includes("Mobile") && !this.$Cypress) {
        const leftSidebar = this.$refs["left-sidebar-wrapper"];
        const rightSidebar = this.$refs["right-sidebar-wrapper"];
        const header = this.$refs.header?.$el;
        const footer = this.$refs.footer;

        // Check if cursor is over header or footer
        const isOverHeader = header && header.getBoundingClientRect().bottom > e.clientY;
        const isOverFooter = footer && footer.$el.getBoundingClientRect().top < e.clientY;
        if (isOverHeader || isOverFooter) {
          return;
        }

        // Handle left sidebar
        if (!this.leftSidebarOpen) {
          const isNearLeftEdge = e.clientX < 50;
          const isOverLeftSidebar = leftSidebar && e.clientX >= leftSidebar.getBoundingClientRect().left && e.clientX <= leftSidebar.getBoundingClientRect().right;
          if (isNearLeftEdge || isOverLeftSidebar) {
            this.cursorIsOnLeftEdge = true;
            if (this.cursorTimeout) {
              clearTimeout(this.cursorTimeout);
              this.cursorTimeout = null;
            }
          } else if (this.cursorTimeout === null && this.cursorIsOnLeftEdge) {
            this.cursorTimeout = setTimeout(() => {
              this.cursorIsOnLeftEdge = false;
              this.cursorTimeout = null;
            }, 500);
          }
        }

        // Handle right sidebar
        if (!this.rightSidebarOpen && !this.cursorTimeout) {
          const isNearRightEdge = e.clientX > window.innerWidth - 50;
          const isOverRightSidebar = rightSidebar && e.clientX >= rightSidebar.getBoundingClientRect().left && e.clientX <= rightSidebar.getBoundingClientRect().right;
          if (isNearRightEdge || isOverRightSidebar) {
            this.cursorIsOnRightEdge = true;
            if (this.cursorTimeout) {
              clearTimeout(this.cursorTimeout);
              this.cursorTimeout = null;
            }
          } else if (this.cursorTimeout === null && this.cursorIsOnRightEdge) {
            this.cursorTimeout = setTimeout(() => {
              this.cursorIsOnRightEdge = false;
              this.cursorTimeout = null;
            }, 500);
          }
        }
      }
    },
    /**
     * Close the sidebar if the user moves the touch event to the right
     */
    onTouchMove(e) {
      if (e.touches && e.touches.length && (this.leftSidebarOpen || this.rightSidebarOpen) && window.innerWidth < 768 // allow tablets
      ) {
        if (this.leftSidebarOpen && e.touches[0].clientX > this.$refs["left-sidebar-wrapper"].offsetWidth) {
          this.leftSidebarOpen = false;
        }
        if (this.rightSidebarOpen && e.touches[0].clientX < window.innerWidth - this.$refs["right-sidebar-wrapper"].offsetWidth) {
          this.rightSidebarOpen = false;
        }
      }
    },
    visibilityChange() {
      if (document.visibilityState === "visible") {
        this.$store.dispatch("timestamp");
      }
    },
    initResizeObserverHeader() {
      this.resizeObserverHeader = new ResizeObserver(() => {
        if (this.$refs.main && this.$refs.header?.$el) {
          this.$refs.main.style.paddingTop = "calc(" + (this.$refs.header?.$el !== null ? this.$refs.header?.$el.offsetHeight : 0) + "px + env(safe-area-inset-top, 0px))";
        }
      });
      this.resizeObserverHeader.observe(this.$refs.header?.$el);
    },
    initResizeObserverFooter() {
      this.resizeObserverFooter = new ResizeObserver(() => {
        if (this.$refs.main && this.$refs.footer) {
          this.$refs.main.style.paddingBottom = "calc(" + this.$refs.footer?.$el.offsetHeight + "px + env(safe-area-inset-bottom, 0px)";
        }
      });
      this.resizeObserverFooter.observe(this.$refs.footer.$el);
    },
    getDocumentTitle() {
      return this.entry ? "Entry: " + this.entry.name + (this.entry2 ? " / " + this.entry2.name : "") : this.$route.meta.title ? this.$route.meta.title : this.$route.name ? this.$route.name.charAt(0).toUpperCase() + this.$route.name.slice(1) : "Pensive";
    },
    onWindowFocusChange(e) {
      let inView = false;
      if ({
        focus: 1,
        pageshow: 1
      }[e.type]) {
        if (inView) return;
        inView = true;
      } else if (inView) {
        inView = false;
      }

      // Reset cursor edge states and close sidebars when leaving window
      if (!inView) {
        this.cursorIsOnLeftEdge = false;
        this.cursorIsOnRightEdge = false;
        if (window.innerWidth < 768) {
          this.leftSidebarOpen = false;
          this.rightSidebarOpen = false;
        }
      }
      if (inView) {
        if (this.lastReload.format("YYYY-MM-DD") != this.$moment().format("YYYY-MM-DD")) {
          location.reload();
        } else {
          this.$store.dispatch("timestamp");
        }
      }
    },
    detectFocusOut() {
      window.addEventListener("focus", this.onWindowFocusChange);
      window.addEventListener("blur", this.onWindowFocusChange);
      window.addEventListener("pageshow", this.onWindowFocusChange);
      window.addEventListener("pagehide", this.onWindowFocusChange);
    },
    onWindowResize() {
      this.windowWidth = window.innerWidth;
      this.handleSidebarOpen();
    },
    handleSidebarOpen() {
      if (window.innerWidth < 768) {
        this.leftSidebarOpen = false;
        this.rightSidebarOpen = false;
      } else if (this.user) {
        this.leftSidebarOpen = this.user?.settings?.display?.left_sidebar;
        this.rightSidebarOpen = this.user?.settings?.display?.right_sidebar;
      }
    },
    onScroll() {
      this.scrollY = this.$refs.main.scrollTop;
    },
    handleKeyDown(event) {
      // console.log(event);
      if (event.target.tagName !== "INPUT" && event.target.isContentEditable !== true) {
        if (event.metaKey && event.keyCode === 219) {
          window.history.back();
        }
        if (event.metaKey && event.keyCode === 221) {
          window.history.forward();
        }
        if (!this.$route.params.id2) {
          // if (event.keyCode === 77 && !event.repeat && !event.metaKey) {
          //   if (this.$windowSize.width < 768) {
          //     this.leftSidebarOpen = !this.leftSidebarOpen;
          //   } else {
          //     this.leftSidebarOpen = !this.leftSidebarOpen;
          //     this.user.settings = {
          //       ...this.user.settings,
          //       display: {
          //         ...this.user.settings?.display,
          //         left_sidebar: this.leftSidebarOpen,
          //         right_sidebar: this.rightSidebarOpen,
          //       },
          //     };
          //     this.$store.dispatch("push", {
          //       event: "user_update",
          //       params: { user: this.user },
          //       user: this.user,
          //     });
          //   }
          // }
          // if (event.keyCode === 70 && !event.repeat && !event.metaKey) {
          //   this.$store.dispatch(
          //     "focus",
          //     this.$store.getters.focus ? null : this.entry,
          //   );
          // }
          if (event.key === "h" && !event.repeat && !event.metaKey) {
            this.$router.push({
              name: "entry",
              params: {
                id: this.anchors[0].id
              }
            });
          }
          if (event.metaKey && event.keyCode === 75) {
            this.showCommandPalette = !this.showCommandPalette;
          }
        }
      }
    },
    initPTR() {
      if (this.$Cypress) return;
      PullToRefresh.init({
        mainElement: "#main",
        triggerElement: "#main",
        resistanceFunction: t => Math.min(1, t / 3.5),
        distThreshold: 80,
        distReload: 80,
        distIgnore: 20,
        distMax: 160,
        onRefresh() {
          window.location.reload();
        },
        shouldPullToRefresh() {
          return document.querySelector("#main").scrollTop === 0;
        }
      });
    },
    destroyPTR() {
      if (this.$Cypress) return;
      PullToRefresh.destroyAll();
    },
    onWindowBlur() {
      this.cursorIsOnLeftEdge = false;
      this.cursorIsOnRightEdge = false;
      if (window.innerWidth < 768) {
        this.leftSidebarOpen = false;
        this.rightSidebarOpen = false;
      }
    },
    onMouseLeave(e) {
      // Only trigger if the mouse actually leaves the document
      if (e.clientY <= 0 || e.clientY >= window.innerHeight || e.clientX <= 0 || e.clientX >= window.innerWidth) {
        this.cursorIsOnLeftEdge = false;
        this.cursorIsOnRightEdge = false;
        this.showLeftIndicator = false;
        this.showRightIndicator = false;
      }
    },
    parseVersion(version) {
      // Match pattern like "0.13.2-RC5" or "1.2.3" or "1.2.3-RC1"
      const match = version.match(/^(\d+)\.(\d+)\.(\d+)(?:-RC(\d+))?$/i);
      if (!match) {
        throw new Error(`Invalid version format: ${version}`);
      }
      const [, major, minor, patch, rc] = match;

      // Pad each number to 2 digits
      return major.padStart(2, "0") + minor.padStart(2, "0") + patch.padStart(2, "0") + (rc ? rc.padStart(2, "0") : "00");
    },
    resetApp() {
      this.$router.push({
        name: "logout"
      });
      window.location.reload();
    },
    startResizingLeft(e) {
      this.isResizingLeft = true;
      e.preventDefault();
    },
    startResizingRight(e) {
      this.isResizingRight = true;
      e.preventDefault();
    },
    handleResize(e) {
      if (this.isResizingLeft) {
        const newWidth = e.clientX;
        // Limit width between 200px and 500px
        this.leftSidebarWidth = Math.min(Math.max(newWidth, 200), 500);
      } else if (this.isResizingRight) {
        const newWidth = window.innerWidth - e.clientX;
        // Limit width between 200px and 500px
        this.rightSidebarWidth = Math.min(Math.max(newWidth, 200), 500);
      }
    },
    stopResizing() {
      if (this.isResizingLeft || this.isResizingRight) {
        // Save the new widths to user settings
        this.user.settings = {
          ...this.user.settings,
          display: {
            ...this.user.settings?.display,
            left_sidebar: this.leftSidebarOpen,
            right_sidebar: this.rightSidebarOpen,
            left_sidebar_width: this.leftSidebarWidth,
            right_sidebar_width: this.rightSidebarWidth
          }
        };
        this.$store.dispatch("push", {
          event: "user_update",
          params: {
            user: this.user
          },
          user: this.user
        });
      }
      this.isResizingLeft = false;
      this.isResizingRight = false;
    }
  }
};