import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createVNode as _createVNode, createElementBlock as _createElementBlock, withModifiers as _withModifiers } from "vue";
const _hoisted_1 = {
  class: "flex items-start gap-x-2"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_SparklesIcon = _resolveComponent("SparklesIcon");
  const _component_TextIcon = _resolveComponent("TextIcon");
  const _component_TextEditor = _resolveComponent("TextEditor");
  return _openBlock(), _createElementBlock("section", _hoisted_1, [$options.description ? (_openBlock(), _createElementBlock("div", {
    key: 0,
    class: "flex w-full gap-x-1",
    onClick: _cache[2] || (_cache[2] = e => {
      $options.editable ? e.stopPropagation() : null;
    })
  }, [$options.description.source == 'ai' ? (_openBlock(), _createBlock(_component_SparklesIcon, {
    key: 0,
    size: "18",
    "stroke-width": "1.25",
    class: "ps_description_display_icon mt-0.5 dark:text-neutral-500",
    title: 'AI generated description'
  })) : (_openBlock(), _createBlock(_component_TextIcon, {
    key: 1,
    size: "18",
    "stroke-width": "1.25",
    class: "ps_description_display_icon mt-0.5 dark:text-neutral-500"
  })), _createVNode(_component_TextEditor, {
    id: "description_editor",
    modelValue: $options.description.content,
    "onUpdate:modelValue": [_cache[0] || (_cache[0] = $event => $options.description.content = $event), _cache[1] || (_cache[1] = html => {
      $options.description = {
        ...$options.description,
        ...{
          content: html
        }
      };
    })],
    ref: "description_editor",
    editable: $options.editable,
    class: _normalizeClass({
      [`${_ctx.$style.textEditor}`]: true,
      [`${_ctx.$style.editable}`]: $options.editable
      // 'line-clamp-2': !editable && !['bound'].includes(position),
      // 'line-clamp-4': !editable && ['bound'].includes(position),
    }),
    placeholder: "Write a Description..."
  }, null, 8, ["modelValue", "editable", "class"])])) : (_openBlock(), _createElementBlock("button", {
    key: 1,
    onClick: _cache[3] || (_cache[3] = _withModifiers((...args) => $options.createDescription && $options.createDescription(...args), ["stop"])),
    "data-table-column-target": ""
  }))]);
}