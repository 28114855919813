import { Scheduling } from "@/components/mixins/Scheduling";
import { Statusing } from "@/components/mixins/Statusing";
import { Filtering } from "@/components/mixins/Filtering";
export default {
  mixins: [Filtering, Scheduling, Statusing],
  props: {
    modelValue: null,
    entry: Object
  },
  data() {
    return {
      showLinkSearch: false,
      value: typeof this.modelValue == "object" ? JSON.parse(JSON.stringify(this.modelValue)) : this.modelValue,
      edit: false,
      cursor: null
    };
  },
  watch: {
    /**
     * If any external cause of change happened,
     * we will adjust the value properly.
     */
    modelValue: {
      handler(n, o) {
        if (JSON.stringify(n) != JSON.stringify(o)) {
          if (this.$Cypress) {
            alert(JSON.stringify(o) + " >>> " + JSON.stringify(n));
          }
          this.value = typeof this.modelValue == "object" ? JSON.parse(JSON.stringify(this.modelValue)) : this.modelValue;
        }
      },
      deep: true
    },
    value: {
      handler() {
        if (JSON.stringify(this.value) != JSON.stringify(this.modelValue)) {
          setTimeout(() => {
            this.$emit("onChange", this.value);
          }, 0);
        }
      },
      deep: true
    }
  },
  computed: {
    entries() {
      return this.$store.getters.entries.filter(e => e.deleted_at === null);
    },
    statuses() {
      return this.getOwnAvailableStatuses(this.entry, true);
    },
    validatedValue() {
      return this.value?.filter(o => o.key && o.op);
    }
  },
  methods: {
    cancelEdit() {
      this.value = typeof this.modelValue == "object" ? JSON.parse(JSON.stringify(this.modelValue)) : this.modelValue;
      this.edit = false;
    },
    checkEditFocus(e) {
      if (!this.$el.contains(e.relatedTarget)) {
        this.edit = false;
      }
      this.$emit("onBlur");
    }
  }
};