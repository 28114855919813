export default {
  props: {
    modelValue: Object,
    entry: Object,
    position: String
  },
  data() {
    return {
      priorities: [1, 2, 3, 4, null]
    };
  },
  computed: {
    priority: {
      get() {
        return this.modelValue;
      },
      set(value) {
        // eslint-disable-next-line vue/no-mutating-props
        this.entry.priority = value;
        this.$store.dispatch("push", {
          event: "entry_update",
          params: {
            entry: this.entry
          },
          entry: this.entry
        });
      }
    }
  }
};