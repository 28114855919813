import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, renderSlot as _renderSlot, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  class: "flex"
};
const _hoisted_2 = {
  class: "flex-shrink-0"
};
const _hoisted_3 = {
  class: "ml-3 space-y-2"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_InfoIcon = _resolveComponent("InfoIcon");
  const _component_CheckIcon = _resolveComponent("CheckIcon");
  const _component_AlertCircleIcon = _resolveComponent("AlertCircleIcon");
  const _component_XCircleIcon = _resolveComponent("XCircleIcon");
  return _openBlock(), _createElementBlock("div", {
    class: _normalizeClass(`mb-2 rounded-md bg-${$options.color}-50 dark:bg-${$options.color}-950 p-4`)
  }, [_createElementVNode("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [$props.variant === 'info' ? (_openBlock(), _createBlock(_component_InfoIcon, {
    key: 0,
    class: _normalizeClass(`text-${$options.color}-400`),
    size: 20
  }, null, 8, ["class"])) : _createCommentVNode("", true), $props.variant === 'success' ? (_openBlock(), _createBlock(_component_CheckIcon, {
    key: 1,
    class: _normalizeClass(`text-${$options.color}-400`),
    size: 20
  }, null, 8, ["class"])) : _createCommentVNode("", true), $props.variant === 'warning' ? (_openBlock(), _createBlock(_component_AlertCircleIcon, {
    key: 2,
    class: _normalizeClass(`text-${$options.color}-400`),
    size: 20
  }, null, 8, ["class"])) : _createCommentVNode("", true), $props.variant === 'error' ? (_openBlock(), _createBlock(_component_XCircleIcon, {
    key: 3,
    class: _normalizeClass(`text-${$options.color}-400`),
    size: 20
  }, null, 8, ["class"])) : _createCommentVNode("", true)]), _createElementVNode("div", _hoisted_3, [_ctx.$slots['title'] ? (_openBlock(), _createElementBlock("h3", {
    key: 0,
    class: _normalizeClass(`text-sm font-medium text-${$options.color}-800 dark:text-${$options.color}-300`)
  }, [_renderSlot(_ctx.$slots, "title")], 2)) : _createCommentVNode("", true), _createElementVNode("div", {
    class: _normalizeClass(`text-sm text-${$options.color}-700 dark:text-${$options.color}-400`)
  }, [_createElementVNode("p", null, [_renderSlot(_ctx.$slots, "default")])], 2)])])], 2);
}