import { addonBlueprint } from "@/addonBlueprint";
import moment from "moment";

export const Statusing = {
  inject: {
    overrideStatuses: {
      default: null,
    },
  },
  methods: {

    setStatus(object, status) {


      if (!this.statusIsDone(object.status_id) && this.statusIsDone(status)) {
        object.completed_at = moment.utc().format("YYYY-MM-DD HH:mm:ss");
        if (typeof object.completed_count === 'undefined')
          object.completed_count = 0;
        object.completed_count++;
      }


      if (status) {
        if (typeof status === 'string') {
          object.status_id = status;
        }
        else {
          object.status_id = status.id;
        }
      } else {
        object.status_id = null;
      }


      // Handling Procrastination
      if (object.procrastination) {
        if (this.statusIsDone(object.status_id) || object.status_id === null) {
          const procrastinationId = object.procrastination.id;
          delete object.procrastination;
          if (!object.temp)
            this.$store.dispatch("push", {
              event: "procrastination_delete",
              params: { id: procrastinationId },
              entry: object,
              undo: true,
            });
        }
      }

      // Handling automatic time tracking
      if (this.statusIsDone(object.status_id) && object.time_trackings && object.time_trackings.find(tt => tt.end_at === null)) {

        // Will close active time tracking

        const time_track = object.time_trackings.find(tt => tt.end_at === null);
        time_track.end_at = moment.utc().format("YYYY-MM-DD HH:mm:ss");

      } else if (this.statusIsDone(object.status_id) && object.time_trackings && object.schedule && object.schedule.duration) {

        // Will create a new time tracking with the duration of the schedule

        const time_track = addonBlueprint("time_track", object, this);

        const end = /*object.schedule.date ? moment.utc(object.schedule.date + " " + moment.utc().format('HH:mm:ss')) :*/ moment.utc();
        const start = end.clone().subtract(object.schedule.duration, "seconds");

        time_track.start_at = start.format("YYYY-MM-DD HH:mm:ss");
        time_track.end_at = end.format("YYYY-MM-DD HH:mm:ss");

        // console.log(start.format("YYYY-MM-DD HH:mm:ss") + " - " + end.format("YYYY-MM-DD HH:mm:ss"));

        object.time_trackings.push(time_track);

      }

      if (this.statusIsDone(object.status_id) && object.routine) {
        this.handleRoutineCheck(object);
      }

      // Handling recurrence
      if (this.statusIsDone(object.status_id) && object.schedule?.recurrence) {
        /**
         * If the object is done and has a recurrence, we need to set the next date
         * but only if there is a possible next open status available
         */
        const nextStatus = this.getNextStatusInType('open', this.getAvailableStatuses(object));
        if (nextStatus) {
          this.setStatus(object, nextStatus);
          object.schedule.date = this.interpretDate({ date: object.schedule.recurrence }, moment(object.schedule.date));
        }
      }
    },
    getStatusById(id, statuses = null) {
      if (this.overrideStatuses?.length && this.overrideStatuses.find((s) => s.id == id))
        return this.overrideStatuses.find((s) => s.id == id)


      if (statuses !== null && statuses?.find((s) => s.id == id)) {
        return statuses.find((s) => s.id == id)
      }
      return this.$store.getters.statuses.find((s) => s.id == id);
    },
    getStatusColorById(id, statuses = null) {
      return this.getStatusById(id, statuses)?.color;
    },
    getNextStatusInType(type, statuses = null) {
      if (statuses === null)
        statuses = this.$store.getters.space?.statuses;
      return statuses.find(s => s.type == type)
    },
    statusIsDone(status) {
      if (typeof status === 'string') {
        status = this.$store.getters.statuses.find((s) => s.id === status);
      }

      if (typeof status != 'undefined' && status !== null && typeof status.type != 'undefined')
        return status.type == "done" || status.type == "closed";

      return false;
    },
    getOwnAvailableStatuses(entry, all = false) {
      if (this.overrideStatuses?.length)
        return this.overrideStatuses;


      var statuses = [];
      if (entry?.links.length && all) {

        statuses = [...statuses, ...this.entry.links
          .map((id) => this.$store.getters.entries.find((e) => e.id == id))
          .filter((e) => e).flatMap((e) => e.statuses).filter(s => s)]
      }

      statuses = [...statuses, ...entry ? entry.statuses : []
        //.map((status) => this.$store.getters.space.statuses.find((e) => e.id == status.id))
        .filter((e) => e)];

      if (statuses.length == 0) {
        statuses = this.$store.getters.statuses.filter((s) => all || !s.entry_id);
      }

      return statuses
    },
    getAvailableStatuses(entry) {
      var statuses = [];

      if (entry?.links?.length) {
        statuses = entry.links
          .map((link) => this.$store.getters.entries.find((e) => e.id == link.id))
          .filter((e) => e).flatMap((e) => e.statuses).filter(s => s);
      }

      return statuses.length
        ? statuses
        : this.$store.getters.statuses.filter((s) => !s.entry_id);
    }
  },
};
