export default {
  props: {
    modelValue: Object
  },
  computed: {
    status() {
      return this.modelValue;
    },
    entry() {
      return this.$store.getters.entries.find(entry => entry.id === this.modelValue.entry_id);
    }
  }
};