import { createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, normalizeStyle as _normalizeStyle, withCtx as _withCtx, createBlock as _createBlock } from "vue";
const _hoisted_1 = ["r"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_ButtonComponent = _resolveComponent("ButtonComponent");
  return _openBlock(), _createBlock(_component_ButtonComponent, {
    variant: "minimal",
    class: _normalizeClass(["relative", {
      hidden: $props.hideOnOk && $options.ok
    }]),
    title: $props.position != 'calendar' ? `${$options.sense.name}` : '',
    style: _normalizeStyle({
      '--ps-sense-color': _ctx.$colors[$options.color][500],
      '--ps-sense-color-text': _ctx.$colors[$options.color][700],
      '--ps-sense-color-bg': _ctx.$colors[$options.color][100]
    })
  }, {
    default: _withCtx(() => [_createElementVNode("span", {
      class: _normalizeClass({
        [_ctx.$style.full]: $props.full
      })
    }, [!$options.ok ? (_openBlock(), _createElementBlock("svg", {
      key: 0,
      class: _normalizeClass([_ctx.$style.activeDot]),
      viewBox: "0 0 6 6",
      "aria-hidden": "true"
    }, _cache[0] || (_cache[0] = [_createElementVNode("circle", {
      cx: "3",
      cy: "3",
      r: "3"
    }, null, -1)]), 2)) : _createCommentVNode("", true), (_openBlock(), _createElementBlock("svg", {
      class: _normalizeClass([_ctx.$style.passiveDot]),
      viewBox: "0 0 6 6",
      "aria-hidden": "true"
    }, [_createElementVNode("circle", {
      cx: "3",
      cy: "3",
      r: $options.ok ? '2' : '3'
    }, null, 8, _hoisted_1)], 2))], 2)]),
    _: 1
  }, 8, ["class", "title", "style"]);
}