import { normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = ["aria-label"];
const _hoisted_2 = {
  class: "sr-only ml-3 block truncate"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock(_Fragment, null, [_createElementVNode("span", {
    "aria-label": $props.status?.name || 'No status',
    class: _normalizeClass({
      [_ctx.$style.statusIndicator]: $props.status,
      [_ctx.$style.statusIndicatorDone]: $props.status?.type == 'done',
      'h-2 w-2': ['xs', 'sm'].includes($props.size),
      'h-3 w-3': !['xs', 'sm'].includes($props.size)
    }),
    style: _normalizeStyle({
      '--ps-status-color': _ctx.$colors[$props.status?.color || 'neutral'][500],
      '--ps-status-color-dark': _ctx.$colors[$props.status?.color || 'neutral'][400]
    })
  }, null, 14, _hoisted_1), _createElementVNode("span", _hoisted_2, _toDisplayString($props.status?.name || "No status"), 1)], 64);
}