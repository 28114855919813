import moment from "moment";
import { Scheduling } from "../mixins/Scheduling";
export default {
  name: "DateTimePicker",
  mixins: [Scheduling],
  props: {
    entry: Object,
    modelValue: [Object, String],
    disabled: Boolean
  },
  mounted() {
    this.extractInfo();
  },
  data() {
    return {
      date: null,
      time: null
    };
  },
  computed: {
    validatedValue() {
      if (this.date == null || this.time == null || this.date && this.date.op && this.date.op === "null" || this.time && this.time.op && this.time.op === "null") return null;
      return this.date + " " + this.time;
    }
  },
  watch: {
    modelValue() {
      this.extractInfo();
    },
    validatedValue() {
      if (this.validatedValue != this.modelValue) this.$emit("update:modelValue", this.validatedValue);
    }
  },
  methods: {
    extractInfo() {
      if (this.modelValue) {
        const datetime = moment.utc(this.modelValue);
        this.date = datetime.format("YYYY-MM-DD");
        this.time = datetime.format("HH:mm:ss");
      }
    }
  }
};