import SectionHeader from "@/components/helpers/SectionHeader.vue";
import { TimerIcon } from "lucide-vue-next";
import { Scheduling } from "../mixins/Scheduling";
import { Statusing } from "../mixins/Statusing";
import { Crud } from "../mixins/Crud";
import { Filtering } from "../mixins/Filtering";
import TimeTrackingsWrapper from "./partials/TimeTrackingsWrapper.vue";
import { Tracking } from "../mixins/Tracking";
import { TimeTrackingsMixin } from "./mixins/TimeTrackingsMixin";
import { Addon } from "../mixins/Addon";
// import { defineAsyncComponent } from "vue";

export default {
  mixins: [Addon, Scheduling, Statusing, Crud, Filtering, Tracking, TimeTrackingsMixin],
  components: {
    TimerIcon,
    SectionHeader,
    TimeTrackingsWrapper
    // OutputDisplay: defineAsyncComponent(() =>
    //   import("@/components/output/OutputDisplay.vue"),
    // ),
  },
  computed: {
    time_trackings: {
      get() {
        return this.entry.time_trackings;
      },
      set(value) {
        this.$emit("update:modelValue", value);
      }
    },
    outputComputed() {
      return {
        data: [{
          key: "links",
          op: "any",
          val: [this.entry.id]
        }]
      };
    }
  },
  methods: {}
};