import "core-js/modules/es.array.push.js";
import moment from "moment";
export default {
  name: "LoginProviderComponent",
  computed: {
    entries() {
      return this.$store.getters.entries.filter(e => e.deleted_at === null);
    }
  },
  created() {
    this.$store.dispatch("retrieveProviderCallback", {
      provider: this.$route.params.provider,
      code: this.$route.query.code,
      user: this.$route.query.user,
      oauth_token: this.$route.query.oauth_token,
      oauth_verifier: this.$route.query.oauth_verifier,
      timezone: moment.tz.guess(),
      entries: this.entries
    }).then(() => {
      this.$router.push({
        name: "home"
      });
    }).catch(() => {
      this.$router.push({
        name: "login"
      });
    });
  }
};