import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withModifiers as _withModifiers, withCtx as _withCtx, createVNode as _createVNode, renderSlot as _renderSlot, createElementVNode as _createElementVNode } from "vue";
const _hoisted_1 = {
  class: "flex flex-wrap items-center gap-2"
};
const _hoisted_2 = {
  class: "flex items-center leading-normal"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_LabelButton = _resolveComponent("LabelButton");
  const _component_LinksPopover = _resolveComponent("LinksPopover");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.entries, (entry, index) => {
    return _openBlock(), _createElementBlock("div", {
      key: entry.id
    }, [_createVNode(_component_LabelButton, {
      onClick: _withModifiers($event => _ctx.$emit('update:modelValue', $options.links = $options.links.filter((e, i) => i != index)), ["stop", "prevent"]),
      variant: _ctx.variant == 'round' ? 'default' : _ctx.variant,
      size: _ctx.size,
      color: entry.color,
      title: 'Unlink ' + _ctx.clearMarkdown(entry.name),
      class: "w-full"
    }, {
      default: _withCtx(() => [_createTextVNode(_toDisplayString(entry.name ? _ctx.clearMarkdown(entry.name).length > 20 ? _ctx.clearMarkdown(entry.name).slice(0, 17) + "..." : _ctx.clearMarkdown(entry.name) : "Unnamed"), 1)]),
      _: 2
    }, 1032, ["onClick", "variant", "size", "color", "title"])]);
  }), 128)), _createElementVNode("div", _hoisted_2, [_createVNode(_component_LinksPopover, {
    modelValue: $options.links,
    "onAdd:linkId": _cache[0] || (_cache[0] = id => {
      $options.links = [...$options.links, id];
    })
  }, {
    button: _withCtx(() => [_renderSlot(_ctx.$slots, "button")]),
    _: 3
  }, 8, ["modelValue"])])]);
}