import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, Teleport as _Teleport, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue";
const _hoisted_1 = {
  class: "z-[999999]"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return $options.toasts.length ? (_openBlock(), _createBlock(_Teleport, {
    key: 0,
    to: "body"
  }, [_createElementVNode("ul", _hoisted_1, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.toasts, toast => {
    return _openBlock(), _createElementBlock("li", {
      key: toast.id
    }, _toDisplayString(toast.message), 1);
  }), 128))])])) : _createCommentVNode("", true);
}