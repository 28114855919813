import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue";
const _hoisted_1 = ["title"];
const _hoisted_2 = ["title"];
const _hoisted_3 = {
  class: "p-2"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_DateTimePicker = _resolveComponent("DateTimePicker");
  const _component_PopoverHelper = _resolveComponent("PopoverHelper");
  return _openBlock(), _createBlock(_component_PopoverHelper, {
    style: {
      "position": "relative"
    }
  }, {
    button: _withCtx(() => [$data.entry_quick_fix.completed_at ? (_openBlock(), _createElementBlock("span", {
      key: 0,
      title: 'Last completed: ' + $data.moment.utc($data.entry_quick_fix.completed_at).local().format('YYYY-MM-DD HH:mm:ss'),
      style: {
        "font-weight": "normal"
      }
    }, _toDisplayString($data.moment.utc($data.entry_quick_fix.completed_at).fromNow()), 9, _hoisted_1)) : (_openBlock(), _createElementBlock("span", {
      key: 1,
      onClick: _cache[0] || (_cache[0] = $event => [$data.entry_quick_fix.completed_at = $data.moment.utc().format('YYYY-MM-DD HH:mm:ss'), !$data.entry_quick_fix.temp ? _ctx.$store.dispatch('push', {
        event: 'entry_update',
        params: {
          entry: $data.entry_quick_fix
        },
        entry: $data.entry_quick_fix
      }) : null]),
      title: 'Edit the completion date of "' + $data.entry_quick_fix.name + '"',
      style: {
        "font-weight": "normal"
      }
    }, " never completed ", 8, _hoisted_2))]),
    default: _withCtx(() => [_createElementVNode("div", _hoisted_3, [_createVNode(_component_DateTimePicker, {
      modelValue: $data.entry_quick_fix.completed_at,
      "onUpdate:modelValue": [_cache[1] || (_cache[1] = $event => $data.entry_quick_fix.completed_at = $event), _cache[2] || (_cache[2] = $event => !$data.entry_quick_fix.temp ? _ctx.$store.dispatch('push', {
        event: 'entry_update',
        params: {
          entry: $data.entry_quick_fix
        },
        entry: $data.entry_quick_fix
      }) : null)],
      entry: $data.entry_quick_fix
    }, null, 8, ["modelValue", "entry"])])]),
    _: 1
  });
}