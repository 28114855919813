import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass, createBlock as _createBlock } from "vue";
const _hoisted_1 = {
  role: "button",
  class: "w-full"
};
const _hoisted_2 = {
  key: 0
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_FlagIcon = _resolveComponent("FlagIcon");
  const _component_ButtonComponent = _resolveComponent("ButtonComponent");
  const _component_ListboxOption = _resolveComponent("ListboxOption");
  const _component_ListboxHelper = _resolveComponent("ListboxHelper");
  return _openBlock(), _createElementBlock("div", null, [_createVNode(_component_ListboxHelper, {
    modelValue: $data.level,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => $data.level = $event)
  }, {
    button: _withCtx(() => [_createElementVNode("button", _hoisted_1, [_renderSlot(_ctx.$slots, "button", {}, () => [_cache[1] || (_cache[1] = _createElementVNode("span", {
      class: "sr-only"
    }, "Set priority", -1)), _createVNode(_component_ButtonComponent, {
      title: "Set priority",
      variant: _ctx.variant,
      size: _ctx.size,
      color: $options.prioColor,
      colorWeight: _ctx.colorWeight
    }, {
      default: _withCtx(() => [_createVNode(_component_FlagIcon, {
        size: "20"
      }), _ctx.showLabel && _ctx.variant != 'round' ? (_openBlock(), _createElementBlock("span", _hoisted_2, " Priority " + _toDisplayString($options.priority?.level), 1)) : _createCommentVNode("", true)]),
      _: 1
    }, 8, ["variant", "size", "color", "colorWeight"])])])]),
    default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.priorities, (priority, index) => {
      return _openBlock(), _createBlock(_component_ListboxOption, {
        as: "template",
        key: index,
        value: priority
      }, {
        default: _withCtx(({
          active,
          selected
        }) => [_createElementVNode("li", {
          class: _normalizeClass([selected ? 'font-semibold' : 'font-normal', active ? 'bg-neutral-600 text-white dark:bg-neutral-800 dark:text-neutral-300' : 'text-neutral-900 dark:text-neutral-400', 'relative cursor-default select-none whitespace-nowrap py-2 pl-3 pr-9'])
        }, " Priority " + _toDisplayString(priority), 3)]),
        _: 2
      }, 1032, ["value"]);
    }), 128)), _createVNode(_component_ListboxOption, {
      value: null
    }, {
      default: _withCtx(({
        active,
        selected
      }) => [_createElementVNode("li", {
        class: _normalizeClass([selected ? 'font-semibold' : 'font-normal', active ? 'bg-neutral-600 text-white dark:bg-neutral-800 dark:text-neutral-300' : 'text-neutral-900 dark:text-neutral-400', 'relative cursor-default select-none whitespace-nowrap py-2 pl-3 pr-9'])
      }, " No Priority ", 2)]),
      _: 1
    })]),
    _: 3
  }, 8, ["modelValue"])]);
}