import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, withModifiers as _withModifiers, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  class: "flex flex-1 flex-wrap items-center gap-x-4 gap-y-2 text-sm lg:flex-nowrap"
};
const _hoisted_2 = {
  class: "flex-1 text-left"
};
const _hoisted_3 = {
  class: "font-semibold"
};
const _hoisted_4 = {
  class: "flex flex-col gap-y-2 bg-white p-4 dark:bg-black"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_LabelHelper = _resolveComponent("LabelHelper");
  const _component_TimeTrackingSettings = _resolveComponent("TimeTrackingSettings");
  const _component_PopoverHelper = _resolveComponent("PopoverHelper");
  const _component_TrashIcon = _resolveComponent("TrashIcon");
  return _openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.$style.timeTrackingsRow)
  }, [_createVNode(_component_PopoverHelper, {
    teleport: true,
    class: "flex-1"
  }, {
    button: _withCtx(() => [_createElementVNode("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.$moment.utc($props.time_tracking.start_at).local().format("DD.MM. HH:mm")), 1), _createElementVNode("span", _hoisted_3, _toDisplayString($options.duration), 1)])]),
    default: _withCtx(({
      close
    }) => [_createElementVNode("div", _hoisted_4, [_createVNode(_component_LabelHelper, null, {
      default: _withCtx(() => _cache[1] || (_cache[1] = [_createTextVNode(" Edit Time Tracking ")])),
      _: 1
    }), _createVNode(_component_TimeTrackingSettings, {
      modelValue: $props.time_tracking,
      entry: $props.entry,
      onClose: $event => close()
    }, null, 8, ["modelValue", "entry", "onClose"])])]),
    _: 1
  }), _createElementVNode("button", {
    onClick: _cache[0] || (_cache[0] = _withModifiers(() => {
      _ctx.deleteTimeTracking($props.time_tracking);
    }, ["stop"])),
    class: _normalizeClass(_ctx.$style.buttonDelete)
  }, [_createVNode(_component_TrashIcon, {
    size: "16"
  })], 2)], 2);
}