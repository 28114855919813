import { addonBlueprint } from "@/addonBlueprint";
import { Addon } from "../mixins/Addon";
import { EditUtilities } from "../mixins/EditUtilities";
export default {
  mixins: [Addon, EditUtilities],
  data() {
    return {
      setTimeout: setTimeout,
      name: "description"
    };
  },
  computed: {
    description: {
      get() {
        return this.modelValue;
      },
      set(value) {
        // eslint-disable-next-line vue/no-mutating-props
        this.entry.description = value;
        if (!this.entry.temp) this.$store.dispatch("push", {
          event: "entry_update",
          params: {
            entry: this.entry
          },
          entry: this.entry,
          undo: 1
        });
      }
    }
  },
  methods: {
    createDescription() {
      this.description = addonBlueprint("description", this.entry, this);
    },
    focusEditor() {
      setTimeout(() => {
        let editor = null;
        if (this.$route.params.id2) {
          editor = document.querySelector("#entry-modal-page-wrapper #description_editor [contenteditable]");
        } else {
          editor = document.querySelector("#description_editor [contenteditable]");
        }
        if (editor) {
          editor.focus();
        }
      }, 200);
    }
  }
};