export default {
  name: "UnverifiedComponent",
  data() {
    return {
      sent: false
    };
  },
  computed: {
    token() {
      return this.$store.getters.token;
    },
    user() {
      return this.$store.getters.user;
    }
  },
  methods: {
    resend() {
      this.$store.dispatch("resendValidation").then(response => {
        if (response.status === 200) this.sent = true;
      }).catch(() => {
        this.message = "An error appeared. Please try again later.";
      });
    }
  }
};