import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue";
const _hoisted_1 = {
  class: "inline-flex items-start gap-x-2"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_TimerIcon = _resolveComponent("TimerIcon");
  const _component_ButtonComponent = _resolveComponent("ButtonComponent");
  const _component_SectionHeader = _resolveComponent("SectionHeader");
  const _component_TimeTrackingsWrapper = _resolveComponent("TimeTrackingsWrapper");
  const _component_PopoverHelper = _resolveComponent("PopoverHelper");
  return _ctx.entry ? (_openBlock(), _createElementBlock("div", {
    key: 0,
    onClick: _cache[1] || (_cache[1] = _withModifiers(() => {}, ["stop"]))
  }, [_createElementVNode("div", _hoisted_1, [_createVNode(_component_PopoverHelper, {
    teleport: true,
    class: "relative flex items-center"
  }, {
    button: _withCtx(() => [_createVNode(_component_ButtonComponent, {
      variant: _ctx.variant,
      size: _ctx.size,
      color: _ctx.color,
      title: "Time Trackings"
    }, {
      default: _withCtx(() => [_createVNode(_component_TimerIcon, {
        size: "20"
      })]),
      _: 1
    }, 8, ["variant", "size", "color"])]),
    default: _withCtx(() => [_createElementVNode("div", {
      class: _normalizeClass(_ctx.$style.popoverContent)
    }, [_createVNode(_component_SectionHeader, {
      url: "https://help.pensive.io/time-trackings"
    }, {
      title: _withCtx(() => [_createVNode(_component_TimerIcon, {
        size: "16"
      }), _cache[2] || (_cache[2] = _createTextVNode(" Time Trackings "))]),
      _: 1
    }), _createVNode(_component_TimeTrackingsWrapper, {
      modelValue: $options.time_trackings,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => $options.time_trackings = $event),
      entry: _ctx.entry,
      class: "min-w-64 overflow-y-auto"
    }, null, 8, ["modelValue", "entry"])], 2)]),
    _: 1
  })])])) : _createCommentVNode("", true);
}