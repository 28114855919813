import TextInput from "../helpers/TextInput.vue";
export default {
  props: {
    modelValue: null
  },
  data() {
    return {
      value: this.modelValue[0]
    };
  },
  components: {
    TextInput
  }
};