import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, withModifiers as _withModifiers } from "vue";
const _hoisted_1 = {
  key: 0,
  class: "flex items-center"
};
const _hoisted_2 = {
  key: 0,
  class: "ml-2"
};
const _hoisted_3 = {
  key: 1
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_CircleDashedIcon = _resolveComponent("CircleDashedIcon");
  const _component_ButtonComponent = _resolveComponent("ButtonComponent");
  const _component_StatusDisplay = _resolveComponent("StatusDisplay");
  return _openBlock(), _createElementBlock("div", null, [_createVNode(_component_StatusDisplay, {
    modelValue: $options.status,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => $options.status = $event),
    entry: $props.entry,
    statuses: $props.statuses,
    onClick: _cache[1] || (_cache[1] = _withModifiers(e => _ctx.$emit('click', e), ["stop"]))
  }, {
    button: _withCtx(() => [_renderSlot(_ctx.$slots, "button", {}, () => [_createVNode(_component_ButtonComponent, {
      variant: _ctx.variant,
      size: _ctx.size,
      color: _ctx.color,
      title: "Set Status"
    }, {
      default: _withCtx(() => [$options.status ? (_openBlock(), _createElementBlock("span", _hoisted_1, [_createElementVNode("span", {
        class: _normalizeClass({
          [_ctx.$style.statusDot]: true,
          'h-4 w-4': _ctx.variant == 'round',
          'ml-1 h-2 w-2': _ctx.variant != 'round',
          'inline-block flex-shrink-0 rounded-full': true
        }),
        "aria-hidden": "true"
      }, null, 2), _ctx.variant != 'round' ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString($options.status?.name || "Status"), 1)) : _createCommentVNode("", true)])) : (_openBlock(), _createElementBlock("span", _hoisted_3, [_createVNode(_component_CircleDashedIcon, {
        size: "20"
      })]))]),
      _: 1
    }, 8, ["variant", "size", "color"])])]),
    _: 3
  }, 8, ["modelValue", "entry", "statuses"])]);
}