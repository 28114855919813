import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, resolveComponent as _resolveComponent, normalizeStyle as _normalizeStyle } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_router_view = _resolveComponent("router-view");
  const _component_ApplicationShell = _resolveComponent("ApplicationShell");
  return _openBlock(), _createBlock(_component_ApplicationShell, {
    style: _normalizeStyle($options.cssProps)
  }, {
    default: _withCtx(() => [_createVNode(_component_router_view, null, {
      default: _withCtx(({
        Component
      }) => [_createVNode(_Transition, {
        appear: "",
        "enter-active-class": "transition-all duration-[800ms] ease-spring delay-[600ms]",
        "enter-from-class": "opacity-0 translate-y-24",
        "enter-to-class": "opacity-100 translate-y-0",
        "leave-active-class": "transition-all duration-[800ms] ease-spring inset-x-0 absolute w-full",
        "leave-to-class": "opacity-0 -translate-y-24",
        "leave-from-class": "opacity-100 translate-y-0"
      }, {
        default: _withCtx(() => [(_openBlock(), _createBlock(_resolveDynamicComponent(Component)))]),
        _: 2
      }, 1024)]),
      _: 1
    })]),
    _: 1
  }, 8, ["style"]);
}