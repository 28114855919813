import anime from "animejs/lib/anime.es";
export default {
  props: {
    text: null,
    enter: {
      type: Object,
      default: () => ({
        duration: 1200,
        opacity: [0, 1],
        translateY: [30, 0]
      })
    },
    leave: {
      type: Object,
      default: () => ({
        duration: 1200,
        opacity: [1, 0],
        translateY: [0, -30]
      })
    },
    align: {
      type: String,
      default: "left"
    }
  },
  methods: {
    onEnter(el, done) {
      let config = JSON.parse(JSON.stringify(this.enter));
      let duration = typeof Cypress === "undefined" ? config.duration ? config.duration : 0 : 0;
      delete config.duration;
      anime({
        ...{
          targets: el,
          easing: "easeOutExpo",
          complete: done,
          duration: duration
        },
        ...config
      });
    },
    onLeave(el, done) {
      let config = JSON.parse(JSON.stringify(this.leave));
      let duration = typeof Cypress === "undefined" ? config.duration ? config.duration : 0 : 0;
      delete config.duration;
      anime({
        ...{
          targets: el,
          easing: "easeOutExpo",
          complete: done,
          duration: duration
        },
        ...config
      });
    }
  }
};