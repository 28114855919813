import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  class: "relative grid min-w-max gap-8 p-7 lg:grid-cols-1 dark:text-neutral-100"
};
const _hoisted_2 = {
  class: "flex h-10 w-10 shrink-0 items-center justify-center text-neutral-500 sm:h-12 sm:w-12"
};
const _hoisted_3 = {
  class: "flex h-10 w-10 shrink-0 items-center justify-center text-neutral-500 sm:h-12 sm:w-12"
};
const _hoisted_4 = {
  class: "flex h-10 w-10 shrink-0 items-center justify-center text-neutral-500 sm:h-12 sm:w-12"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_InboxIcon = _resolveComponent("InboxIcon");
  const _component_SettingsIcon = _resolveComponent("SettingsIcon");
  const _component_BadgeCheckIcon = _resolveComponent("BadgeCheckIcon");
  const _component_ChevronDownIcon = _resolveComponent("ChevronDownIcon");
  const _component_ButtonComponent = _resolveComponent("ButtonComponent");
  const _component_PopoverButton = _resolveComponent("PopoverButton");
  const _component_PopoverHelper = _resolveComponent("PopoverHelper");
  return _openBlock(), _createElementBlock("div", null, [_createVNode(_component_PopoverHelper, {
    teleport: true
  }, {
    button: _withCtx(() => [_createVNode(_component_ButtonComponent, {
      size: _ctx.size,
      variant: _ctx.variant,
      color: _ctx.color
    }, {
      default: _withCtx(() => [_ctx.modelValue == 'open' ? (_openBlock(), _createBlock(_component_InboxIcon, {
        key: 0,
        size: "20"
      })) : _createCommentVNode("", true), _ctx.modelValue == 'custom' ? (_openBlock(), _createBlock(_component_SettingsIcon, {
        key: 1,
        size: "20"
      })) : _createCommentVNode("", true), _ctx.modelValue == 'done' ? (_openBlock(), _createBlock(_component_BadgeCheckIcon, {
        key: 2,
        size: "20"
      })) : _createCommentVNode("", true), _createVNode(_component_ChevronDownIcon, {
        class: "mt-0.5 h-4 w-4 text-neutral-300/70 transition duration-150 ease-in-out group-hover:text-neutral-300/80",
        "aria-hidden": "true"
      })]),
      _: 1
    }, 8, ["size", "variant", "color"])]),
    default: _withCtx(() => [_createElementVNode("div", _hoisted_1, [_createVNode(_component_PopoverButton, {
      as: "a",
      onClick: _cache[0] || (_cache[0] = $event => _ctx.$emit('update:modelValue', 'open')),
      class: "-m-3 flex cursor-pointer items-center rounded-lg p-2 transition duration-150 ease-in-out hover:bg-gray-50 focus:outline-none focus-visible:ring focus-visible:ring-neutral-500/50 dark:hover:bg-neutral-900"
    }, {
      default: _withCtx(() => [_createElementVNode("div", _hoisted_2, [_createVNode(_component_InboxIcon, {
        size: "20"
      })]), _cache[3] || (_cache[3] = _createElementVNode("div", {
        class: "ml-4"
      }, [_createElementVNode("p", {
        class: "text-sm font-medium text-gray-900 dark:text-neutral-100"
      }, " Open "), _createElementVNode("p", {
        class: "text-sm text-gray-500"
      }, " This status is considered as not started ")], -1))]),
      _: 1
    }), _createVNode(_component_PopoverButton, {
      as: "a",
      onClick: _cache[1] || (_cache[1] = $event => _ctx.$emit('update:modelValue', 'custom')),
      class: "-m-3 flex cursor-pointer items-center rounded-lg p-2 transition duration-150 ease-in-out hover:bg-gray-50 focus:outline-none focus-visible:ring focus-visible:ring-neutral-500/50 dark:hover:bg-neutral-900"
    }, {
      default: _withCtx(() => [_createElementVNode("div", _hoisted_3, [_createVNode(_component_SettingsIcon, {
        size: "20"
      })]), _cache[4] || (_cache[4] = _createElementVNode("div", {
        class: "ml-4"
      }, [_createElementVNode("p", {
        class: "text-sm font-medium text-gray-900 dark:text-neutral-100"
      }, " Custom "), _createElementVNode("p", {
        class: "text-sm text-gray-500"
      }, " This status is considered as a custom status ")], -1))]),
      _: 1
    }), _createVNode(_component_PopoverButton, {
      as: "a",
      onClick: _cache[2] || (_cache[2] = $event => _ctx.$emit('update:modelValue', 'done')),
      class: "-m-3 flex cursor-pointer items-center rounded-lg p-2 transition duration-150 ease-in-out hover:bg-gray-50 focus:outline-none focus-visible:ring focus-visible:ring-neutral-500/50 dark:hover:bg-neutral-900"
    }, {
      default: _withCtx(() => [_createElementVNode("div", _hoisted_4, [_createVNode(_component_BadgeCheckIcon, {
        size: "20"
      })]), _cache[5] || (_cache[5] = _createElementVNode("div", {
        class: "ml-4"
      }, [_createElementVNode("p", {
        class: "text-sm font-medium text-gray-900 dark:text-neutral-100"
      }, " Done "), _createElementVNode("p", {
        class: "max-w-64 text-sm text-gray-500"
      }, " This status is considered as completed. This will trigger reschuduling of the entry and overdue entries will not be marked as such. ")], -1))]),
      _: 1
    })])]),
    _: 1
  })]);
}