import { resolveComponent as _resolveComponent, createVNode as _createVNode, unref as _unref, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, Teleport as _Teleport, createElementBlock as _createElementBlock } from "vue";
import { ref } from "vue";
import { useFloating, shift, flip } from "@floating-ui/vue";
import { Routing } from "@/components/mixins/Routing";
const __default__ = {
  mixins: [Routing],
  props: {
    event: Object,
    entry: Object
  },
  watch: {
    hover: function (n) {
      if (this.showDetailsTimeout) {
        clearTimeout(this.showDetailsTimeout);
      }
      this.showDetailsTimeout = setTimeout(() => {
        this.showDetails = n;
      }, 500);
    }
  },
  data() {
    return {
      hover: false,
      showDetails: false,
      showDetailsTimeout: null,
      position: "calendar"
    };
  }
};
export default /*@__PURE__*/Object.assign(__default__, {
  __name: 'CalendarEntryDisplay',
  setup(__props) {
    const reference = ref(null);
    const floating = ref(null);
    const {
      floatingStyles
    } = useFloating(reference, floating, {
      transform: false,
      placement: "bottom-start",
      middleware: [flip(), shift({
        crossAxis: true
      })]
    });
    return (_ctx, _cache) => {
      const _component_EntryDisplay = _resolveComponent("EntryDisplay");
      const _component_PanelHelper = _resolveComponent("PanelHelper");
      return _openBlock(), _createElementBlock("div", {
        ref_key: "reference",
        ref: reference,
        onMouseover: _cache[3] || (_cache[3] = $event => _ctx.hover = true),
        onMouseleave: _cache[4] || (_cache[4] = $event => _ctx.hover = false)
      }, [_createVNode(_component_EntryDisplay, {
        modelValue: __props.entry,
        permissions: {
          name: false,
          status: true,
          links: false,
          description: false,
          schedule: false,
          output: false,
          settings: false,
          routine: false,
          time_trackings: false,
          custom_fields: false
        },
        display: {
          // status: view.type == 'timeGridDay',
          // procrastination: view.type == 'timeGridDay',
          // priority: view.type == 'timeGridDay',
          links: !__props.event.allDay,
          //view.type == 'timeGridDay',
          schedule: !__props.event.allDay,
          // view.type == 'timeGridDay' && getSlotSize(event) > 1,
          routine: !__props.event.allDay,
          output: false,
          settings: false
        },
        position: "calendar",
        size: "sm",
        disableViewportChecking: true,
        disableInitialization: true,
        disableEdgeDetection: true
      }, null, 8, ["modelValue", "display"]), (_openBlock(), _createBlock(_Teleport, {
        to: "body"
      }, [_createVNode(_Transition, {
        "enter-active-class": "transition ease-out duration-100",
        "enter-from-class": "transform opacity-0 scale-95",
        "enter-to-class": "transform opacity-100 scale-100",
        "leave-active-class": "transition ease-in duration-75",
        "leave-from-class": "transform opacity-100 scale-100",
        "leave-to-class": "transform opacity-0 scale-95"
      }, {
        default: _withCtx(() => [_ctx.showDetails ? (_openBlock(), _createBlock(_component_PanelHelper, {
          key: 0,
          ref_key: "floating",
          ref: floating,
          class: _normalizeClass(_ctx.$style.popover),
          style: _normalizeStyle(_unref(floatingStyles)),
          onMouseover: _cache[0] || (_cache[0] = $event => _ctx.hover = true),
          onMouseleave: _cache[1] || (_cache[1] = $event => _ctx.hover = false),
          onClick: _cache[2] || (_cache[2] = $event => (_ctx.hover = false, _ctx.navigateTo(__props.entry)))
        }, {
          default: _withCtx(() => [_createVNode(_component_EntryDisplay, {
            modelValue: __props.entry,
            disabled: true,
            position: "calendar",
            disableViewportChecking: true,
            disableInitialization: true,
            disableEdgeDetection: true,
            display: {
              settings: false
            },
            class: _normalizeClass(_ctx.$style.entryDisplay)
          }, null, 8, ["modelValue", "class"])]),
          _: 1
        }, 8, ["class", "style"])) : _createCommentVNode("", true)]),
        _: 1
      })]))], 544);
    };
  }
});