import { addonBlueprint } from "@/addonBlueprint";
import TextEditor from "../helpers/TextEditor.vue";
export default {
  props: {
    modelValue: Object,
    entry: Object,
    position: String,
    overrideEditable: Boolean
  },
  components: {
    TextEditor
  },
  computed: {
    editable() {
      return this.overrideEditable || this.$route.params.id == this.entry.id && ["center"].includes(this.position) || this.$route.params.id2 == this.entry.id && ["modal"].includes(this.position);
    },
    description: {
      get() {
        return this.modelValue;
      },
      set(description) {
        if (/*description.content.length && */description.content != "<p></p>") {
          // eslint-disable-next-line vue/no-mutating-props
          this.entry.description = description;
          // eslint-disable-next-line vue/no-mutating-props
        } else this.entry.description = null;
        if (!this.entry.temp) this.$store.dispatch("push", {
          event: "entry_update",
          params: {
            entry: this.entry
          },
          entry: this.entry,
          undo: 1
        });
      }
    }
  },
  methods: {
    createDescription() {
      this.description = {
        ...addonBlueprint("description", this.entry, this),
        content: ""
      };
      setTimeout(() => {
        this.$refs.description_editor?.$el?.querySelector("[contenteditable]")?.focus();
      }, 10);
    }
  }
};