import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, renderSlot as _renderSlot, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString } from "vue";
const _hoisted_1 = ["onClick"];
const _hoisted_2 = {
  class: "ml-4"
};
const _hoisted_3 = {
  class: "text-sm font-medium text-gray-900 dark:text-neutral-300"
};
const _hoisted_4 = {
  class: "text-sm text-gray-500 dark:text-neutral-400"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", null, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.options, option => {
    return _openBlock(), _createElementBlock("button", {
      key: option.value,
      onClick: $event => _ctx.$emit('update:modelValue', option.value?.field === $props.modelValue?.field ? {
        field: $props.modelValue?.field,
        dir: $props.modelValue?.dir * -1
      } : option.value)
    }, [_renderSlot(_ctx.$slots, "default", {
      option: option,
      active: option.value?.field === $props.modelValue?.field,
      direction: option.value?.field === $props.modelValue?.field ? $props.modelValue?.dir : null
    }, () => [_createElementVNode("div", {
      class: _normalizeClass(_ctx.$style.icon)
    }, [(_openBlock(), _createBlock(_resolveDynamicComponent(option.icon), {
      size: "20"
    }))], 2), _createElementVNode("div", _hoisted_2, [_createElementVNode("p", _hoisted_3, _toDisplayString(option.label), 1), _createElementVNode("p", _hoisted_4, _toDisplayString(option.description), 1)])])], 8, _hoisted_1);
  }), 128))]);
}