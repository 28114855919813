// import { addonBlueprint } from "@/addonBlueprint";

export const Markdowning = {
    methods: {
        clearMarkdown(text) {
            if (!text) return text;
            return (
                text
                    // Remove markdown bold (**) and italic (*) syntax
                    .replace(/\*\*/g, "")
                    .replace(/\*/g, "")
                    // Remove markdown links [text](url)
                    .replace(/\[([^\]]+)\]\([^)]+\)/g, "$1")
                    // Remove HTML-style links <url>
                    .replace(/<[^>]+>/g, "")
            );
        },
    },
};

