import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, vModelCheckbox as _vModelCheckbox, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers } from "vue";
const _hoisted_1 = {
  class: "mt-2"
};
const _hoisted_2 = {
  class: "mt-2"
};
const _hoisted_3 = {
  class: "mt-2"
};
const _hoisted_4 = {
  class: "flex justify-between"
};
const _hoisted_5 = {
  class: "mt-6 space-y-6"
};
const _hoisted_6 = {
  class: "relative grid grid-cols-2 gap-2 lg:grid-cols-3"
};
const _hoisted_7 = {
  class: "flex h-6 items-center"
};
const _hoisted_8 = ["onUpdate:modelValue", "id", "name"];
const _hoisted_9 = {
  class: "text-sm leading-6"
};
const _hoisted_10 = {
  for: "template-include-status",
  class: "font-medium text-gray-900"
};
const _hoisted_11 = {
  class: "text-xs text-gray-500"
};
const _hoisted_12 = {
  key: 0
};
const _hoisted_13 = {
  class: "mt-6 flex items-center justify-end gap-x-6"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_Grid2x2PlusIcon = _resolveComponent("Grid2x2PlusIcon");
  const _component_ButtonComponent = _resolveComponent("ButtonComponent");
  const _component_ModalHelper = _resolveComponent("ModalHelper");
  return _openBlock(), _createElementBlock("div", null, [_createVNode(_component_ButtonComponent, {
    onClick: _cache[0] || (_cache[0] = $event => ($data.template.name = _ctx.entry.name, $data.showSaveTemplate = true)),
    class: "whitespace-nowrap rounded-md hover:bg-neutral-100 dark:hover:bg-neutral-900",
    size: _ctx.size,
    variant: _ctx.variant,
    color: _ctx.color
  }, {
    default: _withCtx(() => [_createVNode(_component_Grid2x2PlusIcon, {
      size: "16"
    }), _cache[8] || (_cache[8] = _createTextVNode(" Save as Template "))]),
    _: 1
  }, 8, ["size", "variant", "color"]), _createVNode(_component_ModalHelper, {
    show: $data.showSaveTemplate,
    onClose: _cache[7] || (_cache[7] = $event => $data.showSaveTemplate = false)
  }, {
    title: _withCtx(() => _cache[9] || (_cache[9] = [_createTextVNode(" Save Template ")])),
    default: _withCtx(() => [_createElementVNode("form", {
      onSubmit: _cache[6] || (_cache[6] = _withModifiers((...args) => $options.addTemplate && $options.addTemplate(...args), ["prevent"]))
    }, [_cache[13] || (_cache[13] = _createElementVNode("label", {
      for: "template-name",
      class: "block text-sm font-medium leading-6 text-gray-900"
    }, " Template Name ", -1)), _createElementVNode("div", _hoisted_1, [_withDirectives(_createElementVNode("input", {
      "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => $data.template.name = $event),
      type: "text",
      name: "template-name",
      id: "template-name",
      class: "block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
    }, null, 512), [[_vModelText, $data.template.name]])]), _cache[14] || (_cache[14] = _createElementVNode("label", {
      for: "about",
      class: "mt-2 block text-sm font-medium leading-6 text-gray-900"
    }, "Description", -1)), _createElementVNode("div", _hoisted_2, [_withDirectives(_createElementVNode("textarea", {
      id: "description",
      "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => $data.template.description = $event),
      name: "description",
      rows: "3",
      class: "block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
    }, null, 512), [[_vModelText, $data.template.description]])]), _createElementVNode("fieldset", _hoisted_3, [_createElementVNode("div", _hoisted_4, [_cache[10] || (_cache[10] = _createElementVNode("legend", {
      class: "text-sm font-semibold leading-6 text-gray-900"
    }, " Fields to include ", -1)), _createElementVNode("button", {
      type: "button",
      onClick: _cache[3] || (_cache[3] = () => {
        Object.keys($data.template.include).forEach(key => {
          $data.template.include[key] = !$data.template.include[key];
        });
      }),
      class: "text-xs text-gray-500 hover:text-gray-900"
    }, " Toggle all ")]), _createElementVNode("div", _hoisted_5, [_createElementVNode("div", _hoisted_6, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(Object.keys($data.template.include), index => {
      return _openBlock(), _createElementBlock("div", {
        key: index,
        class: "relative flex gap-x-3"
      }, [_createElementVNode("div", _hoisted_7, [_withDirectives(_createElementVNode("input", {
        "onUpdate:modelValue": $event => $data.template.include[index] = $event,
        id: `template-include-${index}`,
        name: `template-include-${index}`,
        type: "checkbox",
        class: "h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
      }, null, 8, _hoisted_8), [[_vModelCheckbox, $data.template.include[index]]])]), _createElementVNode("div", _hoisted_9, [_createElementVNode("label", _hoisted_10, _toDisplayString(index.charAt(0).toUpperCase() + index.slice(1)), 1), _createElementVNode("p", _hoisted_11, " Include the " + _toDisplayString(index) + " of the entry in the template. ", 1), index == 'schedule' && $data.template.include[index] ? (_openBlock(), _createElementBlock("div", _hoisted_12, [_withDirectives(_createElementVNode("input", {
        "onUpdate:modelValue": _cache[4] || (_cache[4] = $event => $data.template.relative_schedule = $event),
        id: "template-relative-schedule",
        name: "template-relative-schedule",
        type: "checkbox",
        class: "h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
      }, null, 512), [[_vModelCheckbox, $data.template.relative_schedule]]), _cache[11] || (_cache[11] = _createElementVNode("span", {
        class: "text-xs text-gray-500"
      }, " Use relative date ", -1))])) : _createCommentVNode("", true)])]);
    }), 128))])])]), _createElementVNode("div", _hoisted_13, [_createElementVNode("button", {
      onClick: _cache[5] || (_cache[5] = $event => $data.showSaveTemplate = false),
      type: "button",
      class: "text-sm font-semibold leading-6 text-gray-900"
    }, " Cancel "), _cache[12] || (_cache[12] = _createElementVNode("button", {
      type: "submit",
      class: "rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
    }, " Save ", -1))])], 32)]),
    _: 1
  }, 8, ["show"])]);
}