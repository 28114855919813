import { TargetIcon, CalendarClockIcon, RadioIcon, ClockIcon, FlagIcon, CalendarX2Icon, RepeatIcon, DiamondIcon, ListPlusIcon, Link2Icon } from "lucide-vue-next";
const ATTRIBUTE_ICONS = {
  status: TargetIcon,
  schedule: CalendarClockIcon,
  senses: RadioIcon,
  time_trackings: ClockIcon,
  routine: RepeatIcon,
  priority: FlagIcon,
  procrastination: CalendarX2Icon,
  custom_field: DiamondIcon,
  output_settings: ListPlusIcon,
  links: Link2Icon
  // Add more icon mappings here as needed
};
const availableAttributes = {
  status: entry => entry.status_id !== null,
  schedule: entry => entry.schedule !== null && typeof entry.schedule === "object",
  senses: entry => Array.isArray(entry.senses) && entry.senses.length > 0,
  time_trackings: entry => Array.isArray(entry.time_trackings) && entry.time_trackings.length > 0,
  routine: entry => entry.routine !== null && entry.routine !== undefined,
  priority: entry => entry.priority !== null && entry.priority !== undefined,
  procrastination: entry => entry.procrastination !== null && entry.procrastination !== undefined,
  output_settings: entry => entry.output !== null && entry.output !== undefined,
  links: entry => entry.links?.length > 0
};
export default {
  components: {
    TargetIcon,
    CalendarClockIcon
  },
  props: {
    output: Object,
    entry: Object,
    computedDisplay: Object
  },
  computed: {
    existingAttributes() {
      return [...Object.keys(availableAttributes).filter(attribute => availableAttributes[attribute](this.entry)),
      // Getting static attributes and then custom fields
      ...this.customFieldsComputed.flatMap(e => e.custom_fields.map(cf => `custom_field_${cf.id}`))];
    },
    customFieldsComputed() {
      return this.entry.links?.map(link => this.$store.getters.entries.find(e => e.id == link.id)).filter(e => e?.custom_fields?.length);
    }
  },
  methods: {
    handleMouseEvent(e, attribute, eventType) {
      // Only handle mousedown/mouseup for status attribute
      if (eventType !== "click" && attribute !== "status") return;
      const target = this.$refs[attribute]?.[0]?.querySelector("[data-table-column-target]");
      if (target) {
        e.stopPropagation();
        if (eventType !== "click") {
          const mouseEvent = new window.MouseEvent(eventType, {
            bubbles: e.bubbles,
            cancelable: e.cancelable,
            view: window,
            buttons: e.buttons,
            button: e.button,
            clientX: e.clientX,
            clientY: e.clientY,
            screenX: e.screenX,
            screenY: e.screenY
          });
          target.dispatchEvent(mouseEvent);
        } else {
          target.click();
        }
        target.focus();
      }
    },
    getAttributeIcon(attribute) {
      if (attribute.startsWith("custom_field_")) {
        return ATTRIBUTE_ICONS["custom_field"];
      }
      return ATTRIBUTE_ICONS[attribute];
    }
  }
};