import Scrollparent from "scrollparent";
export default {
  props: {
    items: Array,
    itemHeight: {
      type: Number,
      default: 0
    },
    itemClass: {
      type: Object,
      default: () => ({})
    },
    preserveItems: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      intersectionObserver: null,
      resizeObserver: null,
      visibleItems: [],
      updateTimeout: null,
      visibleItemsDelayed: [],
      cachedSizes: []
    };
  },
  watch: {
    items: {
      handler: function (n, o) {
        if (!o) return;
        n.forEach((entry, index) => {
          if (entry.id != o[index]?.id) {
            if (this.$refs && this.$refs.item && this.$refs.item[index]) {
              entry = this.$refs?.item[index];
              if (entry) {
                const boundary = entry.$refs.boundaries;
                if (boundary) {
                  boundary.style.minHeight = null;
                  this.intersectionObserver.unobserve(boundary);
                  this.resizeObserver.unobserve(boundary);
                }
              }
            }
          }
        });
        this.$nextTick(() => {
          n.forEach((entry, index) => {
            if (entry.id != o[index]?.id) {
              if (this.$refs && this.$refs.item && this.$refs.item[index]) {
                entry = this.$refs?.item[index];
                if (entry) {
                  const boundary = entry.$refs.boundaries;
                  if (boundary) {
                    // boundary.style.minHeight = `${this.itemHeight}px`;
                    this.intersectionObserver.observe(boundary);
                    this.resizeObserver.observe(boundary);
                  }
                }
              }
            }
          });
        });
      },
      immediate: true,
      deep: true
    }
  },
  methods: {
    updateVisibleItems() {
      // console.log("updateVisibleItems");
      for (let i = 0; i < this.visibleItemsDelayed.length; i++) {
        if (this.visibleItems[i] !== this.visibleItemsDelayed[i]) {
          // console.log(i, this.visibleItemsDelayed[i]);
          this.visibleItems[i] = this.visibleItemsDelayed[i];
        }
      }
    }
  },
  mounted() {
    // Initialize an array with the same length as items, filled with `false`
    this.visibleItems = Array(this.items.length).fill(false);
    this.itemsCached = this.items;
    var scrollParent = Scrollparent(this.$el.parentElement);
    // console.log(this.$parent.$el, scrollParent, scrollParent.dataset);
    if (scrollParent.dataset.skipScrollparent) {
      scrollParent = Scrollparent(scrollParent);
    }
    const options = {
      root: scrollParent !== document.body ? scrollParent : null,
      rootMargin: "360px",
      threshold: 0
      // threshold: [0, 0.25, 0.5, 0.75, 1], // Multiple thresholds to detect partial visibility
    };

    // Create a standard IntersectionObserver callback
    const handleIntersect = entries => {
      entries.forEach(entry => {
        if (!entry.target || !document.body.contains(entry.target)) return; // fixing entries going "invisible" when their above entry unmounts
        const index = entry.target.dataset.index;
        const isVisible = entry.isIntersecting;
        // Only update visibleItems if the state has changed
        if (this.visibleItems[index] !== isVisible) {
          this.visibleItems[index] = isVisible; // Directly update the reactive array
        }
      });
    };
    this.intersectionObserver = new IntersectionObserver(handleIntersect, options);
    this.$refs.item?.forEach(entry => {
      const boundary = entry.$refs.boundaries;
      if (boundary) {
        boundary.style.minHeight = `${this.itemHeight}px`;
        this.intersectionObserver.observe(boundary);
      }
    });
    const handleResizes = entries => {
      // eslint-disable-next-line no-unused-vars
      entries.forEach(entry => {
        setTimeout(() => {
          entry.target.style.minHeight = null; // This is needed when the browser window is resized so the entries are not "too high"
        }, 100);
      });
    };

    // Create a ResizeObserver to handle resizing of items
    this.resizeObserver = new ResizeObserver(handleResizes);

    // Observe the boundaries of each item
    this.$refs.item?.forEach(entry => {
      const boundary = entry.$refs.boundaries;
      if (boundary) {
        boundary.style.minHeight = `${this.itemHeight}px`;
        this.intersectionObserver.observe(boundary);
        this.resizeObserver.observe(boundary);
      }
    });
  },
  beforeUnmount() {
    if (this.intersectionObserver) {
      this.intersectionObserver.disconnect();
    }
    if (this.resizeObserver) {
      this.resizeObserver.disconnect();
    }
  }
};