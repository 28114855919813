import { Statusing } from "../mixins/Statusing";
import { Scheduling } from "../mixins/Scheduling";
export default {
  name: "UndosComponent",
  data() {
    return {
      navigator: navigator
    };
  },
  computed: {
    undos() {
      return this.$store.getters.undos;
    }
  },
  mixins: [Statusing, Scheduling]
};