import "core-js/modules/es.array.push.js";
import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue";
const _hoisted_1 = {
  id: "command-palette-wrapper",
  class: "fixed inset-0 z-10 w-screen overflow-y-auto p-4 sm:p-6 md:p-20"
};
const _hoisted_2 = {
  class: "relative"
};
const _hoisted_3 = {
  key: 0
};
const _hoisted_4 = {
  class: "-mx-4 mt-2 text-sm"
};
const _hoisted_5 = {
  key: 1
};
const _hoisted_6 = {
  class: "-mx-4 mt-2 text-sm"
};
const _hoisted_7 = {
  key: 1,
  class: "px-6 py-14 text-center text-sm sm:px-14"
};
import { ref, computed, watch } from "vue";
import { useStore } from "vuex";
import { useRouter, useRoute } from "vue-router";
export default {
  __name: 'CommandPalette',
  props: {
    isOpen: Boolean
  },
  emits: ["onClose"],
  setup(__props, {
    emit: __emit
  }) {
    const query = ref("");
    //const query = computed(() => rawQuery.value.toLowerCase().replace(/^[#>]/, ""));

    const store = useStore();
    const entries = computed(() => query.value.length ? store.getters.entries.filter(e => e.deleted_at === null && e.name && e.name.toLowerCase().indexOf(query.value.toLowerCase()) !== -1).sort((a, b) => {
      return new Date(b.updated_at) - new Date(a.updated_at);
    }).slice(0, 10) : []);
    const recent = computed(() => store.getters.entries.filter(e => e.deleted_at === null).sort((a, b) => {
      return new Date(b.updated_at) - new Date(a.updated_at);
    }).slice(0, 10));
    // const query = computed(() => rawQuery.value.toLowerCase().replace(/^[#>]/, ""));
    // const filteredProjects = computed(() =>
    //   rawQuery.value === "#"
    //     ? projects
    //     : query.value === "" || rawQuery.value.startsWith(">")
    //     ? []
    //     : projects.filter((project) =>
    //         project.name.toLowerCase().includes(query.value),
    //       ),
    // );
    // const filteredUsers = computed(() =>
    //   rawQuery.value === ">"
    //     ? users
    //     : query.value === "" || rawQuery.value.startsWith("#")
    //     ? []
    //     : users.filter((user) => user.name.toLowerCase().includes(query.value)),
    // );
    const router = useRouter();
    const emit = __emit;
    function onSelect(entry) {
      setTimeout(() => query.value = "", 1000);
      emit("onClose");
      router.push({
        name: "entry",
        params: {
          id: entry.id
        }
      });
      //window.location = item.url;
    }
    const timeOut = ref(null);
    watch(query, async () => {
      if (!timeOut.value) {
        timeOut.value = setTimeout(() => {
          store.dispatch("pull", {
            filters: [{
              key: "name",
              op: "any",
              val: query.value.toLowerCase()
            }],
            limit: 10
          });
          timeOut.value = null;
        }, 750);
      }
    });
    function handleClose() {
      setTimeout(() => query.value = "", 1000);
      setTimeout(() => emit("onClose"), 10);
    }
    const route = useRoute();
    watch(() => route.path, () => {
      handleClose();
    });
    return (_ctx, _cache) => {
      const _component_TransitionChild = _resolveComponent("TransitionChild");
      const _component_SearchIcon = _resolveComponent("SearchIcon");
      const _component_ComboboxInput = _resolveComponent("ComboboxInput");
      const _component_EntryDisplay = _resolveComponent("EntryDisplay");
      const _component_ComboboxOption = _resolveComponent("ComboboxOption");
      const _component_ComboboxOptions = _resolveComponent("ComboboxOptions");
      const _component_TriangleAlertIcon = _resolveComponent("TriangleAlertIcon");
      const _component_Combobox = _resolveComponent("Combobox");
      const _component_DialogPanel = _resolveComponent("DialogPanel");
      const _component_Dialog = _resolveComponent("Dialog");
      const _component_TransitionRoot = _resolveComponent("TransitionRoot");
      return _openBlock(), _createBlock(_component_TransitionRoot, {
        show: __props.isOpen,
        as: "template"
      }, {
        default: _withCtx(() => [_createVNode(_component_Dialog, {
          as: "div",
          class: "relative z-[100]",
          onClose: handleClose
        }, {
          default: _withCtx(() => [_createVNode(_component_TransitionChild, {
            as: "template",
            enter: "ease-out duration-300",
            "enter-from": "opacity-0",
            "enter-to": "opacity-100",
            leave: "ease-in duration-200",
            "leave-from": "opacity-100",
            "leave-to": "opacity-0"
          }, {
            default: _withCtx(() => _cache[1] || (_cache[1] = [_createElementVNode("div", {
              class: "fixed inset-0 backdrop-blur transition-opacity"
            }, null, -1)])),
            _: 1
          }), _createElementVNode("div", _hoisted_1, [_createVNode(_component_TransitionChild, {
            as: "template",
            enter: "ease-out duration-300",
            "enter-from": "opacity-0 scale-95",
            "enter-to": "opacity-100 scale-100",
            leave: "ease-in duration-200",
            "leave-from": "opacity-100 scale-100",
            "leave-to": "opacity-0 scale-95"
          }, {
            default: _withCtx(() => [_createVNode(_component_DialogPanel, {
              class: "mx-auto max-w-xl transform divide-y divide-gray-100 rounded-xl bg-white shadow-2xl ring-1 ring-black ring-opacity-5 transition-all dark:divide-neutral-700 dark:bg-black dark:ring-neutral-700"
            }, {
              default: _withCtx(() => [_createVNode(_component_Combobox, {
                "onUpdate:modelValue": onSelect
              }, {
                default: _withCtx(() => [_createElementVNode("div", _hoisted_2, [_createVNode(_component_SearchIcon, {
                  size: "20",
                  class: "pointer-events-none absolute left-4 top-3.5 text-gray-400",
                  "aria-hidden": "true"
                }), _createVNode(_component_ComboboxInput, {
                  class: "h-12 w-full border-0 bg-transparent pl-11 pr-4 text-gray-900 placeholder:text-gray-400 focus:ring-0 focus-visible:outline-none sm:text-sm dark:text-neutral-300",
                  placeholder: "Search...",
                  onChange: _cache[0] || (_cache[0] = $event => query.value = $event.target.value),
                  autocomplete: "off"
                })]), entries.value.length > 0 || query.value.length == 0 ? (_openBlock(), _createBlock(_component_ComboboxOptions, {
                  key: 0,
                  static: "",
                  class: "max-h-80 transform-gpu scroll-py-10 scroll-pb-2 space-y-4 overflow-y-auto overflow-x-hidden p-4 pb-2"
                }, {
                  default: _withCtx(() => [entries.value.length > 0 ? (_openBlock(), _createElementBlock("li", _hoisted_3, [_cache[2] || (_cache[2] = _createElementVNode("h2", {
                    class: "text-xs font-semibold text-gray-900 dark:text-neutral-400"
                  }, " Search Results ", -1)), _createElementVNode("ul", _hoisted_4, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(entries.value, entry => {
                    return _openBlock(), _createBlock(_component_ComboboxOption, {
                      key: entry.id,
                      value: entry,
                      as: "template"
                    }, {
                      default: _withCtx(({
                        active
                      }) => [_createElementVNode("li", {
                        class: _normalizeClass(['cursor-default select-none items-center px-4 py-2', active && 'bg-neutral-100  dark:bg-neutral-900 '])
                      }, [_createVNode(_component_EntryDisplay, {
                        modelValue: entry,
                        display: {
                          description: false,
                          settings: false
                        },
                        permissions: {
                          name: false,
                          description: false,
                          settings: false,
                          status: false,
                          schedule: false
                        },
                        position: "command-palette"
                      }, null, 8, ["modelValue"])], 2)]),
                      _: 2
                    }, 1032, ["value"]);
                  }), 128))])])) : _createCommentVNode("", true), query.value.length == 0 ? (_openBlock(), _createElementBlock("li", _hoisted_5, [_cache[3] || (_cache[3] = _createElementVNode("h2", {
                    class: "text-xs font-semibold text-gray-900 dark:text-neutral-400"
                  }, " Recent Entries ", -1)), _createElementVNode("ul", _hoisted_6, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(recent.value, entry => {
                    return _openBlock(), _createBlock(_component_ComboboxOption, {
                      key: entry.id,
                      value: entry,
                      as: "template"
                    }, {
                      default: _withCtx(({
                        active
                      }) => [_createElementVNode("li", {
                        class: _normalizeClass(['cursor-default select-none items-center px-4 py-2', active && 'bg-neutral-100   dark:bg-neutral-900'])
                      }, [_createVNode(_component_EntryDisplay, {
                        modelValue: entry,
                        display: {
                          description: false,
                          settings: false
                        },
                        permissions: {
                          name: false
                        },
                        position: "command-palette"
                      }, null, 8, ["modelValue"])], 2)]),
                      _: 2
                    }, 1032, ["value"]);
                  }), 128))])])) : _createCommentVNode("", true)]),
                  _: 1
                })) : _createCommentVNode("", true), query.value !== '' && entries.value.length === 0 ? (_openBlock(), _createElementBlock("div", _hoisted_7, [_createVNode(_component_TriangleAlertIcon, {
                  size: "24",
                  class: "mx-auto text-gray-400",
                  "aria-hidden": "true"
                }), _cache[4] || (_cache[4] = _createElementVNode("p", {
                  class: "mt-4 font-semibold text-gray-900"
                }, "No results found", -1)), _cache[5] || (_cache[5] = _createElementVNode("p", {
                  class: "mt-2 text-gray-500"
                }, " We couldn’t find anything with that term. Please try again. ", -1))])) : _createCommentVNode("", true)]),
                _: 1
              })]),
              _: 1
            })]),
            _: 1
          })])]),
          _: 1
        })]),
        _: 1
      }, 8, ["show"]);
    };
  }
};