import { renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("section", {
    "data-has-mouseenter": true,
    onMouseenter: _cache[0] || (_cache[0] = e => _ctx.onMouseEnter(e, {
      id: null,
      schema: _ctx.dropSchema
    })),
    onMouseleave: _cache[1] || (_cache[1] = (...args) => _ctx.onMouseLeave && _ctx.onMouseLeave(...args))
  }, [_renderSlot(_ctx.$slots, "default")], 32);
}