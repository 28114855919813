import "core-js/modules/es.array.push.js";
import "core-js/modules/web.url-search-params.delete.js";
import "core-js/modules/web.url-search-params.has.js";
import "core-js/modules/web.url-search-params.size.js";
/* eslint-disable no-unused-vars */
/* eslint-disable vue/no-mutating-props */

import Placeholder from "@tiptap/extension-placeholder";
import StarterKit from "@tiptap/starter-kit";
import { Markdown } from "tiptap-markdown";
import { Link } from "@tiptap/extension-link";
import { Editor, EditorContent } from "@tiptap/vue-3";
import { Extension } from "@tiptap/core";
import { DateHighlighter } from "@/extensions/DateHighlighter";
import { addonBlueprint } from "@/addonBlueprint";
Markdown.configure({
  html: false,
  tightLists: true,
  tightListClass: "tight",
  bulletListMarker: "-",
  linkify: true,
  breaks: true,
  transformPastedText: true,
  transformCopiedText: true,
  transformLink: url => {
    return url;
  }
});

// Create custom extension as a factory function to access component methods
const createEnterHandlerExtension = component => {
  return Extension.create({
    name: "enterHandler",
    addKeyboardShortcuts() {
      return {
        Enter: ({
          editor
        }) => {
          if (!component.entry.temp) {
            editor.commands.blur();
          }
          component.updateName();
          return true;
        },
        "Shift-Enter": ({
          editor
        }) => {
          // Handle shift+enter differently here
          // For example, insert a line break
          editor.commands.enter();
          return true;
        }
      };
    }
  });
};

// Create a new factory function for the ESC handler extension
const createEscHandlerExtension = component => {
  return Extension.create({
    name: "escHandler",
    addKeyboardShortcuts() {
      return {
        Escape: ({
          editor
        }) => {
          component.cancelChanges();
          return true;
        }
      };
    }
  });
};
export default {
  name: "NameDisplay",
  components: {
    EditorContent
  },
  props: {
    modelValue: null,
    entry: Object,
    disabled: Boolean,
    placeholder: String,
    position: String,
    renderType: String,
    size: {
      type: String,
      default: "md"
    }
  },
  data() {
    return {
      editor: null,
      oldName: this.entry.name,
      originalContent: null,
      originalEntry: null
    };
  },
  watch: {
    name: {
      handler(name) {
        // console.log("name changed in name display", name);
        this.editor.commands.setContent(name);
      }
    }
  },
  computed: {
    name: {
      get() {
        return this.modelValue;
      },
      set(value) {
        if (this.entry.name !== value) {
          this.$emit("update:name", value);
          this.entry.name = value;
          if (!this.entry.temp) {
            this.$store.dispatch("push", {
              event: "entry_update",
              params: {
                entry: this.entry
              },
              entry: this.entry
            });
          }
        }
      }
    }
  },
  beforeUnmount() {
    if (this.$refs.nameDisplay) {
      const content = this.$refs.nameDisplay.innerHTML;
      this.$refs.nameDisplay.innerHTML = content;
    }
    if (this.editor) this.editor.destroy();
  },
  mounted() {
    this.editor = new Editor({
      editable: !this.disabled,
      extensions: [StarterKit.configure({
        history: {
          depth: 10
        }
      }), Extension.create({
        name: "characterLimit",
        addKeyboardShortcuts() {
          return {
            Space: ({
              editor
            }) => {
              if (editor.storage.markdown.getMarkdown().length >= 255) {
                return true;
              }
              return false;
            }
          };
        }
      }), Link.configure({
        openOnClick: false,
        HTMLAttributes: {
          rel: "noopener noreferrer"
        }
      }), Markdown.configure({
        html: false,
        transformPastedText: true,
        transformCopiedText: true,
        transformLink: url => {
          return url;
        },
        linkify: {
          autolink: false
        }
      }), Placeholder.configure({
        placeholder: this.placeholder || "Unnamed",
        emptyEditorClass: "is-editor-empty",
        showOnlyWhenEditable: false
      }), createEnterHandlerExtension(this), DateHighlighter, createEscHandlerExtension(this)],
      content: "",
      onCreate: ({
        editor
      }) => {
        editor.view.dom.addEventListener("click", event => {
          const link = event.target.closest("a");
          if (link && link.href) {
            event.preventDefault();
            event.stopPropagation();
            const href = link.href;
            const currentHost = window.location.host;
            const linkHost = new URL(href).host;
            if (linkHost === currentHost) {
              const path = new URL(href).pathname;
              this.$router.push(path);
            } else {
              window.open(href, "_blank");
            }
          }
        });
      },
      onFocus: () => {
        if (this.originalContent === null) {
          this.originalContent = this.editor.storage.markdown.getMarkdown();
          this.originalEntry = JSON.parse(JSON.stringify(this.entry));
        }
        this.editor.storage.dateHighlighter.isProcessingDates = true;
        this.$emit("onFocus");
      },
      onBlur: () => {
        if (!this.entry.temp && this.editor.storage.markdown.getMarkdown() !== this.entry.name) {
          this.updateName();
        }
        this.$emit("onBlur");
      },
      onUpdate: ({
        editor
      }) => {
        const markdown = editor.storage.markdown.getMarkdown();
        if (markdown.length > 255) {
          editor.commands.setContent(markdown.substring(0, 255));
        }
        this.$emit("live:name", editor.storage.markdown.getMarkdown());
      }
    });
    this.editor.on("nlp:date", ({
      date,
      time
    }) => {
      if (date || time) {
        if (!this.entry.schedule) {
          this.entry.schedule = addonBlueprint("schedule", this.entry, this);
        }
        this.entry.schedule.date = date;
        this.entry.schedule.time = time;
      } else {
        // Clear the schedule when date becomes invalid
        if (this.entry.schedule) {
          if (this.originalEntry.schedule) {
            this.entry.schedule.date = this.originalEntry.schedule.date;
            this.entry.schedule.time = this.originalEntry.schedule.time;
          } else {
            this.entry.schedule = null;
          }
        }
      }
    });
    if (this.entry.name || this.entry.name === "") {
      this.editor.commands.setContent(this.entry.name);
      this.editor.storage.dateHighlighter.hasInitialized = false;
      this.editor.storage.dateHighlighter.initialContent = "";
    }

    // Add this watch to ensure editor state stays in sync with disabled prop
    this.$watch(() => this.disabled, isDisabled => {
      if (this.editor) {
        this.editor.setEditable(!isDisabled);
      }
    }, {
      immediate: true
    } // Important: This ensures it's applied immediately
    );
  },
  methods: {
    makeEmpty() {
      this.editor.commands.setContent("");
      this.editor.storage.dateHighlighter.hasInitialized = false;
      this.editor.storage.dateHighlighter.initialContent = "";
      this.editor.storage.dateHighlighter.isProcessingDates = true;
    },
    /**
     * This method updates the name of the entry by first removing the date highlights and then setting the name
     * It resets afterwards
     */
    updateName() {
      // this removes the date highlight to not have it in the name when updating the name
      this.editor.commands.removeHighlightedDates();

      // since highlights are removed, we can now safely set the name
      this.name = this.editor.storage.markdown.getMarkdown();

      // reset the date highlighter
      this.editor.storage.dateHighlighter.hasInitialized = false;
      this.editor.storage.dateHighlighter.initialContent = "";
      this.editor.storage.dateHighlighter.isProcessingDates = true;
    },
    cancelChanges() {
      if (this.originalContent !== null) {
        this.editor.commands.setContent(this.originalContent);
        if (this.originalEntry) {
          Object.assign(this.entry, this.originalEntry);
        }
        this.originalContent = null;
        this.originalEntry = null;
      }
      this.editor.commands.blur();
    },
    handleContainerClick(e) {
      if (this.disabled /* || this.entry.temp*/) {
        if (!this.$Cypress) e.preventDefault(); // Prevent any default contenteditable behavior
        return;
      }
      const clickedElement = e.target;
      if (clickedElement.tagName === "P" || clickedElement.closest("p")) {
        // Only stop propagation when clicking on text content
        e.stopPropagation();
      } else {
        // When clicking outside text, blur editor and let the event bubble
        if (this.editor) {
          this.editor.commands.blur();
        }
        // Don't stop propagation here, let it bubble up for drag and drop
      }
    }
  }
};