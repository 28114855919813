import { Scheduling } from "../mixins/Scheduling";
import { Statusing } from "../mixins/Statusing";
import { Filtering } from "../mixins/Filtering";
// import Output from "../addons/Output.vue";
import { Aggregate } from "../mixins/Aggregate";
import TextPicker from "@/components/builders/TextPicker.vue";
import LinksBuilder from "@/components/links/partials/LinksBuilder.vue";
import StatusBuilder from "@/components/builders/StatusBuilder.vue";
import OptionPicker from "@/components/builders/OptionPicker.vue";
import DatePickerBuilder from "@/components/builders/DatePickerBuilder.vue";
import TimePickerBuilder from "@/components/builders/TimePickerBuilder.vue";
export default {
  mixins: [Filtering, Scheduling, Statusing, Aggregate],
  components: {
    TextPicker,
    LinksBuilder,
    StatusBuilder,
    OptionPicker,
    DatePickerBuilder,
    TimePickerBuilder
  },
  props: {
    modelValue: Object,
    entry: Object
  },
  data() {
    return {
      showLinkSearch: false,
      options: [{
        title: "Count",
        value: "count"
      }, {
        title: "Schedule Duration",
        value: ["schedule", "duration"]
      }]
    };
  },
  computed: {
    sense: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit("update:modelValue", value);
      }
    },
    children() {
      return this.filterEntries(this.filters);
    },
    filters() {
      return this.sense.filters;
    },
    output() {
      return {
        data: this.sense.filters
      };
    },
    aggregates() {
      return this.sense.aggregates;
    },
    statuses() {
      return this.getOwnAvailableStatuses(this.entry, true);
    },
    validatedValue() {
      return this.value;
    }
  }
};