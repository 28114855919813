import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString } from "vue";
const _hoisted_1 = {
  class: "relative mt-6 flex flex-1 flex-col gap-2 px-4 sm:px-6"
};
const _hoisted_2 = {
  class: "flex flex-col gap-2"
};
const _hoisted_3 = {
  class: "relative"
};
const _hoisted_4 = {
  class: "relative flex items-center justify-between"
};
const _hoisted_5 = {
  class: "flex gap-2 bg-white pl-2 dark:bg-black"
};
const _hoisted_6 = {
  href: "https://help.pensive.io/links",
  target: "_blank"
};
const _hoisted_7 = {
  class: "flex items-center gap-1 text-xs font-bold uppercase text-neutral-500 hover:text-neutral-700"
};
const _hoisted_8 = {
  class: "relative"
};
const _hoisted_9 = {
  class: "relative flex items-center justify-between"
};
const _hoisted_10 = {
  class: "flex gap-2 bg-white pl-2 dark:bg-black"
};
const _hoisted_11 = {
  href: "https://help.pensive.io/outputs",
  target: "_blank"
};
const _hoisted_12 = {
  class: "flex items-center gap-1 text-xs font-bold uppercase text-neutral-500 hover:text-neutral-700"
};
const _hoisted_13 = {
  class: "relative"
};
const _hoisted_14 = {
  class: "relative flex items-center justify-between"
};
const _hoisted_15 = {
  class: "flex gap-2 bg-white pl-2 dark:bg-black"
};
const _hoisted_16 = {
  href: "https://help.pensive.io/inputs",
  target: "_blank"
};
const _hoisted_17 = {
  class: "flex items-center gap-1 text-xs font-bold uppercase text-neutral-500 hover:text-neutral-700"
};
const _hoisted_18 = {
  class: "relative"
};
const _hoisted_19 = {
  class: "relative flex items-center justify-between"
};
const _hoisted_20 = {
  class: "flex gap-2 bg-white pl-2 dark:bg-black"
};
const _hoisted_21 = {
  href: "https://help.pensive.io/priorities",
  target: "_blank"
};
const _hoisted_22 = {
  class: "flex items-center gap-1 text-xs font-bold uppercase text-neutral-500 hover:text-neutral-700"
};
const _hoisted_23 = {
  class: "relative"
};
const _hoisted_24 = {
  class: "relative flex items-center justify-between"
};
const _hoisted_25 = {
  class: "flex gap-2 bg-white pl-2 dark:bg-black"
};
const _hoisted_26 = {
  href: "https://help.pensive.io/routines",
  target: "_blank"
};
const _hoisted_27 = {
  class: "flex items-center gap-1 text-xs font-bold uppercase text-neutral-500 hover:text-neutral-700"
};
const _hoisted_28 = {
  class: "relative"
};
const _hoisted_29 = {
  class: "relative flex items-center justify-between"
};
const _hoisted_30 = {
  class: "flex gap-2 bg-white pl-2 dark:bg-black"
};
const _hoisted_31 = {
  href: "https://help.pensive.io/anchors",
  target: "_blank"
};
const _hoisted_32 = {
  class: "flex items-center gap-1 text-xs font-bold uppercase text-neutral-500 hover:text-neutral-700"
};
const _hoisted_33 = {
  class: "relative"
};
const _hoisted_34 = {
  class: "relative flex items-center justify-between"
};
const _hoisted_35 = {
  class: "flex gap-2 bg-white pl-2 dark:bg-black"
};
const _hoisted_36 = {
  href: "https://help.pensive.io/senses",
  target: "_blank"
};
const _hoisted_37 = {
  class: "flex items-center gap-1 text-xs font-bold uppercase text-neutral-500 hover:text-neutral-700"
};
const _hoisted_38 = {
  class: "relative"
};
const _hoisted_39 = {
  class: "relative flex items-center justify-between"
};
const _hoisted_40 = {
  class: "flex gap-2 bg-white pl-2 dark:bg-black"
};
const _hoisted_41 = {
  href: "https://help.pensive.io/statuses",
  target: "_blank"
};
const _hoisted_42 = {
  class: "flex items-center gap-1 text-xs font-bold uppercase text-neutral-500 hover:text-neutral-700"
};
const _hoisted_43 = {
  class: "relative"
};
const _hoisted_44 = {
  class: "relative flex items-center justify-between"
};
const _hoisted_45 = {
  class: "flex gap-2 bg-white pl-2 dark:bg-black"
};
const _hoisted_46 = {
  href: "https://help.pensive.io/colors",
  target: "_blank"
};
const _hoisted_47 = {
  class: "flex items-center gap-1 text-xs font-bold uppercase text-neutral-500 hover:text-neutral-700"
};
const _hoisted_48 = {
  class: "relative"
};
const _hoisted_49 = {
  class: "relative flex items-center justify-between"
};
const _hoisted_50 = {
  class: "flex gap-2 bg-white pl-2 dark:bg-black"
};
const _hoisted_51 = {
  href: "https://help.pensive.io/templates",
  target: "_blank"
};
const _hoisted_52 = {
  class: "flex items-center gap-1 text-xs font-bold uppercase text-neutral-500 hover:text-neutral-700"
};
const _hoisted_53 = {
  class: "relative"
};
const _hoisted_54 = {
  class: "relative flex items-center justify-between"
};
const _hoisted_55 = {
  class: "flex gap-2 bg-white pl-2 dark:bg-black"
};
const _hoisted_56 = {
  href: "https://help.pensive.io/statuses",
  target: "_blank"
};
const _hoisted_57 = {
  class: "flex items-center gap-1 text-xs font-bold uppercase text-neutral-500 hover:text-neutral-700"
};
const _hoisted_58 = {
  class: "relative"
};
const _hoisted_59 = {
  class: "relative flex items-center justify-between"
};
const _hoisted_60 = {
  class: "flex gap-2 bg-white pl-2 dark:bg-black"
};
const _hoisted_61 = {
  href: "https://help.pensive.io/custom_fields",
  target: "_blank"
};
const _hoisted_62 = {
  class: "flex items-center gap-1 text-xs font-bold uppercase text-neutral-500 hover:text-neutral-700"
};
const _hoisted_63 = {
  class: "bg-gray-50 p-2 dark:bg-neutral-950 dark:text-neutral-500"
};
const _hoisted_64 = {
  key: 0,
  class: "flow-root rounded-md px-2 py-2"
};
const _hoisted_65 = {
  class: "flow-root rounded-md px-2 py-2"
};
const _hoisted_66 = {
  class: "block select-text text-sm text-gray-500 dark:text-neutral-500"
};
const _hoisted_67 = {
  class: "flow-root rounded-md px-2 py-2"
};
const _hoisted_68 = {
  class: "block select-text text-sm text-gray-500 dark:text-neutral-500"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_ConstructionArea = _resolveComponent("ConstructionArea");
  const _component_CircleHelpIcon = _resolveComponent("CircleHelpIcon");
  const _component_LinksSettings = _resolveComponent("LinksSettings");
  const _component_OutputSettings = _resolveComponent("OutputSettings");
  const _component_InputSettings = _resolveComponent("InputSettings");
  const _component_PrioritySettings = _resolveComponent("PrioritySettings");
  const _component_RoutineSettings = _resolveComponent("RoutineSettings");
  const _component_AnchorSettings = _resolveComponent("AnchorSettings");
  const _component_SensesSettings = _resolveComponent("SensesSettings");
  const _component_StatusSettings = _resolveComponent("StatusSettings");
  const _component_ColorSettings = _resolveComponent("ColorSettings");
  const _component_TemplateSettings = _resolveComponent("TemplateSettings");
  const _component_StatusesSettings = _resolveComponent("StatusesSettings");
  const _component_CustomFieldsSettings = _resolveComponent("CustomFieldsSettings");
  const _component_CompletedAtComponent = _resolveComponent("CompletedAtComponent");
  const _component_DeleteComponent = _resolveComponent("DeleteComponent");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_ConstructionArea), _createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [_cache[15] || (_cache[15] = _createElementVNode("div", {
    class: "absolute inset-0 flex items-center",
    "aria-hidden": "true"
  }, [_createElementVNode("div", {
    class: "w-full border-t border-neutral-300 dark:border-neutral-700"
  })], -1)), _createElementVNode("div", _hoisted_4, [_cache[14] || (_cache[14] = _createElementVNode("span", {
    class: "bg-white pr-2 text-xs uppercase text-neutral-500 dark:bg-black"
  }, " Links ", -1)), _createElementVNode("div", _hoisted_5, [_createElementVNode("a", _hoisted_6, [_createElementVNode("button", _hoisted_7, [_createVNode(_component_CircleHelpIcon, {
    size: "20"
  })])])])])]), _createVNode(_component_LinksSettings, {
    entry: _ctx.entry,
    modelValue: _ctx.entry.links,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => _ctx.entry.links = $event),
    position: "dialog",
    class: "rounded border p-2 dark:border-neutral-800",
    size: "sm",
    color: "secondary"
  }, null, 8, ["entry", "modelValue"]), _createElementVNode("div", _hoisted_8, [_cache[17] || (_cache[17] = _createElementVNode("div", {
    class: "absolute inset-0 flex items-center",
    "aria-hidden": "true"
  }, [_createElementVNode("div", {
    class: "w-full border-t border-neutral-300 dark:border-neutral-700"
  })], -1)), _createElementVNode("div", _hoisted_9, [_cache[16] || (_cache[16] = _createElementVNode("span", {
    class: "bg-white pr-2 text-xs uppercase text-neutral-500 dark:bg-black"
  }, " Output ", -1)), _createElementVNode("div", _hoisted_10, [_createElementVNode("a", _hoisted_11, [_createElementVNode("button", _hoisted_12, [_createVNode(_component_CircleHelpIcon, {
    size: "20"
  })])])])])]), _createVNode(_component_OutputSettings, {
    entry: _ctx.entry,
    modelValue: _ctx.entry.output,
    "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => _ctx.entry.output = $event),
    position: "dialog",
    class: "rounded border p-2 dark:border-neutral-800",
    size: "sm",
    color: "secondary"
  }, null, 8, ["entry", "modelValue"]), _createElementVNode("div", _hoisted_13, [_cache[19] || (_cache[19] = _createElementVNode("div", {
    class: "absolute inset-0 flex items-center",
    "aria-hidden": "true"
  }, [_createElementVNode("div", {
    class: "w-full border-t border-neutral-300 dark:border-neutral-700"
  })], -1)), _createElementVNode("div", _hoisted_14, [_cache[18] || (_cache[18] = _createElementVNode("span", {
    class: "bg-white pr-2 text-xs uppercase text-neutral-500 dark:bg-black"
  }, " Input ", -1)), _createElementVNode("div", _hoisted_15, [_createElementVNode("a", _hoisted_16, [_createElementVNode("button", _hoisted_17, [_createVNode(_component_CircleHelpIcon, {
    size: "20"
  })])])])])]), _createVNode(_component_InputSettings, {
    entry: _ctx.entry,
    modelValue: _ctx.entry.input,
    "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => _ctx.entry.input = $event),
    position: "dialog",
    class: "rounded border p-2 dark:border-neutral-800",
    size: "sm",
    color: "secondary"
  }, null, 8, ["entry", "modelValue"]), _createElementVNode("div", _hoisted_18, [_cache[21] || (_cache[21] = _createElementVNode("div", {
    class: "absolute inset-0 flex items-center",
    "aria-hidden": "true"
  }, [_createElementVNode("div", {
    class: "w-full border-t border-neutral-300 dark:border-neutral-700"
  })], -1)), _createElementVNode("div", _hoisted_19, [_cache[20] || (_cache[20] = _createElementVNode("span", {
    class: "bg-white pr-2 text-xs uppercase text-neutral-500 dark:bg-black"
  }, " Priority ", -1)), _createElementVNode("div", _hoisted_20, [_createElementVNode("a", _hoisted_21, [_createElementVNode("button", _hoisted_22, [_createVNode(_component_CircleHelpIcon, {
    size: "20"
  })])])])])]), _createVNode(_component_PrioritySettings, {
    entry: _ctx.entry,
    modelValue: _ctx.entry.priority,
    "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => _ctx.entry.priority = $event),
    position: "dialog",
    class: "rounded border p-2 dark:border-neutral-800",
    size: "sm",
    color: "secondary"
  }, null, 8, ["entry", "modelValue"]), _createElementVNode("div", _hoisted_23, [_cache[23] || (_cache[23] = _createElementVNode("div", {
    class: "absolute inset-0 flex items-center",
    "aria-hidden": "true"
  }, [_createElementVNode("div", {
    class: "w-full border-t border-neutral-300 dark:border-neutral-700"
  })], -1)), _createElementVNode("div", _hoisted_24, [_cache[22] || (_cache[22] = _createElementVNode("span", {
    class: "bg-white pr-2 text-xs uppercase text-neutral-500 dark:bg-black"
  }, " Routine ", -1)), _createElementVNode("div", _hoisted_25, [_createElementVNode("a", _hoisted_26, [_createElementVNode("button", _hoisted_27, [_createVNode(_component_CircleHelpIcon, {
    size: "20"
  })])])])])]), _createVNode(_component_RoutineSettings, {
    entry: _ctx.entry,
    modelValue: _ctx.entry.routine,
    "onUpdate:modelValue": _cache[4] || (_cache[4] = $event => _ctx.entry.routine = $event),
    position: "dialog",
    class: "rounded border p-2 dark:border-neutral-800",
    size: "sm",
    color: "secondary"
  }, null, 8, ["entry", "modelValue"]), _createElementVNode("div", _hoisted_28, [_cache[25] || (_cache[25] = _createElementVNode("div", {
    class: "absolute inset-0 flex items-center",
    "aria-hidden": "true"
  }, [_createElementVNode("div", {
    class: "w-full border-t border-neutral-300 dark:border-neutral-700"
  })], -1)), _createElementVNode("div", _hoisted_29, [_cache[24] || (_cache[24] = _createElementVNode("span", {
    class: "bg-white pr-2 text-xs uppercase text-neutral-500 dark:bg-black"
  }, " Favorite ", -1)), _createElementVNode("div", _hoisted_30, [_createElementVNode("a", _hoisted_31, [_createElementVNode("button", _hoisted_32, [_createVNode(_component_CircleHelpIcon, {
    size: "20"
  })])])])])]), _createVNode(_component_AnchorSettings, {
    entry: _ctx.entry,
    modelValue: _ctx.entry.anchors,
    "onUpdate:modelValue": _cache[5] || (_cache[5] = $event => _ctx.entry.anchors = $event),
    position: "dialog",
    class: "rounded border p-2 dark:border-neutral-800",
    size: "sm",
    color: "secondary"
  }, null, 8, ["entry", "modelValue"]), _createElementVNode("div", _hoisted_33, [_cache[27] || (_cache[27] = _createElementVNode("div", {
    class: "absolute inset-0 flex items-center",
    "aria-hidden": "true"
  }, [_createElementVNode("div", {
    class: "w-full border-t border-neutral-300 dark:border-neutral-700"
  })], -1)), _createElementVNode("div", _hoisted_34, [_cache[26] || (_cache[26] = _createElementVNode("span", {
    class: "bg-white pr-2 text-xs uppercase text-neutral-500 dark:bg-black"
  }, " Senses ", -1)), _createElementVNode("div", _hoisted_35, [_createElementVNode("a", _hoisted_36, [_createElementVNode("button", _hoisted_37, [_createVNode(_component_CircleHelpIcon, {
    size: "20"
  })])])])])]), _createVNode(_component_SensesSettings, {
    entry: _ctx.entry,
    modelValue: _ctx.entry.senses,
    "onUpdate:modelValue": _cache[6] || (_cache[6] = $event => _ctx.entry.senses = $event),
    position: "dialog",
    class: "rounded border p-2 dark:border-neutral-800",
    size: "sm",
    color: "secondary"
  }, null, 8, ["entry", "modelValue"]), _createElementVNode("div", _hoisted_38, [_cache[29] || (_cache[29] = _createElementVNode("div", {
    class: "absolute inset-0 flex items-center",
    "aria-hidden": "true"
  }, [_createElementVNode("div", {
    class: "w-full border-t border-neutral-300 dark:border-neutral-700"
  })], -1)), _createElementVNode("div", _hoisted_39, [_cache[28] || (_cache[28] = _createElementVNode("span", {
    class: "bg-white pr-2 text-xs uppercase text-neutral-500 dark:bg-black"
  }, " Status ", -1)), _createElementVNode("div", _hoisted_40, [_createElementVNode("a", _hoisted_41, [_createElementVNode("button", _hoisted_42, [_createVNode(_component_CircleHelpIcon, {
    size: "20"
  })])])])])]), _createVNode(_component_StatusSettings, {
    entry: _ctx.entry,
    modelValue: _ctx.entry.status,
    "onUpdate:modelValue": _cache[7] || (_cache[7] = $event => _ctx.entry.status = $event),
    position: "dialog",
    class: "rounded border p-2 dark:border-neutral-800",
    size: "sm",
    color: "secondary",
    statuses: _ctx.getAvailableStatuses(_ctx.entry)
  }, null, 8, ["entry", "modelValue", "statuses"]), _createElementVNode("div", _hoisted_43, [_cache[31] || (_cache[31] = _createElementVNode("div", {
    class: "absolute inset-0 flex items-center",
    "aria-hidden": "true"
  }, [_createElementVNode("div", {
    class: "w-full border-t border-neutral-300 dark:border-neutral-700"
  })], -1)), _createElementVNode("div", _hoisted_44, [_cache[30] || (_cache[30] = _createElementVNode("span", {
    class: "bg-white pr-2 text-xs uppercase text-neutral-500 dark:bg-black"
  }, " Color ", -1)), _createElementVNode("div", _hoisted_45, [_createElementVNode("a", _hoisted_46, [_createElementVNode("button", _hoisted_47, [_createVNode(_component_CircleHelpIcon, {
    size: "20"
  })])])])])]), _createVNode(_component_ColorSettings, {
    entry: _ctx.entry,
    modelValue: _ctx.entry.color,
    "onUpdate:modelValue": _cache[8] || (_cache[8] = $event => _ctx.entry.color = $event),
    position: "dialog",
    class: "rounded border p-2 dark:border-neutral-800",
    size: "sm",
    color: "secondary"
  }, null, 8, ["entry", "modelValue"]), _createElementVNode("div", _hoisted_48, [_cache[33] || (_cache[33] = _createElementVNode("div", {
    class: "absolute inset-0 flex items-center",
    "aria-hidden": "true"
  }, [_createElementVNode("div", {
    class: "w-full border-t border-neutral-300 dark:border-neutral-700"
  })], -1)), _createElementVNode("div", _hoisted_49, [_cache[32] || (_cache[32] = _createElementVNode("span", {
    class: "bg-white pr-2 text-xs uppercase text-neutral-500 dark:bg-black"
  }, " Template ", -1)), _createElementVNode("div", _hoisted_50, [_createElementVNode("a", _hoisted_51, [_createElementVNode("button", _hoisted_52, [_createVNode(_component_CircleHelpIcon, {
    size: "20"
  })])])])])]), _createVNode(_component_TemplateSettings, {
    entry: _ctx.entry,
    modelValue: _ctx.entry.template,
    "onUpdate:modelValue": _cache[9] || (_cache[9] = $event => _ctx.entry.template = $event),
    position: "dialog",
    class: "rounded border p-2 dark:border-neutral-800",
    size: "sm",
    color: "secondary"
  }, null, 8, ["entry", "modelValue"]), _createElementVNode("div", _hoisted_53, [_cache[35] || (_cache[35] = _createElementVNode("div", {
    class: "absolute inset-0 flex items-center",
    "aria-hidden": "true"
  }, [_createElementVNode("div", {
    class: "w-full border-t border-neutral-300 dark:border-neutral-700"
  })], -1)), _createElementVNode("div", _hoisted_54, [_cache[34] || (_cache[34] = _createElementVNode("span", {
    class: "bg-white pr-2 text-xs uppercase text-neutral-500 dark:bg-black"
  }, " Custom Statuses ", -1)), _createElementVNode("div", _hoisted_55, [_createElementVNode("a", _hoisted_56, [_createElementVNode("button", _hoisted_57, [_createVNode(_component_CircleHelpIcon, {
    size: "20"
  })])])])])]), _createVNode(_component_StatusesSettings, {
    entry: _ctx.entry,
    modelValue: _ctx.entry.statuses,
    "onUpdate:modelValue": _cache[10] || (_cache[10] = $event => _ctx.entry.statuses = $event),
    position: "dialog",
    class: "rounded border p-2 dark:border-neutral-800",
    size: "sm",
    color: "secondary"
  }, null, 8, ["entry", "modelValue"]), _createElementVNode("div", _hoisted_58, [_cache[37] || (_cache[37] = _createElementVNode("div", {
    class: "absolute inset-0 flex items-center",
    "aria-hidden": "true"
  }, [_createElementVNode("div", {
    class: "w-full border-t border-neutral-300 dark:border-neutral-700"
  })], -1)), _createElementVNode("div", _hoisted_59, [_cache[36] || (_cache[36] = _createElementVNode("span", {
    class: "bg-white pr-2 text-xs uppercase text-neutral-500 dark:bg-black"
  }, " Custom Fields ", -1)), _createElementVNode("div", _hoisted_60, [_createElementVNode("a", _hoisted_61, [_createElementVNode("button", _hoisted_62, [_createVNode(_component_CircleHelpIcon, {
    size: "20"
  })])])])])]), _createVNode(_component_CustomFieldsSettings, {
    entry: _ctx.entry,
    modelValue: _ctx.entry.custom_fields,
    "onUpdate:modelValue": _cache[11] || (_cache[11] = $event => _ctx.entry.custom_fields = $event),
    position: "dialog",
    class: "rounded border p-2 dark:border-neutral-800",
    size: "sm",
    color: "secondary"
  }, null, 8, ["entry", "modelValue"])]), _createElementVNode("div", _hoisted_63, [_ctx.entry.completed_at ? (_openBlock(), _createElementBlock("div", _hoisted_64, [_cache[38] || (_cache[38] = _createElementVNode("span", {
    class: "flex items-center"
  }, [_createElementVNode("span", {
    class: "text-sm font-medium text-gray-900 dark:text-neutral-500"
  }, " Completed ")], -1)), _createVNode(_component_CompletedAtComponent, {
    modelValue: _ctx.entry,
    "onUpdate:modelValue": _cache[12] || (_cache[12] = $event => _ctx.entry = $event)
  }, null, 8, ["modelValue"])])) : _createCommentVNode("", true), _createElementVNode("div", _hoisted_65, [_cache[39] || (_cache[39] = _createElementVNode("span", {
    class: "flex items-center"
  }, [_createElementVNode("span", {
    class: "text-sm font-medium text-gray-900 dark:text-neutral-500"
  }, " Updated ")], -1)), _createElementVNode("span", _hoisted_66, _toDisplayString($data.moment.utc(_ctx.entry.updated_at).local().format("YYYY-MM-DD HH:mm:ss")), 1)]), _createElementVNode("div", _hoisted_67, [_cache[40] || (_cache[40] = _createElementVNode("span", {
    class: "flex items-center"
  }, [_createElementVNode("span", {
    class: "text-sm font-medium text-gray-900 dark:text-neutral-500"
  }, " Created ")], -1)), _createElementVNode("span", _hoisted_68, _toDisplayString($data.moment.utc(_ctx.entry.created_at).local().format("YYYY-MM-DD HH:mm:ss")), 1)])]), _cache[41] || (_cache[41] = _createElementVNode("div", {
    class: "h-4"
  }, null, -1)), _createVNode(_component_DeleteComponent, {
    modelValue: _ctx.entry,
    onOnDelete: _cache[13] || (_cache[13] = () => $data.open = false)
  }, null, 8, ["modelValue"])]);
}