import { Routing } from "@/components/mixins/Routing";
import { Markdowning } from "@/components/mixins/Markdowning";
export default {
  inject: {
    output_entry_origin: {
      default: {}
    }
  },
  mixins: [Routing, Markdowning],
  props: {
    modelValue: Object,
    position: String,
    internalSettings: {
      type: Object,
      default: () => ({
        display: {
          label: false,
          settings: false
        }
      })
    }
  },
  data() {
    return {
      entry: this.modelValue
      // links: [],
    };
  },
  watch: {
    modelValue: function () {
      this.entry = this.modelValue;
      // this.loadLinks();
    }
    // "entry.links": function () {
    //   this.loadLinks();
    // },
  },
  // mounted() {
  //   this.loadLinks();
  // },
  computed: {
    linksObjects() {
      return this.links.map(link => {
        const entry = this.$store.getters.entries.find(e => e.id == link.id);
        return entry ? {
          ...entry,
          linkSettings: link.settings
        } : null;
      }).filter(e => e);
    },
    links: {
      get() {
        return this.entry.links;
      },
      set(links) {
        // eslint-disable-next-line vue/no-mutating-props
        this.entry.links = links;
        this.$store.dispatch("push", {
          event: "entry_update",
          params: {
            entry: this.entry
          },
          entry: this.entry
        });
      }
    }
  },
  methods: {
    // loadLinks() {
    //   this.links = this.entry.links
    //     .map((id) => this.$store.getters.entries.find((e) => e.id == id))
    //     .filter((e) => e);
    //   //.filter((e) => e.id != this.$route.params.id);
    // },
    onClick(entry, e) {
      if (!e.shiftKey) {
        this.navigateTo(entry);
      }
    }
  }
};