import { Coloring } from "../../mixins/Coloring";
import { Addon } from "@/components/mixins/Addon";
import { Markdowning } from "@/components/mixins/Markdowning";
export default {
  mixins: [Coloring, Addon, Markdowning],
  data() {
    return {
      entries: []
    };
  },
  mounted() {
    this.entries = this.links?.map(id => this.$store.getters.entries.find(e => e.id == id)).filter(e => e);
  },
  watch: {
    links: {
      handler(links) {
        this.entries = links?.map(id => this.$store.getters.entries.find(e => e.id == id)).filter(e => e);
      }
    }
  },
  computed: {
    links: {
      get() {
        return this.modelValue;
      },
      set(links) {
        this.$emit("update:modelValue", links);
      }
    }
  }
};