import { Scheduling } from "../mixins/Scheduling";
import { Statusing } from "../mixins/Statusing";
export default {
  name: "DatePicker",
  mixins: [Scheduling, Statusing],
  props: {
    modelValue: null,
    entry: Object,
    showRecurrence: {
      type: Boolean,
      default: true
    },
    autoSetOnClick: {
      type: Boolean,
      default: true
    },
    teleport: Boolean,
    showCalendar: Boolean
  },
  data() {
    return {
      cursor: this.modelValue || this.$moment().format("YYYY-MM-DD"),
      recurrenceOptions: [{
        title: "Every Day",
        value: {
          op: "nextXDays",
          x: 1
        }
      }, {
        title: "Every 2 Days",
        value: {
          op: "nextXDays",
          x: 2
        }
      }, {
        title: "2x / Week",
        value: {
          op: "nextXWeeks",
          x: 0.5
        }
      }, {
        title: "Every Week",
        value: {
          op: "nextXWeeks",
          x: 1
        }
      }, {
        title: "Every 2 Weeks",
        value: {
          op: "nextXWeeks",
          x: 2
        }
      }, {
        title: "Every Month",
        value: {
          op: "nextXMonths",
          x: 1
        }
      }, {
        title: "Every 2 Months",
        value: {
          op: "nextXMonths",
          x: 2
        }
      }, {
        title: "Every Quarter",
        value: {
          op: "nextXMonths",
          x: 3
        }
      }, {
        title: "2x / Year",
        value: {
          op: "nextXYears",
          x: 0.5
        }
      }, {
        title: "Every Year",
        value: {
          op: "nextXYears",
          x: 1
        }
      }]
    };
  },
  watch: {
    modelValue: {
      handler() {
        this.cursor = this.modelValue || this.$moment().format("YYYY-MM-DD");
      },
      deep: true
    }
  },
  computed: {
    recurrence: {
      get() {
        return this.entry?.schedule?.recurrence;
      },
      set(value) {
        this.$emit("update:recurrence", value);
      }
    },
    date: {
      get() {
        return this.modelValue || {
          op: null
        };
      },
      set(date) {
        this.$emit("update", date);
        this.$emit("update:modelValue", date);
      }
    }
  }
};